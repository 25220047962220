import React, { useEffect } from "react";

import { Button, CheckboxGroup, Container, Input, TwoColumnLayout } from "@roo-dev/ui-components";

import { PasswordInput } from "../../../../Common/Form/PasswordInput";
import { PhoneInput } from "../../../../Common/Form/PhoneInput";
import { StateSelect } from "../../../../Common/Form/StateSelect";
import { TravelDistanceSlider } from "../../../../Common/Form/TravelDistanceSlider";
import { Event, EventTracker } from "../../../../tracking/service/EventTracker";
import { StyledCheckboxGroupWrapper } from "../../../../vetTechDashboard/components/styles";
import { EventSignUpFooter } from "../common/EventSignUpFooter";
import { ButtonGroup, FooterGroup } from "../styles";

import { useFormContext } from "./useFormContext";

export const VetSignUp = (_props: { booth: boolean; eventSlug: string }) => {
  const { eventSlug } = _props;
  const {
    form: {
      firstName,
      lastName,
      email,
      password,
      phoneNumber,
      zipcode,
      reenterZipcode,
      travelDistance,
      licenseState,
      licenseNumber,
      yearsOfPractice,
      preferredAreasOfPractice,
      preferredProcedures,
    },
    onCancel,
    onChange,
    onSave,
    errors,
    loading,
  } = useFormContext();

  useEffect(() => {
    if (!eventSlug) {
      return;
    }
    EventTracker.send(
      Event.Name.VET_CONFERENCE_EVENT_REGISTRATION_START,
      Event.Type.PAGE_VIEW,
      Event.Entity.EVENT,
      null,
      { eventSlug }
    );
  }, [eventSlug]);

  return (
    <Container data-testid="vetContainer">
      <TwoColumnLayout>
        <Input
          label="First Name"
          value={firstName}
          onChange={(e) => onChange("firstName", e.target.value)}
          error={errors["firstName"]}
          name="firstName"
        />
        <Input
          label="Last Name"
          value={lastName}
          onChange={(e) => onChange("lastName", e.target.value)}
          error={errors["lastName"]}
          name="lastName"
        />
        <Input
          label="Email"
          type="email"
          value={email}
          onChange={(e) => onChange("email", e.target.value)}
          error={errors["email"]}
          name="email"
        />
        <PasswordInput
          password={password}
          error={errors["password"]}
          onChange={(value) => onChange("password", value)}
        />
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridColumnGap: "1rem" }}>
          <Input
            label="Desired work ZIP code"
            inputMode="numeric"
            value={zipcode}
            maxLength={5}
            onChange={(e) => onChange("zipcode", e.target.value)}
            error={errors["zipcode"]}
            name="zipcode"
          />
          <Input
            label="Re-enter ZIP code"
            inputMode="numeric"
            value={reenterZipcode}
            maxLength={5}
            onChange={(e) => onChange("reenterZipcode", e.target.value)}
            error={errors["reenterZipcode"]}
            name="reenterZipcode"
          />
        </div>
        <TravelDistanceSlider
          value={travelDistance}
          tooltip={{ placement: "right" }}
          onChange={(value: number) => onChange("travelDistance", value)}
        />
        <StateSelect
          label="Licensed state"
          value={licenseState}
          onChange={(_option, value) => {
            onChange("licenseState", value);
          }}
          error={errors["licenseState"]}
          testId="licenseState"
          searchable
        />
        <Input
          label="License number"
          value={licenseNumber}
          onChange={(e) => onChange("licenseNumber", e.target.value)}
          error={errors["licenseNumber"]}
          name="licenseNumber"
          data-testid="vetLicenseNumber"
        />
        <PhoneInput
          label="Phone number"
          value={phoneNumber}
          error={errors["phoneNumber"]}
          placeholder="Enter your mobile number"
          onChange={(phone) => onChange("phoneNumber", phone?.toString())}
          id="mobileInput"
        />
        <Input
          label="Years of practice"
          type="number"
          value={yearsOfPractice}
          onChange={(e) => onChange("yearsOfPractice", e.target.valueAsNumber)}
          error={errors["yearsOfPractice"]}
          id="yearsOfPractice"
        />
        <StyledCheckboxGroupWrapper>
          <CheckboxGroup
            label="My preferred area(s) of practice (check any that apply):"
            onChange={(_e, updatedCheckboxes) => {
              onChange("preferredAreasOfPractice", updatedCheckboxes);
            }}
            checkboxes={preferredAreasOfPractice}
            error={errors["preferredAreasOfPractice"]}
            name="preferredAreasOfPractice"
          />
        </StyledCheckboxGroupWrapper>
        <StyledCheckboxGroupWrapper>
          <CheckboxGroup
            label="What types of procedures are you comfortable doing? (optional)"
            onChange={(_e, updated) => {
              onChange("preferredProcedures", updated);
            }}
            checkboxes={preferredProcedures}
            error={errors["preferredProcedures"]}
            name="preferredProcedures"
          />
        </StyledCheckboxGroupWrapper>
      </TwoColumnLayout>
      <FooterGroup>
        <EventSignUpFooter />
        <ButtonGroup>
          <Button
            title="Create My Account"
            buttonType="primary"
            onClick={onSave}
            loading={loading}
            testId="createAccount"
          />
          <Button title="Cancel" onClick={onCancel} loading={loading} testId="cancel" />
        </ButtonGroup>
      </FooterGroup>
    </Container>
  );
};
