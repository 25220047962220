import * as Sentry from "@sentry/react";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import Cookies from "js-cookie";
import jwt from "jsonwebtoken";
import posthog from "posthog-js";

export class AuthUtility {
  /*
   * Retrieves the Cognito authorization context from the JWT in local storage
   */
  public static async getAuthContext(): Promise<AuthContext> {
    try {
      const session = await this.getCurrentSession();
      const authToken = session?.getIdToken()?.getJwtToken();

      if (authToken) return this.getAuthContextFromToken(authToken);
    } catch (error: unknown) {
      Sentry.captureException(error, {
        extra: { context: "AuthUtility : getAuthContext error. Unable to parse Cognito idToken." },
      });
    }

    return null;
  }

  public static getAuthContextFromToken(token: string): AuthContext {
    const decodedAuthToken = jwt.decode(token, { complete: true });
    const authPayload: any = decodedAuthToken?.payload;

    if (authPayload) {
      return {
        cognitoId: authPayload["sub"],
        userTypeId: Number(authPayload["custom:usertype"]),
        email: authPayload["email"],
      } as AuthContext;
    }
    throw new Error("Unable to parse auth token.");
  }

  public static async getCurrentSession(): Promise<CognitoUserSession> {
    let session = null;
    try {
      session = await Auth.currentSession();
    } catch (err) {
      // User isn't authenticated
    }
    return session;
  }

  /*
   * Primarily use case is for transitioning back-and-forth between users via superuser authentication.
   * Clears all the localStorage values for the previously logged in user, but retains the Cognito
   * authentiacation tokens (to keep the user logged in).
   */
  public static clearPreviousSession() {
    for (const item in localStorage) {
      if (item.startsWith("Cognito") || item === "jwtToken" || item === "isAuthorisedUser") {
        continue;
      }
      localStorage.removeItem(item);
    }

    sessionStorage.clear();
    posthog.reset();
  }

  public static isAuthorisedUser() {
    const isAuthorisedUser = localStorage.getItem("isAuthorisedUser") === "true";
    if (!Cookies.get("rauthorized")) {
      AuthUtility.setRooAuthorizedCookie(isAuthorisedUser);
    }
    return isAuthorisedUser;
  }

  public static setIsAuthorisedUser(isAuthorised: boolean) {
    localStorage.setItem("isAuthorisedUser", isAuthorised ? "true" : "false");
    AuthUtility.setRooAuthorizedCookie(isAuthorised);
  }

  private static setRooAuthorizedCookie(isAuthorisedUser: boolean) {
    Cookies.set("rauthorized", isAuthorisedUser ? "true" : "false", {
      expires: 3650, // Match the Cognito refresh token expiration
      domain: "roo.vet",
      secure: true,
    });
  }
}

export interface AuthContext {
  cognitoId: string;
  userTypeId: number;
  email: string;
}
