import * as ActionTypes from "../chatConstants";
import { ChatHistoryArr, ConvoInfoResp } from "../types/chatType";

export type ActionModule = typeof import("../chatConstants");

type InitialState = {
  chatHistory: ChatHistoryArr;
  chatHistoryAPIFail: boolean;
  convoInfo: ConvoInfoResp;
  isChatWindowLoading: boolean;
  isChatUnavailable: boolean;
  chatWindowLoadingMessage: string;
  isCreateConvoLoading: boolean;
  createConvoLoadingMsg: string;
  bannerData?: [];
  isChatPanelOpen: boolean;
  shiftFetchParams: {
    shiftId?: string | number;
    hospitalId?: string | number;
    contractorUserId?: string | number;
    vetId?: string | number;
    techId?: string | number;
  };
};

const initialState: InitialState = {
  chatHistory: [],
  chatHistoryAPIFail: false,
  convoInfo: {} as ConvoInfoResp,
  isChatWindowLoading: false,
  chatWindowLoadingMessage: "",
  isChatUnavailable: false,
  isCreateConvoLoading: false,
  createConvoLoadingMsg: "",
  bannerData: null,
  isChatPanelOpen: false,
  shiftFetchParams: {},
};

type ChatReducerActionType = {
  type: ActionModule[keyof ActionModule];
  payload: any;
};

// eslint-disable-next-line default-param-last
const chatReducer = (state = initialState, action: ChatReducerActionType) => {
  switch (action.type) {
    case ActionTypes.START_CHAT_WINDOW_LOADING: {
      return {
        ...state,
        isChatWindowLoading: true,
        chatWindowLoadingMessage: action.payload.message,
      };
    }
    case ActionTypes.STOP_CHAT_WINDOW_LOADING: {
      return {
        ...state,
        isChatWindowLoading: false,
        chatWindowLoadingMessage: "",
      };
    }
    case ActionTypes.TOGGLE_CHAT: {
      return {
        ...state,
        isChatUnavailable: action.payload,
      };
    }
    case ActionTypes.CREATE_CONVERSATION_LOADING: {
      return {
        ...state,
        isCreateConvoLoading: true,
        createConvoLoadingMsg: action.payload.message,
      };
    }
    case ActionTypes.STOP_CREATE_CONVERSATION_LOADING: {
      return {
        ...state,
        isCreateConvoLoading: false,
        createConvoLoadingMsg: "",
      };
    }
    case ActionTypes.GET_CONVO_INFO:
      return {
        ...state,
        convoInfo: {},
      };
    case ActionTypes.GET_CONVO_INFO_SUCCESS:
      return {
        ...state,
        convoInfo: action.payload,
      };
    case ActionTypes.GET_CHAT_HISTORY_SUCCESS:
      return {
        ...state,
        chatHistory: action.payload,
      };
    case ActionTypes.GET_CHAT_HISTORY_FAIL:
      return {
        ...state,
        chatHistory: [] as any,
        chatHistoryAPIFail: true,
      };
    case ActionTypes.GET_CONVO_INFO_FAIL:
      return {
        ...state,
        isChatUnavailable: Boolean(action.payload.isUnexpectedError),
      };
    case ActionTypes.CHAT_RESET_STATE:
      return {
        ...initialState,
        bannerData: state.bannerData,
      };
    case ActionTypes.CREATE_CONVERSATION_SUCCESS: {
      return {
        ...state,
        convoInfo: {
          ...action.payload?.createdConvo,
          participants: action.payload.createdParticipants,
        },
      };
    }
    case ActionTypes.CREATE_CONVERSATION_FAIL: {
      return {
        ...state,
        isChatUnavailable: true,
      };
    }
    case ActionTypes.FETCH_CHAT_BANNERS_DATA_SUCCESS: {
      return {
        ...state,
        bannerData: action.payload,
      };
    }
    case ActionTypes.OPEN_CHAT_PANEL: {
      return {
        ...state,
        isChatPanelOpen: true,
        shiftFetchParams: action.payload,
      };
    }
    case ActionTypes.CLOSE_CHAT_PANEL: {
      return {
        ...state,
        isChatPanelOpen: false,
        shiftFetchParams: {},
        convoInfo: {},
        chatHistory: [],
        isChatUnavailable: false,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

// eslint-disable-next-line no-restricted-exports
export default chatReducer;
