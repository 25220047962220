import styled from "styled-components";

import {
  Link,
  Text,
  blueBackground,
  grayLightest,
  redBase,
  redLight400,
  spacingL,
  spacingM,
  spacingS,
  spacingXL,
  spacingXS,
  tealDark700,
  whiteBackground,
} from "@roo-dev/ui-components";

export const NavItem = styled.div`
  padding: 0 4px !important;
`;

export const Messages = styled.div`
  max-height: 520px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const Message = styled.div`
  display: flex;
  max-width: 448px;
  width: 100vw;
  gap: ${spacingM};
  border-bottom: 1px solid ${grayLightest};
  padding: ${spacingS} ${spacingXL};
  cursor: pointer;
  align-items: center;
  &:last-of-type {
    border-bottom: none;
  }
`;

export const MessageContainer = styled.div`
  flex: 1;
`;

export const MessageHeading = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MessageText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const UnreadMessageCount = styled.div`
  height: 24px;
  width: 24px;
  background: ${redLight400};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const IconContainer = styled.div<{ open: boolean }>`
  height: 36px;
  width: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${blueBackground};
  cursor: pointer;
`;

export const IconInner = styled.div`
  position: relative;
`;

export const TotalUnreadCount = styled.div<{ count: number }>`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: ${redBase};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
  transition: opacity 200ms ease-in-out;
  opacity: ${(props) => (props.count ? "1" : "0")};
`;

export const StateContainer = styled.div`
  height: 400px;
  width: 448px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${spacingS};
`;

// Feature call out
export const FeatureCallOutContainer = styled.div`
  max-height: 520px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  width: 236px;
  display: flex;
  flex-direction: column;
`;

export const NewChatHeaderText = styled(Text)`
  font-size: 12px;
  font-weight: 500;
  color: ${tealDark700};
`;

export const NewChatHeaderContainer = styled.div`
  gap: ${spacingS};
  padding: ${spacingXS} ${spacingL};
  display: flex;
`;

export const NewChatBodyContainer = styled.div`
  padding: 12px 16px;
  background-color: ${whiteBackground};
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
`;

export const LinkClick = styled(Link)`
  margin-right: auto;
  margin-left: auto;
`;
