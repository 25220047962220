import * as C from "../constants";

export enum NavReasonModalID {
  SHIFT_PRICING_SURVEY = "SHIFT_PRICING_SURVEY",
}

interface ShiftPricingSurveyModal {
  id: NavReasonModalID.SHIFT_PRICING_SURVEY;
  fields: {
    hospitalId: number;
    shiftType: "vet" | "tech";
  };
}

// for each new modal, add respective type
export type NavReasonModal = ShiftPricingSurveyModal;

export interface NavigationReasonData {
  originalPath: string;
  modal: NavReasonModal;
}

const initialState: { data: NavigationReasonData | null } = {
  data: null,
};

const NavigationReasonReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: { payload: NavigationReasonData; type: string }
) => {
  switch (action.type) {
    case C.SET_NAVIGATION_REASON_MODAL:
      return {
        ...state,
        data: action.payload,
      };
    case C.CLEAR_NAVIGATION_REASON_MODAL:
      return {
        ...state,
        data: null,
      };
    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default NavigationReasonReducer;
