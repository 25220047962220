import * as Sentry from "@sentry/react";
import "isomorphic-fetch";
import { call, put, takeEvery } from "redux-saga/effects";

import { APIResponse } from "../../api/common/response";
import { SET_SESSION_REQUEST } from "../../constants/Login";
import * as C from "../../constants/registerVetConstants";
import "../../helpers/InterceptFetch";
import { VetSubmitProfileRequestAction } from "../Actions/RegisterVetActions";

export function* saveProfileLastStepAsync(action: VetSubmitProfileRequestAction) {
  try {
    const vetData = {
      ...action.payload,
      vetId: action.payload.vetId,
    };

    const response: Response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/vet/updateVetProfile",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(vetData),
      }
    );
    const data: APIResponse<{}> = yield response.json();
    if (data.status_code === 200) {
      yield put({ type: C.VET_SUBMIT_PROFILE_SUCCESS, payload: action.payload });
      yield put({ type: SET_SESSION_REQUEST });
    } else {
      yield put({
        type: C.VET_SUBMIT_PROFILE_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while submitting vet profile: " } });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootRegisterVetSaga() {
  yield takeEvery(C.VET_SUBMIT_PROFILE_REQUEST, saveProfileLastStepAsync);
}
