export const GET_EXTERNSHIP_LIST = "GET_EXTERNSHIP_LIST";
export const GET_EXTERNSHIP_LIST_SUCCESS = "GET_EXTERNSHIP_LIST_SUCCESS";
export const GET_EXTERNSHIP_LIST_FAIL = "GET_EXTERNSHIP_LIST_FAIL";

export const CLEAR_GET_EXTERNSHIP_LIST_SUCCESS_FLAG = "CLEAR_GET_EXTERNSHIP_LIST_SUCCESS_FLAG";

export const INITIALIZE_ADMIN_INTERNSHIP_STATE = "INITIALIZE_ADMIN_INTERNSHIP_STATE";

export const UPDATE_EXTERNSHIP_REQUEST = "UPDATE_EXTERNSHIP_REQUEST";
export const UPDATE_EXTERNSHIP_SUCCESS = "UPDATE_EXTERNSHIP_SUCCESS";
export const UPDATE_EXTERNSHIP_FAIL = "UPDATE_EXTERNSHIP_FAIL";

export const DELETE_EXTERNSHIP_REQUEST = "DELETE_EXTERNSHIP_REQUEST";
export const DELETE_EXTERNSHIP_SUCCESS = "DELETE_EXTERNSHIP_SUCCESS";
export const DELETE_EXTERNSHIP_FAIL = "DELETE_EXTERNSHIP_FAIL";

export const CLEAR_EXTERNSHIP_FLAG = "CLEAR_EXTERNSHIP_FLAG";
