import * as Sentry from "@sentry/react";
import "isomorphic-fetch";
import { call, put, takeEvery } from "redux-saga/effects";
import "../../../helpers/InterceptFetch";

import { showToast } from "../../../Common/Toasts/action/ToastActions";
import { EXCEPTION_MESSAGE } from "../../../constants/errorMessage";
import * as HospitalBillingActionTypes from "../../../constants/hospitalConstants";
import { UNSET_LOADER } from "../../../constants/loader";
import { SET_LOCAL_HOSPITAL_HAS_BILLING_SETUP } from "../../../constants/Login";

export function* addPaymentMethodAsync(action) {
  try {
    const response = yield call(fetch, window.RooConfig.API_URL + "api/hospital/paymentMethod", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({ type: SET_LOCAL_HOSPITAL_HAS_BILLING_SETUP });
      yield put({
        type: HospitalBillingActionTypes.ADD_PAYMENT_METHOD_SUCCESS,
      });
    } else {
      yield put({
        type: HospitalBillingActionTypes.ADD_PAYMENT_METHOD_FAIL,
        payload: { status: data.status, message: data.data },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while adding payment method: " } });
    yield put({ type: HospitalBillingActionTypes.ADD_PAYMENT_METHOD_FAIL });
  }
}

export function* getPlaidTokenAsync(action) {
  try {
    const { hospitalId } = action.payload;
    const redirectURI = window.location.href;
    const response = yield call(fetch, window.RooConfig.API_URL + "api/hospital/getPlaidToken", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ hospitalId, redirectURI }),
    });

    const data = yield response.json();

    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: HospitalBillingActionTypes.GET_PLAID_TOKEN_SUCCESS,
        payload: data.data.data,
      });
    } else {
      yield put({
        type: HospitalBillingActionTypes.GET_PLAID_TOKEN_FAIL,
        payload: { status: data.status, message: data.data },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting plaid token: " } });
    yield put({ type: HospitalBillingActionTypes.GET_PLAID_TOKEN_FAIL });
  }
}

function* saveBillingEmailsAsync(action) {
  try {
    const payloadBillingEmails = action.payload?.billingEmails ?? [];
    let billingEmailsArray = [];
    if (Array.isArray(payloadBillingEmails)) {
      billingEmailsArray = payloadBillingEmails;
    } else if (typeof payloadBillingEmails === "string") {
      billingEmailsArray = payloadBillingEmails.split(",");
    }

    const response = yield call(fetch, window.RooConfig.API_URL + "api/hospital/billingEmails", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...action.payload, billingEmails: billingEmailsArray }),
    });

    const data = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: HospitalBillingActionTypes.SAVE_BILLING_EMAILS_SUCCESS,
      });
    } else if (data.status_code === 400 || !data.status_code) {
      yield put(
        showToast({
          variant: "error",
          message: "Could not update billing emails",
          description: data.message,
        })
      );
      yield put({
        type: HospitalBillingActionTypes.SAVE_BILLING_EMAILS_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while saving billing emails: " } });
    yield put({
      type: HospitalBillingActionTypes.SAVE_BILLING_EMAILS_FAIL,
      payload: { status: error.status_code, message: EXCEPTION_MESSAGE },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootHospitalSaga() {
  yield takeEvery(HospitalBillingActionTypes.GET_PLAID_TOKEN, getPlaidTokenAsync);
  yield takeEvery(HospitalBillingActionTypes.ADD_PAYMENT_METHOD_REQUEST, addPaymentMethodAsync);
  yield takeEvery(HospitalBillingActionTypes.SAVE_BILLING_EMAILS, saveBillingEmailsAsync);
}
