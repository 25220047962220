import { BATCH_UPLOAD_HOSPITAL_FAILED } from "../../constants/confirmMessage";
import * as C from "../../constants/enterpriseConstants";
import { BatchUploadAction } from "../actions/batchUploadActions";

const initialFlags: {
  isHospitalBatchUploadSucceeded: boolean;
  isCheckedForDuplications: boolean;
  failedToUploadHospitals: boolean;
  hospitalUploadData: { hospitalsWithDuplicates?: any[]; hospitalsWithoutDuplicates?: any[] };
  numberOfUploadedHospitals?: number;
} = {
  isHospitalBatchUploadSucceeded: false,
  isCheckedForDuplications: false,
  failedToUploadHospitals: false,
  hospitalUploadData: {},
};

const initialState = {
  ...initialFlags,
};

// eslint-disable-next-line default-param-last
const bulkUploadReducer = (state = initialState, action: BatchUploadAction) => {
  switch (action.type) {
    case C.BATCH_UPLOAD_HOSPITALS_REQUEST:
      return {
        ...state,
        isHospitalBatchUploadSucceeded: false,
        isCheckedForDuplications: false,
        failedToUploadHospitals: false,
      };

    case C.BATCH_UPLOAD_HOSPITALS_SUCCESS:
      return {
        ...state,
        isHospitalBatchUploadSucceeded: true,
        numberOfUploadedHospitals: action.payload,
      };

    case C.BATCH_UPLOAD_HOSPITALS_FAIL:
      return {
        ...state,
        failedToUploadHospitals: true,
        isHospitalBatchUploadSucceeded: false,
        responseMsg: BATCH_UPLOAD_HOSPITAL_FAILED,
      };

    case C.GET_FORMATTED_HOSPITAL_UPLOAD_LIST:
      return {
        ...state,
        isCheckedForDuplications: false,
        hospitalUploadData: {},
      };

    case C.GET_FORMATTED_HOSPITAL_UPLOAD_LIST_SUCCESS:
      return {
        ...state,
        isCheckedForDuplications: true,
        hospitalUploadData: action.payload,
      };

    case C.RESET_BATCH_UPLOAD_FLAGS:
      return {
        ...state,
        ...initialFlags,
      };

    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default bulkUploadReducer;
