import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { useShiftPricingSurvey } from "../../../Hospital/Component/ShiftPricingSurvey/service";
import { ShiftPricingSurveyModal } from "../../../Hospital/Component/ShiftPricingSurvey/ShiftPricingSurveyModal";
import { NavReasonModalID, NavigationReasonData } from "../reducer/NavigationReasonReducer";

export const NavigationReasonContainer = () => {
  const location = useLocation();

  const navReasonData: NavigationReasonData = useSelector(
    (state: { navigationReason: any }) => state.navigationReason.data
  );

  const originalPath = navReasonData?.originalPath;
  const modal = navReasonData?.modal;

  const {
    showShiftPricingSurveyModal,
    setShowShiftPricingSurveyModal,
    onShiftPricingSurveyModalSubmit,
    onShiftPricingSurveyModalDismiss,
    shiftPricingSurveyQuestion,
    shiftPricingSurveyDescription,
    shiftPricingSurveyChoices,
    shiftPricingSurveyAnswer,
    setShiftPricingSurveyAnswer,
    hasSubmittedShiftPricingSurvey,
  } = useShiftPricingSurvey();

  useEffect(() => {
    // display modal upon change in path from original path
    if (originalPath && location.pathname !== originalPath) {
      switch (modal?.id) {
        case NavReasonModalID.SHIFT_PRICING_SURVEY: {
          if (hasSubmittedShiftPricingSurvey || showShiftPricingSurveyModal) {
            break;
          }
          const { hospitalId, shiftType } = modal.fields ?? {};
          setShowShiftPricingSurveyModal(true, { hospitalId, shiftType });
          break;
        }
        default:
          break;
      }
    }
  }, [
    location,
    modal,
    originalPath,
    showShiftPricingSurveyModal,
    hasSubmittedShiftPricingSurvey,
    setShiftPricingSurveyAnswer,
    setShowShiftPricingSurveyModal,
  ]);

  const renderModal = () => {
    switch (modal?.id) {
      case NavReasonModalID.SHIFT_PRICING_SURVEY: {
        const { hospitalId, shiftType } = modal.fields ?? {};
        return (
          <ShiftPricingSurveyModal
            showModal={showShiftPricingSurveyModal}
            surveyQuestion={shiftPricingSurveyQuestion}
            surveyDescription={shiftPricingSurveyDescription}
            surveyChoices={shiftPricingSurveyChoices}
            setSurveyAnswer={setShiftPricingSurveyAnswer}
            primaryButtonProps={{
              title: "Submit",
              onClick: () => {
                if (shiftPricingSurveyAnswer !== "") {
                  onShiftPricingSurveyModalSubmit(shiftPricingSurveyAnswer, hospitalId, shiftType);
                }
              },
              size: "regular",
              disabled: shiftPricingSurveyAnswer === "",
            }}
            onCloseModal={() => onShiftPricingSurveyModalDismiss(hospitalId, shiftType)}
          />
        );
      }
      default:
        return null;
    }
  };

  return <>{renderModal()}</>;
};
