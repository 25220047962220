import { useScript } from "usehooks-ts";

export const ThirdPartyScripts = (): null => {
  useScript("//static.zdassets.com/ekr/snippet.js?key=3fe4a07f-4811-4f40-a39e-1dddc78638f4", {
    removeOnUnmount: false,
    id: "ze-snippet",
  });

  return null;
};
