export const ADD_EXTERNSHIP = "ADD_EXTERNSHIP_REQUEST";
export const ADD_EXTERNSHIP_SUCCESS = "ADD_EXTERNSHIP_SUCCESS";
export const ADD_EXTERNSHIP_FAIL = "ADD_EXTERNSHIP_FAIL";

export const GET_MY_EXTERNSHIPS = "GET_MY_EXTERNSHIPS";
export const GET_MY_EXTERNSHIPS_SUCCESS = "GET_MY_EXTERNSHIPS_SUCCESS";
export const GET_MY_EXTERNSHIPS_FAIL = "GET_MY_EXTERNSHIPS_FAIL";

export const GET_EXTERNSHIP_DETAILS = "GET_EXTERNSHIP_DETAILS";
export const GET_EXTERNSHIP_DETAILS_SUCCESS = "GET_EXTERNSHIP_DETAILS_SUCCESS";
export const GET_EXTERNSHIP_DETAILS_FAIL = "GET_EXTERNSHIP_DETAILS_FAIL";

export const CONTACT_STUDENT_EXTERNSHIP = "CONTACT_STUDENT_EXTERNSHIP";
export const CONTACT_STUDENT_EXTERNSHIP_SUCCESS = "CONTACT_STUDENT_EXTERNSHIP_SUCCESS";
export const CONTACT_STUDENT_EXTERNSHIP_FAIL = "CONTACT_STUDENT_EXTERNSHIP_FAIL";

export const HIRE_STUDENT_EXTERNSHIP = "HIRE_STUDENT_EXTERNSHIP";
export const HIRE_STUDENT_EXTERNSHIP_SUCCESS = "HIRE_STUDENT_EXTERNSHIP_SUCCESS";
export const HIRE_STUDENT_EXTERNSHIP_FAIL = "HIRE_STUDENT_EXTERNSHIP_FAIL";

export const DECLINE_STUDENT_EXTERNSHIP = "DECLINE_STUDENT_EXTERNSHIP";
export const DECLINE_STUDENT_EXTERNSHIP_SUCCESS = "DECLINE_STUDENT_EXTERNSHIP_SUCCESS";
export const DECLINE_STUDENT_EXTERNSHIP_FAIL = "DECLINE_STUDENT_EXTERNSHIP_FAIL";

export const UPDATE_EXTERNSHIP_HOSPITAL_REQUEST = "UPDATE_EXTERNSHIP_HOSPITAL_REQUEST";
export const UPDATE_EXTERNSHIP_HOSPITAL_SUCCESS = "UPDATE_EXTERNSHIP_HOSPITAL_SUCCESS";
export const UPDATE_EXTERNSHIP_HOSPITAL_FAIL = "UPDATE_EXTERNSHIP_HOSPITAL_FAIL";

export const DELETE_EXTERNSHIP_HOSPITAL_REQUEST = "DELETE_EXTERNSHIP_HOSPITAL_REQUEST";
export const DELETE_EXTERNSHIP_HOSPITAL_SUCCESS = "DELETE_EXTERNSHIP_HOSPITAL_SUCCESS";
export const DELETE_EXTERNSHIP_HOSPITAL_FAIL = "DELETE_EXTERNSHIP_HOSPITAL_FAIL";

export const CLEAR_EXTERNSHIP_HOSPITAL_FLAG = "CLEAR_EXTERNSHIP_HOSPITAL_FLAG";
