import * as C from "./constants";

export function setCheckForUnsavedChanges(data: boolean) {
  return {
    type: C.CHECK_FOR_UNSAVED_CHANGES,
    payload: {
      checkForUnsavedChanges: data,
    },
  };
}

export function setContinueFromUnsavedChanges(data: boolean) {
  return {
    type: C.SET_CONTINUE_FROM_UNSAVED_CHANGES,
    payload: {
      continueFromUnsavedChanges: data,
    },
  };
}

export function clearUnsavedChangesState() {
  return {
    type: C.CLEAR_UNSAVED_CHANGES_STATE,
  };
}
