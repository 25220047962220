import * as C from "../../../constants/techConstants";

const initialState = {
  shiftHistory: [],
  isHospitalRatingSubmitted: false,
  totalCount: 0,
  isShowReferralModal: false,
  totalShiftEarning: 0,
  totalReferralBonus: 0,
  totalBonus: 0,
  yearToDateEarning: 0,
  isActionCompleted: false,
  unratedShiftDate: "",
  promoLog: [],
};

// eslint-disable-next-line default-param-last
const techShiftHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case C.TECH_SHIFT_HISTORY_SUCCESS:
      const shifts = action.payload.shiftHistory;
      if (action.payload.isOvertime) {
        // this is used for overtime confirmation page
        shifts[0].overtime = JSON.parse(shifts[0].overtime);
        if (shifts[0].overtime.status == "accepted" || shifts[0].overtime.status == "rejected") {
          shifts[0].overtime.processed = true;
        }
      }
      return {
        ...state,
        shiftHistory: shifts,
        isHospitalRatingSubmitted: false,
        totalCount: action.payload.totalCount,
        totalShiftEarning: action.payload.totalShiftEarning,
        totalReferralBonus: action.payload.totalReferralBonus,
        totalBonus: action.payload.totalBonus,
        yearToDateEarning: action.payload.yearToDateEarning,
        unratedShiftDate: "",
        isActionCompleted: true,
        bonusLog: action.payload.bonusLog,
        referralLog: action.payload.referralLog,
        shiftBonusLog: action.payload.shiftBonusLog,
        promoLog: action.payload.promoLog,
        totalOvertime: action.payload.totalOvertime,
        totalEarnings: action.payload.totalEarnings,
      };
    case C.CLEAR_SHIFT_HISTORY:
    case C.TECH_SHIFT_HISTORY_REQUEST:
    case C.TECH_SHIFT_HISTORY_FAIL:
      return {
        ...state,
        shiftHistory: [],
        isHospitalRatingSubmitted: false,
        totalCount: 0,
        totalShiftEarning: 0,
        totalReferralBonus: 0,
        totalBonus: 0,
        yearToDateEarning: 0,
        totalEarnings: 0,
        isActionCompleted: false,
      };
    case C.SUBMIT_RATE_HOSPITAL_AS_TECH_SUCCESS: {
      return {
        ...state,
        isHospitalRatingSubmitted: true,
      };
    }

    case C.RESET_RATING_SUBMITTED: {
      return {
        ...state,
        isHospitalRatingSubmitted: false,
      };
    }

    case C.SHOW_REFERRAL_MODAL: {
      return {
        ...state,
        isShowReferralModal: true,
      };
    }
    case C.HIDE_REFERRAL_MODAL: {
      return {
        ...state,
        isShowReferralModal: false,
      };
    }

    case C.SET_UNRATED_SHIFT_DATE:
      return {
        ...state,
        unratedShiftDate: action.payload.shiftDate,
      };
    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default techShiftHistoryReducer;
