export const INITIALIZE_TECH_REGISTRATION = "INITIALIZE_TECH_REGISTRATION";

export const CREATE_TECH_ACCOUNT_REQUEST = "CREATE_TECH_ACCOUNT_REQUEST";
export const CREATE_TECH_ACCOUNT_SUCCESS = "CREATE_TECH_ACCOUNT_SUCCESS";
export const CREATE_TECH_ACCOUNT_FAIL = "CREATE_TECH_ACCOUNT_FAIL";

export const SAVE_TECH_BASIC_INFO = "SAVE_TECH_BASIC_INFO";
export const SAVE_TECH_BASIC_INFO_SUCCESS = "SAVE_TECH_BASIC_INFO_SUCCESS";
export const SAVE_TECH_BASIC_INFO_FAIL = "SAVE_TECH_BASIC_INFO_FAIL";

export const SAVE_TECH_EDUCATION_EXP = "SAVE_TECH_EDUCATION_EXP";
export const SAVE_TECH_EDUCATION_EXP_SUCCESS = "SAVE_TECH_EDUCATION_EXP_SUCCESS";
export const SAVE_TECH_EDUCATION_EXP_FAIL = "SAVE_TECH_EDUCATION_EXP_FAIL";

export const SAVE_TECH_EDUCATION = "SAVE_TECH_EDUCATION";
export const SAVE_TECH_EDUCATION_SUCCESS = "SAVE_TECH_EDUCATION_SUCCESS";
export const SAVE_TECH_EDUCATION_FAIL = "SAVE_TECH_EDUCATION_FAIL";

export const SAVE_TECH_EXP = "SAVE_TECH_EXP";
export const SAVE_TECH_EXP_SUCCESS = "SAVE_TECH_EXP_SUCCESS";
export const SAVE_TECH_EXP_FAIL = "SAVE_TECH_EXP_FAIL";

export const SAVE_TECH_GET_TO_KNOW = "SAVE_TECH_GET_TO_KNOW";
export const SAVE_TECH_GET_TO_KNOW_SUCCESS = "SAVE_TECH_GET_TO_KNOW_SUCCESS";
export const SAVE_TECH_GET_TO_KNOW_FAIL = "SAVE_TECH_GET_TO_KNOW_FAIL";

export const SAVE_TECH_PHOTO_UPLOAD = "SAVE_TECH_PHOTO_UPLOAD";
export const SAVE_TECH_PHOTO_UPLOAD_SUCCESS = "SAVE_TECH_PHOTO_UPLOAD_SUCCESS";
export const SAVE_TECH_PHOTO_UPLOAD_FAIL = "SAVE_TECH_PHOTO_UPLOAD_FAIL";

export const COMPLETE_TECH_PROFILE_REQUEST = "COMPLETE_TECH_PROFILE_REQUEST";
export const COMPLETE_TECH_PROFILE_SUCCESS = "COMPLETE_TECH_PROFILE_SUCCESS";
export const COMPLETE_TECH_PROFILE_FAIL = "COMPLETE_TECH_PROFILE_FAIL";

export const SIGN_UP_REQUESTED = "SIGN_UP_REQUESTED";

export const FETCH_STATES_REQUEST = "FETCH_STATES_REQUEST";
export const FETCH_STATES_SUCCESS = "FETCH_STATES_SUCCESS";
export const FETCH_STATES_FAIL = "FETCH_STATES_FAIL";

export const UPLOAD_TECH_IMAGE_REQUEST = "UPLOAD_TECH_IMAGE_REQUEST";
export const UPLOAD_TECH_IMAGE_SUCCESS = "UPLOAD_TECH_IMAGE_SUCCESS";
export const UPLOAD_TECH_IMAGE_FAIL = "UPLOAD_TECH_IMAGE_FAIL";

export const DELETE_TECH_IMAGE_REQUEST = "DELETE_TECH_IMAGE_REQUEST";
export const DELETE_TECH_IMAGE_SUCCESS = "DELETE_TECH_IMAGE_SUCCESS";
export const DELETE_TECH_IMAGE_FAIL = "DELETE_TECH_IMAGE_FAIL";

export const FETCH_TECH_BASIC_INFO = "FETCH_TECH_BASIC_INFO";
export const FETCH_TECH_BASIC_INFO_SUCCESS = "FETCH_TECH_BASIC_INFO_SUCCESS";
export const FETCH_TECH_BASIC_INFO_FAIL = "FETCH_TECH_BASIC_INFO_FAIL";

export const FETCH_TECH_EDUCATION_EXP = "FETCH_TECH_EDUCATION_EXP";
export const FETCH_TECH_EDUCATION_EXP_SUCCESS = "FETCH_TECH_EDUCATION_EXP_SUCCESS";
export const FETCH_TECH_EDUCATION_EXP_FAIL = "FETCH_TECH_EDUCATION_EXP_FAIL";

export const FETCH_TECH_GET_TO_KNOW = "FETCH_TECH_GET_TO_KNOW";
export const FETCH_TECH_GET_TO_KNOW_SUCCESS = "FETCH_TECH_GET_TO_KNOW_SUCCESS";
export const FETCH_TECH_GET_TO_KNOW_FAIL = "FETCH_TECH_GET_TO_KNOW_FAIL";

export const FETCH_TECH_IMAGE_REQUEST = "FETCH_TECH_IMAGE_REQUEST";
export const FETCH_TECH_IMAGE_SUCCESS = "FETCH_TECH_IMAGE_SUCCESS";
export const FETCH_TECH_IMAGE_FAIL = "FETCH_TECH_IMAGE_FAIL";

export const SAVE_TECH_EDUCATION_EXP_REDUCER = "SAVE_TECH_EDUCATION_EXP_REDUCER";
export const SAVE_TECH_EDUCATION_REDUCER = "SAVE_TECH_EDUCATION_REDUCER";
export const SAVE_TECH_EXP_REDUCER = "SAVE_TECH_EXP_REDUCER";
export const FETCH_TECH_EDUCATION_EXP_REDUCER = "FETCH_TECH_EDUCATION_EXP_REDUCER";

export const SAVE_TECH_GET_TO_KNOW_REDUCER = "SAVE_TECH_GET_TO_KNOW_REDUCER";
export const FETCH_TECH_GET_TO_KNOW_REDUCER = "FETCH_TECH_GET_TO_KNOW_REDUCER";

export const SAVE_TECH_PHOTO_REDUCER = "SAVE_TECH_PHOTO_REDUCER";
export const FETCH_TECH_PHOTO_REDUCER = "FETCH_TECH_PHOTO_REDUCER";

export const CLEAR_TECH_LOCAL_STORAGE = "CLEAR_TECH_LOCAL_STORAGE";

export const DELETE_TECH_RESUME_SUCCESS = "DELETE_TECH_RESUME_SUCCESS";
export const DELETE_TECH_RESUME_REQUEST = "DELETE_TECH_RESUME_REQUEST";
export const DELETE_TECH_RESUME_FAIL = "DELETE_TECH_RESUME_FAIL";

export const UPLOAD_TECH_RESUME_FAIL = "UPLOAD_TECH_RESUME_FAIL";
export const UPLOAD_TECH_RESUME_SUCCESS = "UPLOAD_TECH_RESUME_SUCCESS";
export const UPLOAD_TECH_RESUME_REQUEST = "UPLOAD_TECH_RESUME_REQUEST";

export const FETCH_TECH_HIREFLIX_LINK_REQUEST = "FETCH_TECH_HIREFLIX_LINK_REQUEST";
export const FETCH_TECH_HIREFLIX_LINK_SUCCESS = "FETCH_TECH_HIREFLIX_LINK_SUCCESS";
export const FETCH_TECH_HIREFLIX_LINK_FAIL = "FETCH_TECH_HIREFLIX_LINK_FAIL";

export const CLEAR_EXP_FLAG = "CLEAR_EXP_FLAG";
