import React from "react";
import { Route, RouteProps, useLocation } from "react-router-dom";

import { AuthUtility } from "../helpers/AuthUtility";

export interface ProtectedRouteProps<C extends React.ComponentType<any>> extends RouteProps {
  component: C;
  componentProps?: React.ComponentProps<C>;
}

const ProtectedRoute = <C extends React.ComponentType<any>>({
  component: Component,
  componentProps,
  ...routeProps
}: ProtectedRouteProps<C>) => {
  const { pathname, search } = useLocation();

  return (
    <Route
      {...routeProps}
      render={(routeComponentProps) => {
        const isAuthorisedUser = AuthUtility.isAuthorisedUser();

        if (!isAuthorisedUser) {
          const redirectUrl = pathname + search;

          if (
            localStorage.getItem("redirectUrl") !== redirectUrl &&
            !redirectUrl.startsWith("/login")
          ) {
            localStorage.setItem("redirectUrl", redirectUrl);
          }

          window.location.replace(`/login/${new Date().getTime()}`);
          return null;
        }

        const props = {
          ...componentProps,
          ...routeComponentProps,
        } as React.ComponentProps<C>;

        return <Component {...props} />;
      }}
    />
  );
};

// eslint-disable-next-line no-restricted-exports
export default ProtectedRoute;
