import * as C from "../../../constants/techConstants";

const initialState = {
  onboardingInfo: [],
  isLoaded: false,
};

// eslint-disable-next-line default-param-last
const techOnboardingReducer = (state = initialState, action) => {
  switch (action.type) {
    case C.GET_TECH_ONBOARDING_INFO_SUCCESS: {
      return {
        ...state,
        onboardingInfo: action.payload.data,
        isLoaded: true,
      };
    }
    case C.GET_TECH_ONBOARDING_INFO_FAIL: {
      return {
        ...state,
        onboardingInfo: [],
        isLoaded: false,
      };
    }
    case C.UPDATE_TECH_SHOW_ONBOARDING_SUCCESS: {
      return {
        ...state,
        isShowTechReferralModal: true,
      };
    }
    case C.CLOSE_TECH_REFERRAL_MODAL: {
      return {
        ...state,
        isShowTechReferralModal: false,
      };
    }
    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default techOnboardingReducer;
