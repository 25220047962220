export const INITIALIZE_VET_DASHBOARD = "INITIALIZE_VET_DASHBOARD";

export const SEARCH_SHIFT_REQUEST = "SEARCH_SHIFT_REQUEST";
export const SEARCH_SHIFT_SUCCESS = "SEARCH_SHIFT_SUCCESS";
export const SEARCH_SHIFT_FAIL = "SEARCH_SHIFT_FAIL";

export const GET_LINKED_SHIFT_REQUEST = "GET_LINKED_SHIFT_REQUEST";
export const GET_LINKED_SHIFT_SUCCESS = "GET_LINKED_SHIFT_SUCCESS";
export const GET_LINKED_SHIFT_FAIL = "GET_LINKED_SHIFT_FAIL";

export const VET_REQUEST_SHIFT_REQUEST = "VET_REQUEST_SHIFT_REQUEST";
export const VET_REQUEST_SHIFT_SUCCESS = "VET_REQUEST_SHIFT_SUCCESS";
export const VET_REQUEST_SHIFT_NOT_AVAILABLE = "VET_REQUEST_SHIFT_NOT_AVAILABLE";
export const VET_REQUEST_SHIFT_FAIL = "VET_REQUEST_SHIFT_FAIL";

export const SET_VET_DASHBOARD_CALENDAR_VIEW = "SET_VET_DASHBOARD_CALENDAR_VIEW";
export const SET_VET_DASHBOARD_GRID_VIEW = "SET_VET_DASHBOARD_GRID_VIEW";
export const SET_VET_DASHBOARD_MAP_VIEW = "SET_VET_DASHBOARD_MAP_VIEW";
export const SET_VET_DASHBOARD_RECS_VIEW = "SET_VET_DASHBOARD_RECS_VIEW";

export const FETCH_SHIFT_RECOMMENDATIONS = "FETCH_SHIFT_RECOMMENDATIONS";
export const GET_SHIFT_RECOMMENDATIONS_SUCCESS = "GET_SHIFT_RECOMMENDATIONS_SUCCESS";
export const GET_SHIFT_RECOMMENDATIONS_FAIL = "GET_SHIFT_RECOMMENDATIONS_FAIL";

export const FETCH_HOSPITAL_VIEW_PROFILE_REQUEST = "FETCH_HOSPITAL_VIEW_PROFILE_REQUEST";
export const FETCH_HOSPITAL_VIEW_PROFILE_SUCCESS = "FETCH_HOSPITAL_VIEW_PROFILE_SUCCESS";
export const FETCH_HOSPITAL_VIEW_PROFILE_FAIL = "FETCH_HOSPITAL_VIEW_PROFILE_FAIL";

export const FETCH_HOSPITAL_AVAILABLE_SHIFT_REQUEST = "FETCH_HOSPITAL_AVAILABLE_SHIFT_REQUEST";
export const FETCH_HOSPITAL_AVAILABLE_SHIFT_SUCCESS = "FETCH_HOSPITAL_AVAILABLE_SHIFT_SUCCESS";
export const FETCH_HOSPITAL_AVAILABLE_SHIFT_FAIL = "FETCH_HOSPITAL_AVAILABLE_SHIFT_FAIL";

export const REQUEST_AVAILABLE_SHIFT_REQUEST = "REQUEST_AVAILABLE_SHIFT_REQUEST";
export const REQUEST_AVAILABLE_SHIFT_SUCCESS = "REQUEST_AVAILABLE_SHIFT_SUCCESS";
export const REQUEST_AVAILABLE_SHIFT_FAIL = "REQUEST_AVAILABLE_SHIFT_FAIL";
export const CLOSE_SHIFT_NOT_AVAILABLE_MODAL = "CLOSE_SHIFT_NOT_AVAILABLE_MODAL";

export const CHECK_VET_LICENSE_REQUEST = "CHECK_VET_LICENSE_REQUEST";
export const CHECK_VET_LICENSE_SUCCESS = "CHECK_VET_LICENSE_SUCCESS";
export const CHECK_VET_LICENSE_FAIL = "CHECK_VET_LICENSE_FAIL";

export const HIDE_INCOMPLETE_LICENSE_MODAL = "HIDE_INCOMPLETE_LICENSE_MODAL";
export const SHOW_INCOMPLETE_LICENSE_MODAL = "SHOW_INCOMPLETE_LICENSE_MODAL";

export const SHOW_FIRST_TIMER_MODAL = "SHOW_FIRST_TIMER_MODAL";
export const HIDE_FIRST_TIMER_MODAL = "HIDE_FIRST_TIMER_MODAL";

export const SHOW_FIRST_TIMER_MODAL_NO_SKIP = "SHOW_FIRST_TIMER_MODAL_NO_SKIP";

export const FETCH_VET_FIRST_TIMER_INFO_REQUEST = "FETCH_VET_FIRST_TIMER_INFO_REQUEST";
export const FETCH_VET_FIRST_TIMER_INFO_SUCCESS = "FETCH_VET_FIRST_TIMER_INFO_SUCCESS";
export const FETCH_VET_FIRST_TIMER_INFO_FAIL = "FETCH_VET_FIRST_TIMER_INFO_FAIL";

export const UPDATE_VET_FIRST_TIMER_INFO_REQUEST = "UPDATE_VET_FIRST_TIMER_INFO_REQUEST";
export const UPDATE_VET_FIRST_TIMER_INFO_SUCCESS = "UPDATE_VET_FIRST_TIMER_INFO_SUCCESS";
export const UPDATE_VET_FIRST_TIMER_INFO_FAIL = "UPDATE_VET_FIRST_TIMER_INFO_FAIL";

// export const UPDATE_VET_BANKING_INFO_REQUEST = "UPDATE_VET_BANKING_INFO_REQUEST";
// export const UPDATE_VET_BANKING_INFO_SUCCESS = "UPDATE_VET_BANKING_INFO_SUCCESS";
// export const UPDATE_VET_BANKING_INFO_FAIL = "UPDATE_VET_BANKING_INFO_FAIL";

export const VET_SHIFT_HISTORY_REQUEST = "VET_SHIFT_HISTORY_REQUEST";
export const VET_SHIFT_HISTORY_SUCCESS = "VET_SHIFT_HISTORY_SUCCESS";
export const VET_SHIFT_HISTORY_FAIL = "VET_SHIFT_HISTORY_FAIL";

export const SUBMIT_RATE_HOSPITAL_AS_VET = "SUBMIT_RATE_HOSPITAL_AS_VET";
export const SUBMIT_RATE_HOSPITAL_AS_VET_SUCCESS = "SUBMIT_RATE_HOSPITAL_AS_VET_SUCCESS";
export const SUBMIT_RATE_HOSPITAL_AS_VET_FAIL = "SUBMIT_RATE_HOSPITAL_AS_VET_FAIL";

export const SIGN_NOW = "SIGN_NOW";

export const GET_PENDING_VET_RATINGS_REQUEST = "GET_PENDING_VET_RATINGS_REQUEST";
export const GET_PENDING_VET_RATINGS_SUCCESS = "GET_PENDING_VET_RATINGS_SUCCESS";
export const GET_PENDING_VET_RATINGS_FAIL = "GET_PENDING_VET_RATINGS_FAIL";

export const UPDATE_CURRENT_HOSPITAL_RATING_BY_VET_INDEX =
  "UPDATE_CURRENT_HOSPITAL_RATING_BY_VET_INDEX";

export const RESET_RATING_SUBMITTED = "RESET_RATING_SUBMITTED";

export const SET_UNRATED_VET_SHIFT_DATE = "SET_UNRATED_VET_SHIFT_DATE";

// export const SEARCH_VET_HOSPITAL_REQUEST = "SEARCH_VET_HOSPITAL_REQUEST";
// export const SEARCH_VET_HOSPITAL_SUCCESS = "SEARCH_VET_HOSPITAL_SUCCESS";
// export const SEARCH_VET_HOSPITAL_FAIL = "SEARCH_VET_HOSPITAL_FAIL";

// export const SIGN_NOW_LINK_REQUEST = "SIGN_NOW_LINK_REQUEST";
// export const SIGN_NOW_LINK_SUCCESS = "SIGN_NOW_LINK_SUCCESS";
// export const SIGN_NOW_LINK_FAIL = "SIGN_NOW_LINK_FAIL";

export const SUBMIT_VET_W9_REQUEST = "SUBMIT_VET_W9_REQUEST";
export const SUBMIT_VET_W9_SUCCESS = "SUBMIT_VET_W9_SUCCESS";
export const SUBMIT_VET_W9_FAIL = "SUBMIT_VET_W9_FAIL";
export const HIDE_VET_W9_ERROR = "HIDE_VET_W9_ERROR";

export const SAVE_SEARCH_SHIFT_PARAMETERS = "SAVE_SEARCH_SHIFT_PARAMETERS";

export const SAVE_SHARED_SEARCH = "SAVE_SHARED_SEARCH";

export const GET_VET_SETTING_INFORMATION = "GET_VET_SETTING_INFORMATION";
export const GET_VET_SETTING_INFORMATION_SUCCESS = "GET_VET_SETTING_INFORMATION_SUCCESS";
export const GET_VET_SETTING_INFORMATION_FAIL = "GET_VET_SETTING_INFORMATION_FAIL";

export const SET_LOCAL_VET_MICRO_DEPOSITS_STATUS = "SET_LOCAL_VET_MICRO_DEPOSITS_STATUS";

export const UPDATE_VET_BASIC_INFO = "UPDATE_VET_BASIC_INFO";
export const UPDATE_VET_BASIC_INFO_SUCCESS = "UPDATE_VET_BASIC_INFO_SUCCESS";
export const UDPATE_VET_BASIC_INFO_FAIL = "UDPATE_VET_BASIC_INFO_FAIL";

export const GET_VET_W9_INFO = "GET_VET_W9_INFO";
export const GET_VET_W9_INFO_SUCCESS = "GET_VET_W9_INFO_SUCCESS";
export const GET_VET_W9_INFO_FAIL = "GET_VET_W9_INFO_FAIL";

export const UPDATE_VET_W9 = "UPDATE_VET_W9";
export const UPDATE_VET_W9_SUCCESS = "UPDATE_VET_W9_SUCCESS";
export const UPDATE_VET_W9_FAIL = "UPDATE_VET_W9_FAIL";

export const INITIALIZE_VET_SETTINGS = "INITIALIZE_VET_SETTINGS";
export const RESET_VET_BASIC_INFO_UPDATE_SUCCESS = "RESET_VET_BASIC_INFO_UPDATE_SUCCESS";
export const RESET_VET_W9_UPDATE_SUCCESS = "RESET_VET_W9_UPDATE_SUCCESS";

export const FETCH_VET_PROFILE_REQUEST = "FETCH_VET_PROFILE_REQUEST";
export const FETCH_VET_PROFILE_SUCCESS = "FETCH_VET_PROFILE_SUCCESS";
export const FETCH_VET_PROFILE_FAIL = "FETCH_VET_PROFILE_FAIL";

export const FETCH_ADMIN_VET_PROFILE_REQUEST = "FETCH_ADMIN_VET_PROFILE_REQUEST";
export const FETCH_ADMIN_VET_PROFILE_SUCCESS = "FETCH_ADMIN_VET_PROFILE_SUCCESS";
export const FETCH_ADMIN_VET_PROFILE_FAIL = "FETCH_ADMIN_VET_PROFILE_FAIL";

export const UPDATE_VET_PROFILE_IMAGE_REQUEST = "UPDATE_VET_PROFILE_IMAGE_REQUEST";
export const UPDATE_VET_PROFILE_IMAGE_SUCCESS = "UPDATE_VET_PROFILE_IMAGE_SUCCESS";
export const UPDATE_VET_PROFILE_IMAGE_FAIL = "UPDATE_VET_PROFILE_IMAGE_FAIL";

export const DELETE_VET_PROFILE_IMAGE_REQUEST = "DELETE_VET_PROFILE_IMAGE_REQUEST";
export const DELETE_VET_PROFILE_IMAGE_SUCCESS = "DELETE_VET_PROFILE_IMAGE_SUCCESS";
export const DELETE_VET_PROFILE_IMAGE_FAIL = "DELETE_VET_PROFILE_IMAGE_FAIL";

export const UPDATE_VET_PROFILE_SUCCESS = "UPDATE_VET_PROFILE_SUCCESS";
export const UPDATE_VET_PROFILE_FAIL = "UPDATE_VET_PROFILE_FAIL";

export const RESET_VET_PLAID_MD_STATUS_SUCCESS = "RESET_VET_PLAID_MD_STATUS_SUCCESS";
export const RESET_ADMIN_VET_PROFILE_SAVED_FLAGS = "RESET_ADMIN_VET_PROFILE_SAVED_FLAGS";

export const DELETE_VET_LICENSE_REQUEST = "DELETE_VET_LICENSE_REQUEST";
export const DELETE_VET_LICENSE_SUCCESS = "DELETE_VET_LICENSE_SUCCESS";
export const DELETE_VET_LICENSE_FAIL = "DELETE_VET_LICENSE_FAIL";

export const DELETE_VET_SPECIALIZATION_REQUEST = "DELETE_VET_SPECIALIZATION_REQUEST";
export const DELETE_VET_SPECIALIZATION_SUCCESS = "DELETE_VET_SPECIALIZATION_SUCCESS";
export const DELETE_VET_SPECIALIZATION_FAIL = "DELETE_VET_SPECIALIZATION_FAIL";

export const GET_ALL_MY_SHIFTS_REQUEST = "GET_ALL_MY_SHIFTS_REQUEST";
export const GET_ALL_MY_SHIFTS_SUCCESS = "GET_ALL_MY_SHIFTS_SUCCESS";
export const GET_ALL_MY_SHIFTS_FAIL = "GET_ALL_MY_SHIFTS_FAIL";

export const INITIALIZE_SEARCH_RESULTS = "INITIALIZE_SEARCH_RESULTS";

export const UPLOAD_VET_RESUME_REQUEST = "UPLOAD_VET_RESUME_REQUEST";
export const UPLOAD_VET_RESUME_SUCCESS = "UPLOAD_VET_RESUME_SUCCESS";
export const UPLOAD_VET_RESUME_FAIL = "UPLOAD_VET_RESUME_FAIL";

export const UPDATE_ADMIN_VET_PROFILE_REQUEST = "UPDATE_ADMIN_VET_PROFILE_REQUEST";
export const UPDATE_ADMIN_VET_PROFILE_SUCCESS = "UPDATE_ADMIN_VET_PROFILE_SUCCESS";
export const UPDATE_ADMIN_VET_PROFILE_FAIL = "UPDATE_ADMIN_VET_PROFILE_FAIL";

export const FETCH_VET_IAV_TOKEN = "FETCH_VET_IAV_TOKEN";
export const FETCH_IAV_TOKEN_SUCCESS = "FETCH_IAV_TOKEN_SUCCESS";
export const FETCH_IAV_TOKEN_FAIL = "FETCH_IAV_TOKEN_FAIL";

export const SET_VET_DEFAULT_BANK = "SET_VET_DEFAULT_BANK";
export const SET_VET_DEFAULT_BANK_SUCCESS = "SET_VET_DEFAULT_BANK_SUCCESS";
export const SET_VET_DEFAULT_BANK_FAIL = "SET_VET_DEFAULT_BANK_FAIL";
export const RESET_ADD_BANK = "RESET_ADD_BANK";

export const SWITCH_VET_DEFAULT_BANK = "SWITCH_VET_DEFAULT_BANK";
export const SWITCH_VET_DEFAULT_BANK_SUCCESS = "SWITCH_VET_DEFAULT_BANK_SUCCESS";
export const SWITCH_VET_DEFAULT_BANK_FAIL = "SWITCH_VET_DEFAULT_BANK_FAIL";

export const INITIALIZE_VET_FIRST_TIMER = "INITIALIZE_VET_FIRST_TIMER";

export const DELETE_VET_BANK_ACCOUNT_REQUEST = "DELETE_VET_BANK_ACCOUNT_REQUEST";
export const DELETE_VET_BANK_ACCOUNT_SUCCESS = "DELETE_VET_BANK_ACCOUNT_SUCCESS";
export const DELETE_VET_BANK_ACCOUNT_FAIL = "DELETE_VET_BANK_ACCOUNT_FAIL";

export const UPDATE_VET_MICRO_DEPOSITS_VERIFIED = "UPDATE_VET_MICRO_DEPOSITS_VERIFIED";
export const UPDATE_VET_MICRO_DEPOSITS_VERIFIED_SUCCESS =
  "UPDATE_VET_MICRO_DEPOSITS_VERIFIED_SUCCESS";
export const UPDATE_VET_MICRO_DEPOSITS_VERIFIED_FAIL = "UPDATE_VET_MICRO_DEPOSITS_VERIFIED_FAIL";

export const UPDATE_VET_MICRO_DEPOSITS_FAILED = "UPDATE_VET_MICRO_DEPOSITS_FAILED";
export const UPDATE_VET_MICRO_DEPOSITS_FAILED_SUCCESS = "UPDATE_VET_MICRO_DEPOSITS_FAILED_SUCCESS";
export const UPDATE_VET_MICRO_DEPOSITS_FAILED_FAIL = "UPDATE_VET_MICRO_DEPOSITS_FAILED_FAIL";

export const CLEAR_AVAILBLE_SHIFT = "CLEAR_AVAILBLE_SHIFT";

export const CLEAR_SHIFT_HISTORY = "CLEAR_SHIFT_HISTORY";

export const CHECK_VET_LICENSE_EXPIRING_REQUEST = "CHECK_VET_LICENSE_EXPIRING_REQUEST";
export const CHECK_VET_LICENSE_EXPIRING_SUCCESS = "CHECK_VET_LICENSE_EXPIRING_SUCCESS";
export const CHECK_VET_LICENSE_EXPIRING_FAIL = "CHECK_VET_LICENSE_EXPIRING_FAIL";

export const CHECK_VET_LICENSE_EXPIRED_REQUEST = "CHECK_VET_LICENSE_EXPIRED_REQUEST";
export const CHECK_VET_LICENSE_EXPIRED_SUCCESS = "CHECK_VET_LICENSE_EXPIRED_SUCCESS";
export const CHECK_VET_LICENSE_EXPIRED_FAIL = "CHECK_VET_LICENSE_EXPIRED_FAIL";

export const OPEN_VET_LICENSE_EXPIRED_MODAL = "OPEN_VET_LICENSE_EXPIRED_MODAL";
export const CLOSE_VET_LICENSE_EXPIRED_MODAL = "CLOSE_VET_LICENSE_EXPIRED_MODAL";

export const INITIALIZE_VET_LICENSE_EXPIRING = "INITIALIZE_VET_LICENSE_EXPIRING";

export const SHOW_REFERRAL_MODAL = "SHOW_REFERRAL_MODAL";
export const HIDE_REFERRAL_MODAL = "HIDE_REFERRAL_MODAL";

export const INITIALIZE_SEARCH_CLICK = "INITIALIZE_SEARCH_CLICK";

export const FETCH_VET_SHIFT_DETAILS_REQUEST = "FETCH_VET_SHIFT_DETAILS_REQUEST";
export const FETCH_VET_SHIFT_DETAILS_SUCCESS = "FETCH_VET_SHIFT_DETAILS_SUCCESS";
export const FETCH_VET_SHIFT_DETAILS_FAIL = "FETCH_VET_SHIFT_DETAILS_FAIL";

export const FETCH_COMMON_DATA_REQUEST = "FETCH_COMMON_DATA_REQUEST";
export const FETCH_COMMON_DATA_SUCCESS = "FETCH_COMMON_DATA_SUCCESS";
export const FETCH_COMMON_DATA_FAIL = "FETCH_COMMON_DATA_FAIL";

export const FETCH_CALCULATE_SHIFT_PRICING = "FETCH_CALCULATE_SHIFT_PRICING";
export const FETCH_CALCULATE_SHIFT_PRICING_SUCCESS = "FETCH_CALCULATE_SHIFT_PRICING_SUCCESS";
export const FETCH_CALCULATE_SHIFT_PRICING_FAIL = "FETCH_CALCULATE_SHIFT_PRICING_FAIL";

export const UPDATE_VET_OFFLINE_PAYMENT_SETTING = "UPDATE_VET_OFFLINE_PAYMENT_SETTING";
export const UPDATE_VET_OFFLINE_PAYMENT_SETTING_SUCCESS =
  "UPDATE_VET_OFFLINE_PAYMENT_SETTING_SUCCESS";
export const UPDATE_VET_OFFLINE_PAYMENT_SETTING_FAIL = "UPDATE_VET_OFFLINE_PAYMENT_SETTING_FAIL";

export const GET_BLOCKED_HOSPITAL_LIST = "GET_BLOCKED_HOSPITAL_LIST";
export const GET_BLOCKED_HOSPITAL_LIST_FAIL = "GET_BLOCKED_HOSPITAL_LIST_FAIL";
export const GET_BLOCKED_HOSPITAL_LIST_SUCCESS = "GET_BLOCKED_HOSPITAL_LIST_SUCCESS";

export const REMOVE_BLOCKED_HOSPITAL = "REMOVE_BLOCKED_HOSPITAL";
export const REMOVE_BLOCKED_HOSPITAL_FAIL = "REMOVE_BLOCKED_HOSPITAL_FAIL";
export const REMOVE_BLOCKED_HOSPITAL_SUCCESS = "REMOVE_BLOCKED_HOSPITAL_SUCCESS";

export const BLOCK_HOSPITAL = "BLOCK_HOSPITAL";
export const BLOCK_HOSPITAL_SUCCESS = "BLOCK_HOSPITAL_SUCCESS";
export const BLOCK_HOSPITAL_FAIL = "BLOCK_HOSPITAL_FAIL";
export const HIDE_BLOCK_HOSPITAL_CONFIRMATION_MODAL = "HIDE_BLOCK_HOSPITAL_CONFIRMATION_MODAL";

export const GET_VET_PAYMENT_INFO = "GET_VET_PAYMENT_INFO";
export const GET_VET_PAYMENT_INFO_SUCCESS = "GET_VET_PAYMENT_INFO_SUCCESS";
export const GET_VET_PAYMENT_INFO_FAIL = "GET_VET_PAYMENT_INFO_FAIL";

export const UPDATE_BLOCKED_HOSPITAL_COUNT = "UPDATE_BLOCKED_HOSPITAL_COUNT";

export const GET_EVENT_FOR_VET = "GET_EVENT_FOR_VET";
export const GET_EVENT_FOR_VET_FAIL = "GET_EVENT_FOR_VET_FAIL";
export const GET_EVENT_FOR_VET_SUCCESS = "GET_EVENT_FOR_VET_SUCCESS";
export const UPDATE_VET_DETAILS_PAYMENT_INFO = "UPDATE_VET_DETAILS_PAYMENT_INFO";
export const UPDATE_VET_DETAILS_PAYMENT_INFO_SUCCESS = "UPDATE_VET_DETAILS_PAYMENT_INFO_SUCCESS";
export const UPDATE_VET_DETAILS_PAYMENT_INFO_FAIL = "UPDATE_VET_DETAILS_PAYMENT_INFO_FAIL";
export const RESET_VET_DETAILS_PAYMENT_INFO = "RESET_VET_DETAILS_PAYMENT_INFO";

export const CREATE_VET_PROMO_TRACKING = "CREATE_VET_PROMO_TRACKING";
export const CREATE_VET_PROMO_TRACKING_SUCCESS = "CREATE_VET_PROMO_TRACKING_SUCCESS";
export const CREATE_VET_PROMO_TRACKING_FAIL = "CREATE_VET_PROMO_TRACKING_FAIL";
export const CLEAR_VET_PROMO_TRACKING_DATA = "CLEAR_VET_PROMO_TRACKING_DATA";

export const GET_VET_PROMO_TRACKING_BY_USER_ID = "GET_VET_PROMO_TRACKING_BY_USER_ID";
export const GET_VET_PROMO_TRACKING_BY_USER_ID_SUCCESS =
  "GET_VET_PROMO_TRACKING_BY_USER_ID_SUCCESS";
export const GET_VET_PROMO_TRACKING_BY_USER_ID_FAIL = "GET_VET_PROMO_TRACKING_BY_USER_ID_FAIL";
export const GET_VET_COMPLETED_PROMOS = "GET_VET_COMPLETED_PROMOS";
export const GET_VET_COMPLETED_PROMOS_SUCCESS = "GET_VET_COMPLETED_PROMOS_SUCCESS";
export const GET_VET_COMPLETED_PROMOS_FAIL = "GET_VET_COMPLETED_PROMOS_FAIL";

export const UPDATE_VET_PREFERRED_AREAS_OF_PRACTICE = "UPDATE_VET_PREFERRED_AREAS_OF_PRACTICE";
export const UPDATE_VET_PREFERRED_AREAS_OF_PRACTICE_SUCCESS =
  "UPDATE_VET_PREFERRED_AREAS_OF_PRACTICE_SUCCESS";
export const UPDATE_VET_PREFERRED_AREAS_OF_PRACTICE_FAIL =
  "UPDATE_VET_PREFERRED_AREAS_OF_PRACTICE_FAIL";

export const UPDATE_VET_PREFERRED_AREAS_SLIDERS = "UPDATE_VET_PREFERRED_AREAS_SLIDERS";
export const UPDATE_VET_PREFERRED_AREAS_SLIDERS_SUCCESS =
  "UPDATE_VET_PREFERRED_AREAS_SLIDERS_SUCCESS";
export const UPDATE_VET_PREFERRED_AREAS_SLIDERS_FAIL = "UPDATE_VET_PREFERRED_AREAS_SLIDERS_FAIL";

export const UPDATE_VET_CHARACTER_TYPE = "UPDATE_VET_CHARACTER_TYPE";
export const UPDATE_VET_CHARACTER_TYPE_SUCCESS = "UPDATE_VET_CHARACTER_TYPE_SUCCESS";
export const UPDATE_VET_CHARACTER_TYPE_FAIL = "UPDATE_VET_CHARACTER_TYPE_FAIL";

export const UPDATE_VET_ADDITIONAL_SKILLS = "UPDATE_VET_ADDITIONAL_SKILLS";
export const UPDATE_VET_ADDITIONAL_SKILLS_SUCCESS = "UPDATE_VET_ADDITIONAL_SKILLS_SUCCESS";
export const UPDATE_VET_ADDITIONAL_SKILLS_FAIL = "UPDATE_VET_ADDITIONAL_SKILLS_FAIL";

export const UPDATE_VET_SHOW_ONBOARDING = "UPDATE_VET_SHOW_ONBOARDING";
export const UPDATE_VET_SHOW_ONBOARDING_SUCCESS = "UPDATE_VET_SHOW_ONBOARDING_SUCCESS";
export const UPDATE_VET_SHOW_ONBOARDING_FAIL = "UPDATE_VET_SHOW_ONBOARDING_FAIL";

export const GET_VET_ONBOARDING_INFO = "GET_VET_ONBOARDING_INFO";
export const GET_VET_ONBOARDING_INFO_SUCCESS = "GET_VET_ONBOARDING_INFO_SUCCESS";
export const GET_VET_ONBOARDING_INFO_FAIL = "GET_VET_ONBOARDING_INFO_FAIL";

export const GET_VET_BADGES = "GET_VET_BADGES";
export const GET_VET_BADGES_SUCCESS = "GET_VET_BADGES_SUCCESS";
export const GET_VET_BADGES_FAIL = "GET_VET_BADGES_FAIL";

export const SHOW_VET_BADGE_CONGRATS_MODAL = "SHOW_VET_BADGE_CONGRATS_MODAL";
export const CLOSE_VET_BADGE_CONGRATS_MODAL = "CLOSE_VET_BADGE_CONGRATS_MODAL";
export const GET_VET_CONFIRMED_SHIFT_DATA = "GET_VET_CONFIRMED_SHIFT_DATA";
export const GET_VET_CONFIRMED_SHIFT_DATA_SUCCESS = "GET_VET_CONFIRMED_SHIFT_DATA_SUCCESS";
export const GET_VET_CONFIRMED_SHIFT_DATA_FAIL = "GET_VET_CONFIRMED_SHIFT_DATA_FAIL";

export const VET_SKIP_W9_MODAL = "VET_SKIP_W9_MODAL";
export const VET_SKIP_W9_MODAL_SUCCESS = "VET_SKIP_W9_MODAL_SUCCESS";
export const VET_SKIP_W9_MODAL_FAIL = "VET_SKIP_W9_MODAL_FAIL";

export const GET_REQUESTS_BY_VET_ID = "GET_REQUESTS_BY_VET_ID";
export const GET_REQUESTS_BY_VET_ID_SUCCESS = "GET_REQUESTS_BY_VET_ID_SUCCESS";
export const GET_REQUESTS_BY_VET_ID_FAIL = "GET_REQUESTS_BY_VET_ID_FAIL";

export const OPEN_HOSPITAL_PROFILE_PANEL = "OPEN_HOSPIRAL_PROFILE_PANEL";
export const OPEN_HOSPITAL_PROFILE_PANEL_SUCCESS = "OPEN_HOSPITAL_PROFILE_PANEL_SUCCESS";
export const OPEN_HOSPITAL_PROFILE_PANEL_FAIL = "OPEN_HOSPITAL_PROFILE_PANEL_FAIL";

export const CLOSE_HOSPITAL_PROFILE_PANEL = "CLOSE_HOSPITAL_PROFILE_PANEL";

export const DEFER_HOSPITAL_RATING = "DEFER_HOSPITAL_RATING";
export const DEFER_HOSPITAL_RATING_SUCCESS = "DEFER_HOSPITAL_RATING_SUCCESS";
export const DEFER_HOSPITAL_RATING_FAIL = "DEFER_HOSPITAL_RATING_FAIL";

export const DEFER_ALL_HOSPITAL_RATINGS = "DEFER_HOSPITAL_RATINGS";
export const DEFER_ALL_HOSPITAL_RATINGS_SUCCESS = "DEFER_ALL_HOSPITAL_RATINGS_SUCCESS";
export const DEFER_ALL_HOSPITAL_RATINGS_FAIL = "DEFER_ALL_HOSPITAL_RATINGS_FAIL";

export const SAVE_SHIFT_COUNT = "SAVE_SHIFT_COUNT";
export const SAVE_SHIFT_COUNT_SUCCESS = "SAVE_SHIFT_COUNT_SUCCESS";
export const SAVE_SHIFT_COUNT_FAIL = "SAVE_SHIFT_COUNT_FAIL";

export const SET_DASHBOARD_VIEW = "SET_DASHBOARD_VIEW";
export const SET_DASHBOARD_VIEW_SUCCESS = "SET_DASHBOARD_VIEW_SUCCESS";
export const SET_DASHBOARD_VIEW_FAIL = "SET_DASHBOARD_VIEW_FAIL";

export const CLEAR_DASHBOARD_VIEW = "CLEAR_DASHBOARD_VIEW";

export const GET_NON_REQUEST_SURVEY_ELIGIBILITY = "GET_NON_REQUEST_SURVEY_ELIGIBILITY";
export const GET_NON_REQUEST_SURVEY_ELIGIBILITY_SUCCESS =
  "GET_NON_REQUEST_SURVEY_ELIGIBILITY_SUCCESS";
export const GET_NON_REQUEST_SURVEY_ELIGIBILITY_FAIL = "GET_NON_REQUEST_SURVEY_ELIGIBILITY_FAIL";

export const VET_SUBMIT_SURVEY = "VET_SUBMIT_SURVEY";
export const VET_SUBMIT_SURVEY_SUCCESS = "VET_SUBMIT_SURVEY_SUCCESS";
export const VET_SUBMIT_SURVEY_FAIL = "VET_SUBMIT_SURVEY_FAIL";

export const SET_IS_INITIAL_MAP_SORT_COMPLETE = "SET_IS_INITIAL_MAP_SORT_COMPLETE";
export const SET_IS_INITIAL_MAP_SORT_COMPLETE_SUCCESS = "SET_IS_INITIAL_MAP_SORT_COMPLETE_SUCCESS";
export const SET_IS_INITIAL_MAP_SORT_COMPLETE_FAIL = "SET_IS_INITIAL_MAP_SORT_COMPLETE_FAIL";

export const GET_VET_PROMO_SHIFTS = "GET_VET_PROMO_SHIFTS";
export const GET_VET_PROMO_SHIFTS_SUCCESS = "GET_VET_PROMO_SHIFTS_SUCCESS";
export const GET_VET_PROMO_SHIFTS_FAIL = "GET_VET_PROMO_SHIFTS_FAIL";

export const TOGGLE_VET_PROMO_SHIFTS_PANEL = "TOGGLE_VET_PROMO_SHIFTS_PANEL";
export const TOGGLE_VET_PROMO_SHIFTS_PANEL_OPEN = "TOGGLE_VET_PROMO_SHIFTS_PANEL_OPEN";
export const TOGGLE_VET_PROMO_SHIFTS_PANEL_CLOSED = "TOGGLE_VET_PROMO_SHIFTS_PANEL_CLOSED";

export const DELETE_VET_SHIFT_REQUEST = "DELETE_VET_SHIFT_REQUEST";
export const DELETE_VET_SHIFT_REQUEST_SUCCESS = "DELETE_VET_SHIFT_REQUEST_SUCCESS";
export const DELETE_VET_SHIFT_REQUEST_FAIL = "DELETE_VET_SHIFT_REQUEST_FAIL";
export const RESET_VET_SHIFT_REQUEST_CANCELLED_SUCCESS =
  "RESET_VET_SHIFT_REQUEST_CANCELLED_SUCCESS";

export const CANCEL_CONFIRMED_VET_SHIFT = "CANCEL_CONFIRMED_VET_SHIFT";
export const CANCEL_CONFIRMED_VET_SHIFT_SUCCESS = "CANCEL_CONFIRMED_VET_SHIFT_SUCCESS";
export const CANCEL_CONFIRMED_VET_SHIFT_FAIL = "CANCEL_CONFIRMED_VET_SHIFT_FAIL";

export const CONFIRMED_VET_SHIFT_CANCELLATION_REQUEST_SUCCESS =
  "CONFIRMED_VET_SHIFT_CANCELLATION_REQUEST_SUCCESS";

export const RESET_CONFIRMED_VET_SHIFT_CANCELLATION_REQUEST_SUCCESS =
  "RESET_CONFIRMED_VET_SHIFT_CANCELLATION_REQUEST_SUCCESS";

export const RESET_CANCELLED_VET_SHIFT_SUCCESS_FLAG = "RESET_CANCELLED_VET_SHIFT_SUCCESS_FLAG";

export const TOGGLE_VET_DELETE_SHIFT_REQUEST_PANEL = "TOGGLE_VET_DELETE_SHIFT_REQUEST_PANEL";
export const TOGGLE_VET_DELETE_SHIFT_REQUEST_PANEL_OPEN =
  "TOGGLE_VET_DELETE_SHIFT_REQUEST_PANEL_OPEN";
export const TOGGLE_VET_DELETE_SHIFT_REQUEST_PANEL_CLOSED =
  "TOGGLE_VET_DELETE_SHIFT_REQUEST_PANEL_CLOSED";

export const TOGGLE_VET_CANCEL_CONFIRMED_SHIFT_PANEL = "TOGGLE_VET_CANCEL_CONFIRMED_SHIFT_PANEL";

export const UPDATE_DEA_LICENSE_INFO = "UPDATE_DEA_LICENSE_INFO";
export const UPDATE_DEA_LICENSE_INFO_SUCCESS = "UPDATE_DEA_LICENSE_INFO_SUCCESS";
export const UPDATE_DEA_LICENSE_INFO_FAIL = "UPDATE_DEA_LICENSE_INFO_FAIL";

export const SET_REQUEST_DETAILS = "SET_REQUEST_DETAILS";
export const RESET_REQUEST_DETAILS = "RESET_REQUEST_DETAILS";

export const UPDATE_SHOW_PAYMENT_INFO_MODAL = "UPDATE_SHOW_PAYMENT_INFO_MODAL";
