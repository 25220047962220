import { useQuery } from "@tanstack/react-query";

import { GET } from "@roo-dev/roo-node-types";

import { get } from "../common/rooFetch";

import { CACHE } from "./constants";

export const getStates = async () => {
  const response = await get<GET.Utility.GetStatesResponse>("api/utility/getStates", true);
  return response.data.data;
};

export const useGetStates = () => useQuery({ queryKey: [CACHE.GET_STATES], queryFn: getStates });
