// These do NOT follow the correct naming convention.
export enum LEGACY_FEATURE_FLAGS {
  HOSPITAL_BIDDING_FEATURE = "hospital-bidding-feature",
  HOSPITAL_SHIFT_CANCELLATION = "hospital-shift-cancellation",
  DETECT_CANCEL_KEYWORD_EXPERIMENT = "detect-cancel-keyword-experiment",
  ACTIVATION_Q4_EXPERIMENT_2 = "activation-q4-experiment-2",
  NEW_PROMO_PAGE = "new-promo-page",
  IS_REQUIRE_DEA_LICENSE = "is-require-dea-license",
  DISABLE_ADD_TECH_SHIFT = "disable-add-tech-shift",
  CHAT_FEATURE = "chat-feature",
  SHIFT_RECOMMENDATION_PAGE = "shift-recommendation-page",
  SHARE_SEARCH_SHIFTS = "share-search-shifts",
  VALIDATE_PRICING = "validate-pricing",
  NEW_EXTERNSHIP_UI = "new-externship-ui",
  HOSPITAL_PROMO_TECH_SHIFTS = "hospital-promo-tech-shifts",
}

export enum NEW_FEATURE_FLAGS {
  AUTO_APPROVAL_FLOW = "aug-2024-ap-v2",
  AUTO_LICENSE_VERIFICATION_DEBUG_A_A_TEST = "jul-2024-auto-license-verification-debug-a-a-test",
  CALENDLY_PREFER_ANOTHER_TIME = "may-2024-calendly-prefer-another-time",
  CALENDLY_IMPROVE_CLARITY = "apr-2024-calendly-improve-clarity",
  NEW_PROMO_PAGE_CALLOUT = "may-2024-new-promo-page-callout",
  MULTI_PROMO_DROPDOWN = "jun-2024-multi-promo-dropdown",
  CONFERENCE_EVENTS_AUTO_APPROVAL = "jun-2024-conference-events-auto-approval",
  HOSPITAL_BOOST_RECOMMENDATIONS = "jun-2024-hospital-boost-recommendations",
  SAVE_LAST_SEARCH = "jun-2024-save-last-search",
  NEW_MANAGE_SHIFTS_UI = "jun-2024-new-manage-shifts-ui",
  SMOOTH_ONBOARDING_V1 = "jul-2024-smooth-onboarding-v1",
  NEW_HOSPITAL_REGISTRATION = "jul-2024-new-hospital-registration",
  NEW_REQUEST_SIDE_PANEL = "aug-2024-new-request-panel",
  MANUAL_PROMO_PAYOUT = "aug-2024-manual-promo-payout",
  HOSPITAL_CUSTOM_PRICE = "aug-2024-hospital-custom-price",
  VET_BID_REASON = "aug-2024-vet-bid-reason",
  COUNTER_OFFER = "aug-2024-counteroffer",
  REQUEST_MORE_SHIFTS = "aug-2024-request-more-shifts",
  IMPROVE_FILTERS = "sep-2024-improve-filters",
  SHIFT_PRICING_SURVEY = "sep-2024-shift-pricing-survey",
}

export const FEATURE_FLAGS = { ...NEW_FEATURE_FLAGS, ...LEGACY_FEATURE_FLAGS };
export type FeatureFlagName = NEW_FEATURE_FLAGS | LEGACY_FEATURE_FLAGS;

export enum FEATURE_FLAG_VARIANTS {
  CONTROL = "control",
  TEST = "test",
}

export const MONTH_PREFIXES = [
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec",
];
