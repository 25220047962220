import { compact } from "lodash";
import { useEffect } from "react";

import { blueBase, grayBase } from "@roo-dev/ui-components";

import { CalendlyViewProps } from "./CalendlyView";

enum CalendlyEvent {
  EVENT_SCHEDULED = "calendly.event_scheduled",
  PROFILE_PAGE_VIEWED = "calendly.profile_page_viewed",
  EVENT_TYPE_VIEWED = "calendly.event_type_viewed",
  DATE_AND_TIME_SELECTED = "calendly.date_and_time_selected",
}

export const useCalendlyEventListener = ({
  onEventScheduled,
  onProfilePageViewed,
  onDateAndTimeSelected,
  onEventTypeViewed,
}: Pick<
  CalendlyViewProps,
  "onEventScheduled" | "onDateAndTimeSelected" | "onProfilePageViewed" | "onEventTypeViewed"
>) => {
  useEffect(() => {
    const handleCalendlyEvent = (e: MessageEvent) => {
      const eventName = e.data.event;

      if (eventName === CalendlyEvent.EVENT_SCHEDULED && onEventScheduled) {
        onEventScheduled();
      } else if (eventName === CalendlyEvent.PROFILE_PAGE_VIEWED && onProfilePageViewed) {
        onProfilePageViewed();
      } else if (eventName === CalendlyEvent.EVENT_TYPE_VIEWED && onEventTypeViewed) {
        onEventTypeViewed();
      } else if (eventName === CalendlyEvent.DATE_AND_TIME_SELECTED && onDateAndTimeSelected) {
        onDateAndTimeSelected();
      }
    };

    window.addEventListener("message", handleCalendlyEvent);

    return function cleanup() {
      window.removeEventListener("message", handleCalendlyEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onEventScheduled]);
};

export const getCalendlyLinkPrefilled = ({
  calendlyLink,
  email,
  name,
  phone,
  utmSource,
  customAnswer,
  hospitalName,
  embed = true,
}: CalendlyViewProps & { customAnswer?: string; embed?: boolean }) => {
  let calendlyLinkForEnv = `${calendlyLink}`;
  const isNotProduction =
    window.RooConfig.WHICH_ENV === "stage" || window.RooConfig.WHICH_ENV === "dev";
  if (isNotProduction) {
    calendlyLinkForEnv = hospitalName
      ? `https://calendly.com/huy-dang-f8u/30-minute-meeting-clone`
      : `https://calendly.com/huy-dang-f8u/30min`;
  }

  const formattedPhone = `1${phone}`;
  const queryString = compact([
    email ? `email=${encodeURIComponent(email)}` : undefined,
    name ? `name=${encodeURIComponent(name)}` : undefined,
    phone ? `location=${formattedPhone}` : undefined,
    phone ? `phone_number=${formattedPhone}` : undefined,
    utmSource ? `utm_source=${utmSource}` : undefined,
    `text_color=${grayBase.replace("#", "")}`,
    `primary_color=${blueBase.replace("#", "")}`,
    embed ? "embed_type=Inline" : undefined,
    embed ? "embed_domain=1" : undefined,
    customAnswer ? `${hospitalName ? "a1" : "a2"}=${customAnswer}` : undefined,
    hospitalName ? `a2=${hospitalName}` : undefined,
    "hide_gdpr_banner=1",
  ]).join("&");

  return `${calendlyLinkForEnv}?${queryString}`;
};
