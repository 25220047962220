import * as actionTypes from "../../../constants/editRating";

const initialState = {
  adminRating: null,
  isRooAdminRatingUpdated: null,
};

// eslint-disable-next-line default-param-last
const editRatingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_RATING_SUCCESS:
      return {
        ...state,
        adminRating: action.payload.adminRating,
        isRooAdminRatingUpdated: true,
      };

    case actionTypes.UPDATE_RATING_FAIL:
      return {
        ...state,
        isRooAdminRatingUpdated: false,
      };

    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default editRatingReducer;
