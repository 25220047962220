import React from "react";

import { ButtonTabs } from "@roo-dev/ui-components";

import { SHIFTS } from "../../../constants/notificationConstants";
import { NotificationPreference } from "../interfaces";

import NotificationPanelContent from "./NotificationPanelContent";
import { ToggleUpdateParams } from "./NotificationSettings";

interface HospitalNotificationProps {
  handleToggleChange: (arg: ToggleUpdateParams) => void;
  processedData: NotificationPreference[];
  areAllNotificationsEnabled: () => number;
  toggleAllSettings: (arg: number) => void;
}

export const StudentOrHospitalNotifications = ({
  handleToggleChange,
  processedData,
  areAllNotificationsEnabled,
  toggleAllSettings,
}: HospitalNotificationProps) =>
  processedData.length ? (
    <ButtonTabs
      defaultSelectedTabIndex={0}
      tabNameArr={processedData.map((opt, index) => ({
        index,
        name: opt.label,
        testId: `button-tab-${opt.label.replace(/\s/g, "")}`,
        panelContent: (
          <NotificationPanelContent
            content={opt}
            renderAllNotifications={SHIFTS === opt.label}
            handleToggleChange={handleToggleChange}
            getAllSettingsValue={areAllNotificationsEnabled}
            toggleAllSettings={toggleAllSettings}
          />
        ),
      }))}
    />
  ) : null;
