import React from "react";

import { CalendlyView } from "../../../../Common/CalendlyView/CalendlyView";
import PageLoader from "../../../../Common/PageLoader";
import { useCalendlyLink } from "../../../../RegistrationNew/Components/Common/hooks/useCalendlyLink";
import { EventSignupSuccessContainer } from "../common/EventSignUpSuccessContainer";

export type EventCalendlySignUpProps = {
  userId: number;
  vetId: number;
  name: string;
  email: string;
  phone: string;
  onBack?: () => void;
};

export const EventCalendlySignUp = (props: EventCalendlySignUpProps) => {
  const { onBack, ...rest } = props;
  const { calendlyLink, isLoading } = useCalendlyLink(props.userId);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <EventSignupSuccessContainer onClickButton={onBack}>
      <CalendlyView calendlyLink={calendlyLink} {...rest} />
    </EventSignupSuccessContainer>
  );
};
