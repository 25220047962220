export enum ProfilePanelTypes {
  SingleRequestViewProfile,
  ViewProfle,
  RequestedState,
  ShiftDetails,
  DeclineRequest,
  RemoveShift,
  OnlyProfile,
}

export const profileTabNames = ["Snapshot", "Preferences", "Reviews"];

export enum ShiftStatus {
  Unfilled,
  Requested,
  AllRequestExpired,
  Confirmed,
  Completed,
  Boost,
  Expired,
  Unavailable,
  Cancelled,
}

export enum ToastType {
  VetConfirmed,
  VetConfirmedError,
  TechConfirmed,
  TechConfirmedError,
  VetRequestDeclined,
  VetRequestDeclinedError,
  TechRequestDeclined,
  TechRequestDeclinedError,
  VetRated,
  TechRated,
  VetShiftRemoved,
  TechShiftRemoved,
  ShiftBoosted,
  ShiftRequested,
  ShiftRequestedError,
  ShiftConfirmed,
  ShiftConfirmedError,
  HospitalRated,
  HospitalRatedError,
  TechProbationLimitReached,
  AdminShiftRequestConfirmed,
  AdminShiftRequestRemoved,
  CounterBidSubmitted,
}

export enum Action {
  Confirm,
}

export interface ProfilePanelDataType {
  isVet?: boolean;
  contractorId?: number;
  localShiftData?: any;
  panelShiftDetails?: any;
  shiftGroupId?: number;
  shiftId?: number;
  panelType?: ProfilePanelTypes;
  shiftStatus?: ShiftStatus;
  fromPage?: string;
  isFetchFullfilledShiftDetails?: boolean;
  shiftData?: any;
}

export interface ProilePanelDataType {
  panelType: ProfilePanelTypes;
  isVet: boolean;
  contractorId?: number;
  shiftData?: any;
}

export interface SnapshotType {
  basicInfo: any;
  experienceDetails: any;
  techCredentials?: any;
}

export interface PreferencesType {
  preferences: any;
}

export interface BasicInfoType {
  userId: number;
  profileImage: string | null;
  fullName: string;
  yearsOfPractice?: number;
  techTier?: number;
  hasSurgery?: boolean;
  averageRating?: number;
  hasFearFreeCertification?: number;
}

export interface TechCredentialsType {
  credentialTypes: string[];
  credentialStates: any;
  licenseList: any;
}

export interface ExpeirenceDetailsType {
  header: string;
  data: any;
}

export interface PreferenceDetailsType {
  softwareList?: string[];
  equipmentList?: string[];
  characterType: string;
  appointmentFlow: number;
  prefAreaOfPractice?: string[];
  clientInteract?: number;
  staffInteract?: number;
}

export interface ShiftBoostDataType {
  boostPercent: number;
  boostAmount: number;
  handleSubmit?: () => void;
  handleClose?: () => void;
}

export type ShiftSearch = {
  shiftDate?: string;
  hospitalName?: string;
};
