import { CustomToastProps } from "@roo-dev/ui-components/dist/ui-components/Toast";

import * as C from "../constants";
import { PROMO_SHIFT_REQUEST } from "../constants";

export function showToast(data: CustomToastProps) {
  return {
    type: C.SET_TOAST_DATA,
    payload: data,
  };
}

export function showPromoShiftSignUpToast() {
  return {
    type: C.SET_TOAST_DATA,
    payload: PROMO_SHIFT_REQUEST,
  };
}

export function hideToast() {
  return {
    type: C.CLEAR_TOAST_DATA,
    payload: null,
  } as {
    payload: CustomToastProps;
  };
}
