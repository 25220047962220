import { isRooFeatureEnabled } from "../Common/Wrappers/isRooFeatureEnabled";
import {
  MAXIMUM_HOSPITAL_CUSTOM_PRICE_MODIFIER,
  MINIMUM_TECH_SHIFT_BOOST_AMOUNT,
  MINIMUM_VET_SHIFT_BOOST_AMOUNT,
} from "../constants/constants";
import { FEATURE_FLAGS } from "../constants/postHogFeatureFlags";

export const getMinimumBoostAmount = (isVetShift: boolean): number => {
  let minBoostAmount = 0;

  if (isVetShift) {
    minBoostAmount = isRooFeatureEnabled(FEATURE_FLAGS.HOSPITAL_CUSTOM_PRICE)
      ? MAXIMUM_HOSPITAL_CUSTOM_PRICE_MODIFIER
      : MINIMUM_VET_SHIFT_BOOST_AMOUNT;
  } else {
    minBoostAmount = MINIMUM_TECH_SHIFT_BOOST_AMOUNT;
  }

  return minBoostAmount;
};
