import * as Sentry from "@sentry/react";
import { call, put, takeEvery } from "redux-saga/effects";

import * as errorMessage from "../../../constants/errorMessage";
import { SET_LOADER, UNSET_LOADER } from "../../../constants/loader";
import {
  SET_SESSION_REQUEST,
  UPDATE_USER_PERSONAL_INFO_SUCCESS,
  UPDATE_USER_PROFILE_INFO_FROM_W9,
} from "../../../constants/Login";
import * as vetActions from "../../../constants/vetConstants";
import "../../../helpers/InterceptFetch";

function* getVetSettingInformation(action) {
  try {
    const response = yield call(
      fetch,
      window.RooConfig.API_URL +
        "api/vet/getVetInfoById?vetId=" +
        action.vetId +
        "&userType=" +
        action.userTypeId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = yield response.json();

    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      yield put({ type: vetActions.GET_VET_SETTING_INFORMATION_SUCCESS, payload: { data: data } });
    } else if (data.status_code === 400) {
      yield put({
        type: vetActions.GET_VET_SETTING_INFORMATION_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting vet setting information: " },
    });
    yield put({
      type: vetActions.GET_VET_SETTING_INFORMATION_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* updateVetBasicInfo(action) {
  try {
    const response = yield call(fetch, window.RooConfig.API_URL + "api/vet/vetSettings", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(action.payload.vetInformation),
    });

    const data = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: UPDATE_USER_PERSONAL_INFO_SUCCESS,
        payload: action.payload.vetInformation,
      });
      yield put({ type: SET_SESSION_REQUEST });

      yield put({
        type: vetActions.UPDATE_VET_BASIC_INFO_SUCCESS,
        payload: { data: action.payload.vetInformation },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: vetActions.UDPATE_VET_BASIC_INFO_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while updating vet basic info: " } });
    yield put({
      type: vetActions.UDPATE_VET_BASIC_INFO_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* updateW9Async(action) {
  try {
    const {
      isUpdateProfile,
      signNowAddress,
      signNowAptUnitSuite,
      signNowCity,
      signNowStateId,
      signNowZipcode,
    } = action.payload;
    const response = yield call(fetch, window.RooConfig.API_URL + "api/vet/taxpayerDetails", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();

    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: vetActions.UPDATE_VET_W9_SUCCESS,
        payload: { status: data.status_code, message: data.data[0] },
      });
      if (isUpdateProfile) {
        yield put({
          type: UPDATE_USER_PROFILE_INFO_FROM_W9,
          payload: {
            address: signNowAddress,
            aptUnitSuite: signNowAptUnitSuite,
            city: signNowCity,
            stateId: signNowStateId,
            zipcode: signNowZipcode,
          },
        });
      }
    } else {
      yield put({
        type: vetActions.UPDATE_VET_W9_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while updating vet w9: " } });
    yield put({
      type: vetActions.UPDATE_VET_W9_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* getW9InfoAsync(action) {
  try {
    const response = yield call(
      fetch,
      window.RooConfig.API_URL +
        "api/vet/getW9Info?vetId=" +
        action.vetId +
        "&userId=" +
        action.userId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = yield response.json();

    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      yield put({ type: vetActions.GET_VET_W9_INFO_SUCCESS, payload: data.data });
    } else if (data.status_code === 400) {
      yield put({
        type: vetActions.GET_VET_W9_INFO_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting vet w9 info: " } });
    yield put({
      type: vetActions.GET_VET_W9_INFO_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* getBlockedHospitalListAsync(action) {
  try {
    const { vetId } = action.payload;
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + `api/vet/getBlockedHospitalList/${vetId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = yield response.json();
    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      yield put({
        type: vetActions.GET_BLOCKED_HOSPITAL_LIST_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: vetActions.GET_BLOCKED_HOSPITAL_LIST_FAIL,
        payload: { status_code: data.status_code, message: data.data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting blocked hospital list: " },
    });
    yield put({
      type: vetActions.GET_BLOCKED_HOSPITAL_LIST_FAIL,
      payload: { status_code: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* removeBlockedHospitalAsync(action) {
  try {
    yield put({ type: SET_LOADER });
    let data;
    if (!action.payload.isNewlyAdded) {
      const body = { ...action.payload };
      delete body.isNewlyAdded;
      const response = yield call(
        fetch,
        window.RooConfig.API_URL + "api/vet/removeBlockedHospital",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );

      data = yield response.json();
    }
    yield put({ type: UNSET_LOADER });

    if (action.payload.isNewlyAdded || data.status_code === 200) {
      yield put({
        type: vetActions.REMOVE_BLOCKED_HOSPITAL_SUCCESS,
        payload: action.payload,
      });
    } else {
      yield put({
        type: vetActions.REMOVE_BLOCKED_HOSPITAL_FAIL,
        payload: { status_code: data.status_code, message: data.data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while removing blocked hospital: " },
    });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: vetActions.REMOVE_BLOCKED_HOSPITAL_FAIL,
      payload: { status_code: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* blockHospitalAsync(action) {
  try {
    yield put({ type: SET_LOADER });
    let data;
    if (!action.payload.isNewlyAdded) {
      const body = { ...action.payload };
      delete body.isNewlyAdded;
      const response = yield call(fetch, window.RooConfig.API_URL + "api/vet/blockHospital", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      data = yield response.json();
    }

    yield put({ type: UNSET_LOADER });

    if (action.payload.isNewlyAdded || data.status_code === 200) {
      yield put({
        type: vetActions.BLOCK_HOSPITAL_SUCCESS,
        payload: { data: action.payload },
      });
    } else {
      yield put({
        type: vetActions.BLOCK_HOSPITAL_FAIL,
        payload: { status: data.status_code, message: errorMessage.EXCEPTION_MESSAGE },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while blocking hospital: " } });
    yield put({ type: UNSET_LOADER });

    yield put({
      type: vetActions.BLOCK_HOSPITAL_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootVetSettingSaga() {
  yield takeEvery(vetActions.GET_VET_SETTING_INFORMATION, getVetSettingInformation);
  yield takeEvery(vetActions.GET_VET_W9_INFO, getW9InfoAsync);
  yield takeEvery(vetActions.UPDATE_VET_BASIC_INFO, updateVetBasicInfo);
  yield takeEvery(vetActions.UPDATE_VET_W9, updateW9Async);
  yield takeEvery(vetActions.GET_BLOCKED_HOSPITAL_LIST, getBlockedHospitalListAsync);
  yield takeEvery(vetActions.REMOVE_BLOCKED_HOSPITAL, removeBlockedHospitalAsync);
  yield takeEvery(vetActions.BLOCK_HOSPITAL, blockHospitalAsync);
}
