import React from "react";
const PageLoader = () => {
  return (
    <React.Fragment>
      <div className="custom-loader page-loader d-block"></div>
      <div className="keep-scroll-on-load"></div>
    </React.Fragment>
  );
};

// eslint-disable-next-line no-restricted-exports
export default PageLoader;
