import * as Sentry from "@sentry/react";
import { call, put, takeEvery } from "redux-saga/effects";

import * as C from "../../constants/Unsubscribe";

function* unsubscribeVetWeeklyEmailAsync(action) {
  try {
    const { vetId, userId } = action.payload;
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/vet/unsubscribeWeeklyEmail",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          vetId,
          userId,
        }),
      }
    );

    const data = yield response.json();

    if (data.status_code === 200) {
      yield put({ type: C.UNSUBSCRIBE_VET_WEEKLY_EMAIL_SUCCESS });
    } else {
      Sentry.captureException(`Error while unsub vet weekly emails: ${data.status_code}`);
      yield put({ type: C.UNSUBSCRIBE_VET_WEEKLY_EMAIL_FAIL });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while unsubscribing vet weekly email: " },
    });
    yield put({ type: C.UNSUBSCRIBE_VET_WEEKLY_EMAIL_FAIL });
  }
}

function* unsubscribeNewEventsEmailAsync(action) {
  try {
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/user/unsubscribeNewEventEmail",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();

    if (data.status_code === 200) {
      yield put({ type: C.UNSUBSCRIBE_NEW_EVENTS_EMAIL_SUCCESS });
    } else {
      yield put({ type: C.UNSUBSCRIBE_NEW_EVENTS_EMAIL_FAIL });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while unsubscribing new events email: " },
    });
    yield put({ type: C.UNSUBSCRIBE_NEW_EVENTS_EMAIL_FAIL });
  }
}

function* unsubscribeShiftPostedAlertEmailAsync(action) {
  try {
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/user/unsubscribeShiftPostedAlertEmail",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();

    if (data.status_code === 200) {
      yield put({ type: C.UNSUBSCRIBE_SHIFT_POSTED_ALERT_EMAIL_SUCCESS });
    } else {
      yield put({ type: C.UNSUBSCRIBE_SHIFT_POSTED_ALERT_EMAIL_FAIL });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while unsubscribing shift posted alert email: " },
    });
    yield put({ type: C.UNSUBSCRIBE_SHIFT_POSTED_ALERT_EMAIL_FAIL });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootUnsubscribeSaga() {
  yield takeEvery(C.UNSUBSCRIBE_VET_WEEKLY_EMAIL, unsubscribeVetWeeklyEmailAsync);
  yield takeEvery(C.UNSUBSCRIBE_NEW_EVENTS_EMAIL, unsubscribeNewEventsEmailAsync);
  yield takeEvery(C.UNSUBSCRIBE_SHIFT_POSTED_ALERT_EMAIL, unsubscribeShiftPostedAlertEmailAsync);
}
