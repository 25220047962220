import React, { useRef } from "react";

import { Slider } from "@roo-dev/ui-components";
import { SliderProps } from "@roo-dev/ui-components/dist/ui-components/Slider";

import { useIsVisibleOnScreen } from "../windowUtils";

export const TravelDistanceSlider = (
  props: Omit<SliderProps, "type" | "label" | "range" | "testId" | "tooltip"> & {
    tooltip: Omit<SliderProps["tooltip"], "open" | "formatter">;
  }
) => {
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useIsVisibleOnScreen(ref);

  return (
    <div ref={ref}>
      {/*  @ts-expect-error -- antd typed their slider props so that value cant be dynamically passedin */}
      <Slider
        {...props}
        type="blue"
        label="Travel distance"
        tooltip={{
          open: isVisible,
          formatter: (v) => `${v} miles`,
          ...props.tooltip,
        }}
        testId="travelDistanceSlider"
      />
    </div>
  );
};
