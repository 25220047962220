import moment from "moment-timezone";

import { getStates as fetchStates } from "../../api/utility/getStates";
import { SPECIALIZATION_TYPE } from "../../constants/dropDownConstants";

export const months = Array.from(Array(12).keys()).map((key) => ({
  value: String(key + 1),
  label: String(key + 1),
}));

export const pastYears = Array.from(Array(parseInt(moment().format("YYYY")) - 1960).keys()).map(
  (key) => ({
    value: String(1961 + key),
    label: String(1961 + key),
  })
);

export const futureYears = Array.from(Array(10).keys()).map((key) => ({
  value: String(Number(moment().format("YYYY")) + key),
  label: String(Number(moment().format("YYYY")) + key),
}));

export const specializationOptions = SPECIALIZATION_TYPE.map((specialization) => ({
  id: specialization.id,
  value: specialization.id,
  label: specialization.label,
}));

export type StateOption = Awaited<ReturnType<typeof getStates>>[number];

export const getStates = async () => getStateOptions(await fetchStates());

export const getStateOptions = (states: Awaited<ReturnType<typeof fetchStates>>) =>
  states.map(({ id, state_code: stateCode, state_name: label }) => ({
    id,
    name: "stateId",
    stateCode,
    value: id,
    label,
  }));

export const HOSPITAL_TYPE_OPTIONS = [
  {
    label: "General Practice",
    id: "isPrefGeneral",
  },
  {
    label: "Emergency",
    id: "isPrefEmergency",
  },
  {
    label: "High Volume/Low Cost",
    id: "isPrefHighVol",
  },
  {
    label: "Large Animal",
    id: "isPrefLargeAnimal",
  },
  {
    label: "Shelter",
    id: "isPrefShelter",
  },
  {
    label: "Surgery",
    id: "isPrefSurgery",
  },
  {
    label: "Specialty",
    id: "isPrefSpecialty",
  },
  {
    label: "Chiropractic",
    id: "isPrefChiropractic",
  },
  {
    label: "Laser therapy",
    id: "isPrefLaserTherapy",
  },
  {
    label: "Acupuncture",
    id: "isPrefAcupunture",
  },
  {
    label: "Urgent care",
    id: "isPrefUrgentCare",
  },
  {
    label: "Other",
    id: "isPrefOther",
  },
];

export const HOSPITAL_APPT_OPTIONS = [
  {
    label: "General Practice",
    id: "hasApptGeneral",
  },
  {
    label: "Emergency",
    id: "hasApptEmergency",
  },
  {
    label: "High Volume/Low Cost",
    id: "hasApptHighVol",
  },
  {
    label: "Large Animal",
    id: "hasApptLargeAnimal",
  },
  {
    label: "Shelter",
    id: "hasApptShelter",
  },
  {
    label: "Surgery",
    id: "hasApptSurgery",
  },
  {
    label: "Specialty",
    id: "hasApptSpecialty",
  },
  {
    label: "Chiropractic",
    id: "hasApptChiropractic",
  },
  {
    label: "Laser therapy",
    id: "hasApptLaser",
  },
  {
    label: "Acupuncture",
    id: "hasApptAcupuncture",
  },
  {
    label: "Urgent care",
    id: "hasApptUrgentCare",
  },
];

export const getAnimalTypeCheckboxes = (selectedValue: number) =>
  ANIMAL_TYPES.map((checkbox) => ({ ...checkbox, checked: selectedValue === checkbox.value }));

export const ANIMAL_TYPES = [
  {
    label: "Cheetah - fast paced, agile",
    id: 1,
    value: 1,
    type: "informational",
    name: "animalTypeId",
  },
  {
    label: "Horse - hard-working, faithful",
    id: 2,
    value: 2,
    type: "informational",
    name: "animalTypeId",
  },
  {
    label: "Dolphin - free-flowing, adaptable",
    id: 3,
    value: 3,
    type: "informational",
    name: "animalTypeId",
  },
  {
    label: "Falcon - laser-focused, determined",
    id: 4,
    value: 4,
    type: "informational",
    name: "animalTypeId",
  },
  {
    label: "Squirrel - fun-loving, energetic",
    id: 5,
    value: 5,
    type: "informational",
    name: "animalTypeId",
  },
  {
    label: "Octopus - multi-tasking, clever",
    id: 6,
    value: 6,
    type: "informational",
    name: "animalTypeId",
  },
];
