import { ToastType } from "../ProfilePanels/ProfilePanelTypes";

export const SHOW_NEW_HOSPITAL_PROFILE_PANEL = "SHOW_NEW_HOSPITAL_PROFILE_PANEL";
export const CLOSE_NEW_HOSPITAL_PROFILE_PANEL = "CLOSE_NEW_HOSPITAL_PROFILE_PANEL";
export const CHANGE_SHIFT_STATUS = "CHANGE_SHIFT_STATUS";
export const RELOAD_SHIFTS_FOR_CONTRACTOR = "RELOAD_SHIFTS_FOR_CONTRACTOR";
export const RESET_RELOAD_SHIFTS_FOR_CONTRACTOR = "RESET_RELOAD_SHIFTS_FOR_CONTRACTOR";
export const UPDATE_ACTIVE_TAB = "UPDATE_ACTIVE_TAB";

export enum HospitalProfilePanelTab {
  RequestShift = 1,
  HospitalSnapshot,
  MoreShifts,
}

export const profileTabNames = ["Request Shift", "Hospital Snapshot", "More Shifts"];

export const toastMessages = {
  [ToastType.ShiftRequested]: "Shift was successfully requested.",
  [ToastType.HospitalRated]: "Shift was successfully rated.",
  [ToastType.ShiftConfirmed]: "Shift was successfully confirmed.",
};
