import { combineReducers } from "redux";

import contractorBonusReducer from "../Admin/ContractorBonus/reducer/ContractorBonusReducer";
import adminEnterpriseReducer from "../Admin/Enterprise/reducers/EnterpriseReducer";
import FeedbackFormReducer from "../Admin/FeedbackForm/reducer/FeedbackFormReducer";
import internshipReducer from "../Admin/Internships/reducers/internshipReducer";
import manualSMSReducer from "../Admin/ManualSMS/reducer/ManualSMSReducer";
import ReferralUserReducer from "../Admin/Referral/reducers/ReferralReducer";
import shiftReducer from "../Admin/Shifts/reducers/shiftReducer";
import userReducer from "../Admin/Users/reducers/UserReducer";
import w9Reducer from "../Admin/W9/reducer/W9Reducer";
import CalendarReducer from "../calendar/reducers/CalendarReducer";
import AddCalendarNoteReducer from "../Common/AddCalendarNote/reducers/addCalendarNoteRedcuer";
import EditAdminRating from "../Common/EditRating/reducer/EditRatingReducer";
import ManageHospitalUserDetailsReducer from "../Common/ManageHospitalUserDetails/reducers/ManageHospitalUserDetailsReducer";
import ReferNewuserReducer from "../Common/ReferralInvite/reducers/ReferNewUserReducer";
import enterpriseReducer from "../Enterprise/reducer/enterpriseReducer";
import hospitalBillingReducer from "../Hospital/billing/reducer/hospitalBillingReducer";
import ManageHospitalUsersReducer from "../Hospital/ManageHospitalUsers/reducers/ManageHospitalUsersReducer";
import hospitalProfileReducer from "../Hospital/profile/reducer/hospitalProfileReducer";
import hospitalReducer from "../Hospital/reducer/hospitalReducer";
import rooUniversityHospitalReducer from "../Hospital/rooUniversityHospital/reducer/rooUniversityHospitalReducer";
import HospitalSettingsReducer from "../Hospital/settings/reducer/hospitalSettingsReducer";
import loaderReducer from "../loader/reducer/loaderReducer";
import LoginReducer from "../Login/Reducer/LoginReducer";
import RegistrationReducer from "../Registration/Reducer/RegistrationReducer";
import RegistrationEnterpriseReducer from "../Registration/RegistrationEnterprise/Reducer/RegistrationEnterpriseReducer";
import RegistrationHospitalReducer from "../Registration/RegistrationHospital/Reducer/RegistrationHospitalReducer";
import RegistrationRooInternalUserReducer from "../Registration/RegistrationRooInternalUser/Reducer/RegistrationRooInternalUserReducer";
import RegistrationStudentReducer from "../Registration/RegistrationStudent/Reducer/RegistrationStudentReducer";
import RegistrationTechReducer from "../RegistrationNew/Reducer/RegistrationTechReducer";
import RegistrationVetReducer from "../RegistrationNew/Reducer/RegistrationVetReducer";
import studentDashboardReducer from "../student/dashboard/reducer/dashboardReducer";
import studentReducer from "../student/reducer/studentReducer";
import techFirstTimerReducer from "../tech/firstTimer/reducers/firstTimeReducer";
import techProfileReducer from "../tech/profile/reducer/techProfileReducer";
import techSettingReducer from "../tech/settings/reducer/settingReducer";
import techShiftHistoryReducer from "../tech/techShiftHistory/reducer/techShiftHistoryReducer";
import techCalendarReducer from "../techCalendar/reducers/TechCalendarReducer";
import UnsubscribeReducer from "../Unsubscribe/reducers/UnsubscribeReducer";
import vetFirstTimerReducer from "../vet/firstTimer/reducers/firstTimeReducer";
import vetProfileReducer from "../vet/profile/reducer/vetProfileReducer";
import vetSettingReducer from "../vet/settings/reducer/settingReducer";
import vetShiftHistoryReducer from "../vet/vetShiftHistory/reducer/vetShiftHistoryReducer";
import vetCalendarReducer from "../vetCalendar/reducers/VetCalendarReducer";
import techDashboardReducer from "../vetTechDashboard/reducers/techDashboardReducer";
import vetDashboardReducer from "../vetTechDashboard/reducers/vetDashboardReducer";
// import internshipDetailsReducer from "../student/internshipDetails/reducer/internshipDetailsReducer";
// import externshipDetailsReducer from "../student/externshipDetails/reducer/externshipDetailsReducer";
import EventReducer from "../Admin/Events/reducer/EventReducer";
import externshipReducer from "../Admin/Externships/reducers/ExternshipReducer";
import PromosReducer from "../Admin/Promos/reducers/PromosReducer";
import UnsavedChangesReducer from "../Admin/Users/components/UnsavedChanges/reducer";
import bulkUploadReducer from "../BulkUpload/reducer/batchUploadReducer";
import chatReducer from "../Common/Chat/reducers/chatReducer";
import ExperimentReducer from "../Common/Experiment/reducer/ExperimentReducer";
import fearFreeReducer from "../Common/FearFree/reducers/reducers";
import NavigationReasonReducer from "../Common/NavigationReason/reducer/NavigationReasonReducer";
import PanelReducer from "../Common/Panels/reducer/PanelReducer";
import ToastReducer from "../Common/Toasts/reducer/ToastReducer";
import shiftRatingByHospitalReducer from "../Hospital/ShiftRatingByHospital/reducer/shiftRatingByHospitalReducer";
import HospitalProfilePanelReducer from "../HospitalProfilePanels/reducer";
import ProfilePanelReducer from "../ProfilePanels/reducer/profilePanelReducer";
import userPromoReducer from "../Promo/reducers/userPromoReducer";
import studentHospitalProfileReducer from "../student/studentHospitalProfile/reducer/studentHospitalProfileReducer";
import techOnboardingReducer from "../tech/onboarding/reducer/techOnboardingReducer";
import vetOnboardingReducer from "../vet/onboarding/reducer/vetOnboardingReducer";

// eslint-disable-next-line no-restricted-exports
export default combineReducers({
  registerRooInternalUser: RegistrationRooInternalUserReducer,
  registerHospital: RegistrationHospitalReducer,
  registerEnterprise: RegistrationEnterpriseReducer,
  calendar: CalendarReducer,
  registerVet: RegistrationVetReducer,
  registerStudent: RegistrationStudentReducer,
  registerTech: RegistrationTechReducer,
  login: LoginReducer,
  register: RegistrationReducer,
  hospital: hospitalReducer,
  adminEnterprise: adminEnterpriseReducer,
  enterprise: enterpriseReducer,
  vetDashboard: vetDashboardReducer,
  techDashboard: techDashboardReducer,
  hospitalProfile: hospitalProfileReducer,
  vetCalendar: vetCalendarReducer,
  techCalendar: techCalendarReducer,
  vetProfile: vetProfileReducer,
  techProfile: techProfileReducer,
  vetShiftHistory: vetShiftHistoryReducer,
  techShiftHistory: techShiftHistoryReducer,
  shiftRatingByHospital: shiftRatingByHospitalReducer,
  user: userReducer,
  student: studentReducer,
  studentDashboard: studentDashboardReducer,
  // internshipDetails: internshipDetailsReducer,
  // externshipDetails: externshipDetailsReducer,
  studentHospitalProfile: studentHospitalProfileReducer,
  loader: loaderReducer,
  hospitalSettings: HospitalSettingsReducer,
  vetSettingReducer: vetSettingReducer,
  techSettingReducer: techSettingReducer,
  adminShift: shiftReducer,
  adminInternships: internshipReducer,
  rooUniversityHospital: rooUniversityHospitalReducer,
  hospitalBilling: hospitalBillingReducer,
  vetFirstTimer: vetFirstTimerReducer,
  techFirstTimer: techFirstTimerReducer,
  manualSMS: manualSMSReducer,
  w9: w9Reducer,
  manageHospitalUsers: ManageHospitalUsersReducer,
  manageHospitalUserDetailsReducer: ManageHospitalUserDetailsReducer,
  referNewUser: ReferNewuserReducer,
  adminReferral: ReferralUserReducer,
  unsubscribe: UnsubscribeReducer,
  addCalendarNote: AddCalendarNoteReducer,
  editAdminRating: EditAdminRating,
  shiftFeedbackForm: FeedbackFormReducer,
  contractorBonus: contractorBonusReducer,
  event: EventReducer,
  promos: PromosReducer,
  vetOnboarding: vetOnboardingReducer,
  adminExternships: externshipReducer,
  chatData: chatReducer,
  techOnboarding: techOnboardingReducer,
  bulkUpload: bulkUploadReducer,
  experiment: ExperimentReducer,
  toast: ToastReducer,
  panel: PanelReducer,
  userPromos: userPromoReducer,
  profilePanel: ProfilePanelReducer,
  hospitalProfilePanel: HospitalProfilePanelReducer,
  fearFreeData: fearFreeReducer,
  unsavedChanges: UnsavedChangesReducer,
  navigationReason: NavigationReasonReducer,
});
