import _ from "lodash";

import * as C from "../../../constants/techConstants";

const getHospitalProfileInitalState = () => ({
  hospitalName: "",
  hospitalWebsite: "",
  medicalDirector: "",
  roleId: null,

  typeOfHospital: null,
  isAahaAccredited: "",
  averageNoOfDVMWorkPerDay: "",
  averageNoOfTechPerDvmPerDay: "",
  typicalLunchBreakSetup: "",

  practiceManagementSoftware: "",

  hospitalReliefWork: "",
  hospitalMaintainRecords: "",
  hospitalDressCodes: "",

  hasApptGeneral: "",
  hasApptWellness: "",
  hasApptWalkin: "",
  hasApptNonwell: "",
  hasApptEmergency: "",
  hasApptHighVol: "",
  hasApptLargeAnimal: "",
  hasApptShelter: "",
  hasApptSurgery: "",
  hasApptSpecialty: "",
  hasApptDental: "",
  hasApptAcupuncture: "",
  hasApptChiropractic: "",
  hasApptLaser: "",
  hasApptUrgentCare: "",

  apptFlow: "",
  clientInteract: "",
  staffInteract: "",

  amenitiesNotes: "",
  parkingNotes: "",
  errorMessage: "",
});

const getHospitalUserInitialState = () => ({
  additionalSkills: "",
  characterTypeId: null,
  city: "",
  fullName: "",
  hospitalAddress: "",
  hospitalPhoneNumber: "",
  mobilePhoneNumber: "",
  stateId: null,
  zipcode: null,
});

const initialState = {
  isTechProfileFetched: false,
  hospitalViewDetails: {
    hospital: getHospitalProfileInitalState(),
    user: getHospitalUserInitialState(),
    profileImage: [],
    availableShifts: [],
  },

  techEditProfileDetails: {
    profileImage: [],
    user: {
      aptUnitSuite: "",
      city: "",
      emailId: "",
      fullName: "",
      address: "",
      mobilePhoneNumber: "",
      stateId: "",
      zipcode: "",
    },

    tech: {
      routingNumber: "",
      accountNumber: "",
      accountType: "",
      accountCreatedOn: "",
    },
    techAnimalType: [],
    techSpecialization: [],
    techTaxpayerInfo: [],
  },
};

// eslint-disable-next-line default-param-last
const techProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case C.TECH_FETCH_HOSPITAL_VIEW_PROFILE_SUCCESS:
      return {
        ...state,
        hospitalViewDetails: {
          hospital: action.payload.hospital[0],
          user: action.payload.user[0],
          profileImage: action.payload.profileImage,
          availableShifts: [],
        },
      };

    case C.CLEAR_AVAILBLE_SHIFT:
    case C.TECH_FETCH_HOSPITAL_VIEW_PROFILE_FAIL:
      return {
        ...state,
        hospitalViewDetails: {
          hospital: getHospitalProfileInitalState(),
          user: getHospitalUserInitialState(),
          profileImage: [],
          availableShifts: [],
        },
      };

    case C.FETCH_HOSPITAL_AVAILABLE_TECH_SHIFT_SUCCESS:
      if (action.payload.data !== undefined) {
        return {
          ...state,
          hospitalViewDetails: {
            ...state.hospitalViewDetails,
            availableShifts: [...action.payload.data],
          },
        };
      } else {
        return {
          ...state,
          hospitalViewDetails: {
            ...state.hospitalViewDetails,
            availableShifts: [],
          },
        };
      }

    case C.REQUEST_AVAILABLE_TECH_SHIFT_SUCCESS:
      let shiftIndex = null;
      const updatedAvailableShift = _.cloneDeep(state.hospitalViewDetails.availableShifts);
      updatedAvailableShift.map((shift, index) => {
        if (shift.shiftGroupId === action.payload.data.shiftGroupId) {
          shiftIndex = index;
        }
      });
      updatedAvailableShift.splice(shiftIndex, 1);
      return {
        ...state,
        hospitalViewDetails: {
          ...state.hospitalViewDetails,
          availableShifts: updatedAvailableShift,
        },
      };

    case C.FETCH_TECH_PROFILE_SUCCESS:
      return {
        ...state,
        techEditProfileDetails: {
          ...state.techEditProfileDetails,
          profileImage: action.payload.profileImage,
          user: action.payload.user[0],
          tech: action.payload.tech[0],
          techAnimalType: action.payload.techAnimalType,
          techSpecialization: action.payload.techSpecilization,
        },
        isTechProfileFetched: true,
      };

    case C.FETCH_TECH_PROFILE_FAIL:
      return {
        ...state,
      };

    case C.REQUEST_AVAILABLE_TECH_SHIFT_FAIL:
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
      };

    case C.CLOSE_ERROR_MODAL:
      return {
        ...state,
        errorMessage: "",
      };

    case C.UPDATE_TECH_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        techEditProfileDetails: {
          ...state.techEditProfileDetails,
          profileImage: action.payload.techImage,
        },
      };

    case C.DELETE_TECH_PROFILE_IMAGE_SUCCESS: {
      return {
        ...state,
        techEditProfileDetails: {
          ...state.techEditProfileDetails,
          profileImage: [],
        },
      };
    }
    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default techProfileReducer;
