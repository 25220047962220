import React, { useEffect, useState } from "react";

import { ROO_MAIL } from "../constants/constants";
import { breakpoints } from "../constants/responsiveBreakpoints";

const TermsOfUse = (props) => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const isMobileWindow = window.innerWidth < breakpoints.tablet;
    if (isMobileWindow) setIsMobile(true);
  }, []);

  const isModal = props.modal;
  const titleStyle = {
    fontFamily: "Jost",
    fontWeight: "600",
    fontSize: isMobile ? "20px" : "32px",
    lineHeight: isMobile ? "28.9px" : "46.24px",
    color: "#303031",
  };

  const bodyStyle = {
    fontWeight: "500",
    fontSize: isMobile ? "14px" : "16px",
    lineHeight: isMobile ? "21px" : "24px",
    color: "#303031",
  };

  const linkStyle = {
    fontWeight: "600",
    fontSize: isMobile ? "14px" : "16px",
    lineHeight: isMobile ? "21px" : "24px",
    color: "#007A4C",
    backgroundColor: "transparent",
  };
  return (
    <div className={isModal ? "body-content" : "container"}>
      <div className={`${isModal ? "" : " col-md-10 offset-md-1 pb-5"}`}>
        {isModal ? null : (
          <p
            style={{
              color: "#412894",
              fontWeight: "700",
              fontSize: isMobile ? "40px" : "56px",
              lineHeight: isMobile ? "60px" : "84px",
              marginTop: "40px",
            }}
          >
            ROO TERMS OF SERVICE AND USER AGREEMENT
          </p>
        )}
        <p
          style={{
            fontFamily: "Jost",
            fontWeight: "500",
            fontSize: isMobile ? "18px" : "20px",
            lineHeight: isMobile ? "26px" : "28.9px",
            color: "#59595A",
          }}
        >
          EFFECTIVE: September 1, 2024
        </p>
        <p style={titleStyle}>1. AGREEMENT TO AND ACCEPTANCE OF USER AGREEMENT</p>
        <p style={bodyStyle}>
          Roo Veterinary, Inc. (“Roo”) makes available the Roo mobile website and the associated
          technology platform (together with any other website, any mobile application software and
          any technology platform Roo may provide now or in the future, collectively, the “Roo
          Platform”), all information and other content on the Roo Platform (collectively, the
          “Content”), all services available through the Roo Platform (collectively, the “Services”)
          pursuant to the terms and conditions set forth below and the terms and conditions
          incorporated herein by reference (collectively, this “User Agreement”), which is a legally
          binding agreement between Roo and the individual or professional organization who
          registers for an account as a described herein (“you” or the “User”). Any individual who
          registers any User hereby represents and warrants that (a) he or she has the authority to
          act on behalf of the User and is authorized to bind the User to this User Agreement, and
          (b) he or she (or the User) has complied with, and completed, all applicable registration
          procedures and requirements with respect to the User.
        </p>
        <p style={bodyStyle}>
          PLEASE READ THIS USER AGREEMENT CAREFULLY. YOU MAY ACCEPT THIS USER AGREEMENT DURING THE
          ACCOUNT REGISTRATION PROCESS OR LOGIN PROCESS, WHICH CONTAIN THE EXPRESS ACKNOWLEDGEMENT
          THAT YOU HAVE READ AND AGREE TO THIS USER AGREEMENT. YOU MAY ALSO ACCEPT THIS USER
          AGREEMENT BY OTHERWISE INDICATING YOUR ASSENT TO THESE TERMS OR BY ACCESSING OR USING THE
          ROO PLATFORM OR ANY SERVICES. YOUR ACCEPTANCE, BY ANY OF THE FOREGOING METHODS,
          CONSTITUTES YOUR AGREEMENT TO BE BOUND BY THIS USER AGREEMENT. IF YOU DO NOT EXPRESSLY
          AGREE TO ALL OF THE TERMS AND CONDITIONS CONTAINED IN THIS USER AGREEMENT, THEN ROO DOES
          NOT AUTHORIZE YOU TO ACCESS OR USE THE ROO PLATFORM OR ANY SERVICES.
        </p>
        <p style={bodyStyle}>
          ARBITRATION NOTICE: AS SET FORTH IN GREATER DETAIL IN THE “DISPUTE RESOLUTION” SECTION
          BELOW, DISPUTES BETWEEN YOU AND ROO WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION
          AND YOU ARE WAIVING YOUR RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE AS A PLAINTIFF OR
          CLASS MEMBER IN ANY PURPORTED CLASS ACTION OR REPRESENTATIVE PROCEEDING, SUBJECT IN EACH
          CASE TO THE LIMITED EXCEPTIONS DESCRIBED IN THE “ARBITRATION” SECTION BELOW.
        </p>
        <p style={titleStyle}>2. AMENDMENTS TO THIS USER AGREEMENT</p>
        <p style={bodyStyle}>
          You understand that Roo may amend this User Agreement from time to time on a prospective
          basis without notice to you. A link to this User Agreement is presented each time you log
          in to the Roo Platform and, by completing the login process, you accept any amendments
          made since your last acceptance of the terms of this User Agreement. Accordingly, you
          should review this User Agreement each time you elect to log in to the Roo Platform. Roo
          may elect to provide you with e-mail, text or other notice of any amendment this User
          Agreement (and may summarize any such amendment), but neither the failure to provide, nor
          any inaccuracy in, any such notice shall affect your acceptance of the modified terms of
          this User Agreement by logging into the Roo Platform.
        </p>
        <p style={bodyStyle}>
          IN THE EVENT THAT YOU DO NOT AGREE TO ANY CHANGE TO THIS USER AGREEMENT, YOU MAY REFUSE
          SUCH CHANGE BY DISCONTINUING ALL ACCESS TO THE ROO PLATFORM AND CEASING ALL USE OF THE
          SERVICES. IF YOU CONTINUE TO ACCESS THE ROO PLATFORM OR USE THE SERVICES IN ANY MANNER
          AFTER SUCH CHANGE BECOMES EFFECTIVE, SUCH ACCESS OR USE CONSTITUTES YOUR ACCEPTANCE OF THE
          MODIFIED USER AGREEMENT.
        </p>
        <p style={titleStyle}>3. DESCRIPTION OF ROO PLATFORM AND SERVICES OFFERED</p>
        <p style={bodyStyle}>
          The Roo Platform is a marketplace where licensed veterinarians and other qualified
          veterinary professionals who are registered as users of the Roo Platform (“Professionals”)
          can be matched with veterinary hospitals, veterinarian offices or other facilities engaged
          in the practice of veterinary medicine who are registered as users of the Roo Platform
          (“Practices”), where the Practices are seeking the services of a Professional on a
          contract basis to perform veterinary services as directed by the Practice (“Veterinary
          Services”), under temporary working arrangements consisting of one or more full or partial
          day timeframes (each a “Shift”). Professionals and Practices may be individually referred
          to herein as a “User” and collectively referred to herein as “Users.” The Roo Platform and
          Services are intended for professional and commercial use solely by licensed veterinarians
          and other qualified veterinary professionals and businesses engaged in the lawful practice
          of veterinary medicine.
        </p>
        <p style={bodyStyle}>
          The Roo Platform allows a Practice to identify potential Shifts for which it is seeking
          Professionals to perform Veterinary Services and for one or more Professionals to offer to
          perform Veterinary Services for such prospective Shifts. To the extent that one or more
          Professionals offer to perform Veterinary Services for any prospective Shift identified by
          a Practice, the Practice will select from among such offers to fill the prospective Shift
          within the time period established by the Roo Platform for such circumstances. You
          acknowledge and agree that any decision by a Professional to offer a Shift is a decision
          made in such Professional’s sole discretion and any decision by a Practice to accept a
          Shift offered by a Professional is a decision made in such Practice’s sole discretion. Roo
          provides a marketplace for Professionals and Practices to come together and shall not be
          liable to its Users related to any Veterinary Services and by accepting the terms and
          conditions of this User Agreement, Professionals and Practices hereby agree to hold Roo
          harmless and indemnify Roo for any third party claims related thereto.
        </p>
        <p style={bodyStyle}>
          At its discretion, Roo may offer additional Services, or update, revise or otherwise
          modify the Roo Platform and any Services or Content, in which case this User Agreement
          shall apply to the modified Roo Platform and any and all such additional or modified
          Services or Content. Roo reserves the right to discontinue, suspend, cancel or otherwise
          cease offering the Roo Platform or any Services or Content, with or without prior notice,
          either generally or specifically with respect to you. You agree that Roo shall not be held
          liable for any such additions, updates, revisions, modifications, discontinuances,
          suspensions, cancellation or cessation of any of the Roo Platform, Services and/or
          Content. You also agree that you are not relying on any statements or expectations
          relating to any potential future functionality or features of the Roo Platform, Services
          and/or Content.
        </p>
        <p style={bodyStyle}>
          If you receive access to any trial, free, or beta functionality, Content, Services,
          components, applications, versions, environments, instances, enhancements, or extensions
          of the Roo Platform (collectively, “Beta Services”), which may be made available to you in
          connection with testing or evaluation and may be marked or otherwise indicated as trial,
          test, beta, pilot, preview, non-production, evaluation, or another similar description,
          Roo will make such Beta Services available to you, subject to the terms of this User
          Agreement, until the earlier of (a) the end of the applicable trial period, or (b)
          suspension or termination by Roo, in its sole discretion, of access to such Beta Services.
          Notwithstanding anything to the contrary in this User Agreement (and to the maximum extent
          permissible under applicable law), any such Beta Services are provided on an “AS IS” AND
          WITH ALL FAULTS basis, and you assume all risks associated with, and Roo shall not have
          any obligations or liability arising from or related to, any such Beta Services (or any
          use thereof). You acknowledge that Beta Services are not a final, generally available
          commercial product and are solely for the testing or evaluation purpose(s) expressly
          authorized by Roo, and you agree to provide Roo with reasonable feedback and cooperation
          in connection with such purpose(s). Additional terms and conditions may appear on Roo’s
          website or the Roo Platform regarding Beta Services, and any such additional terms and
          conditions are incorporated into this User Agreement by reference. For clarity, except as
          otherwise set forth herein, the Roo Platform, Content and/or Services (as applicable)
          include the Beta Services for the purposes of this User Agreement.
        </p>
        <p style={titleStyle}>4. REGISTRATION AND LOGIN</p>
        <p style={bodyStyle}>
          Access to the Roo Platform and the Services offered on the Roo Platform are available only
          to Users who complete the registration process (including express acceptance of this User
          Agreement during such registration process) and meet the requirements imposed by Roo (or
          as required by applicable law) to participate as a Professional or the requirements
          imposed by Roo to participate as a Practice. Roo reserves the right to refuse any or all
          of the Services to (and to refuse registration of or deactivate the account of) anyone at
          any time, in its sole discretion. Without limitation to the foregoing, Roo reserves the
          right to refuse registration to (or suspend or terminate access to the Roo Platform by or
          deactivate the account of) anyone who has previously had a prior registered account
          suspended or terminated for any reason.
        </p>
        <p style={bodyStyle}>
          When you register to access the Roo Platform, you will select a user name and a password
          so that you can access your account at the Roo Platform. You agree that you will keep your
          password and any other access credentials (collectively, “Password”) confidential and that
          you are responsible for maintaining the secrecy and confidentially of your Password. You
          further agree that you are solely responsible for activities regarding your account and
          Password (including activities by third parties using your user name and Password or using
          a login session you commenced). If you think there has been unauthorized use of your
          account or Password, you agree to immediately notify Roo and cooperate to resolve the
          issue. You understand that if you do not follow the terms of this paragraph, Roo may
          suspend or terminate your account, which will prevent your use of the Roo Platform. You
          understand that Roo is not responsible in any way and will not be liable to you or to any
          third person, if you do not comply with the terms of this paragraph. You may only create
          one account for any User, and Roo reserves the right to shut down any additional accounts.
        </p>
        <p style={titleStyle}>5. ACCURACY OF INFORMATION PROVIDED</p>
        <p style={bodyStyle}>
          When you register to access the Roo Platform and participate in the Services and from time
          to time when you use the Roo Platform and/or the Services, you will be required to provide
          additional information and data about yourself or your use of the Services and you may be
          required to upload copies of documents or other materials (including, without limitation,
          verification of identity, professional licensing information and payment information). You
          hereby represent, warrant and covenant that (a) all information you provide in the
          registration process, through the functionality of the Roo Platform or otherwise in
          connection with your use of the Services is and will be accurate, correct, current and
          complete, (b) all documents and other materials you provide in the registration process,
          through the functionality of the Roo Platform or otherwise in connection with your use of
          the Services are and will be accurate, correct, current and complete, and (c) you will
          maintain and promptly update your registration and profile information and all other
          information you have provided and all documentation and materials you have provided, so as
          to maintain their accuracy, correctness, contemporaneousness and completeness at all
          times. Without limitation to the foregoing, you represent and warrant that (i) if you
          register as a Professional, you are the individual identified during the registration
          process and that you are providing your complete and accurate legal name, (ii) if you
          register as a Practice which is an individual (for example, in the case of a solo
          practitioner), you have completely and accurately identified the full legal name of the
          individual operating as the Practice, and (iii) if you register as a Practice which is an
          organization (for example, in the cases of a partnership or a professional corporation),
          you have completely and accurately identified the full legal name of the organization
          operating as the Practice and the nature of the organization operating as the Practice and
          you are authorized to act on behalf of the Practice and bind the Practice to this User
          Agreement.
        </p>
        <p style={bodyStyle}>
          You agree that Roo may obtain, and hereby authorize Roo to obtain, information about you,
          including background, credit and other checks with respect to any criminal, professional
          or financial records, and you agree to provide any further necessary authorizations to
          facilitate such checks or Roo’s access to such records. In the event that Roo determines
          that any information, documents or materials you submit through the Roo Platform or
          otherwise provide to Roo are not accurate, correct, current and complete in all respects
          or the results of any investigation performed by Roo are not satisfactory to Roo in its
          sole discretion, Roo reserves the right to suspend or terminate your access to the Roo
          Platform and the Services and/or deactivate your account, in its sole discretion.
        </p>
        <p style={titleStyle}>6. PRIVACY POLICY</p>
        <p style={bodyStyle}>
          Information that you provide to Roo through the Roo Platform or Services ("Your Data")
          will be treated by Roo in compliance with Roo’s&nbsp;
          <a
            style={linkStyle}
            className="marketing-links-8"
            href="https://roo.vet/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            <u>Privacy Policy</u>
          </a>
          &nbsp;(the “Privacy Policy”) regarding personal information. You hereby agree to the terms
          of the Privacy Policy, which is hereby incorporated into and made a part of this User
          Agreement. You hereby grant to Roo a worldwide, non-exclusive license to use Your Data to
          perform, maintain, support, and improve the Services (and as otherwise authorized in this
          User Agreement). You represent and warrant that you have all rights and consents necessary
          to grant the foregoing license. In addition, Roo may use Your Data (or any data based on
          or derived from Your Data or your use of the Roo Platform or Services) in an aggregated or
          de-identified format, with or without data provided by other third parties, for any
          purposes not prohibited by applicable law, provided that Roo will not disclose such
          aggregated or de-identified data to third parties (other than those acting on Roo’s
          behalf) unless it is not reasonably possible for third parties to identify you from such
          aggregated or de-identified data.
        </p>
        <p style={titleStyle}>7. ARBITRATION</p>
        <p style={bodyStyle}>
          Any, claim, controversy, cause of action and/or dispute (each a “Claim”) either Roo or you
          have arising out of this User Agreement or otherwise relating to the Roo Platform, the
          Services or the Content shall be resolved by a single impartial arbitrator pursuant to
          proceedings administered by the American Arbitration Association under its rules for
          resolution of commercial disputes, except that each party retains the right: (a) to bring
          an individual action in small claims court and (b) to seek injunctive or other equitable
          relief in a court of competent jurisdiction to prevent the actual or threatened
          infringement, misappropriation, or violation of a party’s copyrights, trademarks, trade
          secrets, patents, or other intellectual property rights. The arbitration shall be held in
          Houston, Texas. All submissions to the arbitrator, the proceedings and the award shall be
          confidential. The arbitration shall be conducted on an expedited basis with minimal
          discovery. The arbitrator’s award shall be final and binding. Judgment on the arbitration
          award may be entered in any court having jurisdiction thereof. EACH PARTY IRREVOCABLY
          WAIVES ANY AND ALL RIGHT TO TRIAL BY JURY IN ANY LEGAL PROCEEDING ARISING OUT OF OR
          RELATED TO THIS USER AGREEMENT. Neither party consents or agrees to any arbitration on a
          class or representative basis, and the arbitrator shall have no authority to proceed with
          an arbitration on a class or representative basis. No arbitration will be consolidated
          with any other arbitration proceeding without the consent of all parties. Any claim or
          controversy as to the enforceability of this arbitration provision’s restriction on your
          right to participate in or pursue a class action or class- wide arbitration shall be
          brought only in the United States District Court for the Southern District of Texas or any
          State of Texas court located in Harris County, Texas. Each party agrees to unconditionally
          and irrevocably submit to the exclusive jurisdiction and venue of such courts and agree to
          not object to such jurisdiction and venue on the grounds of lack of personal jurisdiction,
          inconvenient forum or otherwise.
        </p>
        <p style={bodyStyle}>
          Notwithstanding the foregoing, in the event that Roo asserts a right to indemnification
          under this User Agreement with respect to any third party action filed and pending in the
          court of another jurisdiction, Roo may assert such claim for indemnification in the court
          in which such third party action has been filed and the parties agree that both parties’
          interests and judicial economy are served by resolving the indemnification claim in the
          same proceeding as the underlying third party claim subject to indemnification.
        </p>
        <p style={titleStyle}>8. PERFORMANCE OF VETERINARY SERVICES DURING SHIFTS</p>
        <p style={bodyStyle}>
          To the extent that you are a Professional performing Veterinary Services as a result of
          use of the Roo Platform, you represent, warrant, covenant and agree that:
        </p>
        <ol type="1">
          <li style={bodyStyle}>
            To the extent that you indicate through the Roo Platform that you are a licensed
            veterinarian or veterinary professional (rather than a non-licensed veterinary
            professional), you are duly licensed as indicated by the applicable governmental
            authority in the jurisdiction in which the Veterinary Services are performed.
          </li>
          <li style={bodyStyle}>
            You have all of the qualifications, credentials and/or certifications identified by you
            through your interactions through the Roo Platform (including any qualifications,
            credentials and/or certifications you specify in your profile or in your communications
            with the relevant Practice) and all of the information, data, images, documents and
            other materials you provide to the Practice through the Roo Platform or otherwise is
            accurate, correct, current and complete and you will promptly update such materials to
            ensure they remain accurate, correct, current and complete. You will promptly update
            your qualifications, credentials and/or certifications and all of the information, data,
            images, documents and other materials you make available through the Roo Platform after
            any changes in the status thereof to ensure they remain accurate, correct, current and
            complete.
          </li>
          <li style={bodyStyle}>
            You will perform the Veterinary Services as directed by the Practice in a professional,
            competent and timely manner, in accordance with all applicable laws, regulations, self-
            governing professional organizational rules and best industry practices. Without
            limitation to the foregoing, you will arrive at the Practice on time for your Shift and
            perform the Veterinary Services for the entire Shift, unless otherwise agreed with the
            Practice and documented through the Roo Platform.
          </li>
          <li style={bodyStyle}>
            You will not demand or request, and will not accept, any payment or compensation from
            any Practice for any Veterinary Services or other activity you perform during any Shift,
            otherwise alter the scope, nature or duration of the Veterinary Services to be performed
            during any Shift, except through transactions completed through the Roo Platform, or
            otherwise engage in any activity that is inconsistent with your obligations under this
            User Agreement.
          </li>
          <li style={bodyStyle}>
            You will pay all applicable federal, state and local taxes based on your provision of
            Veterinary Services and any payments received by you in connection therewith. You have
            provided, or will at the request of Roo at any time provide, an executed Form W-9 or
            other tax document as may be required by Roo. You acknowledge that Roo will report
            compensation paid to you as required by applicable law and at no time shall Roo be
            responsible for paying, withholding or otherwise reporting any federal taxes, state
            taxes, or local taxes or other contributions imposed by or required under any federal,
            state or local tax statute or taxing authority.
          </li>
          <li style={bodyStyle}>
            To the extent that you indicate through your interactions through the Roo Platform that
            you maintain such a policy of professional liability insurance, you have a valid policy
            of such professional liability insurance (in coverage amounts consistent with all
            applicable legal requirements and any additional requirements specified through the Roo
            Platform) that covers your performance of Veterinary Services.
          </li>
        </ol>
        <p style={bodyStyle}>
          In the event that you are a Professional who does not perform the Veterinary Services in
          accordance with the requirements of this User Agreement (including, by way of example and
          not limitation, if you do not arrive at the Practice on time at the commencement of your
          Shift or perform the Veterinary Services for the entire Shift, unless otherwise agreed
          with the Practice through the Roo Platform), Roo may suspend or terminate your access to
          the Roo Platform and the Services and/or deactivate your account, in its sole discretion,
          and may, to the extent permitted by law, reduce or eliminate the Shift Compensation
          otherwise payable to you for the Shift.
        </p>
        <p style={bodyStyle}>
          To the extent that you are a Practice retaining a Professional to perform Veterinary
          Services as a result of use of the Roo Platform, you represent, warrant, covenant and
          agree that:
        </p>
        <ol type="1">
          <li style={bodyStyle}>
            You are duly authorized to operate your Practice and offer and engage in the practice of
            veterinary medicine by the applicable governmental authority in the jurisdiction in
            which the Veterinary Services are performed. You will operate your Practice in
            accordance with all applicable laws, regulations, self-governing professional
            organizational rules and best industry practices.
          </li>
          <li style={bodyStyle}>
            You have all of the qualifications, credentials and/or certifications identified by you
            through your interactions through the Roo Platform (including any qualifications,
            credentials and/or certifications you specify in your profile or in your communications
            with the relevant Professionals) and all of the information, data, images, documents and
            other materials you have or will provide to any Professional through the Roo Platform or
            otherwise is accurate, correct, current and complete. You will promptly update your
            qualifications, credentials and/or certifications and all of the information, data,
            images, documents and other materials you make available through the Roo Platform after
            any changes in the status thereof to ensure they remain accurate, correct, current and
            complete.
          </li>
          <li style={bodyStyle}>
            You will supervise the performance of the Veterinary Services and exercise due care and
            judgment in the utilization of any Professional performing Veterinary Services.
          </li>
          <li style={bodyStyle}>
            You will not pay any compensation or other amount to any Professional for any Veterinary
            Services or other activity performed during any Shift, otherwise alter the scope, nature
            or duration of the Veterinary Services to be performed during any Shift, except through
            transactions completed through the Roo Platform, or otherwise engage in any activity
            that is inconsistent with your obligations under this User Agreement.
          </li>
        </ol>
        <p style={bodyStyle}>
          In the event that you are a Practice which does not comply with the requirements of this
          User Agreement, Roo may suspend or terminate your access to the Roo Platform and the
          Services and/or deactivate your account, in its sole discretion.
        </p>
        <p style={titleStyle}>9. PLACEMENTS</p>
        <p style={bodyStyle}>
          Users acknowledge and agree that, in reliance upon the terms of this User Agreement, Roo
          is providing Practices with access to valuable proprietary information through the Roo
          Platform concerning Professionals seeking work as veterinary professionals and is
          providing Professionals with access to valuable information through the Roo Platform
          concerning Practices seeking Professionals who are willing and able to provide veterinary
          services. Users further acknowledge and agree that the creation and operation of the Roo
          Platform required and requires the utilization of substantial resources by Roo and that
          Roo would not facilitate the matching of Practices and Professionals, and the attendant
          exchange of proprietary information, in the absence of the terms contained in this Section
          9 (“Placements”) and that the terms set forth in this Section 9 (“Placements”) are
          reasonable.
        </p>
        <p style={bodyStyle}>
          Accordingly, (a) each Practice agrees that it shall not, except by payment to Roo of the
          applicable placement fee (as posted on the Roo Platform or otherwise communicated by Roo,
          the “Placement Fee”), hire or offer to hire as an employee, whether on a fulltime or
          part-time basis, any Professional who has performed or offered to perform any Veterinary
          Services for such Practice for a Shift or with whom such Practice has come into contact
          through the Roo Platform during the twelve month period prior to such offer, hire or
          retention, and (b) each User agrees that he, she or it shall not, except by payment to Roo
          of the applicable Placement Fee, hire any Roo employee who has come into contact with User
          through the Roo Platform during the twelve month period prior to such hire.
        </p>
        <p style={bodyStyle}>
          Each User agrees to promptly notify Roo (by email at fulltimeplacement@roo.vet or
          hello@roo.vet) of any prospective (or actual) solicitation, hire or placement that would
          be subject to a Placement Fee and to provide such information relating thereto as may be
          reasonably requested by Roo. Unless otherwise instructed by Roo, any Placement Fee will be
          invoiced by Roo, and paid by User, in accordance with the Invoicing Terms (as defined
          below).
        </p>
        <p style={bodyStyle}>
          Without limitation to its other rights and remedies under this User Agreement, in the
          event that any User(s) violate this Section 9 through an unpaid or unreported Placement
          (collectively, “Unauthorized Placements”), Roo reserves the right (a) to deactivate the
          account of, or otherwise suspend or terminate access to the Roo Platform by, any such
          User(s), (b) to recover from such User(s) the Placement Fee (and Roo is authorized to
          charge the payment method on file in accordance with Section 12 (“Payment Methods”) set
          forth below, including interest for late payment), and/or (c) recover all such other
          damages, costs and amounts Roo can demonstrate that it has sustained as a result of such
          Unauthorized Placement. To view Roo’s fee schedule for Placements, please click{" "}
          <a
            style={linkStyle}
            href="https://hello.roo.vet/permplacement"
            target="_blank"
            rel="noreferrer"
          >
            <u>here</u>
          </a>
          .
        </p>
        <p style={titleStyle}>
          10. PAYMENT OF SHIFT COMPENSATION OR PROMOTIONAL FEES TO PROFESSIONALS
        </p>
        <p style={bodyStyle}>
          Subject to the terms of this User Agreement, you are entitled to Shift Compensation for
          each Shift that you work as a Professional. Roo, acting as a pass-through entity,
          transfers such payment to you from the Practice through the Roo Platform. For clarity, Roo
          does not retain such funds as Roo’s own income. Payment will be made by direct deposit
          using the banking information you provide through the Roo Platform. You acknowledge that
          if the banking information you provide through the Roo Platform is incorrect or
          incomplete, or if you fail to keep it up to date, the payment of Shift Compensation may be
          misdirected or delayed. You also acknowledge that it often takes a few days for funds to
          be available after a payment is made, and you may consult your banking institution to find
          out more about payment timing. Practices and Professionals acknowledge that Roo's
          responsibility is limited to processing and forwarding payments of Shift Compensation as
          directed by Practices.
        </p>
        <p style={bodyStyle}>
          The “Shift Compensation” consists of the amount or rate agreed by you and the Practice at
          the time you and the Practice agree to the Shift. Roo reserves the right to adjust or
          withhold all or a portion of a Shift Compensation or other payment owed to you (i) if it
          believes that you have attempted to defraud or abuse the Practice, Roo or Roo’s payment
          systems, or (ii) in order to resolve a complaint raised by a Practice (e.g., a Practice
          alleges that you did not perform the Veterinary Services in accordance with the
          requirements of this User Agreement or the terms specified for the Shift). Roo’s decision
          to adjust or withhold the Shift Compensation or other payment in any way shall be
          exercised in a reasonable manner. Roo will use reasonable efforts to ensure that your
          Shift Compensation and any other payments to you will be paid to you on a regular basis as
          specified in the Roo Platform. You acknowledge and agree that all payments owed to you
          shall not include any interest and, to the extent that Roo is at any time required by law
          to withhold any amounts, you authorize it to do so.
        </p>
        <p style={bodyStyle}>
          From time to time Roo may offer Professionals a promotional award payment for reaching
          certain milestones (e.g., total number of Shifts) or for the referral of new professionals
          to Roo that are not already Users (collectively, the "Promotional Payments"). Where Roo
          offers Promotional Payments and a Professional complies with the terms and conditions of
          such promotion, subject to the terms of this User Agreement, Roo will issue the applicable
          Promotional Payment to qualifying Professionals. It is hereby agreed that any such
          Promotional Payment shall not be deemed a payment for services rendered from a
          Professional to Roo. Professionals hereby agree to indemnify and hold Roo harmless from
          any liability from a third party related to the payment of a Promotional Payment.
        </p>
        <p style={titleStyle}>11. PAYMENT OF COMPENSATION TO ROO</p>
        <p style={bodyStyle}>
          In exchange for the use of the Roo Platform and the services provided by Roo, you agree
          that Roo shall receive a service fee (“Shift Fee”) for each Shift for which a Professional
          is matched with a Practice to perform Veterinary Services through the Roo Platform. The
          Shift Fee for each Shift shall be a determined amount (or an amount that can be determined
          from the information supplied by the Professional and the Practice prior to the agreement
          to the Shift) as displayed to the Practice through the Roo Platform at the time of the
          acceptance of the Shift. In addition, you agree that Roo shall be reimbursed for any
          third-party charges (including processing fees) paid or payable by Roo in connection with
          any Shift (“Third Party Fees”). You agree that Roo has the authority and reserves the
          right to determine and modify pricing by posting applicable pricing terms in the Roo
          Platform or quoting you an amount or rate for any Shift at the time you make a request.
          You are responsible for reviewing, and remaining familiar with, the applicable price quote
          within the Roo Platform and shall be responsible for all charges incurred under your
          account regardless of your awareness of such charges or the amounts thereof. You also
          expressly authorize Roo’s service provider, Stripe, Inc. to originate credit transfers to
          your financial institution account. More information can be found at{" "}
          <a
            style={linkStyle}
            href="https://stripe.com/connect-account/legal/recipient"
            target="_blank"
            rel="noreferrer"
          >
            <u>https://stripe.com/connect-account/legal/recipient</u>
          </a>
          .
        </p>
        <p style={bodyStyle}>
          For each Shift, the Practice agrees to pay Roo the sum of (a) all compensation that the
          Practice is required to pay to the Professional performing the Shift, including the Shift
          Compensation payable to the Professional performing the Shift and, if applicable, any
          required withholding or other taxes, (b) the Shift Fee for the Shift, and (c) any
          applicable Third Party Fees. All such payments shall be made in accordance with Section 12
          (“Payment Methods”) set forth below. Each Practice acknowledges that the establishment
          through the Roo Platform of a payment mechanism in accordance with Section 12 (“Payment
          Methods”) is a prerequisite to utilizing the functionality of the Roo Platform.
        </p>
        <p style={bodyStyle}>
          In addition, Roo reserves the right to introduce or change fees at any time for, or in
          connection with, the Roo Platform, Content, and/or Services, and Roo will provide notice
          of any material changes to its applicable fees.
        </p>
        <p style={titleStyle}>12. PAYMENT METHODS</p>
        <p style={bodyStyle}>
          Each Practice may provide through the Roo Platform a valid credit card, debit card,
          prepaid charge card or other payment mechanism approved by Roo to facilitate payments owed
          by you to Roo under this User Agreement. Each Practice acknowledges that having a current
          and valid payment mechanism on file may be a precondition to its ability to utilize the
          Roo Platform and receive the Services.
        </p>
        <p style={bodyStyle}>
          You authorize Roo to charge any and all amounts payable by you under this User Agreement
          using any such payment mechanism on file with the Roo Platform, provided that if any
          payment amount cannot be processed with payment mechanism held by Roo, you shall make,
          upon Roo’s request, immediate payment of such amount by ACH, wire transfer or other
          payment method required by Roo. Upon addition of a new payment method, Roo may seek
          authorization of the selected payment method to verify the payment method, ensure payment
          charges will be covered, and protect against unauthorized behavior. The authorization is
          not a charge, but it may reduce your available credit by the authorization amount until
          your bank’s next processing cycle. Should the amount of the authorization exceed the total
          funds on deposit in your account, you may be subject to overdraft of NSF charges by the
          bank issuing your debit or prepaid card. Roo shall not be held responsible for these
          charges and is unable to assist you in recovering them from your issuing bank.
        </p>
        <p style={bodyStyle}>
          If expressly authorized by Roo (or this User Agreement), Roo will invoice User for certain
          amounts due hereunder pursuant to the following terms (collectively, the “Invoicing
          Terms”): (a) each such invoice is due and payable 60 days (or a different time period
          specified by Roo or this User Agreement) after the invoice date, (b) the outstanding
          balance of any payment not received from User by the due date shall accrue interest at
          1.5% per month (or, if lower, the maximum rate permitted by applicable law) from the date
          such payment is due until the date paid, and (c) User shall also pay all costs incurred
          (including reasonable legal fees) in collecting overdue amounts.
        </p>
        <p style={titleStyle}>13. OWNERSHIP OF ROO PLATFORM AND CONTENT ON ROO PLATFORM</p>
        <p style={bodyStyle}>
          Except for Your User-Generated Content (as described below), as between Roo and you, the
          Roo Platform (including, any Software Components), the Services and all information, text,
          software, data, photographs, music, video, messages, tags or any other Content made
          available through the Roo Platform or Services, and all intellectual property rights
          therein (including, without limitation, database rights, copyright, design rights,
          trademarks and other similar rights wherever existing in the world), are and shall remain
          the sole property of Roo and each User hereby assigns, conveys and transfers to Roo any
          rights it may acquire therein. The Roo Platform and the Content may be protected by
          copyrights, trademarks, service marks, patents or other proprietary rights and laws. Any
          use of Roo’s name (or any of Roo’s logos or other trademarks) will be subject to Roo’s
          express prior written (email acceptable) approval and will be solely for Roo’s benefit.
          Except as expressly authorized by Roo, and as a condition of your access to the Roo
          Platform, you shall not modify, rent, lease, loan, sell, distribute or create derivative
          works based on the Roo Platform or any Content, in whole or in part. Further, as a
          condition of your access to the Roo Platform, you will not attempt to hack, crack or
          otherwise access any portion of the Roo Platform which is not made available by Roo in the
          ordinary course of making the Roo Platform generally available to registered Users. Roo
          herein reserves the right to use security components that may permit digital Content to be
          protected (including, by way of example and not limitation, digital watermark technology),
          and you agree that use of protected Content is subject to usage guidelines established by
          Roo or any other providers supplying the Content. You shall not attempt to override or
          circumvent any such security components.
        </p>
        <p style={bodyStyle}>
          The Roo Platform may contain information, text, software, data, photographs, music, video,
          messages, tags and other Content submitted by Users or third parties (“User-Generated
          Content”). User-Generated Content may include, without limitation, User profile
          information, performance ratings or reviews, survey responses, forum postings, message
          board posts, and similar comments and feedback. You agree that all User-Generated Content,
          whether it is publicly or privately posted and/or transmitted, is the sole responsibility
          of the User from whom such User-Generated Content originated and, accordingly, that (i)
          you own or have a license to such User-Generated Content to allow you to transmit such to
          the Roo Platform, (ii) you are solely responsible for evaluating any and all
          User-Generated Content made available by way of the Roo Platform or Services, and (iii)
          Roo provides no assurances concerning the accuracy, integrity or quality of such
          User-Generated Content. It is expressly understood that by use of the Roo Platform or
          Services, you shall be responsible for any losses or liability to which you may be exposed
          as a result of User-Generated Content including, but not limited to, any errors or
          omissions in any User-Generated Content, and/or any loss or damage of any kind incurred as
          a result of the use of any User-Generated Content posted, emailed, transmitted or
          otherwise made available by Roo and you hereby agree to hold Roo harmless and indemnify
          Roo from any claims related thereto. Roo reserves the right to remove, redact or edit
          User-Generated Content posted or transmitted through the Roo Platform (including, by way
          of example and not limitation, be removing or condensing reviews posted by Users), but no
          exercise of such right shall make Roo responsible in any way for the contents of
          User-Generated Content.
        </p>
        <p style={bodyStyle}>
          Roo does not lay claim to ownership of any User-Generated Content which you may submit,
          provide or otherwise make available to Roo (“Your User-Generated Content”). Therefore, you
          hereby grant to Roo a worldwide, fully-paid, royalty-free, irrevocable, transferrable and
          fully- sub-licensable, non-exclusive right and license to use, permit the use of, copy,
          reproduce, distribute, publish, modify, adapt or otherwise create derivative works from,
          publicly perform, publicly display and otherwise exploit and utilize in any manner all of
          Your User-Generated Content, in accordance with the Privacy Policy.
        </p>
        <p style={titleStyle}>14. BROWSER CONFIGURATION AND SOFTWARE COMPONENTS</p>
        <p style={bodyStyle}>
          The Roo Platform may be accessed via a mobile website, in which case you may be required
          to have your browser configured to accept cookies. An explanation of cookies can be found
          in the Privacy Policy.
        </p>
        <p style={bodyStyle}>
          Roo may, now or in the future, make available mobile applications or other downloadable
          software or software components (including, without limitation, applications on various
          social media networking platforms or other mobile software) as part of the Roo Platform to
          access the Services (“Software Component”), in which event you agree that such Software
          Component is provided to you pursuant to the terms of the end user license made available
          with such Software Component. If no end user license is made available with such Software
          Component, then Roo grants you only a personal, non-transferable and non-exclusive license
          to make use of the object code of the Software Component on your personal device and, as a
          condition of such license grant, you shall not, duplicate, reproduce, alter, modify,
          reverse engineer, reverse assemble or otherwise make an attempt to locate or discern any
          source code (or underlying algorithms or structure), sell, assign, sublicense, grant a
          security interest in and/or otherwise transfer any such right in the Software Component.
          Furthermore, as a condition of the aforementioned license grant, you agree not to alter or
          change the Software Component in any manner, nature or form, and as such, not to use any
          modified versions of the Software Component, including and without limitation, for the
          purpose of obtaining unauthorized access to the Roo Platform or Services. You acknowledge
          and agree that the Software Component may automatically download and install upgrades,
          updates, or other new features. You may be able to adjust whether you receive these
          automatic downloads through your hardware’s settings.
        </p>
        <p style={titleStyle}>15. THIRD PARTY SERVICES AND CONTENT</p>
        <p style={bodyStyle}>
          The Services may be made available or accessed in connection with third party services and
          content (including advertising) that Roo does not control. You acknowledge that different
          terms of use and privacy policies may apply to your use of such third party services and
          content. Roo does not endorse such third party services and content and in no event shall
          Roo be responsible or liable for any products or services of such third party providers.
          Without limitation to the foregoing, to the extent that you download any Software
          Component from the Apple Store, Google Play, the Microsoft Store or another mobile
          application store or distribution platform operated by a third party (the “App Platform
          Provider”), your downloading of the Software Component shall be subject to any terms of
          service established by the App Platform Provider and you further acknowledge and agree
          that:
        </p>
        <ul>
          <li style={bodyStyle}>
            This User Agreement is between you and Roo, and not with the App Platform Provider. As
            between Roo and the App Platform Provider, Roo is solely responsible for the Software
            Component and all other aspects of the Roo Platform. Without limitation to the
            foregoing, the App Platform Provider has no obligation to furnish any maintenance and
            support services with respect to the Software Component or any other aspect of the Roo
            Platform.
          </li>
          <li style={bodyStyle}>
            The App Platform Provider and its subsidiaries are third-party beneficiaries of this
            User Agreement as relates to your license of the Software Component, and the App
            Platform Provider will have the right to enforce this User Agreement as related to your
            license of the Software Component against you as a third-party beneficiary hereunder.
          </li>
        </ul>
        <p style={titleStyle}>16. CONDUCT ON ROO PLATFORM</p>
        <p style={bodyStyle}>
          You agree, as a condition to the rights granted to you herein, to follow the following
          basic rules of conduct when using the Roo Platform and/or Services:
        </p>
        <ol type="1">
          <li style={bodyStyle}>
            You will comply with this User Agreement and all applicable laws.
          </li>
          <li style={bodyStyle}>
            You will not share your Password with anyone or transfer or sell, or attempt to transfer
            or sell, your user account and/or Password, and you will not use any other person’s
            Password.
          </li>
          <li style={bodyStyle}>
            You will not use the Roo Platform or the Services in connection with any discrimination
            against anyone on the basis of race, national origin, religion, gender, gender identity,
            physical or mental disability, medical condition, marital status, age, sexual
            orientation or other attribute of a protected class.
          </li>
          <li style={bodyStyle}>
            You will not harass, threaten or abuse other people when using the Roo Platform or
            Services in any manner, and you will not post, upload, post, email, or otherwise
            transmit to, through or in connection with the Roo Platform any Content that Roo
            determines to be unlawful, harmful, fraudulent, libelous, defamatory, vulgar, obscene,
            tortuous or illegal, or that Roo determines to be abusive, profane, harassing, hateful,
            and/or racially, ethnically, or otherwise objectionable.
          </li>
          <li style={bodyStyle}>
            You will not post, upload, post, email, or otherwise transmit to, through or in
            connection with the Roo Platform any Content in violation of any third party’s
            copyright, patent, trademark, trade secret, rights of publicity or privacy or other
            proprietary or intellectual property rights or otherwise use the Roo Platform in any way
            that infringes any third party’s proprietary or intellectual property rights.
          </li>
          <li style={bodyStyle}>
            You will not post, upload, post, email, or otherwise transmit to, through or in
            connection with the Roo Platform any Content that may contain a virus, cancelbot, Trojan
            horse, harmful code, or other computer code, files and/or programs which have been
            designed to interfere, interrupt, damage, destroy and/or limit the functionality or
            operation of any computer software, hardware, or telecommunication equipment or
            surreptitiously intercept or expropriate any system, data or personal information.
          </li>
          <li style={bodyStyle}>
            You will not impersonate any other person or misrepresent a relationship with any person
            or entity, including, without limitation, impersonating a Roo representative or
            misrepresenting a relationship with Roo, you will not adopt a false identity or deceive
            or defraud another User, and you will not access or use any functionality, Content,
            Services, components, applications, versions, environments, instances, enhancements, or
            extensions for which you are not expressly then-authorized by Roo.
          </li>
          <li style={bodyStyle}>
            You will not disrupt the normal flow of communication, or otherwise act in any manner
            that would negatively affect other Users’ ability to participate in any real time
            interactions through the Roo Platform or Services.
          </li>
          <li style={bodyStyle}>
            You will not manipulate the Roo Platform or the Services to hide your identity or
            participation in the Roo Platform or to disguise the origin of any information
            transmitted through the Roo Platform (including by using another person’s identity,
            changing headers, or otherwise modifying any other possible identifier).
          </li>
          <li style={bodyStyle}>
            You will not, without Roo’s prior written authorization, “frame” or “mirror” any part of
            the Roo Platform, use meta tags or code or other devices containing any reference to Roo
            in order to direct any person to any other web site for any purpose, or use any robot,
            spider, site search/retrieval application, or other manual or automatic device or
            process to retrieve, index, scrape, “data mine”, or in any way reproduce or circumvent
            the navigational structure or presentation of the Roo Platform or its Content.
          </li>
          <li style={bodyStyle}>
            You will not knowingly use any device, software or technique to interfere with or
            attempt to interfere with the proper working of the Roo Platform.
          </li>
          <li style={bodyStyle}>
            You will not modify, adapt, translate, reverse engineer, decipher, decompile or
            otherwise disassemble any portion of the Roo Platform or any software used on or for the
            Roo Platform or rent, lease, lend, sell, redistribute, license or sublicense the Roo
            Platform or access to any portion of the Roo Platform.
          </li>
          <li style={bodyStyle}>
            You will not intentionally or unintentionally violate any local, state, federal,
            national or international law, including, but not limited to, rules, guidelines, and/or
            regulations decreed by the U.S. Securities and Exchange Commission, in addition to any
            rules of any nation or other securities exchange.
          </li>
          <li style={bodyStyle}>
            You will not upload, post, email, transmit, offer or otherwise send any bulk unsolicited
            advertising, promotional information, e-mail or other solicitation (including without
            limitation junk mail, “spam,” chain letters or pyramid schemes of any sort) to any
            person through the use of the Roo Platform or Services.
          </li>
          <li style={bodyStyle}>
            You will not cause any third party to engage in, or assist or encourage any third party
            engaging in, any restricted activities identified above.
          </li>
        </ol>
        <p style={bodyStyle}>
          If you violate any of the rules of conduct set forth above, or any other aspect of this
          User Agreement, Roo reserves the right to suspend or terminate your account and your
          access to and use of the Roo Platform and the Services without giving you any notice. Roo
          further reserves the right (but shall have no obligation) to at any time remove and/or
          delete any Content that Roo determines in its sole discretion to be inappropriate or
          undesirable for the Roo Platform.
        </p>
        <p style={titleStyle}>17. FEEDBACK BY USERS</p>
        <p style={bodyStyle}>
          Roo may provide mechanisms for Users to contribute feedback to the Roo Platform or
          Services. If, and to the extent that you submit any feedback, ideas, documents,
          suggestions and/or proposals concerning the Roo Platform or the Services
          (“Contributions”), you agree that: (1) Roo is granted a worldwide, fully-paid,
          royalty-free, irrevocable, transferrable and fully-sub licensable, non-exclusive right and
          license to use, copy, reproduce, distribute, publish, modify, adapt or otherwise create
          derivative works from and otherwise exploit and utilize in any manner all such
          Contributions, (2) Roo is under no obligation to either compensate or provide any form of
          reimbursement in any manner or nature for any such Contribution nor is Roo under any
          obligation to ensure or maintain the confidentiality of any Contribution; and (3) Roo
          shall accordingly be entitled to make use of and/or disclose any such Contributions in any
          such manner as it may see fit. You further represent and warrant that your Contributions
          do not contain any type of confidential or proprietary information and are not subject to
          any proprietary rights of any third party.
        </p>
        <p style={titleStyle}>18. INTELLECTUAL PROPERTY INFRINGEMENT CLAIMS PROCEDURES</p>
        <p style={bodyStyle}>
          If you believe that any of the Content on the Roo Platform infringes upon your copyright,
          or otherwise violates your intellectual property rights, you should provide to the Roo
          agent identified below all of the following:
        </p>
        <ol type="1">
          <li style={bodyStyle}>
            The electronic or the physical signature of the individual that is authorized on behalf
            of the owner of the copyright or other intellectual property interest;
          </li>
          <li style={bodyStyle}>
            A description of the copyrighted work or other intellectual property that you believe
            has been infringed upon;
          </li>
          <li style={bodyStyle}>
            A description of the Content, and its location within the Roo Platform, which you allege
            is infringing upon your intellectual property;
          </li>
          <li style={bodyStyle}>
            The physical address, telephone number, and email address of the owner of the
            intellectual property;
          </li>
          <li style={bodyStyle}>
            A statement, in which you state that the alleged and disputed use of your copyright or
            other intellectual property is not authorized by the owner of the copyright or other
            intellectual property, its agents or the law;
          </li>
          <li style={bodyStyle}>
            And finally, a statement, made under penalty of perjury, that the aforementioned
            information in your notice is the truth and accurate, and that you are the copyright or
            intellectual property owner, or a representative or agent authorized to act on the
            copyright or intellectual property owner’s behalf.
          </li>
        </ol>
        <p style={bodyStyle}>
          The Roo agent for notice of claims of copyright or other intellectual property
          infringement can be contacted as follows:
        </p>
        <p style={bodyStyle}>
          Roo
          <br />
          595 Pacific Ave
          <br />
          4th floor
          <br />
          San Francisco, CA 94133
          <br />
          Attn: DMCA Agent
          <br />
          Telephone: 1-833-ROO-VETS (833-766-8387)
          <br />
          Email:
          <u>
            <a style={linkStyle} href={`mailto:${ROO_MAIL}`}>
              {ROO_MAIL}
            </a>
          </u>
        </p>
        <p style={bodyStyle}>
          Notwithstanding anything to the contrary in this User Agreement, Roo will have no
          liability for any infringement or misappropriation action or claim of any kind to the
          extent that it results from: (a) modifications to the Roo Platform or Service made by a
          party other than Roo, if the infringement or misappropriation would not have occurred but
          for such modifications; (b) your failure to use updated or modified access to the Roo
          Platform or Services provided by Roo to avoid infringement or misappropriation; (c) Roo's
          compliance with any designs or specifications provided by you; (d) Beta Services (or any
          use thereof); (e) any events or circumstances beyond Roo’s control; or (f) your use of the
          Roo Platform or Service other than as authorized by this User Agreement.
        </p>
        <p style={titleStyle}>19. THIRD PARTIES ADVERTISERS AND LINKS TO OTHER RESOURCES</p>
        <p style={bodyStyle}>
          Any correspondence or business dealings with, or the participation in any promotions of,
          other Users or other third parties through the Roo Platform or Services (which business
          dealings may include the payment and/or delivery of goods and/or services, and any such
          other term, condition, warranty and/or representation associated with such dealings) are
          and shall be solely between you and any such third party. You agree that Roo shall not be
          responsible or liable for any loss or damage of any nature or manner incurred as a direct
          result of any such dealings with any such third party.
        </p>
        <p style={bodyStyle}>
          Roo and third parties may provide links to other websites and/or resources. You
          acknowledge and agree that Roo is not responsible for the availability of any such
          external sites or resources, and Roo does not endorse, nor is it responsible or liable
          for, any content, products, advertising or any other materials, on or available from such
          third party sites or resources. Furthermore, you acknowledge and agree that Roo shall not
          be responsible or liable, directly or indirectly, for any damage or loss which may be a
          result of, caused or allegedly to be caused by or in connection with the use of or the
          reliance on any content, goods or services made available on or through any such site or
          resource.
        </p>
        <p style={titleStyle}>20. WARRANTY DISCLAIMERS</p>
        <p style={bodyStyle}>
          YOU HEREIN EXPRESSLY ACKNOWLEDGE AND AGREE THAT THE USE OF THE ROO PLATFORM, SERVICES AND
          CONTENT ARE AT YOUR SOLE RISK. THE ROO PLATFORM, SERVICES AND CONTENT ARE PROVIDED ON AN
          “AS IS” AND/OR “AS AVAILABLE” BASIS. ROO EXPRESSLY DISCLAIMS ANY AND ALL REPRESENTATIONS
          AND WARRANTIES OF ANY KIND, WHETHER EXPRESSED OR IMPLIED, WITH RESPECT TO THE ROO
          PLATFORM, SERVICES AND CONTENT, INCLUDING, BUT NOT LIMITED TO ANY IMPLIED WARRANTIES OF
          TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. WITHOUT
          LIMITATION TO THE FOREGOING, ROO EXPRESSLY DISCLAIMS ANY REPRESENTATIONS AND WARRANTIES
          THAT (i) THE ROO PLATFORM, SERVICES OR CONTENT WILL MEET YOUR REQUIREMENTS; (ii) THE ROO
          PLATFORM, SERVICES OR CONTENT SHALL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE; (iii)
          THAT SUCH RESULTS WHICH MAY BE OBTAINED FROM THE USE OF THE ROO PLATFORM, SERVICES OR
          CONTENT WILL BE ACCURATE OR RELIABLE; (iv) QUALITY OF ANY SERVICES, ANY INFORMATION OR
          OTHER MATERIAL WHICH MAY BE OBTAINED THROUGH THE ROO PLATFORM, SERVICES OR CONTENT WILL
          MEET YOUR EXPECTATIONS; AND (v) THAT ANY ERRORS CONTAINED IN THE ROO PLATFORM SHALL BE
          CORRECTED. ANY SOFTWARE COMPONENTS OR CONTENT ACCESSED, DOWNLOADED OR OTHERWISE OBTAINED
          BY WAY OF THE ROO PLATFORM SHALL BE ACCESSED BY YOUR SOLE DISCRETION AND SOLE RISK, AND AS
          SUCH YOU SHALL BE SOLELY RESPONSIBLE FOR AND HEREBY WAIVE ANY AND ALL CLAIMS AND CAUSES OF
          ACTION WITH RESPECT TO ANY DAMAGE TO YOUR COMPUTER AND/OR INTERNET ACCESS, DOWNLOADING
          AND/OR DISPLAYING, OR FOR ANY LOSS OF DATA THAT COULD RESULT FROM THE DOWNLOAD OF ANY SUCH
          SOFTWARE COMPONENTS OR CONTENT. ROO IS MERELY A PASSIVE CONDUIT BETWEEN PROFESSIONALS AND
          PRACTICES, AND USER IS SOLELY RESPONSIBLE FOR ANY DECISION TO HIRE OR UTILIZE (OR REJECT,
          SUSPEND, REPRIMAND, PUNISH, OR TERMINATE) ANY PROFESSIONAL (INCLUDING ANY RELATED
          DILIGENCE ACTIVITIES, SUCH AS QUALIFICATION OR BACKGROUND VERIFICATION OR MONITORING).
        </p>
        <p style={bodyStyle}>
          Without limitation to the foregoing, Roo is not responsible for the conduct, whether
          online or offline, of any User of the Roo Platform or the Services. You are solely
          responsible for your interactions with other Users. By its very nature, other Users’
          information may be offensive, harmful or inaccurate, and in some cases will be mislabeled
          or deceptively labeled. Moreover, because User authentication on the Internet is
          difficult, Roo cannot and does not confirm that each User is who they claim to be.
          Accordingly, you should use common sense when using the Roo Platform and Services,
          including looking at the photos of any other User with whom you are matched with to make
          sure it is the same individual with whom you deal in person. Please note that there are
          also risks of dealing with people acting under false pretense, and Roo does not accept
          responsibility or liability for any content, communication or other use or access of the
          Roo Platform by other Users. By using the Roo Platform, you agree to accept and assume
          such risks and agree that Roo is not responsible for the acts or omissions of Users on the
          Roo Platform or participating in the Services. In the event that you have a dispute with
          one or more other Users, you release Roo and its subsidiaries and affiliates and their
          respective directors, officers, shareholders, employees, printers, contractors, licensors,
          suppliers, vendors, partners, agents and representatives (collectively “Roo Parties”) from
          claims, demands and damages (actual and consequential and direct and indirect) of every
          kind and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed,
          arising out of or in any way connected with such disputes to the fullest extent permitted
          by law.
        </p>
        <p style={titleStyle}>21. LIMITATION OF LIABILITY</p>
        <p style={bodyStyle}>
          YOU EXPLICITLY ACKNOWLEDGE, UNDERSTAND AND AGREE THAT THE ROO PARTIES SHALL NOT BE LIABLE
          TO YOU FOR ANY PUNITIVE, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY
          DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES WHICH MAY BE RELATED TO THE LOSS OF ANY
          PROFITS, GOODWILL, USE, DATA AND/OR OTHER INTANGIBLE LOSSES, EVEN THOUGH THE ROO PARTIES
          MAY HAVE BEEN ADVISED OF SUCH POSSIBILITY THAT SAID DAMAGES MAY OCCUR. IN ADDITION, THE
          AGGREGATE LIABILITY OF THE ROO PARTIES TO YOU OR ANY THIRD PARTIES IN ANY CIRCUMSTANCE IS
          LIMITED TO THE GREATER OF $1,000 OR THE AMOUNT YOU HAVE PAID TO ROO IN THE TWELVE MONTHS
          BEFORE THE ACTION GIVING RISE TO LIABILITY. IN ADDITION, THE ROO PARTIES SHALL NOT BE
          RESPONSIBLE OR LIABLE FOR ANY DELAY OR FAILURE, OR ANY ASSOCIATED DAMAGES, RESULTING FROM
          ANY EVENTS OR CIRCUMSTANCES BEYOND ROO’S CONTROL, INCLUDING ANY ACTS OF GOD, ACTS OF
          GOVERNMENT, FLOODS, FIRES, EARTHQUAKES, CIVIL UNREST, WARS, ACTS OF TERROR, STRIKES,
          EPIDEMICS, PANDEMICS, CYBERATTACKS, OR THIRD-PARTY INTERNET SERVICE INTERRUPTIONS OR
          SLOWDOWNS. ROO SHALL NOT BE RESPONSIBLE OR LIABLE FOR ANY VETERINARY SERVICES (OR ANY
          RELATED ACTS OR OMISSIONS BY OR ON BEHALF OF ANY PROFESSIONAL).
        </p>
        <p style={titleStyle}>22. INDEMNITY</p>
        <p style={bodyStyle}>
          You shall indemnify and hold harmless the Roo Parties from and against any liability,
          loss, cost, claim, demand or other harm of any kind, including reasonable attorneys’ fees,
          incurred or suffered by the Roo Parties and arising out of or in connection with (a) your
          User-Generated Content; (b) your use of the Roo Platform or Services; (c) the use of the
          Roo Platform or Services by any person who uses the Roo Platform or Services while logged
          on under your user name or Password; (d) your violation of any provision in this User
          Agreement; (e) any claim by a Professional and/or a Practice (as applicable) related to
          claims for tax, employment status, misclassification, wage and hour disputes, workers'
          compensation, unemployment claims, bodily injury (including death), property damage and/or
          personal injury, or (f) your violation of any laws or of any rights of a third party.
        </p>
        <p style={titleStyle}>23. RELATIONSHIP OF THE PARTIES</p>
        <p style={bodyStyle}>
          As stated above, the Roo Platform allows a Practice to identify potential Shifts for which
          it is seeking Professionals to perform Veterinary Services and for one or more
          Professionals to offer to perform Veterinary Services for such prospective Shifts. Roo’s
          role, in connection with transactions between Practices and Professionals, is strictly to
          facilitate the connection between these parties through the use of the Roo Platform and to
          process payments on behalf of the Practices. As a User of the Roo Platform, you
          acknowledge and agree that you and Roo are in a direct business relationship, and the
          relationship between the parties under this User Agreement is solely that of independent
          contracting parties. You agree that Roo has no involvement in decisions of a Professional
          or a Practice to engage in Veterinary Services through the Roo Platform, but rather, Roo’s
          purpose hereunder is to provide the Roo Platform to Professionals and Practices as a means
          to connecting the parties. You and Roo expressly agree that (1) this User Agreement is not
          an employment agreement and does not create an employment relationship between you and
          Roo; and (2) no joint venture, franchisor-franchisee, partnership, or agency relationship
          is intended or created by this User Agreement. You agree that neither you nor any other
          User has any authority to bind Roo, and you undertake not to hold yourself out as an
          employee, agent or authorized representative of Roo.
        </p>
        <p style={bodyStyle}>
          Roo does not, and shall not be deemed to, supervise, direct or control any User generally
          or the performance of any User under this User Agreement specifically, including in
          connection with any provision of Veterinary Services by any Professional to a Practice or
          any other User’s acts or omissions. Each User retains the sole right to determine when,
          where, and for how long the User will utilize the Roo Platform and whether to engage in
          Veterinary Services with other Users. Each User retains the option to accept or to decline
          or ignore another User’s request for or offer of a Shift via the Roo Platform, or to
          cancel an accepted Shift via the Roo Platform, subject to the then-current cancellation
          policies on the Roo Platform. You acknowledge and agree that Users have complete
          discretion in connection with the provision of Veterinary Services by Professionals, the
          utilization of Veterinary Services by Practices and the manner in which they otherwise
          engage in other business or employment activities. You expressly acknowledge and agree
          that the issuance of payments through the Roo Platform as compensation from a Practice to
          a Professional for Veterinary Services is purely for convenience and in no way is intended
          to implicate Roo as an employer or a recipient of the services of either a Professional or
          a Practice. Without limitation to the foregoing, Roo will have no power or authority to
          provide instructions to or direct, supervise or control any Professional with respect to
          the means, manner or method of performance of Veterinary Services, and each Professional
          will, in the exercise of his or her independent judgment, select the means, manner,
          sequence and method of performance thereof.
        </p>
        <p style={titleStyle}>24. NOTICE AND COMMUNICATIONS</p>
        <p style={bodyStyle}>
          Roo may furnish you with notices relating to this User Agreement by any reasonable means
          currently known or which may be developed including, but not limited to email, regular
          mail, MMS or SMS, text messaging, or postings on the Roo Platform. To the extent that Roo
          uses any contact information you have provided through your registration of your user
          account or otherwise, you agree that transmittal in accordance with such contact
          information shall be deemed valid notice to you, and any failure by you to update such
          contact information shall not affect the validity of such notice given to you. You hereby
          expressly consent to Roo's furnishing you with the aforementioned notices and may rescind
          such consent in accordance with, and subject to the terms of, the Privacy Policy.
        </p>
        <p style={bodyStyle}>
          If you wish to contact Roo, you may use the following contact information.
        </p>
        <p style={bodyStyle}>
          Roo
          <br />
          595 Pacific Ave
          <br />
          4th floor
          <br />
          San Francisco, CA 94133
          <br />
          Telephone: 1-833-Roo-Vets (833-766-8387)
          <br />
          Email: {ROO_MAIL}
        </p>
        <p style={bodyStyle}>
          In addition to notices under this User Agreement, by entering into this User Agreement or
          using the Roo Platform, you agree to receive communications from Roo, including via
          e-mail, text message, calls, and push notifications. You agree that texts, calls or
          prerecorded messages may be generated by automatic telephone dialing systems.
          Communications from Roo, its affiliated companies and/or other Users, may include but are
          not limited to: operational communications concerning your User account or use of the Roo
          Platform or Services, updates concerning new and existing features on the Roo Platform,
          communications concerning promotions run by Roo or third-party partners, and news
          concerning Roo and industry developments. Standard text messaging charges applied by your
          cell phone carrier will apply to text messages sent under this User Agreement.
        </p>
        <p style={titleStyle}>25. GENERAL TERMS</p>
        <p style={bodyStyle}>
          This User Agreement constitutes the entire agreement between you and Roo and shall govern
          the use of the Roo Platform, Services and Content, superseding any prior version of this
          User Agreement. The headings in this User Agreement are inserted as a matter of
          convenience only, and do not define, limit, or describe the scope of this User Agreement
          or the intent of the provisions hereof. Common nouns and pronouns shall be deemed to refer
          to the masculine, feminine, neuter, singular and plural, as the identity of the person may
          in the context require. The use of the words “include,” “including” or variations thereof
          in this User Agreement shall be deemed to be by way of example rather than by limitation.
          No failure or delay in exercising or enforcing any right or provision of this User
          Agreement shall constitute a waiver of such right or provision. You and Roo mutually agree
          that the relationship between the parties shall be governed by the laws of the State of
          Texas without regard to its conflict of law provisions. If any provision of this User
          Agreement is found by a court of competent jurisdiction to be invalid, the parties
          nevertheless agree that the court should endeavor to give maximum effect to the parties’
          intentions as reflected in the provision, and the other provisions of this User Agreement
          remain in full force and effect. Except as expressly provided in Section 15 (“Third Party
          Services and Content”), the parties agree that there are no third-party beneficiaries to
          this User Agreement. For the avoidance of doubt, each contract between Roo and each User
          who agrees to the terms of this User Agreement is a separate and independent contract and
          so no User may enforce the terms of any User Agreement between Roo and another User. Roo
          may at any time assign Roo’s rights under this User Agreement or any part hereof. You may
          not sell or assign any of your rights under this User Agreement or any part hereof, either
          voluntarily or by operation of law, without the prior written consent of Roo. Subject to
          the foregoing, this User Agreement shall be binding upon Roo, you and Roo’s and your
          respective legal representatives, successors and assigns.
        </p>
      </div>
    </div>
  );
};

// eslint-disable-next-line no-restricted-exports
export default TermsOfUse;
