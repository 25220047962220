import posthog from "posthog-js";

import { FEATURE_FLAG_VARIANTS, FeatureFlagName } from "../../constants/postHogFeatureFlags";

import { isRooFeatureEnabled } from "./isRooFeatureEnabled";

export const isRooFeatureFlagVariant = (
  flag: FeatureFlagName,
  variant: FEATURE_FLAG_VARIANTS
): boolean => {
  // eslint-disable-next-line roo/no-restricted-functions
  const flagValue = posthog.getFeatureFlag(flag);

  if (window.RooConfig.WHICH_ENV === "stage" || window.RooConfig.WHICH_ENV === "dev") {
    return isRooFeatureEnabled(flag);
  }

  return flagValue === variant;
};
