import React from "react";
import { Helmet } from "react-helmet-async";
import { Route, RouteProps } from "react-router-dom";

export interface RooRouteProps<C extends React.ComponentType<any>> extends RouteProps {
  title?: string;
  component: C;
  componentProps?: React.ComponentProps<C>;
}

export const RooRoute = <C extends React.ComponentType<any>>({
  title,
  component: Component,
  componentProps,
  ...routeProps
}: RooRouteProps<C>) => {
  const defaultTitle = "Roo";
  const titleForHelmet = [title || "", defaultTitle]
    .filter((item) => item.trim() !== "")
    .join(" | ");
  const index = window.RooConfig.WHICH_ENV === "prod" ? "index" : "noindex,nofollow";
  return (
    <Route
      {...routeProps}
      render={(routeComponentProps) => {
        const props = {
          ...componentProps,
          ...routeComponentProps,
        } as React.ComponentProps<C>;
        return (
          <>
            <Helmet>
              <title>{titleForHelmet}</title>
              <meta name="robots" content={index} />
            </Helmet>
            <Component {...props} />
          </>
        );
      }}
    />
  );
};
