import * as C from "../../../constants/adminReferrals";

export type ActionModule = typeof import("../../../constants/adminReferrals");

export interface ReferralInitialState {
  referralUsers: any[];
  hospitalCredits: any[];
  totalCount: number;
  currentReferralPromoAmount: number;
  emailFooter: string;
  referralDetails: any;
  isReferredUserListFetched: boolean;
  isPromoAmountUdpated: boolean;
  isEmailFooterFetched: boolean;
  isEmailFooterUpdated: boolean;
  isPaymentSuccess: boolean;
  isPaymentFailed: boolean;
  isActionCompleted: boolean;
  message: string;
  referredByUserTypeId: number | null;
  bonusPaidUsers: any[];
  referralSearchParams: string;
}

const initialState: ReferralInitialState = {
  referralUsers: [],
  hospitalCredits: [],
  totalCount: 0,
  currentReferralPromoAmount: 0,
  emailFooter: "",
  referralDetails: null,
  isReferredUserListFetched: false, // promo amount also fetched w/ referred user list
  isPromoAmountUdpated: false,
  isEmailFooterFetched: false,
  isEmailFooterUpdated: false,
  isPaymentSuccess: false,
  isPaymentFailed: false,
  isActionCompleted: false,
  message: "",
  referredByUserTypeId: null,
  bonusPaidUsers: [],
  referralSearchParams: "",
};

export type ReferralActionType = {
  type: ActionModule[keyof ActionModule];
  payload?: any;
};

// eslint-disable-next-line default-param-last
const referralUserReducer = (state = initialState, action: ReferralActionType) => {
  switch (action.type) {
    case C.ADMIN_INITIALIZE_REFERRAL:
      return {
        ...state,
        isPromoAmountUdpated: false,
        isEmailFooterUpdated: false,
        isActionCompleted: false,
        isPaymentSuccess: false,
        isPaymentFailed: false,
        message: "",
        referredByUserTypeId: null,
      };
    case C.ADMIN_INITIALIZE_TO_BE_PAID:
      return {
        ...state,
        referralDetails: null,
      };
    case C.ADMIN_GET_REFER_USER_REQUEST: {
      const newState: ReferralInitialState = {
        ...state,
        referralUsers: [],
        totalCount: 0,
        currentReferralPromoAmount: 0,
        referralSearchParams: action.payload.referralSearchParams,
      };
      return newState;
    }
    case C.ADMIN_GET_REFER_USER_SUCCESS:
      return {
        ...state,
        referralUsers: action.payload.referralList.data[0],
        totalCount: action.payload.referralList.data[1][0].totalCount,
        currentReferralPromoAmount: action.payload.referralPromoAmount.referralPromoAmount,
        isReferredUserListFetched: true,
        message: "",
      };
    case C.ADMIN_CHANGE_PROMO_AMOUNT_SUCCESS:
      return {
        ...state,
        isPromoAmountUdpated: true,
        isActionCompleted: true,
        currentReferralPromoAmount: action.payload.promoamount,
      };
    case C.ADMIN_CHANGE_PROMO_AMOUNT_FAIL:
      return {
        ...state,
        isPromoAmountUdpated: false,
        message: action.payload.message,
      };
    case C.ADMIN_PAY_REFER_AMOUNT_FAIL:
      return {
        ...state,
        isPaymentFailed: true,
        message: action.payload.message,
        referredByUserTypeId: null,
      };
    case C.ADMIN_PAY_REFER_AMOUNT_SUCCESS:
      return {
        ...state,
        isPaymentSuccess: true,
        isActionCompleted: true,
        referredByUserTypeId: action.payload.referredByUserTypeId,
      };
    case C.ADMIN_GET_EMAIL_FOOTER_SUCCESS:
      return {
        ...state,
        emailFooter: action.payload,
        isEmailFooterFetched: true,
      };
    case C.ADMIN_UPDATE_EMAIL_FOOTER_SUCCESS:
      return {
        ...state,
        emailFooter: action.payload.emailFooter,
        isEmailFooterUpdated: true,
        isActionCompleted: true,
      };
    case C.ADMIN_UPDATE_EMAIL_FOOTER_FAIL:
      return {
        ...state,
        isEmailFooterUpdated: false,
        isActionCompleted: true,
      };
    case C.ADMIN_GET_HOSPITAL_CREDIT_SUCCESS: {
      const newState: ReferralInitialState = {
        ...state,
        hospitalCredits: action.payload.hospitalCredits.data[0],
        totalCount: action.payload.hospitalCredits.data[1][0].totalCount,
        referralUsers: [],
        message: "",
      };
      return newState;
    }
    case C.ADMIN_GET_CONTRACTOR_BONUS_REQUEST: {
      const newState: ReferralInitialState = {
        ...state,
        bonusPaidUsers: [],
        totalCount: 0,
        referralSearchParams: action.payload.referralSearchParams,
      };
      return newState;
    }
    case C.ADMIN_GET_CONTRACTOR_BONUS_SUCCESS:
      return {
        ...state,
        bonusPaidUsers: action.payload.bonusPaidUsers.data[0],
        totalCount: action.payload.bonusPaidUsers.data[1][0].totalCount,
        message: "",
      };
    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default referralUserReducer;
