import React from "react";

import { Container, Heading } from "@roo-dev/ui-components";

import { SubHeaderContainer } from "../../EditProfile/styles";
import { Shift } from "../interfaces";

import { NotificationRow } from "./NotificationRow";
import ToggleRowNew from "./ToggleRow";

interface NotificationPanelContentProps {
  content?: any;
  renderAllNotifications?: boolean;
  handleToggleChange?: (params: {
    label: string;
    value: boolean;
    notificationTypeId: number;
  }) => void;
  getAllSettingsValue?: () => number;
  toggleAllSettings?: (arg: number) => void;
}

const NotificationPanelContent: React.FC<NotificationPanelContentProps> = ({
  content,
  renderAllNotifications,
  handleToggleChange,
  getAllSettingsValue,
  toggleAllSettings,
}) => {
  return (
    <>
      <Container>
        <SubHeaderContainer>
          <Heading level={3}>{content?.title}</Heading>
        </SubHeaderContainer>
        {renderAllNotifications && (
          <ToggleRowNew
            customToggle={{
              label: "All notifications",
              isTitle: true,
              value: getAllSettingsValue() == 1,
            }}
            handleToggleChange={({ value }) => {
              toggleAllSettings(value ? 1 : 0);
            }}
          />
        )}
        {content?.shifts?.length ? ( // TODO refactor how we set content
          content.shifts.map((shift: Shift) => (
            <NotificationRow
              key={shift.title} // Use `shift.title` directly
              settingKey={shift.title}
              settingValue={shift}
              handleToggleChange={handleToggleChange}
            />
          ))
        ) : (
          <NotificationRow
            key={content?.title}
            settingKey={content?.title}
            settingValue={content}
            handleToggleChange={handleToggleChange}
            renderOnlyToggle={true}
          />
        )}
      </Container>
    </>
  );
};

// eslint-disable-next-line no-restricted-exports
export default NotificationPanelContent;
