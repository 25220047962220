import * as actionTypes from "../../constants/Login";

export function loginRequestAction(data) {
  return {
    type: actionTypes.LOGIN_REQUEST,
    payload: data,
  };
}

export function checkLoginAction() {
  return {
    type: actionTypes.CHECK_LOGIN,
  };
}

export function initializeLoginStateRequestAction(value) {
  return {
    type: actionTypes.INITIALIZE_LOGIN_STATE,
    data: value,
  };
}

export function exitTrainingRequestAction(data) {
  return {
    type: actionTypes.EXIT_TRAINING_REQUEST,
    payload: data,
  };
}

export function hospitalCompleteTrainingRequestAction() {
  return {
    type: actionTypes.HOSPITAL_COMPLETE_TRAINING_REQUEST,
  };
}

export function setSessionOnRefresh() {
  return {
    type: actionTypes.SET_SESSION_REQUEST,
  };
}

export function setRedirect(redirectUrl) {
  return {
    type: actionTypes.SET_REDIRECT_URL,
    urlString: redirectUrl,
  };
}

export function clearRedirect() {
  return {
    type: actionTypes.CLEAR_REDIRECT_URL,
  };
}

export function updateAddShiftClick(payload) {
  return {
    type: actionTypes.UPDATE_ADD_SHIFT_CLICK,
    payload,
  };
}

export function logout() {
  return {
    type: actionTypes.INIT_AUTHORISE_USER,
  };
}

export function showBlockedUserPopup() {
  return {
    type: actionTypes.SHOW_USER_BLOCKED_POP_UP,
  };
}

export function hideBlockedUserPopup() {
  return {
    type: actionTypes.HIDE_USER_BLOCKED_POP_UP,
  };
}

export function showSuspendedUserPopup() {
  return {
    type: actionTypes.SHOW_USER_SUSPENDED_POP_UP,
  };
}

export function hideSuspendedUserPopup() {
  return {
    type: actionTypes.HIDE_USER_SUSPENDED_POP_UP,
  };
}

export function hideAlertFormPopupAction(count) {
  return {
    type: actionTypes.HIDE_ALERT_FORM_POP_UP,
    payload: count,
  };
}

export function showPendingUserPopup() {
  return {
    type: actionTypes.SHOW_USER_PENDING_POP_UP,
  };
}

export function hidePendingUserPopup() {
  return {
    type: actionTypes.HIDE_USER_PENDING_POP_UP,
  };
}

export function closeBackgroundModal() {
  return {
    type: actionTypes.SET_TECH_BACKGROUND_CHECKED,
  };
}

export function showTrainingPopup() {
  return {
    type: actionTypes.SHOW_TRAINING_POPUP,
  };
}

export function requestShiftSuccess() {
  return {
    type: actionTypes.REQUEST_SHIFT_SUCCESS,
  };
}

export function confirmShiftSuccess() {
  return {
    type: actionTypes.CONFIRM_SHIFT_SUCCESS,
  };
}

export function updateFavoritesAction(data) {
  return {
    type: actionTypes.UPDATE_FAVORITES,
    payload: data,
  };
}

export function resetFavoritesUpdatedAction() {
  return {
    type: actionTypes.RESET_FAVORITES_UPDATED,
  };
}

export function requestEventAction(data) {
  return {
    type: actionTypes.SEND_REQUEST_FOR_EVENT,
    payload: data,
  };
}

export function cancelEventAction(data) {
  return {
    type: actionTypes.SEND_CANCEL_REQUEST_FOR_EVENT,
    payload: data,
  };
}

export function showICAgreement() {
  return {
    type: actionTypes.SHOW_AC_CONTRACT,
  };
}

export function addNextStepOnboarding(pageNum) {
  return {
    type: actionTypes.NEXT_STEP_ONBOARDING,
    payload: pageNum,
  };
}

export function hideICAgreement() {
  return {
    type: actionTypes.SIGN_AC_CONTRACT_SUCCESS,
  };
}

export function skipICAgreement() {
  return {
    type: actionTypes.SKIP_IC_CONTRACT,
  };
}

export function signICAgreement(data) {
  return {
    type: actionTypes.SIGN_AC_CONTRACT,
    payload: data,
  };
}

export function acceptTermsAction(data) {
  return {
    type: actionTypes.ACCEPT_TERMS,
    payload: data,
  };
}

export function acceptCodeOfConduct(data) {
  return {
    type: actionTypes.ACCEPT_CODE_OF_CONDUCT,
    payload: data,
  };
}

export function loginRooDemoUser(data) {
  return {
    type: actionTypes.LOGIN_ROO_DEMO_USER,
    payload: data,
  };
}

export function updateUserName(data) {
  return {
    type: actionTypes.UPDATE_USER_NAME,
    payload: data,
  };
}

export function getCancelledShiftDetailsIn30Days(data) {
  return {
    type: actionTypes.GET_CANCELLED_SHIFT_DETAILS_IN_30_DAYS,
    payload: data,
  };
}

export function updateHasSeenFearFreeAnnouncementDatabaseFlag() {
  return {
    type: actionTypes.UPDATE_HAS_SEEN_FEAR_FREE_ANNOUNCEMENT,
  };
}
