export const ADMIN_GET_REFER_USER_REQUEST = "ADMIN_GET_REFER_USER";
export const ADMIN_GET_REFER_USER_FAIL = "ADMIN_GET_REFER_USER_FAIL";
export const ADMIN_GET_REFER_USER_SUCCESS = "ADMIN_GET_REFER_USER_SUCCESS";

export const ADMIN_CHANGE_PROMO_AMOUNT_REQUEST = "ADMIN_CHANGE_PROMO_AMOUNT_REQUEST";
export const ADMIN_CHANGE_PROMO_AMOUNT_FAIL = "ADMIN_CHANGE_PROMO_AMOUNT_FAIL";
export const ADMIN_CHANGE_PROMO_AMOUNT_SUCCESS = "ADMIN_CHANGE_PROMO_AMOUNT_SUCCESS";

export const ADMIN_INITIALIZE_REFERRAL = "ADMIN_INITIALIZE_REFERRAL";

export const ADMIN_PAY_REFER_AMOUNT_REQUEST = "ADMIN_PAY_REFER_AMOUNT_REQUEST";
export const ADMIN_PAY_REFER_AMOUNT_FAIL = "ADMIN_PAY_REFER_AMOUNT_FAIL";
export const ADMIN_PAY_REFER_AMOUNT_SUCCESS = "ADMIN_PAY_REFER_AMOUNT_SUCCESS";

export const ADMIN_INITIALIZE_TO_BE_PAID = "ADMIN_INITIALIZE_TO_BE_PAID";

export const ADMIN_GET_EMAIL_FOOTER = "ADMIN_GET_EMAIL_FOOTER";
export const ADMIN_GET_EMAIL_FOOTER_SUCCESS = "ADMIN_GET_EMAIL_FOOTER_SUCCESS";
export const ADMIN_GET_EMAIL_FOOTER_FAIL = "ADMIN_GET_EMAIL_FOOTER_FAIL";

export const ADMIN_UPDATE_EMAIL_FOOTER = "ADMIN_UPDATE_EMAIL_FOOTER";
export const ADMIN_UPDATE_EMAIL_FOOTER_SUCCESS = "ADMIN_UPDATE_EMAIL_FOOTER_SUCCESS";
export const ADMIN_UPDATE_EMAIL_FOOTER_FAIL = "ADMIN_UPDATE_EMAIL_FOOTER_FAIL";

export const ADMIN_GET_HOSPITAL_CREDIT_REQUEST = "ADMIN_GET_HOSPITAL_CREDIT_REQUEST";
export const ADMIN_GET_HOSPITAL_CREDIT_SUCCESS = "ADMIN_GET_HOSPITAL_CREDIT_SUCCESS";
export const ADMIN_GET_HOSPITAL_CREDIT_FAIL = "ADMIN_GET_HOSPITAL_CREDIT_FAIL";

export const ADMIN_GET_CONTRACTOR_BONUS_REQUEST = "ADMIN_GET_CONTRACTOR_BONUS_REQUEST";
export const ADMIN_GET_CONTRACTOR_BONUS_SUCCESS = "ADMIN_GET_CONTRACTOR_BONUS_SUCCESS";
export const ADMIN_GET_CONTRACTOR_BONUS_FAIL = "ADMIN_GET_CONTRACTOR_BONUS_FAIL";
