import * as Sentry from "@sentry/react";
import lodash from "lodash";
import { call, put, select, takeEvery } from "redux-saga/effects";

import { showPromoShiftSignUpToast } from "../../Common/Toasts/action/ToastActions";
import { SHIFT_REQUEST } from "../../constants/brazeEventNameConstants";
import * as calendarActions from "../../constants/calendar";
import * as errorMessage from "../../constants/errorMessage";
import { UNSET_LOADER } from "../../constants/loader";
import * as dashboardActionTypes from "../../constants/techConstants";
import { logBrazeCustomEvent } from "../../helpers/brazeUtility";

// --- SELECTORS
export const getNumberProbationShiftsLocal = (state) => state.techDashboard.nProbationShifts;

export function* getMyShiftListAsync(action) {
  try {
    const url =
      window.RooConfig.API_URL +
      "api/hospital/tech_shift/getShiftsByHospitalOrContractorId?isFromMobile=0&allShift=0&year=" +
      action.payload.year +
      "&month=" +
      action.payload.month +
      "&techId=" +
      action.payload.techId +
      "&maxTier=" +
      localStorage.getItem("tier");
    const response = yield call(fetch, url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const responseData = yield response.json();

    const data = lodash.cloneDeep(responseData.data);

    yield put({ type: UNSET_LOADER });
    if (responseData.status_code === 200) {
      yield put({
        type: calendarActions.GET_MY_TECH_SHIFTS_SUCCESS,
        payload: data,
        eventListForTech: action.payload.eventListForTech,
      });
    } else {
      yield put({
        type: calendarActions.GET_MY_TECH_SHIFTS_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting tech shift list: " } });
  }
}

export function* getMyShiftDetailsAsync(action) {
  try {
    const response = yield call(
      fetch,
      window.RooConfig.API_URL +
        "api/hospital/tech_shift/getShiftDetailsById?shiftGroupId=" +
        action.payload.shiftGroupId +
        "&techId=" +
        action.payload.techId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const responseData = yield response.json();
    if (responseData.status_code === 200) {
      const data = [...responseData.data.data];
      yield put({ type: calendarActions.GET_MY_TECH_SHIFTS_DETAILS_SUCCESS, payload: data });
    } else {
      yield put({
        type: calendarActions.GET_MY_TECH_SHIFTS_DETAILS_FAIL,
        payload: { status: responseData.status_code, message: responseData.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting tech shift details: " },
    });
  }
}

function* requestTechShiftAsync(action) {
  try {
    const nProbationShifts = yield select(getNumberProbationShiftsLocal);
    const isProbationLimit = nProbationShifts >= 5;

    if (!isProbationLimit) {
      const response = yield call(fetch, window.RooConfig.API_URL + "api/tech/shift/requestShift", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload),
      });
      const data = yield response.json();
      if (data.status_code === 200) {
        const requestMessage = data.message;
        if (requestMessage == "User blocked") {
          localStorage.setItem("isActive", 4);
          window.location.reload();
        } else {
          if (data.data.added === 0) {
            const { hospitalName } = action.payload;
            yield put({
              type: calendarActions.REQUEST_TECH_SHIFT_NOT_AVAILABLE,
              payload: { hospitalName, message: data.data.message },
            });
          } else {
            yield put({
              type: calendarActions.REQUEST_TECH_SHIFT_SUCCESS,
              payload: { status: data.status_code, data: data.data.data },
            });
            logBrazeCustomEvent(SHIFT_REQUEST, { shift_id: action.payload.shiftId });
            if (data.data.data.promoId) yield put(showPromoShiftSignUpToast());
          }
        }
      } else if (data.status_code === 400) {
        yield put({
          type: calendarActions.REQUEST_TECH_SHIFT_FAIL,
          payload: { status: data.status_code, message: data.message },
        });
      }
    } else {
      // probation limit from local check so show error without api call
      yield put({
        type: dashboardActionTypes.TECH_REQUEST_TECH_SHIFT_FAIL,
        payload: { errorMessage: errorMessage.TECH_PROBATION_LIMIT },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while requesting tech shift: " } });
    yield put({
      type: calendarActions.REQUEST_TECH_SHIFT_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootTechCalendarSaga() {
  yield takeEvery(calendarActions.GET_MY_TECH_SHIFTS, getMyShiftListAsync);
  yield takeEvery(calendarActions.GET_MY_TECH_SHIFTS_DETAILS, getMyShiftDetailsAsync);
  yield takeEvery(calendarActions.REQUEST_TECH_SHIFT, requestTechShiftAsync);
}
