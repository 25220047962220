export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const CHECK_LOGIN = "CHECK_LOGIN";

export const LOG_IN_AS_USER = "LOG_IN_AS_USER";
export const LOG_IN_AS_USER_SUCCESS = "LOG_IN_AS_USER_SUCCESS";

export const NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED";

export const GET_FEATURE_FLAGS = "GET_FEATURE_FLAGS";
export const GET_FEATURE_FLAGS_SUCCESS = "GET_FEATURE_FLAGS_SUCCESS";

export const INITIALIZE_LOGIN_STATE = "INITIALIZE_LOGIN_STATE";

export const VET_COMPLETE_TRAINING_REQUEST = "VET_COMPLETE_TRAINING_REQUEST";
export const VET_COMPLETE_TRAINING_SUCCESS = "VET_COMPLETE_TRAINING_SUCCESS";
export const VET_COMPLETE_TRAINING_FAIL = "VET_COMPLETE_TRAINING_FAIL";

export const TECH_COMPLETE_TRAINING_REQUEST = "TECH_COMPLETE_TRAINING_REQUEST";
export const TECH_COMPLETE_TRAINING_SUCCESS = "TECH_COMPLETE_TRAINING_SUCCESS";
export const TECH_COMPLETE_TRAINING_FAIL = "TECH_COMPLETE_TRAINING_FAIL";

export const HOSPITAL_COMPLETE_TRAINING_REQUEST = "HOSPITAL_COMPLETE_TRAINING_REQUEST";
export const HOSPITAL_COMPLETE_TRAINING_SUCCESS = "HOSPITAL_COMPLETE_TRAINING_SUCCESS";
export const HOSPITAL_COMPLETE_TRAINING_FAIL = "HOSPITAL_COMPLETE_TRAINING_FAIL";

export const EXIT_TRAINING_REQUEST = "EXIT_TRAINING_REQUEST";
export const EXIT_TRAINING_SUCCESS = "EXIT_TRAINING_SUCCESS";
export const EXIT_TRAINING_FAIL = "EXIT_TRAINING_FAIL";

export const SET_LOCAL_VET_HAS_PAYMENT_SETUP = "SET_LOCAL_VET_HAS_PAYMENT_SETUP";
export const SET_LOCAL_TECH_HAS_PAYMENT_SETUP = "SET_LOCAL_TECH_HAS_PAYMENT_SETUP";
export const SET_LOCAL_HOSPITAL_HAS_BILLING_SETUP = "SET_LOCAL_HOSPITAL_HAS_BILLING_SETUP";

export const SET_LOCAL_VET_HAS_W9 = "SET_LOCAL_VET_HAS_W9";
export const SET_LOCAL_TECH_HAS_W9 = "SET_LOCAL_TECH_HAS_W9";

export const CLEAR_ALL_STATE = "CLEAR_ALL_STATE";

export const SET_SESSION_REQUEST = "SET_SESSION_REQUEST";
export const SET_SESSION_SUCCESS = "SET_SESSION_SUCCESS";
export const SET_SESSION_FAIL = "SET_SESSION_FAIL";

export const UNSET_ENTERPRISE_HOSPITAL_SESSION = "UNSET_ENTERPRISE_HOSPITAL_SESSION";

export const SET_REDIRECT_URL = "SET_REDIRECT_URL";
export const CLEAR_REDIRECT_URL = "CLEAR_REDIRECT_URL";

export const SET_AUTHORISE_USER = "SET_AUTHORISE_USER";
export const INIT_AUTHORISE_USER = "INIT_AUTHORISE_USER";

export const SHOW_USER_BLOCKED_POP_UP = "SHOW_USER_BLOCKED_POP_UP";
export const HIDE_USER_BLOCKED_POP_UP = "HIDE_USER_BLOCKED_POP_UP";

export const SHOW_USER_SUSPENDED_POP_UP = "SHOW_USER_SUSPENDED_POP_UP";
export const HIDE_USER_SUSPENDED_POP_UP = "HIDE_USER_SUSPENDED_POP_UP";

export const HIDE_ALERT_FORM_POP_UP = "HIDE_ALERT_FORM_POP_UP";
export const UPDATE_ALERT_FORM_POP_COUNT_SUCCESS = "UPDATE_ALERT_FORM_POP_COUNT_SUCCESS";
export const UPDATE_ALERT_FORM_POP_COUNT_FAIL = "UPDATE_ALERT_FORM_POP_COUNT_FAIL";

export const SHOW_USER_PENDING_POP_UP = "SHOW_USER_PENDING_POP_UP";
export const HIDE_USER_PENDING_POP_UP = "HIDE_USER_PENDING_POP_UP";

export const GET_TECH_REQUEST_COUNT = "GET_TECH_REQUEST_COUNT";
export const GET_TECH_REQUEST_COUNT_SUCCESS = "GET_TECH_REQUEST_COUNT_SUCCESS";
export const GET_TECH_REQUEST_COUNT_FAIL = "GET_TECH_REQUEST_COUNT_FAIL";

export const SET_TECH_BACKGROUND_CHECKED = "SET_TECH_BACKGROUND_CHECKED";

export const SHOW_TRAINING_POPUP = "SHOW_TRAINING_POPUP";
export const CLOSE_TRAINING_POPUP = "CLOSE_TRAINING_POPUP";

export const REQUEST_SHIFT_SUCCESS = "REQUEST_SHIFT_SUCCESS";
export const CONFIRM_SHIFT_SUCCESS = "CONFIRM_SHIFT_SUCCESS";

export const UPDATE_USER_PERSONAL_INFO_SUCCESS = "UPDATE_USER_PERSONAL_INFO_SUCCESS";

export const SET_PROFILE_IMAGE = "SET_PROFILE_IMAGE";

export const UPDATE_FAVORITES = "UPDATE_FAVORITES";
export const UPDATE_FAVORITES_SUCCESS = "UPDATE_FAVORITES_SUCCESS";
export const UPDATE_FAVORITES_FAIL = "UPDATE_FAVORITES_FAIL";
export const RESET_FAVORITES_UPDATED = "RESET_FAVORITES_UPDATED";

export const SET_LOCATION_AND_SHIFT_FAVORABILITY = "SET_LOCATION_AND_SHIFT_FAVORABILITY";

export const SEND_REQUEST_FOR_EVENT = "SEND_REQUEST_FOR_EVENT";
export const SEND_REQUEST_FOR_EVENT_FAIL = "SEND_REQUEST_FOR_EVENT_FAIL";
export const SEND_REQUEST_FOR_EVENT_SECCUSS = "SEND_REQUEST_FOR_EVENT_SECCUSS";

export const SEND_CANCEL_REQUEST_FOR_EVENT = "SEND_CANCEL_REQUEST_FOR_EVENT";
export const SEND_CANCEL_REQUEST_FOR_EVENT_FAIL = "SEND_CANCEL_REQUEST_FOR_EVENT_FAIL";
export const SEND_CANCEL_REQUEST_FOR_EVENT_SECCUSS = "SEND_CANCEL_REQUEST_FOR_EVENT_SECCUSS";

export const SHOW_AC_CONTRACT = "SHOW_AC_CONTRACT";
export const SKIP_IC_CONTRACT = "SKIP_IC_CONTRACT";
export const AC_CONTRACT_SUCCESS_SET_DATA = "AC_CONTRACT_SUCCESS_SET_DATA";
export const SIGN_AC_CONTRACT = "SIGN_AC_CONTRACT";
export const SIGN_AC_CONTRACT_SUCCESS = "SIGN_AC_CONTRACT_SUCCESS";
export const ACCEPT_TERMS = "ACCEPT_TERMS";
export const ACCEPT_TERMS_SUCCESS = "ACCEPT_TERMS_SUCCESS";
export const ACCEPT_CODE_OF_CONDUCT = "ACCEPT_CODE_OF_CONDUCT";
export const ACCEPT_CODE_OF_CONDUCT_SUCCESS = "ACCEPT_CODE_OF_CONDUCT_SUCCESS";

export const LOGIN_ROO_DEMO_USER = "LOGIN_ROO_DEMO_USER";
export const LOGIN_ROO_DEMO_USER_SUCCESS = "LOGIN_ROO_DEMO_USER_SUCCESS";

export const UPDATE_USER_NAME = "UPDATE_USER_NAME";
export const UPDATE_USER_NAME_SUCCESS = "UPDATE_USER_NAME_SUCCESS";
export const UPDATE_USER_NAME_FAIL = "UPDATE_USER_NAME_FAIL";

export const UPDATE_ADD_SHIFT_CLICK = "UPDATE_ADD_SHIFT_CLICK";

export const NEXT_STEP_ONBOARDING = "NEXT_STEP_ONBOARDING";

export const GET_CANCELLED_SHIFT_DETAILS_IN_30_DAYS = "GET_CANCELLED_SHIFT_DETAILS_IN_30_DAYS";
export const GET_CANCELLED_SHIFT_DETAILS_IN_30_DAYS_SUCCESS =
  "GET_CANCELLED_SHIFT_DETAILS_IN_30_DAYS_SUCCESS";
export const GET_CANCELLED_SHIFT_DETAILS_IN_30_DAYS_FAIL =
  "GET_CANCELLED_SHIFT_DETAILS_IN_30_DAYS_FAIL";
export const UPDATE_HAS_SEEN_FEAR_FREE_ANNOUNCEMENT = "UPDATE_HAS_SEEN_FEAR_FREE_ANNOUNCEMENT";
export const UPDATE_HAS_SEEN_FEAR_FREE_ANNOUNCEMENT_SUCCESS =
  "UPDATE_HAS_SEEN_FEAR_FREE_ANNOUNCEMENT_SUCCESS";

export const UPDATE_USER_PROFILE_INFO_FROM_W9 = "UPDATE_USER_PROFILE_INFO_FROM_W9";
