import React from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { ThirdPartyScripts } from "./Init/Components/ThirdPartyScripts";
import { configInit } from "./Init/configInit";
import { thirdPartyInit } from "./Init/thirdPartyInit";
import store from "./store/index";

configInit();
thirdPartyInit();

const render = (Component: React.ComponentType) => {
  ReactDOM.render(
    <HelmetProvider>
      <Provider store={store}>
        <BrowserRouter>
          <Component />
        </BrowserRouter>
        <ThirdPartyScripts />
      </Provider>
    </HelmetProvider>,
    document.querySelector("#root")
  );
};

render(App);

if (module.hot) {
  module.hot.accept();
}
