import { get } from "../common/rooFetch";

export type GetLicenseDataParams = {
  userId: number;
  licenseNumber: string;
  state: string;
  zipcode: string;
  autoApprovalVersion: "v1" | "v2";
};

// We should use the standard APIResponse wrapper here.
// The `Roo-Node` end-point is deviating from the standard response format.
export type GetLicenseDataResponse = {
  message: string;
  success: boolean;
  records: Record<string, unknown>[];
};

export const getLicenseData = async ({
  userId,
  licenseNumber,
  state,
  zipcode,
  autoApprovalVersion,
}: GetLicenseDataParams) =>
  get<GetLicenseDataResponse>(
    `api/user/vet/getLicenseData?${new URLSearchParams({
      userId: userId.toString(),
      licenseNumber,
      state,
      zipcode,
      autoApprovalVersion,
    })}`,
    true
  );
