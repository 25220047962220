import { processNotesDBdata } from "../../Common/notesDataHelper";
import {
  processShiftDetailsDBdata,
  processTechShiftDetailsDBdata,
} from "../../Common/shiftDataHelper";
import * as C from "../../constants/calendar";
import * as ConfirmMessage from "../../constants/confirmMessage";
import { EXCEPTION_MESSAGE } from "../../constants/errorMessage";
import * as hospitalConstants from "../../constants/hospitalConstants";
import { UNSET_ENTERPRISE_HOSPITAL_SESSION } from "../../constants/Login";

const getInitialStateStructures = () => ({
  showLoader: false,

  errorTypeConfirmingShift: null,
  calendarNotesData: [],
  aAskFutureAutoConfirm: [],

  // -- vet
  isShiftActionSuccessUpdate: false,
  requestShiftDetails: [],
  shiftData: [],
  editShiftDetails: [],

  // -- tech
  isTechShiftActionSuccessUpdate: false,
  requestTechShiftDetails: [],
  techShiftData: [],
  editTechShiftDetails: [],
  newTrimmedTechData: [],
  // tech end
});

const getInitialModalBooleans = () => ({
  isVetReqDeclineSuccess: false,
  // -- vet
  isVetShiftConfirmedFromCalendar: false,
  isVetShiftConfirmedFromPanel: false,
  isVetShiftDeletedFromPanel: false,
  isVetShiftRemoved: false,
  isShowVetShiftRemovedModal: false,
  isShowProfileConfirmReqModal: false,
  isShowVetRequestConfirmedModal: false,
  isVetConfirmedError: false,
  isVetActionUpdate: false,
  isShiftRenewed: false,
  isRenewMultipleShiftsSuccess: false,
  isCounterBid: false,

  // --- tech
  isTechShiftConfirmedFromCalendar: false,
  isTechShiftConfirmedFromPanel: false,
  isTechShiftDeletedFromPanel: false,
  isTechShiftRemoved: false,
  isShowTechShiftRemovedModal: false,
  isShowTechRequestConfirmedModal: false,
  isTechConfirmedError: false,
  isTechReqDeclineSuccess: false,
  isTechActionUpdate: false,
  // end tech

  modalMessage: EXCEPTION_MESSAGE,
});

const initialStateStructures = getInitialStateStructures();
const initialModalBooleans = getInitialModalBooleans();

const getInitialState = () => {
  return { ...initialStateStructures, ...initialModalBooleans };
};

const initialState = getInitialState();

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UNSET_ENTERPRISE_HOSPITAL_SESSION:
      return {
        ...initialState,
      };
    case hospitalConstants.GET_VET_SHIFTS:
      return {
        ...state,
        isShiftActionSuccessUpdate: false,
        isVetShiftConfirmedFromPanel: false,
        isVetShiftDeletedFromPanel: false,
        isVetShiftRemoved: false,
        isVetActionUpdate: false,
        isVetShiftConfirmedFromCalendar: false,
      };

    case hospitalConstants.GET_VET_SHIFTS_SUCCESS:
      return {
        ...state,
        isShowProfileConfirmReqModal: false,
      };

    case C.GET_SHIFT_DETAILS_SUCCESS:
      const oShiftDetailsData = processShiftDetailsDBdata(action.payload);
      return {
        ...state,
        shiftData: oShiftDetailsData.aShiftData,
        requestShiftDetails: oShiftDetailsData.requestedShiftDetails,
        isVetShiftConfirmedFromCalendar: false,
        isShowProfileConfirmReqModal: false,
        showLoader: false,
        isVetActionUpdate: false,
        isVetReqDeclineSuccess: false,
        modalMessage: EXCEPTION_MESSAGE,
      };

    case C.CONFIRM_VET_SHIFT_SUCCESS:
      const isAskFutureAutoConfirm = action.payload.data.isAskFutureAutoConfirm === 1;
      const aAskFutureAutoConfirmVet = [...state.aAskFutureAutoConfirm];
      if (isAskFutureAutoConfirm) {
        const oVetInfo = action.payload.data;
        aAskFutureAutoConfirmVet.push(oVetInfo);
      }
      return {
        ...state,
        isShiftActionSuccessUpdate: true,
        aAskFutureAutoConfirm: aAskFutureAutoConfirmVet,
        isShowProfileConfirmReqModal: true,
        isShowVetRequestConfirmedModal: true,
        isVetShiftConfirmedFromCalendar: true,
        isVetConfirmedError: false,
        errorTypeConfirmingShift: null,
      };

    case C.CONFIRM_VET_SHIFT_NO_MODAL_SUCCESS:
      const isAskFutureAutoConfirmVal = action.payload.data.isAskFutureAutoConfirm === 1;
      const oAskFutureAutoConfirmVet = [...state.aAskFutureAutoConfirm];
      if (isAskFutureAutoConfirmVal) {
        const oVetInfo = action.payload.data;
        oAskFutureAutoConfirmVet.push(oVetInfo);
      }
      return {
        ...state,
        isShiftActionSuccessUpdate: true,
        aAskFutureAutoConfirm: oAskFutureAutoConfirmVet,
        isVetConfirmedError: false,
        errorTypeConfirmingShift: null,
      };

    case C.CONFIRM_VET_SHIFT_FAIL:
      return {
        ...state,
        isShowVetRequestConfirmedModal: true,
        isVetConfirmedError: true,
        errorTypeConfirmingShift: action.payload.message,
      };

    case C.CONFIRM_VET_SHIFT_NO_MODAL_FAIL:
      return {
        ...state,
        isShowVetRequestConfirmedModal: true,
        isVetShiftConfirmedFromPanel: false,
        isVetConfirmedError: true,
        errorTypeConfirmingShift: action.payload.message,
      };

    case C.CLOSE_SHIFT_ACTION_RESULT_MESSAGE_MODAL:
      return {
        ...state,
        ...initialModalBooleans,
      };

    case C.INITIALIZE_STATE:
      return getInitialState();

    case C.INITIALIZE_POPUP_STATE:
      return {
        ...state,
        shiftData: [],
        techShiftData: [],
        requestShiftDetails: [],
        requestTechShiftDetails: [],
        ...initialModalBooleans,
      };

    case C.SHOW_LOADER:
      return {
        ...state,
        showLoader: true,
      };

    case C.DELETE_SHIFT_SUCCESS:
      return {
        ...state,
        isShiftActionSuccessUpdate: true,
        isVetShiftRemoved: true,
        isShowVetShiftRemovedModal: true,
      };

    case C.RENEW_SHIFT_SUCCESS:
      return {
        ...state,
        isShiftRenewed: true,
      };

    case C.RENEW_MULTIPLE_SHIFTS_SUCCESS:
      return {
        ...state,
        isRenewMultipleShiftsSuccess: true,
        isShiftActionSuccessUpdate: true,
      };

    case C.CLEAR_RENEW_MULTIPLE_SHIFTS_DATA:
      return {
        ...state,
        isRenewMultipleShiftsSuccess: false,
      };

    case hospitalConstants.GET_TECH_SHIFTS:
      return {
        ...state,
        isTechShiftActionSuccessUpdate: false,
        isTechShiftConfirmedFromPanel: false,
        isTechShiftDeletedFromPanel: false,
        isTechShiftConfirmedFromCalendar: false,
        isTechShiftRemoved: false,
        isTechActionUpdate: false,
        isTechReqDeclineSuccess: false,
        modalMessage: EXCEPTION_MESSAGE,
      };

    case hospitalConstants.GET_TECH_SHIFTS_SUCCESS:
      return {
        ...state,
        isShowProfileConfirmReqModal: false,
      };

    case C.GET_TECH_SHIFT_DETAILS_SUCCESS:
      const oTechShiftDetailsData = processTechShiftDetailsDBdata(action.payload);
      return {
        ...state,
        techShiftData: oTechShiftDetailsData.aShiftData,
        requestTechShiftDetails: oTechShiftDetailsData.requestedShiftDetails,
        isTechShiftConfirmedFromCalendar: false,
        isShowProfileConfirmReqModal: false,
        showLoader: false,
      };

    case C.CONFIRM_TECH_SHIFT_SUCCESS:
      const isAskFutureAutoConfirmTech = action.payload.data.isAskFutureAutoConfirm === 1;
      const aAskFutureAutoConfirmTech = [...state.aAskFutureAutoConfirm];
      if (isAskFutureAutoConfirmTech) {
        const oTechInfo = action.payload.data;
        aAskFutureAutoConfirmTech.push(oTechInfo);
      }
      return {
        ...state,
        isTechShiftActionSuccessUpdate: true,
        isTechShiftConfirmedFromCalendar: true,
        aAskFutureAutoConfirm: aAskFutureAutoConfirmTech,
        isShowProfileConfirmReqModal: true,
        isShowTechRequestConfirmedModal: true,
        isTechConfirmedError: false,
        errorTypeConfirmingShift: null,
      };

    case C.CONFIRM_TECH_SHIFT_NO_MODAL_SUCCESS:
      const isAskFutureAutoConfirmTechVal = action.payload.data.isAskFutureAutoConfirm === 1;
      const oAskFutureAutoConfirmTech = [...state.aAskFutureAutoConfirm];
      if (isAskFutureAutoConfirmTechVal) {
        const oTechInfo = action.payload.data;
        oAskFutureAutoConfirmTech.push(oTechInfo);
      }
      return {
        ...state,
        isTechShiftActionSuccessUpdate: true,
        isTechShiftConfirmedFromCalendar: true,
        aAskFutureAutoConfirm: oAskFutureAutoConfirmTech,
        isShowProfileConfirmReqModal: true,
        isShowTechRequestConfirmedModal: true,
        isTechConfirmedError: false,
        errorTypeConfirmingShift: null,
      };

    case C.CONFIRM_TECH_SHIFT_FAIL:
      return {
        ...state,
        isShowTechRequestConfirmedModal: true,
        isTechConfirmedError: true,
        errorTypeConfirmingShift: action.payload.message,
      };

    case C.CONFIRM_TECH_SHIFT_NO_MODAL_FAIL:
      return {
        ...state,
        isShowTechRequestConfirmedModal: true,
        isTechShiftConfirmedFromPanel: false,
        isTechConfirmedError: true,
        errorTypeConfirmingShift: action.payload.message,
      };

    case C.RESET_PANEL_SHIFT_ACTION_FLAGS:
      return {
        ...state,
        isTechShiftConfirmedFromPanel: false,
        isVetShiftConfirmedFromPanel: false,
        isTechShiftDeletedFromPanel: false,
        isVetShiftDeletedFromPanel: false,
        isVetRequestDeclinedFromPanel: false,
        isTechRequestDeclinedFromPanel: false,
        isCounterBid: false,
      };

    case C.CONFIRM_VET_SHIFT_FROM_PANEL_SUCCESS:
      const isAskFutureAutoConfirmPanel = action.payload.data.isAskFutureAutoConfirm === 1;
      const oAskFutureAutoConfirmVetPanel = [...state.aAskFutureAutoConfirm];
      if (isAskFutureAutoConfirmPanel) {
        const oVetInfo = action.payload.data;
        oAskFutureAutoConfirmVetPanel.push(oVetInfo);
      }
      return {
        ...state,
        isVetShiftConfirmedFromPanel: true,
        aAskFutureAutoConfirm: oAskFutureAutoConfirmVetPanel,
      };

    case C.CONFIRM_TECH_SHIFT_FROM_PANEL_SUCCESS:
      return {
        ...state,
        isTechShiftConfirmedFromPanel: true,
      };

    case C.DECLINE_REQUEST_FROM_PANEL_SUCCESS:
      return {
        ...state,
        isVetRequestDeclinedFromPanel: true,
        isCounterBid: action.payload.isCounterBid,
      };

    case C.DECLINE_TECH_REQUEST_FROM_PANEL_SUCCESS:
      return {
        ...state,
        isTechRequestDeclinedFromPanel: true,
      };

    case C.DELETE_TECH_SHIFT_FROM_PANEL_SUCCESS:
      return {
        ...state,
        isTechShiftDeletedFromPanel: true,
      };

    case C.DELETE_SHIFT_FROM_PANEL_SUCCESS:
      return {
        ...state,
        isVetShiftDeletedFromPanel: true,
      };

    case C.DELETE_TECH_SHIFT_SUCCESS:
      return {
        ...state,
        isTechShiftActionSuccessUpdate: true,
        isTechShiftRemoved: true,
        isShowTechShiftRemovedModal: true,
      };

    case C.GET_CALENDAR_NOTES_SUCCESS:
      const oNotesData = processNotesDBdata(action.payload.data);

      return {
        ...state,
        calendarNotesData: oNotesData,
      };

    case C.DECLINE_REQUEST_SUCCESS:
      return {
        ...state,
        isVetActionUpdate: true,
        isVetReqDeclineSuccess: true,
        modalMessage: ConfirmMessage.ADMIN_REMOVE_CONFIRM_VET_SUCCESS,
      };

    case C.DECLINE_TECH_REQUEST_SUCCESS:
      return {
        ...state,
        isTechActionUpdate: true,
        isTechReqDeclineSuccess: true,
        modalMessage: ConfirmMessage.ADMIN_REMOVE_CONFIRM_TECH_SUCCESS,
      };

    case C.DECLINE_REQUEST_FAIL:
      return {
        ...state,
        isVetActionUpdate: true,
        isVetReqDeclineSuccess: false,
        modalMessage: ConfirmMessage.ADMIN_REMOVE_CONFIRM_TECH_SUCCESS,
      };

    case C.DECLINE_TECH_REQUEST_FAIL:
      return {
        ...state,
        isTechActionUpdate: true,
        isTechReqDeclineSuccess: false,
      };

    case hospitalConstants.CLOSE_FUTURE_AUTO_CONFIRM: {
      const aAskFutureAutoConfirmAfterClose = [...state.aAskFutureAutoConfirm];
      aAskFutureAutoConfirmAfterClose.shift(); // remove first element in array
      return {
        ...state,
        aAskFutureAutoConfirm: aAskFutureAutoConfirmAfterClose,
      };
    }

    case C.GET_HOSPITAL_LATEST_SHIFT_DETAILS:
      return {
        ...state,
        latestVetShiftDetails: null,
        latestTechShiftDetails: null,
        isLatestShiftDetailsFetched: false,
      };

    case C.GET_HOSPITAL_LATEST_SHIFT_DETAILS_SUCCESS:
    case C.GET_HOSPITAL_LATEST_SHIFT_DETAILS_FAIL:
      return {
        ...state,
        latestVetShiftDetails: action?.payload?.shiftDetails,
        latestTechShiftDetails: action?.payload?.techShiftDetails,
        isLatestShiftDetailsFetched: true,
      };

    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default reducer;
