import * as Sentry from "@sentry/react";
import "isomorphic-fetch";
import { call, put, select, takeEvery } from "redux-saga/effects";
import "../../../helpers/InterceptFetch";

import * as ErrorMessage from "../../../constants/errorMessage";
import * as C from "../../../constants/hospitalConstants";
import { UNSET_LOADER } from "../../../constants/loader";
import { setProfileData } from "../../../ProfilePanels/actions/actions";

export const getStates = (state) => state.hospitalProfile.states;

function* fetchHospitalDetailsAsync(action) {
  try {
    const userTypeId = action.payload.userTypeId;
    const userId = action.payload.userId;
    const hospitalId = localStorage.getItem("hospitalId")
      ? localStorage.getItem("hospitalId")
      : action.payload.hospitalId;

    const response = yield call(
      fetch,
      window.RooConfig.API_URL +
        `api/hospital/getHopsitalProfileInfoById?userType=${userTypeId}&userId=${userId}&hospitalId=${hospitalId} `,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = yield response.json();

    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({ type: C.FETCH_HOSPITAL_DETAILS_SUCCESS, payload: data.data });
    } else {
      yield put({ type: C.FETCH_HOSPITAL_DETAILS_FAIL });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while fetching hospital details: " },
    });
    yield put({
      type: C.FETCH_HOSPITAL_DETAILS_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* updateHospitalImagesAsync(action) {
  const formData = new FormData();

  let hospitalId;

  if (action.payload.isFromAdmin === true) {
    hospitalId = action.payload.hospitalId;
  } else {
    hospitalId = localStorage.getItem("hospitalId");
  }

  for (let i = 0; i < action.payload.files.length; i++) {
    formData.append("document", action.payload.files[i]);
    formData.append("fileName", action.payload.fileName); // fileName for extension, since file name removed with fix rotation
  }

  formData.append("userId", hospitalId);
  formData.append("userType", "hospital");
  if (action.payload.isShiftPhoto) {
    formData.append("isShiftPhoto", action.payload.isShiftPhoto);
    if (action.payload.oldShiftPhotoId) {
      // attach old profile photo id to delete it
      formData.append("oldShiftPhotoId", action.payload.oldShiftPhotoId);
    }
  }
  if (action.payload.isFirstImage) {
    formData.append("isFirstImage", action.payload.isFirstImage);
  }
  try {
    const response = yield call(fetch, window.RooConfig.API_URL + "api/user/uploadDocument", {
      method: "POST",
      body: formData,
    });

    const data = yield response.json();

    if (data.status_code === 200) {
      let isProfileImage = "";
      for (const img of data.data.data) {
        if (img.isShiftPhoto) {
          isProfileImage = img.imagePath;
          break;
        }
      }
      yield put({
        type: C.SET_PROFILE_IMAGE,
        payload: isProfileImage,
      });
      yield put({
        type: C.UPDATE_HOSPITAL_IMAGES_SUCCESS,
        payload: { status: data.status_code, hospitalImages: data.data.data },
      });
      yield put({ type: UNSET_LOADER });
    } else if (data.status_code === 400) {
      yield put({
        type: C.UPDATE_HOSPITAL_IMAGES_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating hospital images: " },
    });
    yield put({
      type: C.UPDATE_HOSPITAL_IMAGES_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* fetchVetViewProfileDetailsAsync(action) {
  try {
    const { isFromPanel } = action.payload;
    delete action.payload.isFromPanel;
    const idQuery = action.payload.userId
      ? `userId=${action.payload.userId}`
      : `vetId=${action.payload.vetId}`;

    const response = yield call(
      fetch,
      window.RooConfig.API_URL +
        `api/vet/getVetInfoById?userType=${action.payload.userType}&${idQuery}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({ type: C.FETCH_VET_VIEW_PROFILE_DETAILS_SUCCESS, payload: data.data });
      if (isFromPanel) {
        const states = yield select(getStates);
        yield put(setProfileData({ ...data.data, isVet: true, states }));
      }
    } else {
      yield put({ type: C.FETCH_VET_VIEW_PROFILE_DETAILS_FAIL });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while fetching vet view profile details: " },
    });
    yield put({
      type: C.FETCH_VET_VIEW_PROFILE_DETAILS_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* fetchTechViewProfileDetailsAsync(action) {
  try {
    const { isFromPanel } = action.payload;
    delete action.payload.isFromPanel;
    const response = yield call(
      fetch,
      window.RooConfig.API_URL +
        `api/tech/getTechInfoById?userType=${action.payload.userType}&techId=${action.payload.techId} `,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({ type: C.FETCH_TECH_VIEW_PROFILE_DETAILS_SUCCESS, payload: data.data });
      if (isFromPanel) {
        const states = yield select(getStates);
        yield put(setProfileData({ ...data.data, isVet: false, states }));
      }
    } else {
      yield put({ type: C.FETCH_TECH_VIEW_PROFILE_DETAILS_FAIL });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while fetching tech view profile details: " },
    });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: C.FETCH_TECH_VIEW_PROFILE_DETAILS_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* fetchStateHospitalProfileAsync() {
  try {
    const response = yield call(fetch, window.RooConfig.API_URL + "api/utility/getStates", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = yield response.json();

    if (data.status_code === 200) {
      yield put({ type: C.FETCH_STATES_HOSPITAL_PROFILE_SUCCESS, payload: data.data });
    } else {
      yield put({ type: C.FETCH_STATES_HOSPITAL_PROFILE_FAIL });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while fetching state hospital profile: " },
    });
    yield put({
      type: C.FETCH_STATES_HOSPITAL_PROFILE_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* hospitalImageDeleteAsync(action) {
  try {
    const response = yield call(fetch, window.RooConfig.API_URL + "api/user/deleteProfileImages", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      let isProfileImage = "";
      for (const img of data.data.data) {
        if (img.isShiftPhoto) {
          isProfileImage = img.imagePath;
          break;
        }
      }
      yield put({
        type: C.SET_PROFILE_IMAGE,
        payload: isProfileImage,
      });
      yield put({
        type: C.DELETE_HOSPITAL_PROFILE_IMAGE_SUCCESS,
        payload: { status: data.status_code, hospitalImages: data.data.data },
      });
    } else {
      yield put({
        type: C.DELETE_HOSPITAL_PROFILE_IMAGE_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while deleting hospital profile image: " },
    });
    yield put({ type: UNSET_LOADER });
  }
}

export function* updateHospitalProfileAsync(action) {
  try {
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/hospital/hospitalEditProfile",
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      localStorage.setItem("typeOfHospital", action.payload.typeOfHospital);
      yield fetchHospitalDetailsAsync(action);
    } else {
      yield put({
        type: C.UPDATE_HOSPITAL_PROFILE_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating hospital profile: " },
    });
  }
}

export function* updateHospitalShiftImageAsync(action) {
  try {
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/hospital/updateShiftImages",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      let isProfileImage = "";
      for (const img of data.data.data) {
        if (img.isShiftPhoto) {
          isProfileImage = img.imagePath;
          break;
        }
      }
      yield put({
        type: C.SET_PROFILE_IMAGE,
        payload: isProfileImage,
      });
      yield put({
        type: C.UPDATE_HOSPITAL_IMAGES_SUCCESS,
        payload: { status: data.status_code, hospitalImages: data.data.data },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: C.UPDATE_HOSPITAL_IMAGES_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating hospital shift image: " },
    });
    yield put({
      type: C.UPDATE_HOSPITAL_IMAGES_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* updateHospitalImagesArrangementAsnyc(action) {
  if (action.payload.idList && action.payload.idList.length == 2) {
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/hospital/updateImagesArrangement",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          idList: action.payload.idList,
          hospitalId: localStorage.getItem("hospitalId"),
        }),
      }
    );

    const data = yield response.json();

    if (data.status_code === 400) {
      yield put({
        type: C.UPDATE_HOSPITAL_IMAGES_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  }
}

export function* getShiftIdsByHospitalId(action) {
  try {
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/hospital/shift/shiftIdsByHospitalId",
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({ type: C.SHIFT_IDS_BY_HOSPITAL_ID_SUCCESS, payload: data.data });
    } else {
      yield put({ type: C.SHIFT_IDS_BY_HOSPITAL_ID_FAIL });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting shift ids by hospital id: " },
    });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: C.SHIFT_IDS_BY_HOSPITAL_ID_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* getTechShiftIdsByHospitalId(action) {
  try {
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/hospital/tech_shift/techShiftIdsByHospitalId",
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({ type: C.TECH_SHIFT_IDS_BY_HOSPITAL_ID_SUCCESS, payload: data.data });
    } else {
      yield put({ type: C.TECH_SHIFT_IDS_BY_HOSPITAL_ID_FAIL });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting tech shift ids by hospital id: " },
    });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: C.TECH_SHIFT_IDS_BY_HOSPITAL_ID_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootHospitalProfileSaga() {
  yield takeEvery(C.FETCH_HOSPITAL_DETAILS_REQUEST, fetchHospitalDetailsAsync);
  yield takeEvery(C.UPDATE_HOSPITAL_IMAGES_REQUEST, updateHospitalImagesAsync);
  yield takeEvery(C.FETCH_STATES_HOSPITAL_PROFILE_REQUEST, fetchStateHospitalProfileAsync);
  yield takeEvery(C.FETCH_VET_VIEW_PROFILE_DETAILS_REQUEST, fetchVetViewProfileDetailsAsync);
  yield takeEvery(C.FETCH_TECH_VIEW_PROFILE_DETAILS_REQUEST, fetchTechViewProfileDetailsAsync);
  yield takeEvery(C.DELETE_HOSPITAL_PROFILE_IMAGE_REQUEST, hospitalImageDeleteAsync);
  yield takeEvery(C.UPDATE_HOSPITAL_PROFILE_REQUEST, updateHospitalProfileAsync);
  yield takeEvery(C.UPDATE_HOSPITAL_SHIFT_IMAGE, updateHospitalShiftImageAsync);
  yield takeEvery(C.ARRANGE_HOSPITAL_IMAGES, updateHospitalImagesArrangementAsnyc);
  yield takeEvery(C.SHIFT_IDS_BY_HOSPITAL_ID, getShiftIdsByHospitalId);
  yield takeEvery(C.TECH_SHIFT_IDS_BY_HOSPITAL_ID, getTechShiftIdsByHospitalId);
}
