export const ADD_SHIFT_CONFIRM = "Shift has been added!";
export const MULTIPLE_ADD_SHIFT_CONFIRM = "Shifts have been added!";
export const CONFIRM_VET_SHIFT_SUCCESS_MESSAGE = "Vet is confirmed successfully";
export const CONFIRM_TECH_SHIFT_SUCCESS_MESSAGE = "Tech is confirmed successfully";
export const REMOVE_VET_SHIFT_SUCCESS_MESSAGE = "Vet shift has been removed.";
export const REMOVE_TECH_SHIFT_SUCCESS_MESSAGE = "Tech shift has been removed.";
export const EDIT_SHIFT_CONFIRM = "Shift details have been updated!";
export const EDIT_VET_BID_SHIFT_CONFIRM =
  EDIT_SHIFT_CONFIRM + " Vet bid shift: Please make any price changes manually.";
export const EDIT_SHIFT_CONFIRMED_MORE_THAN_90_DAYS_AGO =
  "Shift details can't be edited after 90 days";
export const SHIFT_BOOSTED_SUCCESS = "Shift is boosted successfully";
export const FORGOT_PASSWORD_SUCCESS = "Password has been successfully saved, you may log in now";
export const ADMIN_SHIFT_PRICE_UPDATE_SUCCESS = "The Shift Price is changed successfully";
export const ADMIN_DELETE_SHIFT_SUCCESS = "The Shift is deleted successfully";
export const VET_FUND_SOURCE_ATTACHED_SUCCESS = "The details have been added successfully";
export const ADMIN_PAYMENT_TO_VET_SUCCESS = "Payment has been completed successfully";
export const ADMIN_OVERTIME_ALREADY_EXISTS = "Oops! Overtime already exists for this contractor.";
export const VET_SETTINGS_UPDATE_DETAILS_SUCCESS = "Details are updated Successfully";
export const ADMIN_PAYMENT_CONFIRM = "Are you sure you want to initiate payment?";
export const ADMIN_CANCEL_PAYMENT_CONFIRM = "Are you sure you want to cancel the payment?";
export const ADMIN_CHANGE_SHIFT_PRICE_CONFIRM = "Are you sure you want to change the price?";
export const ADMIN_CANCEL_PAYMENT_SUCCESS = "The payment has been cancelled";
export const ADMIN_SHIFT_REQUEST_SUCCESS = "The Shift request has been removed";
export const ADMIN_SMS_SENT_SUCCESS = "Message sent successfully.";
export const ADMIN_CONFIRM_SHIFT_REQUEST_SUCCESS = "The Shift is confirmed successfully";
export const PAYMENT_METHOD_ADDED = "The details have been added successfully";

export const ADD_INTERNSHIP_CONFIRM = "Internship has been added.";
export const INTERNSHIP_UPDATED_SUCCESS = "Internship updated successfully.";
export const DELETE_INTERNSHIP_CONFIRM = "Are you sure you want to delete this internship?";
export const INTERNSHIP_DELETED_SUCCESS = "Internship deleted successfully.";
export const STUDENT_CONTACTED_SUCCESS = "Student Contacted Successfully.";
export const STUDENT_CONFIRMED_SUCCESS = "Student Hired Successfully.";
export const STUDENT_DECLINED_SUCCESS = "Student Declined Successfully.";
export const INTERNSHIP_REQUESTED_SUCCESS = "Internship Requested Successfully";
export const NOT_CONFIRMED_INTERNSHIP_DELETED = "Oops! This internship is no longer available.";
export const NOT_CONFIRMED_ALREADY_FILLED_INTERNSHIP =
  "Oops! All positions are filled for this internship.";
export const NOT_CONFIRMED_INTERNSHIP_REQUEST_DELETED =
  "Oops! Student application for this internship is no longer available.";
export const NOT_CONTACTED_INTERNSHIP_OVERLAPPED =
  "Oops! Looks like the student you are trying to contact has already accepted an internship elsewhere.";
export const NOT_CONFIRMED_INTERNSHIP_OVERLAPPED =
  "Oops! Looks like the student you are trying to hire has already accepted an internship elsewhere.";
export const NOT_REQUESTED_INTERNSHIP_OVERLAPPED = "Oops! The Internship requested is overlapped.";

export const ADD_EXTERNSHIP_CONFIRM = "Externship has been added.";
export const EXTERNSHIP_UPDATED_SUCCESS = "Externship updated successfully.";
export const DELETE_EXTERNSHIP_CONFIRM = "Are you sure you want to delete this externship?";
export const EXTERNSHIP_DELETED_SUCCESS = "Externship deleted successfully.";
export const EXTERNSHIP_REQUESTED_SUCCESS = "Externship Requested Successfully";
export const NOT_CONFIRMED_EXTERNSHIP_DELETED = "Oops! This externship is no longer available.";
export const NOT_CONFIRMED_ALREADY_FILLED_EXTERNSHIP =
  "Oops! All positions are filled for this externship.";
export const NOT_CONFIRMED_EXTERNSHIP_REQUEST_DELETED =
  "Oops! Student application for this externship is no longer available.";
export const NOT_CONTACTED_EXTERNSHIP_OVERLAPPED =
  "Oops! Looks like the student you are trying to contact has already accepted an externship elsewhere.";
export const NOT_CONFIRMED_EXTERNSHIP_OVERLAPPED =
  "Oops! Looks like the student you are trying to hire has already accepted an externship elsewhere.";
export const NOT_REQUESTED_EXTERNSHIP_OVERLAPPED = "Oops! The Externship requested is overlapped.";

export const ADMIN_CONFIRM_VET_SWITCH_SUCCESS = "The Vet has been changed successfully";
export const ADMIN_CONFIRM_VET_SWITCH_MESSAGE = "Are you sure you want to change the vet?";
export const ADMIN_REMOVE_CONFIRM_VET_MESSAGE = "Are you sure you want to remove the vet?";
export const ADMIN_REMOVE_CONFIRM_VET_SUCCESS = "The Vet has been removed successfully";

export const ADMIN_CONFIRM_TECH_SWITCH_SUCCESS = "The Tech has been changed successfully";
export const ADMIN_CONFIRM_TECH_SWITCH_MESSAGE = "Are you sure you want to change the tech?";
export const ADMIN_REMOVE_CONFIRM_TECH_MESSAGE = "Are you sure you want to remove the tech?";
export const ADMIN_REMOVE_CONFIRM_TECH_SUCCESS = "The Tech has been removed successfully";

export const ADMIN_REQUEST_VET_SHIFT_SUCCESS = "The Shift has been requested successfully";
export const HOSPITAL_REJECTED_FOR_ENTERPRISE_SUCCESS = "Hospital has been rejected successfully";
export const HOSPITAL_USER_ADDED_SUCCESS = "User has been added successfully";
export const HOSPITAL_USER_UPDATED_SUCCESS = "User has been updated successfully";
export const HOSPITAL_USER_BLOCKED_SUCCESS = "The user has been deleted successfully";
export const HOSPITAL_USER_DELETED_CONFIRM = `Selecting “Remove” will permanently remove this user from the Roo platform.`;
export const HOSPITAL_USER_DELETED_SUCCESS = "User has been deleted successfully";
export const ADMIN_DELETE_VET_REQUEST = "Are you sure you want to delete this shift request?";
export const ADMIN_SHIFT_PROCESSED = "The shift has been requested and confirmed successfully";

export const USER_REFERRED_SUCCESSFULLY = "Invite has been sent successfully";
export const ADMIN_TECH_TIER_UPDATE = "Are you sure you want to update tier?";
export const ADMIN_TECH_TIER_UPDATE_SUCCESS = "Tier has been updated successfully";
export const REMINDER_REFER_EMAIL = "The email has been sent successfully";

export const ADMIN_REQUEST_SHIFT_OVERLAPS =
  "The shift requested is either overlapped, not available, or the requestor doesn't have the necessary license";

export const ADMIN_CONFIRM_CHANGE_REFERRAL_AMOUNT = "Are you sure you want to change the amount?";
export const ADMIN_CHANGE_REFERRAL_AMOUNT_SUCCESS = "Referral amount updated successfully";

export const ADMIN_CONFIRM_CHANGE_EMAIL_FOOTER =
  "Are you sure you want to change the email footer?";
export const ADMIN_EMAIL_FOOTER_UPDATE_SUCCESS = "Email footer updated successfully";

export const ADMIN_PAYMENT_HOSPITAL_REFER_SUCCESS =
  "Referred status has been completed successfully";

export const ADMIN_CONFIRM_HOSPITAL_PAYMENT_DELAY_SETTINGS =
  "Are you sure you want to set the delay payment?";

export const VET_REQUEST_DECLINE_WARNING = "Are you sure you want to decline this vet?";

export const ADMIN_PAYMENT_FOR_OVERTIME = "Are you sure you want to proceed for payment ?";

export const ADMIN_CHANGE_USER_RATING = "Are you sure you want to adjust user rating?";

export const ADMIN_CHANGE_USER_RELIABILITY_RATING =
  "Are you sure you want to adjust user reliability rating?";
export const ADMIN_FEEDBACK_SUCCESS = "Data has been updated successfully.";

export const ADMIN_TECH_PROB_UPDATE_CONFIRM = "Are you sure you want to update probation status?";
export const ADMIN_TECH_PROB_UPDATE_SUCCESS = "Probation has been updated successfully";
export const ADMIN_TECH_UNSUSPENDED_DATE_UPDATE_SUCCESS =
  "Unsuspended date has been updated successfully";

export const ADMIN_TECH_RELIABILITY_WILL_UPDATE = "Tech reliability rating will change";

export const FIND_TECH_REPLACEMENT =
  "We are sorry to hear that. Would you like us to try and find a replacement ASAP?";
export const SORRY_PROMPT_AFTER_TECH_NOT_SHOW = `We are so sorry that our technician didn’t show up as scheduled. If you have
already paid for this shift, you will receive a full refund since you indicated
that you no longer need coverage. Thank you for your understanding!`;
export const FINDING_REPLACEMENT_FOR_SHIFT =
  "We’re on the case! Roo will do our best to find replacement coverage for your shift and we will keep you posted. In the event we cannot find coverage, we will remove your shift and provide a full refund.";
export const BATCH_UPLOAD_USER_SUCCESS = "Users has been uploaded successfully";
export const BATCH_UPLOAD_USER_FAILED = "Failed to upload users";
export const BATCH_UPLOAD_HOSPITAL_SUCCESS = "Hospitals have been uploaded successfully";
export const BATCH_UPLOAD_HOSPITAL_FAILED = "Failed to upload hospitals";
export const INVALID_HEADERS = "Invalid headers. Please use pre-formatted file.";
export const BATCH_UPLOAD_DATA_MISSING =
  "Missing Required Information. Please review and re-upload.";
export const INVALID_EMAIL_IDS =
  "There were invalid entries in Email column. Please fix the following and upload the file again.";
export const INVALID_USER_ROLE =
  "There were invalid entries in Role column. Please fix the following and upload the file again.";
export const INVALID_HOSPITAL_TYPE =
  "There were invalid entries in Hospital Type column. Please fix the following and upload the file again.";
export const INVALID_STATE =
  "There were invalid entries in State column. Please fix the following and upload the file again.";
export const INVALID_ZIPCODE =
  "There were invalid entries in Zipcode column. Please fix the following and upload the file again.";

export const CONFIRM_IR_STATUS_UPDATE =
  "Are you sure you’d like to turn Internal Relief features on for this Enterprise and associated Hospitals?";
