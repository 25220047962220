import { createDictionary } from "../helpers/jsUtility";

export const anyOptionValue = 8888;
export const onlyNoneOptionValue = 9999;

export const maxMetroAreaId = 1000;
export const maxStateId = 52;

export const texasStateId = 45;
export const houstonMetroAreaId = 1;
export const dallasFtWorthMetroAreaId = 2;

export const centerUsLatLng = { lat: 39.827983, lng: -98.579372 };
export const localZoom = 10; // 30 mile radius on desktop map
export const metroZoom = 9;
export const stateZoom = 6.8;
export const countryZoom = 5;

export const aContractorStatusOptions = [
  {
    value: 5,
    label: "Active",
    name: "contractorStatusType",
  },
  {
    value: 0,
    label: "Pending",
    name: "contractorStatusType",
  },
  {
    value: 8,
    label: "Incomplete",
    name: "contractorStatusType",
  },
];
export const oContractorStatusOptions = createDictionary(aContractorStatusOptions, "value");

export const aTechTierOptions = [
  {
    value: 1,
    label: "Tier 1 min",
    name: "minTechTier",
  },
  {
    value: 2,
    label: "Tier 2 min",
    name: "minTechTier",
  },
  {
    value: 3,
    label: "Tier 3 min",
    name: "minTechTier",
  },
  {
    value: 4,
    label: "Specialty",
    name: "minTechTier",
  },
];
export const oTechTierOptions = createDictionary(aTechTierOptions, "value");
