import { extractUrlInfo } from "./urlUtils";

export const TRACKING_PARAMETER_KEYS = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_content",
  "utm_term",
  "ad_set_id",
  "ad_group_id",
  "ad_id",
] as const;

export const getTrackingParams = (queryString: string) => {
  const currentTrackingParams = getQueryParams(queryString, TRACKING_PARAMETER_KEYS);
  return {
    utmCampaign: currentTrackingParams.utm_campaign,
    utmContent: currentTrackingParams.utm_content,
    utmMedium: currentTrackingParams.utm_medium,
    utmSource: currentTrackingParams.utm_source,
    utmTerm: currentTrackingParams.utm_term,
    adSetId: currentTrackingParams.ad_set_id,
    adGroupId: currentTrackingParams.ad_group_id,
    adId: currentTrackingParams.ad_id,
  };
};

export const getReferralCode = (queryString: string) => {
  return getQueryParams(queryString, ["referralCode"]);
};

export const getQueryParams = <T extends string>(
  queryString: string,
  keys: ReadonlyArray<T>
): Record<T, string | null> => {
  const params = new URLSearchParams(queryString);
  const queryParams = {} as Record<T, string | null>;
  keys.forEach((key) => {
    queryParams[key] = params.get(key);
  });
  return queryParams;
};

export function getUtmsParamsWithReferrerFallback() {
  const referrerTrackingParams =
    extractUrlInfo(document.referrer)?.hostname === window.location.hostname
      ? getTrackingParams(document.referrer)
      : undefined;

  const currentTrackingParams = getTrackingParams(window.location.search);

  return {
    utmCampaign: currentTrackingParams.utmCampaign || referrerTrackingParams?.utmCampaign,
    utmContent: currentTrackingParams.utmContent || referrerTrackingParams?.utmContent,
    utmMedium: currentTrackingParams.utmMedium || referrerTrackingParams?.utmMedium,
    utmSource: currentTrackingParams.utmSource || referrerTrackingParams?.utmSource,
    utmTerm: currentTrackingParams.utmTerm || referrerTrackingParams?.utmTerm,
    adSetId: currentTrackingParams.adSetId || referrerTrackingParams?.adSetId,
    adGroupId: currentTrackingParams.adGroupId || referrerTrackingParams?.adGroupId,
  };
}

export const getIsFromEvent = (queryString: string) => {
  const { fromEvent } = getQueryParams(queryString, ["fromEvent"]);
  return fromEvent === "1";
};

export const buildQuery = (url: string, parameters: any) => {
  const queryParams = Object.keys(parameters).reduce((newObj: any, key) => {
    if (parameters[key]) {
      newObj[key] = parameters[key];
    }
    return newObj;
  }, {});

  const paramString = new URLSearchParams(queryParams).toString();
  return url + "?" + paramString;
};
