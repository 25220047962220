import { map } from "lodash";

import { sortSearchedExternships } from "../../../Common/externshipDataHelper";
import {
  getSpecialityInterestLabels,
  sortSearchedInternships,
} from "../../../Common/internshipDataHelper";
import { EXCEPTION_MESSAGE } from "../../../constants/errorMessage";
import * as C from "../../../constants/student";

const getHospitalProfileInitialState = () => ({
  hospitalName: "",
  hospitalWebsite: "",
  medicalDirector: "",
  roleId: null,

  typeOfHospital: null,
  isAahaAccredited: "",
  averageNoOfDVMWorkPerDay: "",
  averageNoOfTechPerDvmPerDay: "",
  typicalLunchBreakSetup: "",

  practiceManagementSoftware: "",

  hospitalReliefWork: "",
  hospitalMaintainRecords: "",
  hospitalDressCodes: "",

  hasApptGeneral: "",
  hasApptWellness: "",
  hasApptWalkin: "",
  hasApptNonwell: "",
  hasApptEmergency: "",
  hasApptHighVol: "",
  hasApptLargeAnimal: "",
  hasApptShelter: "",
  hasApptSurgery: "",
  hasApptSpecialty: "",
  hasApptDental: "",
  hasApptAcupuncture: "",
  hasApptChiropractic: "",
  hasApptLaser: "",
  hasApptUrgentCare: "",

  apptFlow: "",
  clientInteract: "",
  staffInteract: "",

  amenitiesNotes: "",
  parkingNotes: "",
});

const getHospitalUserInitialState = () => ({
  additionalSkills: "",
  characterTypeId: null,
  city: "",
  fullName: "",
  hospitalAddress: "",
  hospitalPhoneNumber: "",
  mobilePhoneNumber: "",
  stateId: null,
  zipcode: null,
});

const initialState = {
  internships: [],
  externships: [],
  hospitalViewDetails: {
    hospital: getHospitalProfileInitialState(),
    user: getHospitalUserInitialState(),
    profileImage: [],
  },
  isInternshipRequested: null,
  isExternshipRequested: null,
  errMsg: EXCEPTION_MESSAGE,
};

// eslint-disable-next-line default-param-last
const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case C.FETCH_HOSPITAL_VIEW_INTERNSHIP_PROFILE_SUCCESS:
      return {
        ...state,
        hospitalViewDetails: {
          hospital: action.payload.hospital[0],
          user: action.payload.user[0],
          profileImage: action.payload.profileImage,
        },
      };

    case C.FETCH_HOSPITAL_VIEW_INTERNSHIP_PROFILE_REQUEST:
    case C.FETCH_HOSPITAL_VIEW_INTERNSHIP_PROFILE_FAIL:
      return {
        ...state,
        hospitalViewDetails: {
          hospital: getHospitalProfileInitialState(),
          user: getHospitalUserInitialState(),
          profileImage: [],
        },
        internships: [],
        externships: [],
      };

    case C.GET_ACTIVE_EXTERNSHIPS_REQUEST: {
      return {
        ...state,
        externships: [],
      };
    }

    case C.GET_ACTIVE_EXTERNSHIPS_SUCCESS:
      map(action.payload.externships, (oExternship) => {
        oExternship.specialInterests = getSpecialityInterestLabels(oExternship.specialInterestIds);
      });
      const exfilteredResult = sortSearchedExternships(action.payload.externships);

      return {
        ...state,
        externships: exfilteredResult.sortedExternships || [],
      };

    case C.GET_ACTIVE_EXTERNSHIPS_FAIL:
      return {
        ...state,
        externships: [],
      };

    case C.REQUEST_EXTERNSHIP_REQUEST:
    case C.CLEAR_EXTERNSHIP_REQUEST_FLAG:
      return {
        ...state,
        isExternshipRequested: null,
        errMsg: EXCEPTION_MESSAGE,
      };

    case C.REQUEST_EXTERNSHIP_SUCCESS:
      return {
        ...state,
        isExternshipRequested: true,
      };

    case C.REQUEST_EXTERNSHIP_FAIL:
      return {
        ...state,
        isExternshipRequested: false,
        errMsg: action.payload.message,
      };

    case C.GET_ACTIVE_INTERNSHIPS_REQUEST: {
      return {
        ...state,
        internships: [],
      };
    }

    case C.GET_ACTIVE_INTERNSHIPS_SUCCESS:
      map(action.payload.internships, (oInternship) => {
        oInternship.specialInterests = getSpecialityInterestLabels(oInternship.specialInterestIds);
      });
      const filteredResult = sortSearchedInternships(action.payload.internships);

      return {
        ...state,
        internships: filteredResult.sortedInternships || [],
      };

    case C.GET_ACTIVE_INTERNSHIPS_FAIL:
      return {
        ...state,
        internships: [],
      };

    case C.REQUEST_INTERNSHIP_REQUEST:
    case C.CLEAR_INTERNSHIP_REQUEST_FLAG:
      return {
        ...state,
        isInternshipRequested: null,
        errMsg: EXCEPTION_MESSAGE,
      };

    case C.REQUEST_INTERNSHIP_SUCCESS:
      return {
        ...state,
        isInternshipRequested: true,
      };

    case C.REQUEST_INTERNSHIP_FAIL:
      return {
        ...state,
        isInternshipRequested: false,
        errMsg: action.payload.message,
      };

    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default profileReducer;
