import { ShiftSearch } from "../ProfilePanels/ProfilePanelTypes";

import {
  CHANGE_SHIFT_STATUS,
  CLOSE_NEW_HOSPITAL_PROFILE_PANEL,
  RELOAD_SHIFTS_FOR_CONTRACTOR,
  RESET_RELOAD_SHIFTS_FOR_CONTRACTOR,
  SHOW_NEW_HOSPITAL_PROFILE_PANEL,
  UPDATE_ACTIVE_TAB,
} from "./constants";

export interface InitialStateProps {
  isOpenHospitalProfilePanel: boolean;
  panelData: any;
  isReloadShifts: boolean;
  reloadShiftsSearch?: ShiftSearch;
}

const initialState: InitialStateProps = {
  isOpenHospitalProfilePanel: false,
  panelData: { activeTab: 1 },
  isReloadShifts: false,
};

const HospitalProfilePanelReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: { payload?: any; type: string }
) => {
  switch (action.type) {
    case SHOW_NEW_HOSPITAL_PROFILE_PANEL:
      return {
        ...state,
        isOpenHospitalProfilePanel: true,
        panelData: action.payload,
      };

    case CLOSE_NEW_HOSPITAL_PROFILE_PANEL:
      return {
        ...state,
        isOpenHospitalProfilePanel: false,
      };

    case RELOAD_SHIFTS_FOR_CONTRACTOR:
      return {
        ...state,
        isReloadShifts: true,
        reloadShiftsSearch: action.payload,
      };

    case RESET_RELOAD_SHIFTS_FOR_CONTRACTOR:
      return {
        ...state,
        isReloadShifts: false,
        reloadShiftsSearch: undefined,
      };

    case CHANGE_SHIFT_STATUS:
      return {
        ...state,
        panelData: {
          ...state.panelData,
          shiftStatus: action.payload,
        },
      };

    case UPDATE_ACTIVE_TAB:
      return {
        ...state,
        panelData: {
          ...state.panelData,
          activeTab: action.payload,
        },
      };

    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default HospitalProfilePanelReducer;
