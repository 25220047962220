// states
export const californiaStateId = 5;
export const texasStateId = 45;
export const STATES = {
  5: "California",
  45: "Texas",
};
// metro areas
export const losAngelesId = 3;
export const sanDiegoId = 4;
export const sanFrancisoId = 5;
export const sacramentoId = 8;

export const centerUSACoordinates = {
  lat: 39.8282,
  lng: -98.5795,
};
