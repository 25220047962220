import * as Sentry from "@sentry/react";
import "isomorphic-fetch";
import { call, put, takeEvery } from "redux-saga/effects";
import "../../../helpers/InterceptFetch";

import * as ErrorMessage from "../../../constants/errorMessage";
import * as ActionTypes from "../../../constants/vetConstants";

export function* updatePreferredAreasOfPractice(action) {
  try {
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/vet/updatePreferredAreasOfPractice",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();
    if (data.status_code === 200) {
      yield put({
        type: ActionTypes.UPDATE_VET_PREFERRED_AREAS_OF_PRACTICE_SUCCESS,
        payload: { status: data.status_code, vetImage: data.data.data },
      });
    } else {
      yield put({
        type: ActionTypes.UPDATE_VET_PREFERRED_AREAS_OF_PRACTICE_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating preferred areas of practice: " },
    });
    yield put({
      type: ActionTypes.UPDATE_VET_PREFERRED_AREAS_OF_PRACTICE_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}
export function* updatePreferredAreasSliders(action) {
  try {
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/vet/updatePreferredAreasSliders",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();
    if (data.status_code === 200) {
      yield put({
        type: ActionTypes.UPDATE_VET_PREFERRED_AREAS_SLIDERS_SUCCESS,
        payload: { status: data.status_code, vetImage: data.data.data },
      });
    } else {
      yield put({
        type: ActionTypes.UPDATE_VET_PREFERRED_AREAS_SLIDERS_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating preferred areas sliders: " },
    });
    yield put({
      type: ActionTypes.UPDATE_VET_PREFERRED_AREAS_SLIDERS_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}
export function* updateVetCharacterType(action) {
  try {
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/vet/updateVetCharacterType",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();
    if (data.status_code === 200) {
      yield put({
        type: ActionTypes.UPDATE_VET_CHARACTER_TYPE_SUCCESS,
        payload: { status: data.status_code, vetImage: data.data.data },
      });
    } else {
      yield put({
        type: ActionTypes.UPDATE_VET_CHARACTER_TYPE_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating vet character type: " },
    });
    yield put({
      type: ActionTypes.UPDATE_VET_CHARACTER_TYPE_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}
export function* updateVetAdditionalSkills(action) {
  try {
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/vet/updateVetAdditionalSkills",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();
    if (data.status_code === 200) {
      yield put({
        type: ActionTypes.UPDATE_VET_ADDITIONAL_SKILLS_SUCCESS,
        payload: { status: data.status_code, vetImage: data.data.data },
      });
    } else {
      yield put({
        type: ActionTypes.UPDATE_VET_ADDITIONAL_SKILLS_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating vet additional skills: " },
    });
    yield put({
      type: ActionTypes.UPDATE_VET_ADDITIONAL_SKILLS_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* updateVetShowOnboarding(action) {
  try {
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/vet/updateVetShowOnboarding",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();
    if (data.status_code === 200) {
      yield put({
        type: ActionTypes.UPDATE_VET_SHOW_ONBOARDING_SUCCESS,
        payload: { status: data.status_code, vetImage: data.data.data },
      });
    } else {
      yield put({
        type: ActionTypes.UPDATE_VET_SHOW_ONBOARDING_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating vet show onboarding: " },
    });
    yield put({
      type: ActionTypes.UPDATE_VET_SHOW_ONBOARDING_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* getVetOnboardingInfo(action) {
  try {
    const vetId = action.payload;
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + `api/vet/getVetOnboardingInfo/${vetId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = yield response.json();
    if (data.status_code === 200) {
      yield put({
        type: ActionTypes.GET_VET_ONBOARDING_INFO_SUCCESS,
        payload: { status: data.status_code, data: data.data[0] },
      });
    } else {
      yield put({
        type: ActionTypes.GET_VET_ONBOARDING_INFO_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting vet onboarding info: " },
    });
    yield put({
      type: ActionTypes.GET_VET_ONBOARDING_INFO_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootVetOnboardingSaga() {
  yield takeEvery(
    ActionTypes.UPDATE_VET_PREFERRED_AREAS_OF_PRACTICE,
    updatePreferredAreasOfPractice
  );
  yield takeEvery(ActionTypes.UPDATE_VET_PREFERRED_AREAS_SLIDERS, updatePreferredAreasSliders);
  yield takeEvery(ActionTypes.UPDATE_VET_CHARACTER_TYPE, updateVetCharacterType);
  yield takeEvery(ActionTypes.UPDATE_VET_ADDITIONAL_SKILLS, updateVetAdditionalSkills);
  yield takeEvery(ActionTypes.UPDATE_VET_SHOW_ONBOARDING, updateVetShowOnboarding);
  yield takeEvery(ActionTypes.GET_VET_ONBOARDING_INFO, getVetOnboardingInfo);
}
