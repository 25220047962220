import { filterInternships } from "../../../Common/internshipDataHelper";
import * as C from "../../../constants/adminInternships";
import * as ErrorMessage from "../../../constants/errorMessage";

const initialState = {
  internshipList: [],
  isInternshipUpdated: null,
  isInternshipDeleted: null,
  modalMessage: ErrorMessage.EXCEPTION_MESSAGE,
};

// eslint-disable-next-line default-param-last
const internshipReducer = (state = initialState, action) => {
  switch (action.type) {
    case C.GET_INTERNSHIP_LIST_SUCCESS:
      if (action.payload.data && action.payload.data.length) {
        action.payload.data = filterInternships([...action.payload.data]);
      }
      return {
        ...state,
        internshipList: action.payload.data || [],
      };

    case C.UPDATE_INTERNSHIP_REQUEST:
      return {
        ...state,
        isInternshipUpdated: null,
      };

    case C.UPDATE_INTERNSHIP_FAIL:
      return {
        ...state,
        isInternshipUpdated: false,
      };

    case C.UPDATE_INTERNSHIP_SUCCESS:
      return {
        ...state,
        isInternshipUpdated: true,
      };

    case C.DELETE_INTERNSHIP_REQUEST:
      return {
        ...state,
        isInternshipDeleted: null,
      };

    case C.DELETE_INTERNSHIP_FAIL:
      return {
        ...state,
        isInternshipDeleted: false,
      };

    case C.DELETE_INTERNSHIP_SUCCESS:
      return {
        ...state,
        isInternshipDeleted: true,
      };

    case C.CLEAR_INTERNSHIP_FLAG:
      return {
        ...state,
        isInternshipUpdated: null,
        isInternshipDeleted: null,
      };

    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default internshipReducer;
