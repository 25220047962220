export const ROO_SUPER_ADMIN = 1;
export const ROO_FINANCE_ADMIN = 2;
export const ROO_ADMIN = 5;
export const ENTERPRISE_ADMIN = 10;
export const GROUP_ADMIN = 20;
export const HOSPITAL_ADMIN = 30;
export const HOSPITAL_USER = 40;

export const oRoleLabels = {
  20: "Group Admin", // groupAdmin
};

export const hospitalAccountTypes = [
  { id: 1, label: "Roo Super Admin" },
  { id: 5, label: "Roo Admin" },
  { id: 10, label: "Enterprise Admin" },
  { id: 20, label: "Group Admin" },
  { id: 30, label: "Hospital Admin" },
  { id: 40, label: "Hospital User" },
];

export const manageUsersByRolesMapping = {
  [HOSPITAL_USER]: [HOSPITAL_ADMIN, HOSPITAL_USER],
  [HOSPITAL_ADMIN]: [HOSPITAL_ADMIN, HOSPITAL_USER],
  [GROUP_ADMIN]: [GROUP_ADMIN, HOSPITAL_ADMIN, HOSPITAL_USER],
  [ENTERPRISE_ADMIN]: [GROUP_ADMIN, HOSPITAL_ADMIN, HOSPITAL_USER],
  [ROO_ADMIN]: [GROUP_ADMIN, HOSPITAL_ADMIN, HOSPITAL_USER],
  [ROO_SUPER_ADMIN]: [GROUP_ADMIN, HOSPITAL_ADMIN, HOSPITAL_USER],
};

export type ORoleLabels = keyof typeof oRoleLabels;
export type ManageUsersByRolesMapping = keyof typeof manageUsersByRolesMapping;
