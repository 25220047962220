export const INITIALIZE_TECH_DASHBOARD = "INITIALIZE_TECH_DASHBOARD";
export const CLOSE_ERROR_MODAL = "CLOSE_ERROR_MODAL";

export const SAVE_SHARED_SEARCH = "SAVE_SHARED_SEARCH";
export const SEARCH_TECH_SHIFT_REQUEST = "SEARCH_TECH_SHIFT_REQUEST";
export const SEARCH_TECH_SHIFT_SUCCESS = "SEARCH_TECH_SHIFT_SUCCESS";
export const SEARCH_TECH_SHIFT_FAIL = "SEARCH_TECH_SHIFT_FAIL";

export const GET_LINKED_TECH_SHIFT_REQUEST = "GET_LINKED_TECH_SHIFT_REQUEST";
export const GET_LINKED_TECH_SHIFT_SUCCESS = "GET_LINKED_TECH_SHIFT_SUCCESS";
export const GET_LINKED_TECH_SHIFT_FAIL = "GET_LINKED_TECH_SHIFT_FAIL";

export const TECH_REQUEST_TECH_SHIFT_REQUEST = "TECH_REQUEST_TECH_SHIFT_REQUEST";
export const TECH_REQUEST_TECH_SHIFT_SUCCESS = "TECH_REQUEST_TECH_SHIFT_SUCCESS";
export const TECH_REQUEST_TECH_SHIFT_NOT_AVAILABLE = "TECH_REQUEST_TECH_SHIFT_NOT_AVAILABLE";
export const TECH_REQUEST_TECH_SHIFT_FAIL = "TECH_REQUEST_TECH_SHIFT_FAIL";

export const SET_TECH_DASHBOARD_CALENDAR_VIEW = "SET_TECH_DASHBOARD_CALENDAR_VIEW";
export const SET_TECH_DASHBOARD_GRID_VIEW = "SET_TECH_DASHBOARD_GRID_VIEW";
export const SET_TECH_DASHBOARD_MAP_VIEW = "SET_TECH_DASHBOARD_MAP_VIEW";

export const TECH_FETCH_HOSPITAL_VIEW_PROFILE_REQUEST = "TECH_FETCH_HOSPITAL_VIEW_PROFILE_REQUEST";
export const TECH_FETCH_HOSPITAL_VIEW_PROFILE_SUCCESS = "TECH_FETCH_HOSPITAL_VIEW_PROFILE_SUCCESS";
export const TECH_FETCH_HOSPITAL_VIEW_PROFILE_FAIL = "TECH_FETCH_HOSPITAL_VIEW_PROFILE_FAIL";

export const FETCH_HOSPITAL_AVAILABLE_TECH_SHIFT_REQUEST =
  "FETCH_HOSPITAL_AVAILABLE_TECH_SHIFT_REQUEST";
export const FETCH_HOSPITAL_AVAILABLE_TECH_SHIFT_SUCCESS =
  "FETCH_HOSPITAL_AVAILABLE_TECH_SHIFT_SUCCESS";
export const FETCH_HOSPITAL_AVAILABLE_TECH_SHIFT_FAIL = "FETCH_HOSPITAL_AVAILABLE_TECH_SHIFT_FAIL";

export const REQUEST_AVAILABLE_TECH_SHIFT_REQUEST = "REQUEST_AVAILABLE_TECH_SHIFT_REQUEST";
export const REQUEST_AVAILABLE_TECH_SHIFT_SUCCESS = "REQUEST_AVAILABLE_TECH_SHIFT_SUCCESS";
export const REQUEST_AVAILABLE_TECH_SHIFT_FAIL = "REQUEST_AVAILABLE_TECH_SHIFT_FAIL";
export const CLOSE_SHIFT_NOT_AVAILABLE_MODAL = "CLOSE_SHIFT_NOT_AVAILABLE_MODAL";

export const SHOW_FIRST_TIMER_MODAL = "SHOW_FIRST_TIMER_MODAL";
export const SHOW_FIRST_TIMER_MODAL_NO_SKIP = "SHOW_FIRST_TIMER_MODAL_NO_SKIP";
export const HIDE_FIRST_TIMER_MODAL = "HIDE_FIRST_TIMER_MODAL";

export const FETCH_TECH_FIRST_TIME_INFO_REQUEST = "FETCH_TECH_FIRST_TIME_INFO_REQUEST";
export const FETCH_FIRST_TIME_INFO_SUCCESS = "FETCH_FIRST_TIME_INFO_SUCCESS";
export const FETCH_FIRST_TIME_INFO_FAIL = "FETCH_FIRST_TIME_INFO_FAIL";

// export const UPDATE_TECH_BANKING_INFO_REQUEST = "UPDATE_TECH_BANKING_INFO_REQUEST";
// export const UPDATE_TECH_BANKING_INFO_SUCCESS = "UPDATE_TECH_BANKING_INFO_SUCCESS";
// export const UPDATE_TECH_BANKING_INFO_FAIL = "UPDATE_TECH_BANKING_INFO_FAIL";

export const TECH_SHIFT_HISTORY_REQUEST = "TECH_SHIFT_HISTORY_REQUEST";
export const TECH_SHIFT_HISTORY_SUCCESS = "TECH_SHIFT_HISTORY_SUCCESS";
export const TECH_SHIFT_HISTORY_FAIL = "TECH_SHIFT_HISTORY_FAIL";

export const SUBMIT_RATE_HOSPITAL_AS_TECH = "SUBMIT_RATE_HOSPITAL_AS_TECH";
export const SUBMIT_RATE_HOSPITAL_AS_TECH_SUCCESS = "SUBMIT_RATE_HOSPITAL_AS_TECH_SUCCESS";
export const SUBMIT_RATE_HOSPITAL_AS_TECH_FAIL = "SUBMIT_RATE_HOSPITAL_AS_TECH_FAIL";

export const SIGN_NOW = "SIGN_NOW";

export const GET_PENDING_TECH_RATINGS_REQUEST = "GET_PENDING_TECH_RATINGS_REQUEST";
export const GET_PENDING_TECH_RATINGS_SUCCESS = "GET_PENDING_TECH_RATINGS_SUCCESS";
export const GET_PENDING_TECH_RATINGS_FAIL = "GET_PENDING_TECH_RATINGS_FAIL";

export const DEFER_HOSPITAL_RATING_BY_TECH = "DEFER_HOSPITAL_RATING_BY_TECH";
export const DEFER_HOSPITAL_RATING_BY_TECH_SUCCESS = "DEFER_HOSPITAL_RATING_BY_TECH_SUCCESS";
export const DEFER_HOSPITAL_RATING_BY_TECH_FAIL = "DEFER_HOSPITAL_RATING_BY_TECH_FAIL";

export const DEFER_ALL_HOSPITAL_RATINGS_BY_TECH = "DEFER_ALL_HOSPITAL_RATINGS_BY_TECH";
export const DEFER_ALL_HOSPITAL_RATINGS_BY_TECH_SUCCESS =
  "DEFER_ALL_HOSPITAL_RATINGS_BY_TECH_SUCCESS";
export const DEFER_ALL_HOSPITAL_RATINGS_BY_TECH_FAIL = "DEFER_ALL_HOSPITAL_RATINGS_BY_TECH_FAIL";

export const UPDATE_CURRENT_HOSPITAL_RATING_BY_TECH_INDEX =
  "UPDATE_CURRENT_HOSPITAL_RATING_BY_TECH_INDEX";

export const RESET_RATING_SUBMITTED = "RESET_RATING_SUBMITTED";

export const SET_UNRATED_SHIFT_DATE = "SET_UNRATED_SHIFT_DATE";

// export const SEARCH_TECH_HOSPITAL_REQUEST = "SEARCH_TECH_HOSPITAL_REQUEST";
// export const SEARCH_TECH_HOSPITAL_SUCCESS = "SEARCH_TECH_HOSPITAL_SUCCESS";
// export const SEARCH_TECH_HOSPITAL_FAIL = "SEARCH_TECH_HOSPITAL_FAIL";

// export const SIGN_NOW_LINK_REQUEST = "SIGN_NOW_LINK_REQUEST";
// export const SIGN_NOW_LINK_SUCCESS = "SIGN_NOW_LINK_SUCCESS";
// export const SIGN_NOW_LINK_FAIL = "SIGN_NOW_LINK_FAIL";

export const SUBMIT_TECH_W9_REQUEST = "SUBMIT_TECH_W9_REQUEST";
export const SUBMIT_TECH_W9_SUCCESS = "SUBMIT_TECH_W9_SUCCESS";
export const SUBMIT_TECH_W9_FAIL = "SUBMIT_TECH_W9_FAIL";
export const HIDE_TECH_W9_ERROR = "HIDE_TECH_W9_ERROR";

export const SAVE_SEARCH_TECH_SHIFT_PARAMETERS = "SAVE_SEARCH_TECH_SHIFT_PARAMETERS";

export const GET_TECH_SETTING_INFORMATION = "GET_TECH_SETTING_INFORMATION";
export const GET_TECH_SETTING_INFORMATION_SUCCESS = "GET_TECH_SETTING_INFORMATION_SUCCESS";
export const GET_TECH_SETTING_INFORMATION_FAIL = "GET_TECH_SETTING_INFORMATION_FAIL";

export const SET_LOCAL_TECH_MICRO_DEPOSITS_STATUS = "SET_LOCAL_TECH_MICRO_DEPOSITS_STATUS";

export const UPDATE_TECH_BASIC_INFO = "UPDATE_TECH_BASIC_INFO";
export const UPDATE_TECH_BASIC_INFO_SUCCESS = "UPDATE_TECH_BASIC_INFO_SUCCESS";
export const UDPATE_TECH_BASIC_INFO_FAIL = "UDPATE_TECH_BASIC_INFO_FAIL";

export const GET_TECH_W9_INFO = "GET_TECH_W9_INFO";
export const GET_TECH_W9_INFO_SUCCESS = "GET_TECH_W9_INFO_SUCCESS";
export const GET_TECH_W9_INFO_FAIL = "GET_TECH_W9_INFO_FAIL";

export const UPDATE_TECH_W9 = "UPDATE_TECH_W9";
export const UPDATE_TECH_W9_SUCCESS = "UPDATE_TECH_W9_SUCCESS";
export const UPDATE_TECH_W9_FAIL = "UPDATE_TECH_W9_FAIL";

export const INITIALIZE_TECH_SETTINGS = "INITIALIZE_TECH_SETTINGS";
export const RESET_TECH_BASIC_INFO_UPDATE_SUCCESS = "RESET_TECH_BASIC_INFO_UPDATE_SUCCESS";
export const RESET_TECH_W9_UPDATE_SUCCESS = "RESET_TECH_W9_UPDATE_SUCCESS";

export const FETCH_TECH_PROFILE_REQUEST = "FETCH_TECH_PROFILE_REQUEST";
export const FETCH_TECH_PROFILE_SUCCESS = "FETCH_TECH_PROFILE_SUCCESS";
export const FETCH_TECH_PROFILE_FAIL = "FETCH_TECH_PROFILE_FAIL";

export const UPDATE_TECH_PROFILE_IMAGE_SUCCESS = "UPDATE_TECH_PROFILE_IMAGE_SUCCESS";
export const UPDATE_TECH_PROFILE_IMAGE_FAIL = "UPDATE_TECH_PROFILE_IMAGE_FAIL";

export const DELETE_TECH_PROFILE_IMAGE_SUCCESS = "DELETE_TECH_PROFILE_IMAGE_SUCCESS";
export const DELETE_TECH_PROFILE_IMAGE_FAIL = "DELETE_TECH_PROFILE_IMAGE_FAIL";

export const UPDATE_TECH_DOCUMENT_FAIL = "UPDATE_TECH_DOCUMENT_FAIL";

export const UPDATE_TECH_PROFILE_FAIL = "UPDATE_TECH_PROFILE_FAIL";

export const GET_ALL_MY_TECH_SHIFTS_REQUEST = "GET_ALL_MY_TECH_SHIFTS_REQUEST";
export const GET_ALL_MY_TECH_SHIFTS_SUCCESS = "GET_ALL_MY_TECH_SHIFTS_SUCCESS";
export const GET_ALL_MY_TECH_SHIFTS_FAIL = "GET_ALL_MY_TECH_SHIFTS_FAIL";

export const INITIALIZE_SEARCH_RESULTS = "INITIALIZE_SEARCH_RESULTS";

export const FETCH_TECH_IAV_TOKEN = "FETCH_TECH_IAV_TOKEN";
export const FETCH_TECH_IAV_TOKEN_SUCCESS = "FETCH_TECH_IAV_TOKEN_SUCCESS";
export const FETCH_TECH_IAV_TOKEN_FAIL = "FETCH_TECH_IAV_TOKEN_FAIL";

export const SET_TECH_DEFAULT_BANK = "SET_TECH_DEFAULT_BANK";
export const SET_TECH_DEFAULT_BANK_SUCCESS = "SET_TECH_DEFAULT_BANK_SUCCESS";
export const SET_TECH_DEFAULT_BANK_FAIL = "SET_TECH_DEFAULT_BANK_FAIL";
export const RESET_ADD_BANK = "RESET_ADD_BANK";

export const SWITCH_TECH_DEFAULT_BANK = "SWITCH_TECH_DEFAULT_BANK";
export const SWITCH_TECH_DEFAULT_BANK_SUCCESS = "SWITCH_TECH_DEFAULT_BANK_SUCCESS";
export const SWITCH_TECH_DEFAULT_BANK_FAIL = "SWITCH_TECH_DEFAULT_BANK_FAIL";

export const INITIALIZE_TECH_FIRST_TIMER = "INITIALIZE_TECH_FIRST_TIMER";

export const DELETE_TECH_BANK_ACCOUNT = "DELETE_TECH_BANK_ACCOUNT";
export const DELETE_TECH_BANK_ACCOUNT_SUCCESS = "DELETE_TECH_BANK_ACCOUNT_SUCCESS";
export const DELETE_TECH_BANK_ACCOUNT_FAIL = "DELETE_TECH_BANK_ACCOUNT_FAIL";

export const UPDATE_TECH_MICRO_DEPOSITS_VERIFIED = "UPDATE_TECH_MICRO_DEPOSITS_VERIFIED";
export const UPDATE_TECH_MICRO_DEPOSITS_VERIFIED_SUCCESS =
  "UPDATE_TECH_MICRO_DEPOSITS_VERIFIED_SUCCESS";
export const UPDATE_TECH_MICRO_DEPOSITS_VERIFIED_FAIL = "UPDATE_TECH_MICRO_DEPOSITS_VERIFIED_FAIL";

export const UPDATE_TECH_MICRO_DEPOSITS_FAILED = "UPDATE_TECH_MICRO_DEPOSITS_FAILED";
export const UPDATE_TECH_MICRO_DEPOSITS_FAILED_SUCCESS =
  "UPDATE_TECH_MICRO_DEPOSITS_FAILED_SUCCESS";
export const UPDATE_TECH_MICRO_DEPOSITS_FAILED_FAIL = "UPDATE_TECH_MICRO_DEPOSITS_FAILED_FAIL";

export const CLEAR_AVAILBLE_SHIFT = "CLEAR_AVAILBLE_SHIFT";

export const CLEAR_SHIFT_HISTORY = "CLEAR_SHIFT_HISTORY";

export const SUBMIT_TECH_BACKGROUND_REQUEST = "SUBMIT_TECH_BACKGROUND_REQUEST";
export const SUBMIT_TECH_BACKGROUND_SUCCESS = "SUBMIT_TECH_BACKGROUND_SUCCESS";
export const SUBMIT_TECH_BACKGROUND_FAIL = "SUBMIT_TECH_BACKGROUND_FAIL";
export const HIDE_TECH_BACKGROUND_ERROR = "HIDE_TECH_BACKGROUND_ERROR";

export const SHOW_REFERRAL_MODAL = "SHOW_REFERRAL_MODAL";
export const HIDE_REFERRAL_MODAL = "HIDE_REFERRAL_MODAL";

export const INITIALIZE_SEARCH_CLICK = "INITIALIZE_SEARCH_CLICK";

export const FETCH_TECH_SHIFT_DETAILS_REQUEST = "FETCH_TECH_SHIFT_DETAILS_REQUEST";
export const FETCH_TECH_SHIFT_DETAILS_SUCCESS = "FETCH_TECH_SHIFT_DETAILS_SUCCESS";
export const FETCH_TECH_SHIFT_DETAILS_FAIL = "FETCH_TECH_SHIFT_DETAILS_FAIL";

export const TECH_TRIGGERED_REMOVE_CONFIRMED_TECH_REQUEST =
  "TECH_TRIGGERED_REMOVE_CONFIRMED_TECH_REQUEST";
export const TECH_TRIGGERED_REMOVE_CONFIRMED_TECH_SUCCESS =
  "TECH_TRIGGERED_REMOVE_CONFIRMED_TECH_SUCCESS";
export const TECH_TRIGGERED_REMOVE_CONFIRMED_TECH_FAIL =
  "TECH_TRIGGERED_REMOVE_CONFIRMED_TECH_FAIL";
export const RESET_TECH_TRIGGERED_REMOVE_CONFIRMED_TECH_ACTION =
  "RESET_TECH_TRIGGERED_REMOVE_CONFIRMED_TECH_ACTION";
export const RELOAD_TECH_SHIFTS = "RELOAD_TECH_SHIFTS";

export const FETCH_CALCULATE_TECH_SHIFT_PRICING = "FETCH_CALCULATE_TECH_SHIFT_PRICING";
export const FETCH_CALCULATE_TECH_SHIFT_PRICING_SUCCESS =
  "FETCH_CALCULATE_TECH_SHIFT_PRICING_SUCCESS";
export const FETCH_CALCULATE_TECH_SHIFT_PRICING_FAIL = "FETCH_CALCULATE_TECH_SHIFT_PRICING_FAIL";

export const GET_BLOCKED_HOSPITAL_LIST = "GET_BLOCKED_HOSPITAL_LIST_FOR_TECH";
export const GET_BLOCKED_HOSPITAL_LIST_FAIL = "GET_BLOCKED_HOSPITAL_LIST_FOR_TECH_FAIL";
export const GET_BLOCKED_HOSPITAL_LIST_SUCCESS = "GET_BLOCKED_HOSPITAL_LIST_FOR_TECH_SUCCESS";
export const HIDE_BLOCK_HOSPITAL_CONFIRMATION_MODAL = "HIDE_BLOCK_HOSPITAL_CONFIRMATION_MODAL";

export const REMOVE_BLOCKED_HOSPITAL = "REMOVE_BLOCKED_HOSPITAL_FOR_TECH";
export const REMOVE_BLOCKED_HOSPITAL_FAIL = "REMOVE_BLOCKED_HOSPITAL_FOR_TECH_FAIL";
export const REMOVE_BLOCKED_HOSPITAL_SUCCESS = "REMOVE_BLOCKED_HOSPITAL_FOR_TECH_SUCCESS";

export const BLOCK_HOSPITAL = "BLOCK_HOSPITAL_FOR_TECH";
export const BLOCK_HOSPITAL_SUCCESS = "BLOCK_HOSPITAL_FOR_TECH_SUCCESS";
export const BLOCK_HOSPITAL_FAIL = "BLOCK_HOSPITAL_FOR_TECH_FAIL";

export const GET_TECH_PAYMENT_INFO = "GET_TECH_PAYMENT_INFO";
export const GET_TECH_PAYMENT_INFO_SUCCESS = "GET_TECH_PAYMENT_INFO_SUCCESS";
export const GET_TECH_PAYMENT_INFO_FAIL = "GET_TECH_PAYMENT_INFO_FAIL";

export const UPDATE_BLOCKED_HOSPITAL_COUNT = "UPDATE_BLOCKED_HOSPITAL_COUNT_FOR_TECH";

export const GET_EVENT_FOR_TECH = "GET_EVENT_FOR_TECH";
export const GET_EVENT_FOR_TECH_FAIL = "GET_EVENT_FOR_TECH_FAIL";
export const GET_EVENT_FOR_TECH_SUCCESS = "GET_EVENT_FOR_TECH_SUCCESS";

export const UPDATE_TECH_DETAILS_PAYMENT_INFO = "UPDATE_TECH_DETAILS_PAYMENT_INFO";
export const UPDATE_TECH_DETAILS_PAYMENT_INFO_SUCCESS = "UPDATE_TECH_DETAILS_PAYMENT_INFO_SUCCESS";
export const UPDATE_TECH_DETAILS_PAYMENT_INFO_FAIL = "UPDATE_TECH_DETAILS_PAYMENT_INFO_FAIL";
export const RESET_TECH_DETAILS_PAYMENT_INFO = "RESET_TECH_DETAILS_PAYMENT_INFO";

export const FETCH_COMMON_DATA_FAIL = "FETCH_COMMON_DATA_FAIL";

export const CREATE_TECH_PROMO_TRACKING = "CREATE_TECH_PROMO_TRACKING";
export const CREATE_TECH_PROMO_TRACKING_SUCCESS = "CREATE_TECH_PROMO_TRACKING_SUCCESS";
export const CREATE_TECH_PROMO_TRACKING_FAIL = "CREATE_TECH_PROMO_TRACKING_FAIL";
export const CLEAR_TECH_PROMO_TRACKING_DATA = "CLEAR_TECH_PROMO_TRACKING_DATA";

export const GET_TECH_PROMO_TRACKING_BY_USER_ID = "GET_TECH_PROMO_TRACKING_BY_USER_ID";
export const GET_TECH_PROMO_TRACKING_BY_USER_ID_SUCCESS =
  "GET_TECH_PROMO_TRACKING_BY_USER_ID_SUCCESS";
export const GET_TECH_PROMO_TRACKING_BY_USER_ID_FAIL = "GET_TECH_PROMO_TRACKING_BY_USER_ID_FAIL";

export const UPDATE_TECH_PROMO_MESSAGE_SEEN = "UPDATE_TECH_PROMO_MESSAGE_SEEN";
export const UPDATE_TECH_PROMO_MESSAGE_SEEN_SUCCESS = "UPDATE_TECH_PROMO_MESSAGE_SEEN_SUCCESS";
export const UPDATE_TECH_PROMO_MESSAGE_SEEN_FAIL = "UPDATE_TECH_PROMO_MESSAGE_SEEN_FAIL";

export const GET_TECH_COMPLETED_PROMOS = "GET_TECH_COMPLETED_PROMOS";
export const GET_TECH_COMPLETED_PROMOS_SUCCESS = "GET_TECH_COMPLETED_PROMOS_SUCCESS";
export const GET_TECH_COMPLETED_PROMOS_FAIL = "GET_TECH_COMPLETED_PROMOS_FAIL";

export const UPDATE_TECH_PREFERRED_AREAS_OF_PRACTICE = "UPDATE_TECH_PREFERRED_AREAS_OF_PRACTICE";
export const UPDATE_TECH_PREFERRED_AREAS_OF_PRACTICE_SUCCESS =
  "UPDATE_TECH_PREFERRED_AREAS_OF_PRACTICE_SUCCESS";
export const UPDATE_TECH_PREFERRED_AREAS_OF_PRACTICE_FAIL =
  "UPDATE_TECH_PREFERRED_AREAS_OF_PRACTICE_FAIL";

export const UPDATE_TECH_PREFERRED_AREAS_SLIDERS = "UPDATE_TECH_PREFERRED_AREAS_SLIDERS";
export const UPDATE_TECH_PREFERRED_AREAS_SLIDERS_SUCCESS =
  "UPDATE_TECH_PREFERRED_AREAS_SLIDERS_SUCCESS";
export const UPDATE_TECH_PREFERRED_AREAS_SLIDERS_FAIL = "UPDATE_TECH_PREFERRED_AREAS_SLIDERS_FAIL";

export const UPDATE_TECH_CHARACTER_TYPE = "UPDATE_TECH_CHARACTER_TYPE";
export const UPDATE_TECH_CHARACTER_TYPE_SUCCESS = "UPDATE_TECH_CHARACTER_TYPE_SUCCESS";
export const UPDATE_TECH_CHARACTER_TYPE_FAIL = "UPDATE_TECH_CHARACTER_TYPE_FAIL";

export const UPDATE_TECH_ADDITIONAL_SKILLS = "UPDATE_TECH_ADDITIONAL_SKILLS";
export const UPDATE_TECH_ADDITIONAL_SKILLS_SUCCESS = "UPDATE_TECH_ADDITIONAL_SKILLS_SUCCESS";
export const UPDATE_TECH_ADDITIONAL_SKILLS_FAIL = "UPDATE_TECH_ADDITIONAL_SKILLS_FAIL";

export const UPDATE_TECH_SHOW_ONBOARDING = "UPDATE_TECH_SHOW_ONBOARDING";
export const UPDATE_TECH_SHOW_ONBOARDING_SUCCESS = "UPDATE_TECH_SHOW_ONBOARDING_SUCCESS";
export const UPDATE_TECH_SHOW_ONBOARDING_FAIL = "UPDATE_TECH_SHOW_ONBOARDING_FAIL";

export const CLOSE_TECH_REFERRAL_MODAL = "CLOSE_TECH_REFERRAL_MODAL";

export const GET_TECH_ONBOARDING_INFO = "GET_TECH_ONBOARDING_INFO";
export const GET_TECH_ONBOARDING_INFO_SUCCESS = "GET_TECH_ONBOARDING_INFO_SUCCESS";
export const GET_TECH_ONBOARDING_INFO_FAIL = "GET_TECH_ONBOARDING_INFO_FAIL";

export const TECH_SKIP_W9_MODAL = "TECH_SKIP_W9_MODAL";
export const TECH_SKIP_W9_MODAL_SUCCESS = "TECH_SKIP_W9_MODAL_SUCCESS";
export const TECH_SKIP_W9_MODAL_FAIL = "TECH_SKIP_W9_MODAL_FAIL";

export const GET_TECH_CONFIRMED_SHIFT_DATA = "GET_TECH_CONFIRMED_SHIFT_DATA";
export const GET_TECH_CONFIRMED_SHIFT_DATA_SUCCESS = "GET_TECH_CONFIRMED_SHIFT_DATA_SUCCESS";
export const GET_TECH_CONFIRMED_SHIFT_DATA_FAIL = "GET_TECH_CONFIRMED_SHIFT_DATA_FAIL";

export const SAVE_SHIFT_COUNT = "SAVE_SHIFT_COUNT";
export const SAVE_SHIFT_COUNT_SUCCESS = "SAVE_SHIFT_COUNT_SUCCESS";
export const SAVE_SHIFT_COUNT_FAIL = "SAVE_SHIFT_COUNT_FAIL";

export const SET_DASHBOARD_VIEW = "SET_DASHBOARD_VIEW";
export const SET_DASHBOARD_VIEW_FAIL = "SET_DASHBOARD_VIEW_FAIL";
export const SET_DASHBOARD_VIEW_SUCCESS = "SET_DASHBOARD_VIEW_SUCCESS";

export const CLEAR_DASHBOARD_VIEW = "CLEAR_DASHBOARD_VIEW";

export const GET_NON_REQUEST_SURVEY_ELIGIBILITY_TECH = "GET_NON_REQUEST_SURVEY_ELIGIBILITY_TECH";
export const GET_NON_REQUEST_SURVEY_ELIGIBILITY_SUCCESS_TECH =
  "GET_NON_REQUEST_SURVEY_ELIGIBILITY_SUCCESS_TECH";
export const GET_NON_REQUEST_SURVEY_ELIGIBILITY_FAIL_TECH =
  "GET_NON_REQUEST_SURVEY_ELIGIBILITY_FAIL_TECH";

export const TECH_SUBMIT_SURVEY = "TECH_SUBMIT_SURVEY";
export const TECH_SUBMIT_SURVEY_SUCCESS = "TECH_SUBMIT_SURVEY_SUCCESS";
export const TECH_SUBMIT_SURVEY_FAIL = "TECH_SUBMIT_SURVEY_FAIL";

export const SET_IS_INITIAL_MAP_SORT_COMPLETE = "SET_IS_INITIAL_MAP_SORT_COMPLETE";
export const SET_IS_INITIAL_MAP_SORT_COMPLETE_SUCCESS = "SET_IS_INITIAL_MAP_SORT_COMPLETE_SUCCESS";
export const SET_IS_INITIAL_MAP_SORT_COMPLETE_FAIL = "SET_IS_INITIAL_MAP_SORT_COMPLETE_FAIL";

export const GET_TECH_PROMO_SHIFTS = "GET_TECH_PROMO_SHIFTS";
export const GET_TECH_PROMO_SHIFTS_SUCCESS = "GET_TECH_PROMO_SHIFTS_SUCCESS";
export const GET_TECH_PROMO_SHIFTS_FAIL = "GET_TECH_PROMO_SHIFTS_FAIL";

export const TOGGLE_TECH_PROMO_SHIFTS_PANEL = "TOGGLE_TECH_PROMO_SHIFTS_PANEL";
export const TOGGLE_TECH_PROMO_SHIFTS_PANEL_OPEN = "TOGGLE_TECH_PROMO_SHIFTS_PANEL_OPEN";
export const TOGGLE_TECH_PROMO_SHIFTS_PANEL_CLOSED = "TOGGLE_TECH_PROMO_SHIFTS_PANEL_CLOSED";

export const DELETE_TECH_SHIFT_REQUEST = "DELETE_TECH_SHIFT_REQUEST";
export const DELETE_TECH_SHIFT_REQUEST_SUCCESS = "DELETE_TECH_SHIFT_REQUEST_SUCCESS";
export const DELETE_TECH_SHIFT_REQUEST_FAIL = "DELETE_TECH_SHIFT_REQUEST_FAIL";
export const RESET_TECH_SHIFT_REQUEST_CANCELLED_SUCCESS =
  "RESET_TECH_SHIFT_REQUEST_CANCELLED_SUCCESS";

export const CANCEL_CONFIRMED_TECH_SHIFT = "CANCEL_CONFIRMED_TECH_SHIFT";
export const CANCEL_CONFIRMED_TECH_SHIFT_SUCCESS = "CANCEL_CONFIRMED_TECH_SHIFT_SUCCESS";
export const CANCEL_CONFIRMED_TECH_SHIFT_FAIL = "CANCEL_CONFIRMED_TECH_SHIFT_FAIL";

export const CONFIRMED_TECH_SHIFT_CANCELLATION_REQUEST_SUCCESS =
  "CONFIRMED_TECH_SHIFT_CANCELLATION_REQUEST_SUCCESS";

export const RESET_CONFIRMED_TECH_SHIFT_CANCELLATION_REQUEST_SUCCESS =
  "RESET_CONFIRMED_TECH_SHIFT_CANCELLATION_REQUEST_SUCCESS";

export const TOGGLE_TECH_DELETE_SHIFT_REQUEST_PANEL = "TOGGLE_TECH_DELETE_SHIFT_REQUEST_PANEL";
export const TOGGLE_TECH_DELETE_SHIFT_REQUEST_PANEL_OPEN =
  "TOGGLE_TECH_DELETE_SHIFT_REQUEST_PANEL_OPEN";
export const TOGGLE_TECH_DELETE_SHIFT_REQUEST_PANEL_CLOSED =
  "TOGGLE_TECH_DELETE_SHIFT_REQUEST_PANEL_CLOSED";

export const TOGGLE_TECH_CANCEL_CONFIRMED_SHIFT_PANEL = "TOGGLE_TECH_CANCEL_CONFIRMED_SHIFT_PANEL";
export const RESET_CANCELLED_TECH_SHIFT_SUCCESS_FLAG = "RESET_CANCELLED_TECH_SHIFT_SUCCESS_FLAG";
