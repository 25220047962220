import { sortExternshipsForHospital } from "../../../Common/externshipDataHelper";
import {
  getSpecialityInterestLabels,
  sortInternshipsForHospital,
} from "../../../Common/internshipDataHelper";
import { EXCEPTION_MESSAGE } from "../../../constants/errorMessage";
import * as E from "../../../constants/externship";
import * as C from "../../../constants/internship";
import { RooUniversityHospitalAction } from "../actions/rooUniversityHospitalActions";

export type ExternshipRequestDetails = {
  gpa: number;
  source: string;
  requestId: number;
  studentId: number;
  confirmedOn?: string;
  contactedOn?: string;
  description: string;
  studentName: string;
  studentSchool: string;
  trackInSchool: string;
  graduationYear: number;
  applicationDate: string;
  confirmedEndDate?: string;
  requestedEndDate: string;
  confirmedStartDate?: string;
  requestedStartDate: string;
  studentProfileImage?: null;
  reason?: string;
  licenseState?: number;
  resumePath?: string;
  prefLearningMethod?: string;
  prefMentorshipType?: string;
  subspecialityInterest?: string;
  characterTypeId?: number;
};
export type Externship = {
  confirmedEndDate?: string;
  confirmedStartDate?: string;
  createdOn?: string;
  specialInterestIds: string;
  description: string;
  expectedProgramLength: number;
  stipend: number;
  stipendSchedule: string;
  travelStipend: string;
  expectedNoOfDoctors: number;
  externshipRequestDetails: ExternshipRequestDetails[];
  hospitalId: number;
  hospitalName: string;
  id: number;
  isExternshipClosed: number;
  title?: string;
};
type RooUniversityHospitalState = {
  isInternshipAdded: boolean;
  isExternshipAdded: boolean;
  isExternshipUpdated: boolean | null;
  isExternshipDeleted: boolean | null;
  isStudentConfirmed: boolean | null;
  isStudentDeclined: boolean | null;
  isStudentContacted: boolean | null;
  studentDetails: any;
  activeInternships: any;
  previousInternships: any;
  activeExternships: Externship[];
  internshipDetails: any;
  externshipDetails: any;
  commonData: {
    specialInterestTypes: any[];
  };
  errMsg: string;
};

const initialState: RooUniversityHospitalState = {
  isInternshipAdded: false,
  isExternshipAdded: false,
  isExternshipUpdated: null,
  isExternshipDeleted: null,
  commonData: {
    specialInterestTypes: [],
  },
  studentDetails: {},
  activeInternships: [],
  previousInternships: [],
  activeExternships: null,
  isStudentContacted: null,
  isStudentConfirmed: null,
  isStudentDeclined: null,
  internshipDetails: {},
  externshipDetails: {},
  errMsg: EXCEPTION_MESSAGE,
};

// eslint-disable-next-line default-param-last
const hospitalInternshipsReducer = (state = initialState, action: RooUniversityHospitalAction) => {
  switch (action.type) {
    case C.ADD_INTERNSHIP:
    case C.ADD_INTERNSHIP_FAIL:
      return {
        ...state,
        isInternshipAdded: false,
      };

    case C.ADD_INTERNSHIP_SUCCESS:
      return {
        ...state,
        isInternshipAdded: true,
      };

    case C.GET_MY_INTERNSHIPS:
      return {
        ...state,
        activeInternships: [],
        previousInternships: [],
      };

    case C.GET_MY_INTERNSHIPS_SUCCESS:
      const data = sortInternshipsForHospital(action.payload);
      return {
        ...state,
        activeInternships: data.sortedInternships,
        previousInternships: data.previousInternships,
      };

    case C.GET_INTERNSHIP_DETAILS:
    case C.GET_INTERNSHIP_DETAILS_FAIL:
      return {
        ...state,
        internshipDetails: {},
      };

    case C.GET_INTERNSHIP_DETAILS_SUCCESS:
      const specialInterestsInternship = getSpecialityInterestLabels(
        action.payload.specialInterestIds
      );
      return {
        ...state,
        internshipDetails: { ...action.payload, specialInterests: specialInterestsInternship },
      };

    case E.ADD_EXTERNSHIP:
    case E.ADD_EXTERNSHIP_FAIL:
      return {
        ...state,
        isExternshipAdded: false,
      };

    case E.ADD_EXTERNSHIP_SUCCESS:
      return {
        ...state,
        isExternshipAdded: true,
      };

    case E.GET_MY_EXTERNSHIPS:
      return {
        ...state,
      };

    case E.GET_MY_EXTERNSHIPS_SUCCESS:
      return {
        ...state,
        activeExternships: sortExternshipsForHospital(action.payload),
        previousExternships: [] as Externship[],
      };

    case E.GET_EXTERNSHIP_DETAILS:
    case E.GET_EXTERNSHIP_DETAILS_FAIL:
      return {
        ...state,
        externshipDetails: {},
      };

    case E.GET_EXTERNSHIP_DETAILS_SUCCESS:
      const specialInterests = getSpecialityInterestLabels(action.payload.specialInterestIds);
      return {
        ...state,
        externshipDetails: { ...action.payload, specialInterests },
      };

    case C.CONTACT_STUDENt:
      return {
        ...state,
        isStudentContacted: null,
      };

    case C.CONTACT_STUDENt_SUCCESS:
      return {
        ...state,
        isStudentContacted: true,
      };

    case C.CONTACT_STUDENt_FAIL:
      return {
        ...state,
        isStudentContacted: false,
        errMsg: action.payload.message,
      };

    case C.HIRE_STUDENT:
      return {
        ...state,
        isStudentConfirmed: null,
      };

    case C.HIRE_STUDENT_SUCCESS:
      return {
        ...state,
        isStudentConfirmed: true,
      };

    case C.HIRE_STUDENT_FAIL:
      return {
        ...state,
        isStudentConfirmed: false,
        errMsg: action.payload.message,
      };

    case C.DECLINE_STUDENT:
      return {
        ...state,
        isStudentDeclined: null,
      };

    case C.DECLINE_STUDENT_SUCCESS:
      return {
        ...state,
        isStudentDeclined: true,
      };

    case C.DECLINE_STUDENT_FAIL:
      return {
        ...state,
        isStudentDeclined: false,
        errMsg: action.payload.message,
      };

    case E.HIRE_STUDENT_EXTERNSHIP:
      return {
        ...state,
        isStudentConfirmed: null,
      };

    case E.HIRE_STUDENT_EXTERNSHIP_SUCCESS:
      return {
        ...state,
        isStudentConfirmed: true,
      };

    case E.HIRE_STUDENT_EXTERNSHIP_FAIL:
      return {
        ...state,
        isStudentConfirmed: false,
        errMsg: action.payload.message,
      };

    case E.DECLINE_STUDENT_EXTERNSHIP:
      return {
        ...state,
        isStudentDeclined: null,
      };

    case E.DECLINE_STUDENT_EXTERNSHIP_SUCCESS:
      return {
        ...state,
        isStudentDeclined: true,
      };

    case E.DECLINE_STUDENT_EXTERNSHIP_FAIL:
      return {
        ...state,
        isStudentDeclined: false,
        errMsg: action.payload.message,
      };

    case C.CLEAR_ROO_UNI_FEEDBACK_FLAGS:
      return {
        ...state,
        isStudentConfirmed: null,
        isStudentContacted: null,
        isStudentDeclined: null,
        errMsg: EXCEPTION_MESSAGE,
      };

    case C.GET_STUDENT_DETAILS:
    case C.GET_STUDENT_DETAILS_FAIL:
      return {
        ...state,
        studentDetails: {},
      };

    case C.GET_STUDENT_DETAILS_SUCCESS:
      const specialitis = getSpecialityInterestLabels(action.payload.subspecialityInterestIds);
      return {
        ...state,
        studentDetails: {
          ...action.payload,
          subspecialityInterests: specialitis,
        },
      };

    case E.CONTACT_STUDENT_EXTERNSHIP:
      return {
        ...state,
        isStudentContacted: null,
      };

    case E.CONTACT_STUDENT_EXTERNSHIP_SUCCESS:
      return {
        ...state,
        isStudentContacted: true,
      };

    case E.CONTACT_STUDENT_EXTERNSHIP_FAIL:
      return {
        ...state,
        isStudentContacted: false,
        errMsg: action.payload.message,
      };

    case E.UPDATE_EXTERNSHIP_HOSPITAL_REQUEST:
      return {
        ...state,
        isExternshipUpdated: null,
      };

    case E.UPDATE_EXTERNSHIP_HOSPITAL_FAIL:
      return {
        ...state,
        isExternshipUpdated: false,
      };

    case E.UPDATE_EXTERNSHIP_HOSPITAL_SUCCESS:
      return {
        ...state,
        isExternshipUpdated: true,
      };

    case E.DELETE_EXTERNSHIP_HOSPITAL_REQUEST:
      return {
        ...state,
        isExternshipDeleted: null,
      };

    case E.DELETE_EXTERNSHIP_HOSPITAL_FAIL:
      return {
        ...state,
        isExternshipDeleted: false,
      };

    case E.DELETE_EXTERNSHIP_HOSPITAL_SUCCESS:
      return {
        ...state,
        isExternshipDeleted: true,
      };

    case E.CLEAR_EXTERNSHIP_HOSPITAL_FLAG:
      return {
        ...state,
        isExternshipUpdated: null,
        isExternshipDeleted: null,
      };

    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default hospitalInternshipsReducer;
