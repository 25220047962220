import * as ForgotPasswordConstants from "../../../constants/forgotPasswordConstants";
export function sendResetPasswordSuccessEmail(userEmailId: string) {
  return {
    type: ForgotPasswordConstants.SEND_RESET_PASSWORD_SUCCESS_EMAIL_REQUEST,
    payload: {
      email: userEmailId,
    },
  } as const;
}

export type ForgotPasswordActions = ReturnType<typeof sendResetPasswordSuccessEmail>;
