import * as C from "../../../constants/techConstants";

export function fetchTechSettingInformationAction(techId, userTypeId) {
  return {
    type: C.GET_TECH_SETTING_INFORMATION,
    techId: techId,
    userTypeId: userTypeId,
  };
}

export function updateTechBasicInfoAction(techInformation) {
  return {
    type: C.UPDATE_TECH_BASIC_INFO,
    payload: {
      techInformation: techInformation,
    },
  };
}

export function initializeTechSettingsAction() {
  return {
    type: C.INITIALIZE_TECH_SETTINGS,
  };
}

export function getW9InfoAction(techId, userId) {
  return {
    type: C.GET_TECH_W9_INFO,
    techId: techId,
    userId: userId,
  };
}

export function updateW9Action(oW9) {
  return {
    type: C.UPDATE_TECH_W9,
    payload: oW9,
  };
}

export function resetTechBasicInfoUpdateSuccessAction() {
  return {
    type: C.RESET_TECH_BASIC_INFO_UPDATE_SUCCESS,
  };
}

export function resetTechW9UpdateSuccessAction() {
  return {
    type: C.RESET_TECH_W9_UPDATE_SUCCESS,
  };
}

export function getBlockedHospitalListAction(data) {
  return {
    type: C.GET_BLOCKED_HOSPITAL_LIST,
    payload: data,
  };
}

export function removeBlockedHospitalAction(data) {
  return {
    type: C.REMOVE_BLOCKED_HOSPITAL,
    payload: data,
  };
}

export function blockHospitalAction(data) {
  return {
    type: C.BLOCK_HOSPITAL,
    payload: data,
  };
}

export function hideBlockHospConfirmationModalAction(data) {
  return {
    type: C.HIDE_BLOCK_HOSPITAL_CONFIRMATION_MODAL,
    payload: data,
  };
}
