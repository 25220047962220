export const ADD_CALENDAR_NOTE_REQUEST = "ADD_CALENDAR_NOTE_REQUEST";
export const ADD_CALENDAR_NOTE_SUCCESS = "ADD_CALENDAR_NOTE_SUCCESS";
export const ADD_CALENDAR_NOTE_FAIL = "ADD_CALENDAR_NOTE_FAIL";

export const FETCH_CALENDAR_NOTE_REQUEST = "FETCH_CALENDAR_NOTE_REQUEST";
export const FETCH_CALENDAR_NOTE_SUCCESS = "FETCH_CALENDAR_NOTE_SUCCESS";
export const FETCH_CALENDAR_NOTE_FAIL = "FETCH_CALENDAR_NOTE_FAIL";

export const DELETE_CALENDAR_NOTE_REQUEST = "DELETE_CALENDAR_NOTE_REQUEST";
export const DELETE_CALENDAR_NOTE_SUCCESS = "DELETE_CALENDAR_NOTE_SUCCESS";
export const DELETE_CALENDAR_NOTE_FAIL = "DELETE_CALENDAR_NOTE_FAIL";

export const INITIALIZE_CALENDAR_NOTE = "INITIALIZE_CALENDAR_NOTE";
