import * as Sentry from "@sentry/react";
import "isomorphic-fetch";
import { call, put, takeEvery } from "redux-saga/effects";

import * as errorMessage from "../../constants/errorMessage";
import * as ActionTypes from "../../constants/loader";

type WebAppVersionData = {
  versionNumber: number;
  userMessageTitle: string;
  userMessageBody: string;
};

type ResponseData = {
  status: string;
  status_code: number;
  message: string;
  data: {
    success: boolean;
    data: Array<WebAppVersionData>;
  };
  headers: {
    [key: string]: string;
  };
};

export function* getWebAppVersionAsync() {
  try {
    const response: Response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/utility/getWebAppVersion",
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data: ResponseData = yield response.json();

    if (data.status_code === 200) {
      yield put({
        type: ActionTypes.GET_WEB_APP_VERSION_SUCCESS,
        payload: data.data?.data[0]?.versionNumber,
      });
    } else {
      yield put({
        type: ActionTypes.GET_WEB_APP_VERSION_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error in getWebAppVersionAsync: " } });
    yield put({
      type: ActionTypes.GET_WEB_APP_VERSION_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* loaderSaga() {
  yield takeEvery(ActionTypes.GET_WEB_APP_VERSION, getWebAppVersionAsync);
}
