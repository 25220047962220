import { ReducerAction } from "../../Types/common";
import * as CONSTANTS from "../constants/UserPromoConstants";
import UserPromoState from "../types/UserPromoState";

const initialState: UserPromoState = {
  userPromos: [],
  requestPeriodActiveUserPromos: [],
};

// eslint-disable-next-line default-param-last
const userPromoReducer = (state = initialState, action: ReducerAction): UserPromoState => {
  switch (action.type) {
    case CONSTANTS.GET_USER_PROMO_SUCCESS:
      if (action.payload.requestPeriodActive) {
        return {
          ...state,
          requestPeriodActiveUserPromos: action.payload.userPromos,
        };
      } else {
        return {
          ...state,
          userPromos: action.payload.userPromos,
        };
      }
    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default userPromoReducer;
