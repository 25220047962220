import * as Sentry from "@sentry/react";
import "isomorphic-fetch";
import { call, put, select, take, takeEvery } from "redux-saga/effects";

import * as ActionTypes from "../chatConstants";
import { ConvoInfoResp, GetConvoInfoAction } from "../types/chatType";

import * as hospitalConst from "./../../../constants/hospitalConstants";
import * as userTypeConst from "./../../../constants/UserTypeConstants";
import * as vetConst from "./../../../constants/vetConstants";

export function* getConvoInfo(action: GetConvoInfoAction) {
  try {
    const { hospitalId, vetUserId, hideLoader, hospitalUserId } = action.payload;
    if (!hideLoader) {
      yield put({
        type: ActionTypes.START_CHAT_WINDOW_LOADING,
        payload: { message: "Fetching the conversation's history..." },
      });
    }
    const response: Response = yield call(
      fetch,
      window.RooConfig.MESSAGING_API_URL +
        `/api/v2/conversation/info?hospitalId=${hospitalId}&vetUserId=${vetUserId}&hospitalUserId=${hospitalUserId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const responseData: ConvoInfoResp = yield response.json();
    if (responseData?.error?.type === ActionTypes.CONVERSATION_NOT_FOUND_ERROR) {
      yield put({
        type: ActionTypes.GET_CONVO_INFO_FAIL,
        payload: { isUnexpectedError: false },
      });
    } else {
      yield put({
        type: ActionTypes.GET_CONVO_INFO_SUCCESS,
        payload: responseData,
      });
      yield put({
        type: ActionTypes.STOP_CHAT_WINDOW_LOADING,
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error in getConvoInfo: " } });
    yield put({
      payload: { isUnexpectedError: true },
      type: ActionTypes.GET_CONVO_INFO_FAIL,
    });
  }
}

export function* createConversation(action: any) {
  try {
    const { shiftGroupId } = action.payload;
    yield put({
      type: ActionTypes.CREATE_CONVERSATION_LOADING,
      payload: { message: "Creating a chat session..." },
    });
    const response: Response = yield call(
      fetch,
      window.RooConfig.API_URL + `api/message/createConversation?shiftGroupId=${shiftGroupId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const responseData: [] = yield response.json();

    yield put({
      type: ActionTypes.CREATE_CONVERSATION_SUCCESS,
      payload: responseData,
    });
    yield put({
      type: ActionTypes.STOP_CREATE_CONVERSATION_LOADING,
    });
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error in createConversation: " } });
    yield put({
      type: ActionTypes.CREATE_CONVERSATION_FAIL,
    });
    yield put({
      type: ActionTypes.STOP_CREATE_CONVERSATION_LOADING,
    });
  }
}

export function* openChatPanelFromBanner(action: any) {
  try {
    yield put({
      type: hospitalConst.FETCH_SHIFT_DETAILS,
      payload: {
        mockVetUserId: action.payload?.vetUserId,
        mockHospitalId: action.payload?.hospitalId,
      },
      shiftId: action.payload?.shiftId,
    });

    yield take(hospitalConst.FETCH_SHIFT_DETAILS_SUCCESS);
    const shiftDetails: {
      userId: string;
      hospitalId: string;
      confirmedVetId: string;
      vetUserId: number;
    } = yield select((state) => state.hospital.shiftDetails);

    if (action.payload.userTypeId === userTypeConst.TECH_USER_TYPE) {
      yield put({
        type: vetConst.OPEN_HOSPITAL_PROFILE_PANEL,
        payload: {
          hospitalId: action.payload?.hospitalId,
          userId: shiftDetails?.userId,
          openChat: true,
          shiftData: {
            ...shiftDetails,
            hospitalId: action.payload?.hospitalId,
            confirmedVetUserId: action.payload?.techUserId,
            isOpenChatFromBanner: true,
          },
        },
      });
    }

    if (action.payload.userTypeId === userTypeConst.VET_USER_TYPE) {
      yield put({
        type: vetConst.OPEN_HOSPITAL_PROFILE_PANEL,
        payload: {
          hospitalId: action.payload?.hospitalId
            ? action.payload?.hospitalId
            : shiftDetails.hospitalId,
          userId: shiftDetails?.userId,
          openChat: true,
          shiftData: {
            ...shiftDetails,
            hospitalId: action.payload?.hospitalId
              ? action.payload?.hospitalId
              : shiftDetails.hospitalId,
            confirmedVetUserId: action.payload?.vetUserId
              ? action.payload?.vetUserId
              : shiftDetails.vetUserId,
            isOpenChatFromBanner: action.payload?.shiftId ? false : true,
          },
        },
      });
    }
    if (action.payload.userTypeId === userTypeConst.HOSPITAL_USER_TYPE) {
      const vetUserId = action.payload?.vetUserId
        ? action.payload?.vetUserId
        : shiftDetails.vetUserId;

      yield put({
        type: hospitalConst.SHOW_PROFILE_PANEL_REQUEST,
        payload: {
          contractorId: shiftDetails?.confirmedVetId,
          isVet: true,
          from: "dashboard",
          showShiftOnProfile: true,
          openChat: true,
          shiftData: {
            ...shiftDetails,
            hospitalId: action.payload?.hospitalId
              ? action.payload?.hospitalId
              : shiftDetails.hospitalId,
            vetUserId: action.payload.techUserId ? action.payload.techUserId : vetUserId,
            isOpenChatFromBanner: action.payload?.shiftId ? false : true,
          },
        },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "openChatPanelFromBanner error" } });
  }
}

export function* fetchChatBannersData(action: any) {
  try {
    const queryStr = action.payload.hospitalId
      ? `hospitalId=${action.payload.hospitalId}`
      : `userEmail=${window.encodeURIComponent(action.payload.userEmail)}`;

    const response: Response = yield call(
      fetch,
      window.RooConfig.MESSAGING_API_URL + `/api/conversation/unreadByEmail?${queryStr}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const responseData: [] = yield response.json();
    yield put({
      type: ActionTypes.FETCH_CHAT_BANNERS_DATA_SUCCESS,
      payload: responseData,
    });
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "fetchChatBannersData" } });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootChatSaga() {
  yield takeEvery(ActionTypes.GET_CONVO_INFO, getConvoInfo);
  yield takeEvery(ActionTypes.CREATE_CONVERSATION, createConversation);
  yield takeEvery(ActionTypes.OPEN_CHAT_PANEL_FROM_BANNER, openChatPanelFromBanner);
  yield takeEvery(ActionTypes.FETCH_CHAT_BANNERS_DATA, fetchChatBannersData);
}
