import {
  CHANGE_PANEL_TYPE,
  CHANGE_SHIFT_STATUS_TYPE,
  CLOSE_BOOST_WARNING_MODAL,
  CLOSE_MOBILE_ACTION_SHEET,
  CLOSE_PROFILE_PANEL,
  CLOSE_RATING_MODAL,
  CONFIRM_SHIFT_REQUEST,
  GET_SHIFT_DETAILS_FOR_PANEL_REQUEST,
  OPEN_PROFILE_PANEL_FROM_CALENDAR,
  OPEN_PROFILE_PANEL_REQUEST,
  RELOAD_SHIFTS_AFTER_PANEL_CLOSED,
  RESET_BOOST_WARNING_DATA,
  RESET_CONTRACTOR_PROFILE_DATA,
  RESET_RELOAD_SHIFTS_AFTER_PANEL_CLOSED,
  SET_CONTRACTOR_PROFILE_DATA,
  SET_PANEL_SHIFT_DATA,
  SET_SELECTED_SHIFT_REQUEST,
  SHOW_BOOST_WARNING_MODAL,
  SHOW_MOBILE_ACTION_SHEET,
  SHOW_RATING_MODAL,
  SHOW_TOAST_FOR_PANEL,
  UPDATE_UNFILLED_SHIFT_DETAILS,
} from "../constants";

export function openProfilePanel(data: any) {
  return {
    type: OPEN_PROFILE_PANEL_REQUEST,
    payload: data,
  };
}

export function openProfilePanelFromCalendar(data: any) {
  return {
    type: OPEN_PROFILE_PANEL_FROM_CALENDAR,
    payload: data,
  };
}

export function closeProfilePanel() {
  return {
    type: CLOSE_PROFILE_PANEL,
  };
}

export function setProfileData(data: any) {
  return {
    type: SET_CONTRACTOR_PROFILE_DATA,
    payload: data,
  };
}

export function resetProfileData() {
  return {
    type: RESET_CONTRACTOR_PROFILE_DATA,
  };
}

export function setSelectedShiftRequest(data: any) {
  return {
    type: SET_SELECTED_SHIFT_REQUEST,
    payload: data,
  };
}

export function changePanelType(data: any) {
  return {
    type: CHANGE_PANEL_TYPE,
    payload: data,
  };
}

export function changeShiftStatusType(data: any) {
  return {
    type: CHANGE_SHIFT_STATUS_TYPE,
    payload: data,
  };
}

export function showRatingModal() {
  return {
    type: SHOW_RATING_MODAL,
  };
}

export function closeRatingModal() {
  return {
    type: CLOSE_RATING_MODAL,
  };
}

export function getShiftDetailsForPanel(data: any) {
  return {
    type: GET_SHIFT_DETAILS_FOR_PANEL_REQUEST,
    payload: data,
  };
}
export function showActionSheet() {
  return {
    type: SHOW_MOBILE_ACTION_SHEET,
  };
}

export function closeActionSheet() {
  return {
    type: CLOSE_MOBILE_ACTION_SHEET,
  };
}

export function confirmShiftRequest(data: any) {
  return {
    type: CONFIRM_SHIFT_REQUEST,
    payload: data,
  };
}

export function setDefaultShiftDetails(data: any) {
  return {
    type: SET_PANEL_SHIFT_DATA,
    payload: data,
  };
}

export function updateUnfilledShiftDetails(data: any) {
  return {
    type: UPDATE_UNFILLED_SHIFT_DETAILS,
    payload: data,
  };
}

export function reloadShiftsAfterPanelClosed() {
  return {
    type: RELOAD_SHIFTS_AFTER_PANEL_CLOSED,
  };
}

export function resetReloadShiftsAfterPanelClosed() {
  return {
    type: RESET_RELOAD_SHIFTS_AFTER_PANEL_CLOSED,
  };
}

export function showToastForPanel(data: any) {
  return {
    type: SHOW_TOAST_FOR_PANEL,
    payload: data,
  };
}

export function showBoostWarningModal(data: any) {
  return {
    type: SHOW_BOOST_WARNING_MODAL,
    payload: data,
  };
}

export function closeBoostWarningModal(data?: any) {
  return {
    type: CLOSE_BOOST_WARNING_MODAL,
    payload: data,
  };
}

export function resetBoostWarningData() {
  return {
    type: RESET_BOOST_WARNING_DATA,
  };
}
