import { RefObject, useEffect, useMemo, useState } from "react";

import { breakpoints } from "../constants/responsiveBreakpoints";

export const useWindowInnerWidth = () => {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setInnerWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return { innerWidth };
};

export const useDevice = () => {
  const { innerWidth } = useWindowInnerWidth();
  const { smallMediaBreakpoint, mediumMediaBreakpoint } = breakpoints;
  return {
    isSmallMedia: innerWidth < smallMediaBreakpoint,
    isMediumMedia: innerWidth < mediumMediaBreakpoint,
  };
};

// https://stackoverflow.com/questions/45514676/react-check-if-element-is-visible-in-dom
export function useIsVisibleOnScreen(ref: RefObject<HTMLElement>) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(
    () => new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ref]
  );

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  return isIntersecting;
}
