import { useTranslation } from "react-i18next";

import { EventTechSignUpForm } from "../../../../api/tech";
import { useRestrictedEmailDomains } from "../../../../api/utility/useRestrictedEmailDomains";
import { EventVetSignUpForm } from "../../../../api/vet";
import validation from "../../../../helpers/Validation";

const OPTIONAL_FIELDS = {
  tech: [
    "utmCampaign",
    "utmSource",
    "utmMedium",
    "isLicensed",
    "isCertified",
    "isRegistered",
    "isVTS",
    "licenseNumber",
  ],
  vet: [
    "utmCampaign",
    "utmSource",
    "utmMedium",
    "lookingToStart",
    "currentSituation",
    "typicalAvailableDays",
  ],
};

export type ValidateOptions =
  | {
      providerType: "vet";
      form: EventVetSignUpForm;
    }
  | {
      providerType: "tech";
      form: EventTechSignUpForm;
    };

export const useValidate = () => {
  const { t } = useTranslation();
  const { data } = useRestrictedEmailDomains();
  const restrictedEmailDomains = data?.domains || [];

  const commonValidation = (options: ValidateOptions) => {
    const errors: { [key: string]: string } = {};
    if (validation.isEmail(options.form["email"])) {
      errors["email"] = t("registration.signUp.form.enterValidEmail");
    } else if (!validation.isEmailDomainAllowed(options.form["email"], restrictedEmailDomains)) {
      errors["email"] = t("registration.signUp.form.personalEmailRequired");
    }
    if (validation.inValidPassword(options.form["password"])) {
      errors["password"] = t("registration.signUp.form.enterValidPassword");
    }
    if (validation.checkValidZipcode(options.form["zipcode"], false)) {
      errors["zipcode"] = t("registration.signUp.form.enterValidZipCode");
    }
    return errors;
  };

  const vetValidation = (form: EventVetSignUpForm, key: string) => {
    const errors: { [key: string]: string } = {};
    if (!form[key as keyof EventVetSignUpForm] && !OPTIONAL_FIELDS.vet.includes(key)) {
      errors[key] = t("registration.signUp.form.fieldRequired");
    }
    if (!form.preferredAreasOfPractice.filter((p) => p.checked).length) {
      errors["preferredAreasOfPractice"] = t("registration.signUp.form.required");
    }
    if (validation.checkValidZipcode(form["reenterZipcode"], false)) {
      errors["reenterZipcode"] = t("registration.signUp.form.enterValidZipCode");
    }
    if (validation.zipcodeMatch(form["zipcode"], form["reenterZipcode"])) {
      errors["zipcode"] = t("registration.signUp.form.nonMatchingZipCode");
      errors["reenterZipcode"] = t("registration.signUp.form.nonMatchingZipCode");
    }
    return errors;
  };

  const techValidation = (form: EventTechSignUpForm, key: string) => {
    const errors: { [key: string]: string } = {};
    if (!form[key as keyof EventTechSignUpForm] && !OPTIONAL_FIELDS.tech.includes(key)) {
      errors[key] = t("registration.signUp.form.fieldRequired");
    }
    return errors;
  };

  const validate = (options: ValidateOptions) => {
    return Object.keys(options.form).reduce((errors, key) => {
      if (options.providerType === "vet") {
        Object.assign(errors, vetValidation(options.form, key));
      } else {
        Object.assign(errors, techValidation(options.form, key));
      }

      Object.assign(errors, commonValidation(options));

      return errors;
    }, {} as { [key: string]: string });
  };

  return { validate };
};
