import styled from "styled-components";

export const CalendlyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 780px;
  max-width: 100% !important;
`;

export const CalendlyIframe = styled.iframe`
  width: 100% !important;
  max-height: 780;
  height: 100%;
  max-width: 100% !important;
`;
