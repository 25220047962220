import { all, call } from "redux-saga/effects";

import rootContractorBonusSaga from "../Admin/ContractorBonus/sagas/ContractorBonusSagas";
import rootAdminEnterpriseSaga from "../Admin/Enterprise/sagas/EnterpriseSagas";
import rootFeedbackSaga from "../Admin/FeedbackForm/sagas/FeedbackSagas";
import rooAdminInternshipsSaga from "../Admin/Internships/sagas/InternshipSagas";
import rootManualSMSSaga from "../Admin/ManualSMS/sagas/ManualSMSSagas";
import rootAdminReferralSaga from "../Admin/Referral/sagas/ReferralSaga";
import rootAdminShiftSaga from "../Admin/Shifts/sagas/ShiftSagas";
import rootUserSaga from "../Admin/Users/sagas/UserSagas";
import rootW9Saga from "../Admin/W9/sagas/W9Sagas";
import rootCalendarSaga from "../calendar/sagas/CalendarSagas";
import rootCalendarNote from "../Common/AddCalendarNote/sagas/addNewCalendarNoteSagas";
import editRatingSaga from "../Common/EditRating/sagas/EditRatingSagas";
import rootManageHospitalUserDetailsSaga from "../Common/ManageHospitalUserDetails/sagas/ManageHospitalUserDetailsSagas";
import rootReferNewUserSaga from "../Common/ReferralInvite/sagas/ReferNewUserSagas";
import rootEnterpriseSaga from "../Enterprise/saga/enterpriseSaga";
import rootHospitalBankBillingSaga from "../Hospital/billing/saga/hospitalBillingSaga";
import rootManageHospitalUsersSaga from "../Hospital/ManageHospitalUsers/sagas/ManageHospitalUsersSagas";
import rootHospitalProfileSaga from "../Hospital/profile/sagas/hospitalProfileSaga";
import rooUniveristyHospitalSaga from "../Hospital/rooUniversityHospital/sagas/rooUniveristyHospitalSagas";
import rootHospitalSaga from "../Hospital/saga/hospitalSaga";
import rootHospitalSettingsSaga from "../Hospital/settings/sagas/hospitalSettingsSaga";
import rootLoaderSaga from "../loader/sagas/loaderSaga";
import rootForgotPasswordSaga from "../Login/forgotPassword/sagas/ForgotPasswordSaga";
import rootLoginSaga from "../Login/Saga/LoginSagas";
import rootRegisterEnterpriseSaga from "../Registration/RegistrationEnterprise/Sagas/RegisterEnterpriseSaga";
import rootRegisterHospitalSaga from "../Registration/RegistrationHospital/Sagas/RegisterHospitalSaga";
import rootRegisterRooAdminSaga from "../Registration/RegistrationRooInternalUser/Sagas/RegisterRooAdminSaga";
import rootRegisterStudentSaga from "../Registration/RegistrationStudent/Sagas/RegisterStudentSaga";
import rootSignUpSaga from "../Registration/Saga/RegistrationSaga";
import rootRegisterTechSaga from "../RegistrationNew/Sagas/RegisterTechSaga";
import rootRegisterVetSaga from "../RegistrationNew/Sagas/RegisterVetSaga";
import rootStudentDashboardSaga from "../student/dashboard/saga/dashboardSaga";
import rootStudentSaga from "../student/saga/studentSaga";
import rootTechFirstTimerSaga from "../tech/firstTimer/sagas/firstTimerSaga";
import rootTechProfileSaga from "../tech/profile/sagas/techProfileSagas";
import rootTechSettingSaga from "../tech/settings/sagas/techSettingSagas";
import rootTechShiftHistorySaga from "../tech/techShiftHistory/sagas/techShiftHistorySaga";
import rootTechCalendarSaga from "../techCalendar/sagas/TechCalendarSagas";
import rootUnsubscribeSaga from "../Unsubscribe/sagas/UnsubscribeSagas";
import rootVetFirstTimerSaga from "../vet/firstTimer/sagas/firstTimerSaga";
import rootVetProfileSaga from "../vet/profile/sagas/vetProfileSagas";
import rootVetSettingSaga from "../vet/settings/sagas/vetSettingSagas";
import rootVetShiftHistorySaga from "../vet/vetShiftHistory/sagas/vetShiftHistorySaga";
import rootVetCalendarSaga from "../vetCalendar/sagas/VetCalendarSagas";
import rootTechDashboardSaga from "../vetTechDashboard/sagas/techDashboardSaga";
import rootVetDashboardSaga from "../vetTechDashboard/sagas/vetDashboardSaga";
// import rootInternshipDetailsSaga from "../student/internshipDetails/saga/internshipDetailsSaga";
// import rootExternshipDetailsSaga from "../student/externshipDetails/saga/externshipDetailsSaga";
import rooEventSaga from "../Admin/Events/sagas/EventSagas";
import rootAdminExternshipSaga from "../Admin/Externships/sagas/ExternshipsSagas";
import rootAdminPromosSaga from "../Admin/Promos/sagas/PromosSaga";
import rooBulkUploadSaga from "../BulkUpload/saga/batchUploadSaga";
import rootChatSaga from "../Common/Chat/sagas/chatSagas";
import rootExperimentSaga from "../Common/Experiment/sagas/ExperimentSagas";
import rootShiftRatingByHospitalSaga from "../Hospital/ShiftRatingByHospital/sagas/shiftRatingByHospitalSagas";
import profilePanelSaga from "../ProfilePanels/sagas/profilePanelSaga";
import userPromoSaga from "../Promo/sagas/userPromoSaga";
import rootHospitalProfileForStudentSaga from "../student/studentHospitalProfile/saga/studentHospitalProfileSaga";
import rootTechOnboardingSaga from "../tech/onboarding/sagas/techOnboardingSaga";
import rootVetOnboardingSaga from "../vet/onboarding/sagas/vetOnboardingSagas";

// eslint-disable-next-line no-restricted-exports
export default function* rootSaga() {
  yield all([
    call(rootLoaderSaga), // to get webAppVersion
    call(rootRegisterRooAdminSaga),
    call(rootRegisterHospitalSaga),
    call(rootRegisterEnterpriseSaga),
    call(rootRegisterVetSaga),
    call(rootRegisterStudentSaga),
    call(rootRegisterTechSaga),
    call(rootCalendarSaga),
    call(rootLoginSaga),
    call(rootSignUpSaga),
    call(rootHospitalSaga),
    call(rootEnterpriseSaga),
    call(rootAdminEnterpriseSaga),
    call(rootVetDashboardSaga),
    call(rootTechDashboardSaga),
    call(rootHospitalProfileSaga),
    call(rootVetCalendarSaga),
    call(rootTechCalendarSaga),
    call(rootVetProfileSaga),
    call(rootTechProfileSaga),
    call(rootVetShiftHistorySaga),
    call(rootTechShiftHistorySaga),
    call(rootShiftRatingByHospitalSaga),
    call(rootUserSaga),
    call(rootHospitalSettingsSaga),
    call(rootVetSettingSaga),
    call(rootTechSettingSaga),
    call(rootStudentSaga),
    call(rootStudentDashboardSaga),
    // call(rootInternshipDetailsSaga),
    // call(rootExternshipDetailsSaga),
    call(rootHospitalProfileForStudentSaga),
    call(rootAdminShiftSaga),
    call(rooAdminInternshipsSaga),
    call(rooUniveristyHospitalSaga),
    call(rootHospitalBankBillingSaga),
    call(rootForgotPasswordSaga),
    call(rootVetFirstTimerSaga),
    call(rootTechFirstTimerSaga),
    call(rootManualSMSSaga),
    call(rootW9Saga),
    call(rootManageHospitalUsersSaga),
    call(rootManageHospitalUserDetailsSaga),
    call(rootReferNewUserSaga),
    call(rootAdminReferralSaga),
    call(rootCalendarNote),
    call(editRatingSaga),
    call(rootFeedbackSaga),
    call(rootUnsubscribeSaga),
    call(rootContractorBonusSaga),
    call(rooEventSaga),
    call(rootAdminPromosSaga),
    call(rootVetOnboardingSaga),
    call(rootAdminExternshipSaga),
    call(rootChatSaga),
    call(rootTechOnboardingSaga),
    call(rooBulkUploadSaga),
    call(rootExperimentSaga),
    call(userPromoSaga),
    call(profilePanelSaga),
  ]);
}
