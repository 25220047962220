import * as Sentry from "@sentry/react";
import { Auth } from "aws-amplify";
import axios from "axios";
import "isomorphic-fetch";
import { call, put, select, takeEvery } from "redux-saga/effects";
import "../../helpers/InterceptFetch";

import { showPromoShiftSignUpToast, showToast } from "../../Common/Toasts/action/ToastActions";
import { PROMO_CLAIMED, SHIFT_REQUEST } from "../../constants/brazeEventNameConstants";
import * as errorMessage from "../../constants/errorMessage";
import { UNSET_LOADER, UNSET_MAP_LOADER } from "../../constants/loader";
import {
  GET_TECH_REQUEST_COUNT,
  GET_TECH_REQUEST_COUNT_FAIL,
  GET_TECH_REQUEST_COUNT_SUCCESS,
  HIDE_ALERT_FORM_POP_UP,
  SET_LOCAL_TECH_HAS_W9,
  UPDATE_ALERT_FORM_POP_COUNT_FAIL,
  UPDATE_ALERT_FORM_POP_COUNT_SUCCESS,
  UPDATE_USER_PROFILE_INFO_FROM_W9,
} from "../../constants/Login";
import * as actionTypes from "../../constants/techConstants";
import { logBrazeCustomEvent } from "../../helpers/brazeUtility";
import { getUserPromos } from "../../Promo/actions/userPromoActions";
import { Event, EventTracker } from "../../tracking/service/EventTracker";

// --- SELECTORS
export const getIsGridView = (state) => state.techDashboard.isGridView;
export const getIsCalendarView = (state) => state.techDashboard.isCalendarView;
export const getNumberProbationShiftsLocal = (state) => state.techDashboard.nProbationShifts;
export const getHasPaymentSetup = (state) => state.login.hasPaymentSetup;

// --- SAGAS
function* searchShiftAsync(action) {
  try {
    const isGridView = yield select(getIsGridView);
    const isCalendarView = yield select(getIsCalendarView);
    const techId = action?.payload?.techId || localStorage.getItem("techId");

    if (isCalendarView) {
      action.payload.startDate = "";
      action.payload.endDate = "";
    }
    const searchParams = {
      ...action.payload,
      techId,
      isGridView,
      isCalendarView,
    };
    const response = yield call(fetch, window.RooConfig.API_URL + "api/tech/shift/searchShifts", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(searchParams),
    });

    const data = yield response.json();
    yield put({ type: actionTypes.GET_NON_REQUEST_SURVEY_ELIGIBILITY_TECH, payload: techId });
    yield put({ type: actionTypes.INITIALIZE_SEARCH_RESULTS });
    yield put({ type: UNSET_LOADER });
    yield put({ type: UNSET_MAP_LOADER });

    const defaultShiftSort = "date";
    EventTracker.send(Event.Name.TECH_SHIFT_SEARCH, Event.Type.SEARCH, Event.Entity.TECH, techId, {
      ...searchParams,
      statusCode: data?.status_code,
      results: data?.data?.totalCount,
      defaultShiftSort: defaultShiftSort,
    });

    if (data.status_code === 200) {
      const searchShiftResult = data.data.data;
      const searchMessage = data.message;
      const userStatus = localStorage.getItem("isActive");
      if (searchMessage == "User blocked" && userStatus != 4) {
        localStorage.setItem("isActive", 4);
        window.location.reload();
      } else {
        yield put({
          type: actionTypes.SEARCH_TECH_SHIFT_SUCCESS,
          payload: {
            status: data.status_code,
            searchShiftResult: searchShiftResult,
            totalCount: data.data.totalCount,
            latitude: data.data.latitude,
            longitude: data.data.longitude,
            isSearchClicked: action.payload.isSearchClicked,
          },
        });
      }
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.SEARCH_TECH_SHIFT_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while searching tech shift" } });
    yield put({
      type: actionTypes.SEARCH_TECH_SHIFT_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* getLinkedShiftAsync(action) {
  try {
    const shiftGroupId = action.payload;
    const techId = localStorage.getItem("techId");
    const response = yield call(
      fetch,
      `${window.RooConfig.API_URL}api/tech/shift/getLinkedShift?shiftGroupId=${shiftGroupId}&techId=${techId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const responseData = yield response.json();

    const data = [...responseData.data.data];

    if (responseData.status_code === 200) {
      yield put({ type: actionTypes.GET_LINKED_TECH_SHIFT_SUCCESS, payload: data });
    } else {
      yield put({
        type: actionTypes.GET_LINKED_TECH_SHIFT_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting linked tech shift" } });
    yield put({
      type: actionTypes.GET_LINKED_TECH_SHIFT_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* requestShiftAsync(action) {
  try {
    const nProbationShifts = yield select(getNumberProbationShiftsLocal);
    const isProbationLimit = nProbationShifts >= 5;

    if (!isProbationLimit) {
      const response = yield call(fetch, window.RooConfig.API_URL + "api/tech/shift/requestShift", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload),
      });

      const data = yield response.json();
      if (data.status_code === 200) {
        const requestMessage = data.message;
        if (requestMessage == "User blocked") {
          localStorage.setItem("isActive", 4);
          window.location.reload();
        } else {
          if (data.data.added === 0) {
            const { hospitalName, shiftDate } = action.payload;
            yield put({
              type: actionTypes.TECH_REQUEST_TECH_SHIFT_NOT_AVAILABLE,
              payload: { hospitalName, shiftDate, message: data.data.message },
            });
          } else {
            yield put({
              type: actionTypes.TECH_REQUEST_TECH_SHIFT_SUCCESS,
              payload: {
                status: data.status_code,
                shiftRequestResponse: data.data.data,
                shiftData: action.shiftData,
                availableShifts: action.availableShifts,
                isFromAllDone: action.isFromAllDone,
              },
            });

            logBrazeCustomEvent(SHIFT_REQUEST, { shift_id: action.payload.shiftId });
            if (data.data.data.promoId) {
              yield put(getUserPromos({ userId: parseInt(localStorage.getItem("userId")) }));
              yield put(showPromoShiftSignUpToast());
            }
          }
        }
      } else if (data.status_code === 400) {
        yield put({
          type: actionTypes.TECH_REQUEST_TECH_SHIFT_FAIL,
          payload: { status: data.status_code, message: data.message },
        });
      }
    } else {
      // probation limit from local check so show error without api call
      yield put({
        type: actionTypes.TECH_REQUEST_TECH_SHIFT_FAIL,
        payload: { errorMessage: errorMessage.TECH_PROBATION_LIMIT },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while requesting tech shift" } });
    yield put({
      type: actionTypes.TECH_REQUEST_TECH_SHIFT_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* fetchFirstTimerInfoAsync(action) {
  try {
    const response = yield call(
      fetch,
      window.RooConfig.API_URL +
        `api/tech/getTechFirstTimerInfo?techId=${localStorage.getItem(
          "techId"
        )}&userId=${localStorage.getItem("userId")}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();

    if (data.status_code === 200) {
      yield put({
        type: actionTypes.FETCH_FIRST_TIME_INFO_SUCCESS,

        payload: { status: data.status_code, techSignNowData: data.data.techSignNowData[0] },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.FETCH_FIRST_TIME_INFO_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while fetching first timer info" } });
    yield put({
      type: actionTypes.FETCH_FIRST_TIME_INFO_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* getPendingRatingsAsync(action) {
  const isGetAllReviews = action.payload ? action.payload.isGetAllReviews : null;
  try {
    const response = yield call(
      fetch,
      window.RooConfig.API_URL +
        `api/tech/shift/getPendingReviewNotification?techId=
        ${localStorage.getItem("techId")}&isGetAllReviews=${isGetAllReviews}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = yield response.json();
    if (data.status_code === 200) {
      if (data.data === undefined) {
        yield put({ type: actionTypes.GET_PENDING_TECH_RATINGS_FAIL });
      } else {
        const responseData = data.data;
        yield put({
          type: actionTypes.GET_PENDING_TECH_RATINGS_SUCCESS,
          payload: { status: data.status_code, responseData },
        });
        if (responseData && responseData.data && responseData.data.length) {
          yield put({
            type: actionTypes.SET_UNRATED_SHIFT_DATE,
            payload: { shiftDate: responseData.data[0].shiftDate },
          });
        }
      }
    } else if (data.status_code === 400) {
      yield put({ type: actionTypes.GET_PENDING_TECH_RATINGS_FAIL });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting pending tech ratings" },
    });
    yield put({
      type: actionTypes.GET_PENDING_TECH_RATINGS_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* deferHospitalRatingAsync(action) {
  try {
    const techId = parseInt(localStorage.getItem("techId"));
    const response = yield call(fetch, window.RooConfig.API_URL + "api/tech/deferRating", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();
    if (data.status_code === 200) {
      EventTracker.send(Event.Name.TECH_SKIP_REVIEW, Event.Type.CLICK, Event.Entity.TECH, techId);

      yield put({
        type: actionTypes.UPDATE_CURRENT_HOSPITAL_RATING_BY_TECH_INDEX,
      });
      yield put({
        type: actionTypes.GET_PENDING_TECH_RATINGS_REQUEST,
        payload: { isGetAllReviews: 1 },
      });
      yield put({ type: UNSET_LOADER });
      yield put({
        type: actionTypes.DEFER_HOSPITAL_RATING_BY_TECH_SUCCESS,
        payload: { status: data.status_code },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.DEFER_HOSPITAL_RATING_BY_TECH_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while deferring hospital rating" } });
    yield put({
      type: actionTypes.DEFER_HOSPITAL_RATING_BY_TECH_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* deferAllHospitalRatingsAsync(action) {
  try {
    const response = yield call(fetch, window.RooConfig.API_URL + "api/tech/deferAllRatings", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();
    if (data.status_code === 200) {
      EventTracker.send(
        Event.Name.TECH_SKIP_ALL_REVIEWS,
        Event.Type.CLICK,
        Event.Entity.TECH,
        action.payload.techId
      );

      yield put({
        type: actionTypes.GET_PENDING_TECH_RATINGS_REQUEST,
        payload: { isGetAllReviews: 1 },
      });
      yield put({ type: UNSET_LOADER });
      yield put({
        type: actionTypes.DEFER_ALL_HOSPITAL_RATINGS_BY_TECH_SUCCESS,
        payload: { status: data.status_code },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.DELETE_TECH_SHIFT_REQUEST_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while deferring all hospital ratings for tech" },
    });
    yield put({
      type: actionTypes.DEFER_ALL_HOSPITAL_RATINGS_BY_TECH_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* submitTechW9Async(action) {
  try {
    const {
      isUpdateProfile,
      signNowAddress,
      signNowAptUnitSuite,
      signNowCity,
      signNowStateId,
      signNowZipcode,
    } = action.payload;
    const response = yield call(fetch, window.RooConfig.API_URL + "api/tech/taxpayerDetails", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({ type: SET_LOCAL_TECH_HAS_W9 });
      const hasPaymentSetup = yield select(getHasPaymentSetup);
      if (hasPaymentSetup) {
        // hide first timer modal since payment already set up
        yield put({ type: actionTypes.HIDE_FIRST_TIMER_MODAL });
      }
      yield put({
        type: actionTypes.SUBMIT_TECH_W9_SUCCESS,
        payload: { status: data.status_code, data: data },
      });
      if (isUpdateProfile) {
        yield put({
          type: UPDATE_USER_PROFILE_INFO_FROM_W9,
          payload: {
            address: signNowAddress,
            aptUnitSuite: signNowAptUnitSuite,
            city: signNowCity,
            stateId: signNowStateId,
            zipcode: signNowZipcode,
          },
        });
      }
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.SUBMIT_TECH_W9_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while submitting tech w9" } });
    yield put({ type: UNSET_LOADER });
    let w9ErrorMessage = errorMessage.EXCEPTION_MESSAGE;
    if (window.navigator.onLine === false) {
      w9ErrorMessage = errorMessage.OFFLINE;
    }
    yield put({
      type: actionTypes.SUBMIT_TECH_W9_FAIL,
      payload: {
        status: error.status_code,
        message: w9ErrorMessage,
      },
    });
  }
}

function* getAllMyShiftsAsync(action) {
  try {
    const isIncludeCompleted = action.payload && action.payload.isIncludeCompleted;
    const techId = parseInt(localStorage.getItem("techId"));
    const response = yield call(
      fetch,
      window.RooConfig.API_URL +
        `api/tech/shift/getAllShiftsByTechId?techId=${techId}${
          isIncludeCompleted ? "&isIncludeCompleted=1" : ""
        }`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      const myShifts = data.data;
      const nProbationShifts = myShifts.filter((o) => String(o.isProbationShift) === "1").length;
      yield put({
        type: actionTypes.GET_ALL_MY_TECH_SHIFTS_SUCCESS,
        payload: { status: data.status_code, myShifts, nProbationShifts },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.GET_ALL_MY_TECH_SHIFTS_FAIL,
        payload: {
          status: data.status_code,
          errorMessage: "Failed to retrieve your shifts.",
        },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting all my tech shifts" },
    });
    yield put({
      type: actionTypes.GET_ALL_MY_TECH_SHIFTS_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* updateAlertFormPopCountAsync(action) {
  const maxAlertFormPopCount = 3;
  const count = action.payload;
  if (typeof count !== "undefined" && count <= maxAlertFormPopCount + 1) {
    // + 1 needed to synch db for last time
    try {
      const uploadData = { techId: localStorage.getItem("techId") };
      const response = yield call(
        fetch,
        window.RooConfig.API_URL + "api/tech/updateAlertFormPopCount",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(uploadData),
        }
      );

      const data = yield response.json();

      if (data.status_code === 200) {
        yield put({
          type: UPDATE_ALERT_FORM_POP_COUNT_SUCCESS,
          payload: { status: data.status_code },
        });
      } else if (data.status_code === 400) {
        yield put({
          type: UPDATE_ALERT_FORM_POP_COUNT_FAIL,
          payload: { status: data.status_code, message: data.message },
        });
      }
    } catch (error) {
      Sentry.captureException(error, {
        extra: { context: "Error while updating alert form pop count" },
      });
      yield put({
        type: UPDATE_ALERT_FORM_POP_COUNT_FAIL,
        payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
      });
    }
  }
}

function* submitTechBackgroundInfoAsync(action) {
  try {
    const response = yield call(fetch, window.RooConfig.API_URL + "api/tech/backgroundInfo", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: actionTypes.SUBMIT_TECH_BACKGROUND_SUCCESS,
        payload: { status: data.status_code, data: data.data },
      });
    } else {
      yield put({
        type: actionTypes.SUBMIT_TECH_BACKGROUND_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while submitting tech background info" },
    });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: actionTypes.SUBMIT_TECH_BACKGROUND_FAIL,
      payload: {
        status: error.status_code,
        message: errorMessage.EXCEPTION_MESSAGE,
      },
    });
  }
}

function* getTechRequestCountAsync() {
  try {
    const response = yield call(
      fetch,
      window.RooConfig.API_URL +
        "api/tech/shift/requestcount?techId=" +
        localStorage.getItem("techId"),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = yield response.json();
    if (data.status_code === 200) {
      if (data.data.requestCount === 0) {
        yield put({ type: GET_TECH_REQUEST_COUNT_FAIL });
      } else {
        yield put({
          type: GET_TECH_REQUEST_COUNT_SUCCESS,
          payload: { status: data.status_code },
        });
      }
    } else {
      yield put({ type: GET_TECH_REQUEST_COUNT_FAIL });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting tech request count" },
    });
    yield put({
      type: GET_TECH_REQUEST_COUNT_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* getTechShiftDetailsAsync(action) {
  try {
    const response = yield call(
      fetch,
      window.RooConfig.API_URL +
        "api/hospital/tech_shift/getShiftDetailsById?shiftGroupId=" +
        action.shiftGroupId +
        "&techId=" +
        localStorage.getItem("techId"),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const responseData = yield response.json();

    const data = [...responseData.data.data];

    if (responseData.status_code === 200) {
      yield put({ type: actionTypes.FETCH_TECH_SHIFT_DETAILS_SUCCESS, payload: data });
    } else {
      yield put({
        type: actionTypes.FETCH_TECH_SHIFT_DETAILS_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting tech shift details" },
    });
  }
}

export function* removeConfirmedTech(action) {
  try {
    const response = yield call(fetch, window.RooConfig.API_URL + "api/tech/shift/removeTech", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(action.payload),
    });
    const responseData = yield response.json();

    yield put({ type: UNSET_LOADER });
    if (responseData.status_code === 200) {
      yield put({
        type: actionTypes.TECH_TRIGGERED_REMOVE_CONFIRMED_TECH_SUCCESS,
        payload: responseData.data,
      });
    } else {
      yield put({
        type: actionTypes.TECH_TRIGGERED_REMOVE_CONFIRMED_TECH_FAIL,
        payload: "Failed to cancel shift",
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting tech shift details" },
    });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: actionTypes.TECH_TRIGGERED_REMOVE_CONFIRMED_TECH_FAIL,
      payload: "Failed to cancel shift",
    });
  }
}

function* fetchCalculateTechShiftPricingAsync(action) {
  try {
    const response = yield call(fetch, window.RooConfig.API_URL + "api/pricing/techshiftPricing", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();

    if (data.status_code === 200) {
      yield put({
        type: actionTypes.FETCH_CALCULATE_TECH_SHIFT_PRICING_SUCCESS,
        payload: data.data,
      });
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.FETCH_CALCULATE_TECH_SHIFT_PRICING_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while fetching tech shift pricing" },
    });
    yield put({
      type: actionTypes.FETCH_CALCULATE_TECH_SHIFT_PRICING_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* getEventForTech(action) {
  const session = yield Auth.currentSession();
  try {
    const response = yield axios.post(
      window.RooConfig.API_URL + "api/tech/event?userType=2",
      action.payload,
      {
        headers: {
          "x-access-token": "Bearer " + session.idToken.jwtToken,
        },
      }
    );
    if (response.data.status_code === 200) {
      yield put({
        type: actionTypes.GET_EVENT_FOR_TECH_SUCCESS,
        payload: response.data.data,
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting tech event" } });
    yield put({
      type: actionTypes.GET_EVENT_FOR_TECH_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* getTechPaymentInfoAsync() {
  try {
    const techId = localStorage.getItem("techId");
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + `api/tech/getTechPaymentInfo/${techId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = yield response.json();

    if (data.status_code === 200) {
      yield put({
        type: actionTypes.GET_TECH_PAYMENT_INFO_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_TECH_PAYMENT_INFO_FAIL,
        payload: { status_code: data.status_code, message: data.data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting tech payment info" } });
    yield put({
      type: actionTypes.GET_TECH_PAYMENT_INFO_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* updateTechDetailsPaymentInfo(action) {
  try {
    const response = yield call(fetch, window.RooConfig.API_URL + "api/tech/updateConnectAccount", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(action.payload),
    });
    const data = yield response.json();
    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      yield put({
        type: actionTypes.UPDATE_TECH_DETAILS_PAYMENT_INFO_SUCCESS,
        payload: { commonData: data.data },
      });
    } else if (data.status_code === 400 || data.status_code === 500) {
      yield put({
        type: actionTypes.UPDATE_TECH_DETAILS_PAYMENT_INFO_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating tech details payment info" },
    });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: actionTypes.FETCH_COMMON_DATA_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* createTechPromoTracking(action) {
  try {
    const response = yield call(fetch, window.RooConfig.API_URL + "api/promoTracking", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(action.payload),
    });
    const data = yield response.json();
    yield put({ type: UNSET_LOADER });

    if (data === true) {
      yield put({
        type: actionTypes.CREATE_TECH_PROMO_TRACKING_SUCCESS,
        payload: true,
      });
      logBrazeCustomEvent(PROMO_CLAIMED, { promo_id: action.payload.promo_id });
    } else {
      yield put({
        type: actionTypes.CREATE_TECH_PROMO_TRACKING_FAIL,
        payload: JSON.parse(data).message,
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while creating tech promo tracking" },
    });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: actionTypes.CREATE_TECH_PROMO_TRACKING_FAIL,
      payload: errorMessage.EXCEPTION_MESSAGE,
    });
  }
}

function* getTechActivePromosByUserId(action) {
  try {
    const userId = action.payload;
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + `api/promoTracking/active/${userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = yield response.json();
    yield put({ type: UNSET_LOADER });

    if (data) {
      yield put({
        type: actionTypes.GET_TECH_PROMO_TRACKING_BY_USER_ID_SUCCESS,
        payload: data,
      });
    } else {
      yield put({
        type: actionTypes.GET_TECH_PROMO_TRACKING_BY_USER_ID_FAIL,
        payload: { message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting tech active promos by user id" },
    });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: actionTypes.GET_TECH_PROMO_TRACKING_BY_USER_ID_FAIL,
      payload: { message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* updateTechPromoMessageSeen(action) {
  try {
    const promoTrackingId = action.payload;
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + `api/promoTracking/updatePromoMessageSeen/${promoTrackingId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload),
      }
    );
    const data = yield response.json();
    yield put({ type: UNSET_LOADER });

    if (data === true) {
      yield put({
        type: actionTypes.UPDATE_TECH_PROMO_MESSAGE_SEEN_SUCCESS,
        payload: true,
      });
    } else {
      yield put({
        type: actionTypes.UPDATE_TECH_PROMO_MESSAGE_SEEN_FAIL,
        payload: false,
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating tech promo message seen" },
    });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: actionTypes.UPDATE_TECH_PROMO_MESSAGE_SEEN_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* getTechCompletedPromos(action) {
  try {
    const userId = action.payload.userId;
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + `api/promoTracking/allByUser/${userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.length) {
      const completedPromos = data.filter((promo) => promo.promoCompletedDate !== null);
      yield put({
        type: actionTypes.GET_TECH_COMPLETED_PROMOS_SUCCESS,
        payload: completedPromos,
      });
    } else if (data) {
      yield put({
        type: actionTypes.GET_TECH_COMPLETED_PROMOS_SUCCESS,
        payload: [],
      });
    } else {
      yield put({
        type: actionTypes.GET_TECH_COMPLETED_PROMOS_FAIL,
        payload: [],
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting tech completed promos" },
    });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: actionTypes.GET_TECH_COMPLETED_PROMOS_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* techSkipModal(action) {
  try {
    const techId = action.payload.techId;
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + `api/tech/techSkipW9Modal/${techId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = yield response.json();
    yield put({ type: UNSET_LOADER });

    if (data) {
      yield put({
        type: actionTypes.TECH_SKIP_W9_MODAL_SUCCESS,
        payload: true,
      });
    } else {
      yield put({
        type: actionTypes.TECH_SKIP_W9_MODAL_FAIL,
        payload: false,
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while tech skip modal" } });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: actionTypes.TECH_SKIP_W9_MODAL_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* getTechConfirmedShiftData(action) {
  try {
    const techId = action.payload.techId;
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + `api/tech/getTechConfirmedShiftData/${techId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = yield response.json();
    yield put({ type: UNSET_LOADER });

    if (data) {
      yield put({
        type: actionTypes.GET_TECH_CONFIRMED_SHIFT_DATA_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_TECH_CONFIRMED_SHIFT_DATA_FAIL,
        payload: {},
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting tech confirmed shift data" },
    });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: actionTypes.GET_TECH_CONFIRMED_SHIFT_DATA_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* saveShiftTotalCount(action) {
  try {
    const totalCount = action.payload;

    yield put({
      type: actionTypes.SAVE_SHIFT_COUNT_SUCCESS,
      payload: { totalCount },
    });
  } catch (error) {
    yield put({
      type: actionTypes.SAVE_SHIFT_COUNT_FAIL,
      payload: { message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}
function* setDashboardViewStateDetails(action) {
  switch (action.payload) {
    case "map":
      yield put({
        type: actionTypes.SET_TECH_DASHBOARD_MAP_VIEW,
      });
      break;
    case "grid":
      yield put({
        type: actionTypes.SET_TECH_DASHBOARD_GRID_VIEW,
      });
      break;
    case "calendar":
      yield put({
        type: actionTypes.SET_TECH_DASHBOARD_CALENDAR_VIEW,
      });
      break;
    default:
      break;
  }
}
function* setDashboardView(action) {
  try {
    if (action.payload) {
      yield put({
        type: actionTypes.SET_DASHBOARD_VIEW_SUCCESS,
        payload: action.payload,
      });
    } else {
      yield put({
        type: actionTypes.SET_DASHBOARD_VIEW_FAIL,
        payload: null,
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while setting dashboard view" } });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: actionTypes.SET_DASHBOARD_VIEW_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* determineNonRequestSurveyEligibility(action) {
  try {
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + `api/tech/getNonRequestSurvey/${action.payload}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = yield response.json();
    if (data.data) {
      yield put({
        type: actionTypes.GET_NON_REQUEST_SURVEY_ELIGIBILITY_SUCCESS_TECH,
        payload: data.data.isEligibleForSurvey,
      });
    } else {
      yield put({
        type: actionTypes.GET_NON_REQUEST_SURVEY_ELIGIBILITY_FAIL_TECH,
        payload: null,
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting non request survey eligibility" },
    });
    yield put({
      type: actionTypes.GET_NON_REQUEST_SURVEY_ELIGIBILITY_FAIL_TECH,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* techSubmitSurvey(action) {
  try {
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/user/insertUserSurveyResponse",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();

    if (data.status_code === 200) {
      yield put({
        type: actionTypes.TECH_SUBMIT_SURVEY_SUCCESS,
        payload: { status: data.status_code },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.TECH_SUBMIT_SURVEY_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while tech submit survey" } });
    yield put({
      type: actionTypes.TECH_SUBMIT_SURVEY_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* setIsInitialMapSortComplete() {
  try {
    yield put({
      type: actionTypes.SET_IS_INITIAL_MAP_SORT_COMPLETE_SUCCESS,
    });
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while setting is initial map sort complete" },
    });
    throw error;
  }
}

function* getTechPromoShifts(action) {
  try {
    const { techId, promoId } = action.payload;
    const response = yield call(
      fetch,
      window.RooConfig.API_URL +
        `api/promo/${promoId}/shifts?contractorId=${techId}&isVet=${false}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = yield response.json();
    if (data) {
      yield put({
        type: actionTypes.GET_TECH_PROMO_SHIFTS_SUCCESS,
        payload: data,
      });
    } else {
      yield put({
        type: actionTypes.GET_TECH_PROMO_SHIFTS_FAIL,
        payload: null,
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting tech promo shifts" } });
    yield put({
      type: actionTypes.GET_TECH_PROMO_SHIFTS_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* toggleTechPromoShiftPanel(action) {
  try {
    const { promoName } = action.payload;
    if (promoName) {
      yield put({
        type: actionTypes.TOGGLE_TECH_PROMO_SHIFTS_PANEL_OPEN,
        payload: promoName,
      });
    } else {
      yield put({
        type: actionTypes.TOGGLE_TECH_PROMO_SHIFTS_PANEL_CLOSED,
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while toggling tech promo shifts panel" },
    });
    yield put({
      type: actionTypes.TOGGLE_TECH_PROMO_SHIFTS_PANEL_CLOSED,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* deleteTechShiftRequest(action) {
  try {
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/hospital/tech_shift/deleteShiftRequest",
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();
    if (data && response.ok) {
      yield put({
        type: actionTypes.DELETE_TECH_SHIFT_REQUEST_SUCCESS,
        payload: action.payload.shiftId,
      });
    } else {
      yield put({
        type: actionTypes.DELETE_TECH_SHIFT_REQUEST_FAIL,
        payload: {
          status: response.status,
          message: data.message || errorMessage.EXCEPTION_MESSAGE,
        },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while deleting tech shift request" },
    });
    yield put({
      type: actionTypes.DELETE_TECH_SHIFT_REQUEST_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* cancelConvirmedTechShift(action) {
  try {
    const response = yield call(fetch, window.RooConfig.API_URL + "api/tech/shift/removeTech", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: actionTypes.CANCEL_CONFIRMED_TECH_SHIFT_SUCCESS,
      });
    } else {
      yield put(
        showToast({
          variant: "error",
          message: "Failed to cancel shift",
          description: data?.message || "An unexpected error occured",
        })
      );
      yield put({
        type: actionTypes.CANCEL_CONFIRMED_TECH_SHIFT_FAIL,
        payload: null,
      });
    }
  } catch (error) {
    yield put({ type: UNSET_LOADER });
    Sentry.captureException(error, {
      extra: { context: "Error while cancelling confirmed tech shift" },
    });
    yield put({
      type: actionTypes.CANCEL_CONFIRMED_TECH_SHIFT_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* toggleTechDeleteShiftRequestPanel(action) {
  try {
    const { deleteShiftRequestDetails } = action.payload;
    delete deleteShiftRequestDetails.vets;
    if (deleteShiftRequestDetails) {
      yield put({
        type: actionTypes.TOGGLE_TECH_DELETE_SHIFT_REQUEST_PANEL_OPEN,
        payload: deleteShiftRequestDetails,
      });
    } else {
      yield put({
        type: actionTypes.TOGGLE_TECH_DELETE_SHIFT_REQUEST_PANEL_CLOSED,
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while toggling tech delete shift request panel" },
    });
    yield put({
      type: actionTypes.TOGGLE_TECH_DELETE_SHIFT_REQUEST_PANEL_CLOSED,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootTechDashboardSaga() {
  yield takeEvery(actionTypes.SEARCH_TECH_SHIFT_REQUEST, searchShiftAsync);
  yield takeEvery(actionTypes.GET_LINKED_TECH_SHIFT_REQUEST, getLinkedShiftAsync);
  yield takeEvery(actionTypes.TECH_REQUEST_TECH_SHIFT_REQUEST, requestShiftAsync);
  yield takeEvery(actionTypes.FETCH_TECH_FIRST_TIME_INFO_REQUEST, fetchFirstTimerInfoAsync);
  yield takeEvery(actionTypes.GET_PENDING_TECH_RATINGS_REQUEST, getPendingRatingsAsync);
  yield takeEvery(actionTypes.DEFER_HOSPITAL_RATING_BY_TECH, deferHospitalRatingAsync);
  yield takeEvery(actionTypes.DEFER_ALL_HOSPITAL_RATINGS_BY_TECH, deferAllHospitalRatingsAsync);
  yield takeEvery(actionTypes.SUBMIT_TECH_W9_REQUEST, submitTechW9Async);
  yield takeEvery(actionTypes.GET_ALL_MY_TECH_SHIFTS_REQUEST, getAllMyShiftsAsync);
  yield takeEvery(HIDE_ALERT_FORM_POP_UP, updateAlertFormPopCountAsync);
  yield takeEvery(actionTypes.SUBMIT_TECH_BACKGROUND_REQUEST, submitTechBackgroundInfoAsync);
  yield takeEvery(GET_TECH_REQUEST_COUNT, getTechRequestCountAsync);
  yield takeEvery(actionTypes.FETCH_TECH_SHIFT_DETAILS_REQUEST, getTechShiftDetailsAsync);
  yield takeEvery(actionTypes.TECH_TRIGGERED_REMOVE_CONFIRMED_TECH_REQUEST, removeConfirmedTech);
  yield takeEvery(
    actionTypes.FETCH_CALCULATE_TECH_SHIFT_PRICING,
    fetchCalculateTechShiftPricingAsync
  );
  yield takeEvery(actionTypes.GET_EVENT_FOR_TECH, getEventForTech);
  yield takeEvery(actionTypes.GET_TECH_PAYMENT_INFO, getTechPaymentInfoAsync);
  yield takeEvery(actionTypes.UPDATE_TECH_DETAILS_PAYMENT_INFO, updateTechDetailsPaymentInfo);
  yield takeEvery(actionTypes.CREATE_TECH_PROMO_TRACKING, createTechPromoTracking);
  yield takeEvery(actionTypes.GET_TECH_PROMO_TRACKING_BY_USER_ID, getTechActivePromosByUserId);
  yield takeEvery(actionTypes.UPDATE_TECH_PROMO_MESSAGE_SEEN, updateTechPromoMessageSeen);
  yield takeEvery(actionTypes.GET_TECH_COMPLETED_PROMOS, getTechCompletedPromos);
  yield takeEvery(actionTypes.TECH_SKIP_W9_MODAL, techSkipModal);
  yield takeEvery(actionTypes.GET_TECH_CONFIRMED_SHIFT_DATA, getTechConfirmedShiftData);
  yield takeEvery(actionTypes.SAVE_SHIFT_COUNT, saveShiftTotalCount);
  yield takeEvery(actionTypes.SET_DASHBOARD_VIEW, setDashboardView);
  yield takeEvery(actionTypes.SET_DASHBOARD_VIEW, setDashboardViewStateDetails);
  yield takeEvery(
    actionTypes.GET_NON_REQUEST_SURVEY_ELIGIBILITY_TECH,
    determineNonRequestSurveyEligibility
  );
  yield takeEvery(actionTypes.TECH_SUBMIT_SURVEY, techSubmitSurvey);
  yield takeEvery(actionTypes.SET_IS_INITIAL_MAP_SORT_COMPLETE, setIsInitialMapSortComplete);
  yield takeEvery(actionTypes.GET_TECH_PROMO_SHIFTS, getTechPromoShifts);
  yield takeEvery(actionTypes.TOGGLE_TECH_PROMO_SHIFTS_PANEL, toggleTechPromoShiftPanel);
  yield takeEvery(actionTypes.DELETE_TECH_SHIFT_REQUEST, deleteTechShiftRequest);
  yield takeEvery(
    actionTypes.TOGGLE_TECH_DELETE_SHIFT_REQUEST_PANEL,
    toggleTechDeleteShiftRequestPanel
  );
  yield takeEvery(actionTypes.CANCEL_CONFIRMED_TECH_SHIFT, cancelConvirmedTechShift);
}
