import { useQuery } from "@tanstack/react-query";

import { Promo } from "@roo-dev/roo-node-types";

import { buildQuery } from "../../Common/queryParamsUtils";
import UserPromo from "../../Promo/types/UserPromo";
import { getUserPromos } from "../../constants/apiRoutes";
import { APIError, get } from "../common/rooFetch";

import { CACHE } from "./constants";

interface UserPromosOptions {
  filter?: string;
  requestPeriodActive?: boolean;
  isVet?: boolean;
  shiftGroupId?: number;
  includeRaffles?: boolean;
}

export const useUserPromos = (userId: number, options?: UserPromosOptions) => {
  const { data, isFetching, error, isError } = useQuery<UserPromo[], APIError<unknown>>({
    queryKey: [CACHE.USER_PROMOS, userId, options],
    queryFn: () => get(buildQuery(getUserPromos(+userId), { ...options, includeRaffles: true })),
    enabled: !!userId,
    refetchOnWindowFocus: false,
  });

  return {
    promos: {
      all: data,
      eligible: data?.filter((u) => u.state === Promo.PromoState.ELIGIBLE),
      started: data?.filter((u) => u.state === Promo.PromoState.STARTED),
      failed: data?.filter((u) => u.state === Promo.PromoState.FAILED),
      completed: data?.filter((u) => u.state === Promo.PromoState.COMPLETED),
    },
    isFetching,
    error,
    isError,
  };
};
