import * as ConfirmMessage from "../../../constants/confirmMessage";
import { EXCEPTION_MESSAGE } from "../../../constants/errorMessage";
import * as C from "../../../constants/feedbackForm";

const initialState = {
  isFeedbackActionCompleted: false,
  feedbackModalMessage: EXCEPTION_MESSAGE,
};

// eslint-disable-next-line default-param-last
const feedbackFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case C.SUBMIT_FEEDBACK_SUCCESS:
      const { shiftAction, isVetShift } = action;
      let msg = ConfirmMessage.ADMIN_FEEDBACK_SUCCESS;
      if (shiftAction === "deleteShift") {
        msg = ConfirmMessage.ADMIN_DELETE_SHIFT_SUCCESS;
      } else if (shiftAction === "removeConfirmedContractor") {
        msg = isVetShift
          ? ConfirmMessage.ADMIN_REMOVE_CONFIRM_VET_SUCCESS
          : ConfirmMessage.ADMIN_REMOVE_CONFIRM_TECH_SUCCESS;
      } else if (shiftAction === "switchContractor") {
        msg = isVetShift
          ? ConfirmMessage.ADMIN_CONFIRM_VET_SWITCH_SUCCESS
          : ConfirmMessage.ADMIN_CONFIRM_TECH_SWITCH_SUCCESS;
      }
      return {
        ...state,
        isFeedbackActionCompleted: true,
        feedbackModalMessage: msg,
      };
    case C.SUBMIT_FEEDBACK_FAIL:
      return {
        ...state,
        isFeedbackActionCompleted: true,
      };
    case C.INITIALIZE_FEEDBACK_STATE:
      return {
        isFeedbackActionCompleted: false,
        feedbackModalMessage: EXCEPTION_MESSAGE,
      };
    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default feedbackFormReducer;
