import * as C from "../../constants/registerVetConstants";

type VetData = {
  email: string;
  referralCodeId: string;
  userTypeId: number | null;
  fullName: string;
  firstName: string;
  lastName: string;
  address: string;
  aptUnitSuite: string;
  city: string;
  stateId: string;
  zipcode: number | null;
  mobilePhoneNumber: string;
  hearAboutRoo: string;
  heardFromWordOfMouth: string;
  vetSchool: string;
  graduationYear: string;
  yearsOfPractice: string;
  licenses: any[];
  specializations: any[];
  animalTypeId: string;
  procedureTypeId: string;
  isDEA: string;
  insurance: any | null;
  isPrefGeneral: string;
  isPrefEmergency: string;
  isPrefHighVol: string;
  isPrefLargeAnimal: string;
  isPrefShelter: string;
  isPrefSurgery: string;
  isPrefSpecialty: string;
  isPrefChiropractic: string;
  isPrefLaserTherapy: string;
  isPrefAcupunture: string;
  isPrefUrgentCare: string;
  isPrefOther: string;
  prefOtherHospType: string;
  apptFlow: string;
  clientInteract: string;
  staffInteract: string;
  questionnaireResp1: string;
  questionnaireResp2: string;
  questionnaireResp3: string;
  questionnaireResp4: string;
  questionnaireResp5: string;
  questionnaireResp6: string;
  characterTypeId: number | null;
  additionalSkills: any | null;
  animals: any[];
  procedureTypes: any[];
  freeDaysInMonthToWork: any | null;
  vetAvailability: any | null;
  wantsFullTime: string;
  professionalLinks: string;
  referrerFullName: string;
  referrerPhoneOrEmail: string;
  isReferralUpdated: boolean;
};

type RegistrationVetState = {
  vetData: VetData;
  createAccountServer: {
    statusCode: number | null;
    message: string;
  };
  states: any[];
  password: string;
  email: string | null;
  accountCreated: boolean;
  isBasicInfoFetched: boolean;
  isEducationExpFetched: boolean;
  isGetToKnowFetched: boolean;
  isPhotoResLinksFetched: boolean;
  vetResume: any[];
  imageUploaded: boolean;
  vetImage: any[];
  isQuestionnaireFetched: boolean;

  isBasicInfoCompleted: boolean;
  isEducationExpCompleted: boolean;
  isGetToKnowCompleted: boolean;
  isPhotoResLinksCompleted: boolean;
  isQuestionnaireCompleted: boolean;
  isProfileCreated: boolean;
  isReducerEduSave: boolean;
  isReducerGetToKnowSave: boolean;
  isReducerVetPhotoSave: boolean;
};

const initialState: RegistrationVetState = {
  vetData: {
    email: "",
    referralCodeId: "",
    userTypeId: null,
    fullName: "",
    firstName: "",
    lastName: "",
    address: "",
    aptUnitSuite: "",
    city: "",
    stateId: "",
    zipcode: null,
    mobilePhoneNumber: "",
    hearAboutRoo: "",
    heardFromWordOfMouth: "",
    vetSchool: "",
    graduationYear: "",
    yearsOfPractice: "",
    licenses: [],
    specializations: [],
    animalTypeId: "",
    procedureTypeId: "",
    isDEA: "",
    insurance: null,
    isPrefGeneral: "",
    isPrefEmergency: "",
    isPrefHighVol: "",
    isPrefLargeAnimal: "",
    isPrefShelter: "",
    isPrefSurgery: "",
    isPrefSpecialty: "",
    isPrefChiropractic: "",
    isPrefLaserTherapy: "",
    isPrefAcupunture: "",
    isPrefUrgentCare: "",
    isPrefOther: "",
    prefOtherHospType: "",
    apptFlow: "",
    clientInteract: "",
    staffInteract: "",
    questionnaireResp1: "",
    questionnaireResp2: "",
    questionnaireResp3: "",
    questionnaireResp4: "",
    questionnaireResp5: "",
    questionnaireResp6: "",
    characterTypeId: null,
    additionalSkills: null,
    animals: [],
    procedureTypes: [],
    freeDaysInMonthToWork: null,
    vetAvailability: null,
    wantsFullTime: "",
    professionalLinks: "",
    referrerFullName: "",
    referrerPhoneOrEmail: "",
    isReferralUpdated: false,
  },
  createAccountServer: {
    statusCode: null,
    message: "",
  },
  states: [],
  password: "",
  email: null,
  accountCreated: false,
  isBasicInfoFetched: false,
  isEducationExpFetched: false,
  isGetToKnowFetched: false,
  isPhotoResLinksFetched: false,
  vetResume: [],
  imageUploaded: false,
  vetImage: [],
  isQuestionnaireFetched: false,

  isBasicInfoCompleted: false,
  isEducationExpCompleted: false,
  isGetToKnowCompleted: false,
  isPhotoResLinksCompleted: false,
  isQuestionnaireCompleted: false,
  isProfileCreated: false,
  isReducerEduSave: false,
  isReducerGetToKnowSave: false,
  isReducerVetPhotoSave: false,
};

// eslint-disable-next-line default-param-last
const RegistrationVetReducer = (state: RegistrationVetState = initialState, action: any) => {
  switch (action.type) {
    case C.CREATE_VET_ACCOUNT_SUCCESS:
      localStorage.setItem("vetId", action.payload.vetId);
      localStorage.setItem("userId", action.payload.userId);
      localStorage.setItem("zipcode", action.payload.zipcode);
      localStorage.setItem("isNameUpdated", "1");
      return {
        ...state,
        vetData: {
          ...state.vetData,
          email: action.payload.email,
          zipcode: action.payload.zipcode,
          referralCodeId: action.payload.referralCodeId,
          userTypeId: action.payload.userTypeId,
        },
        password: action.payload.password,
        accountCreated: true,
      };

    case C.CREATE_VET_ACCOUNT_FAIL:
      return {
        ...state,
        createAccountServer: {
          ...state.createAccountServer,
          statusCode: action.payload.status,
          message: action.payload.message,
        },
        accountCreated: false,
      };

    case C.VET_SUBMIT_PROFILE_SUCCESS:
      localStorage.setItem("isActive", "0");
      localStorage.setItem("isRegistrationComplete", "1");
      localStorage.setItem(
        "profileImage",
        state.vetImage["0"] !== undefined ? state.vetImage["0"].imagePath.toString() : ""
      );
      localStorage.setItem("fullName", `${state.vetData.firstName} ${state.vetData.lastName}`);
      return {
        ...state,
        isProfileCreated: true,
      };

    case C.VET_SUBMIT_PROFILE_FAIL:
      return {
        ...state,
        isProfileCreated: false,
      };

    // --- INITIALIZE
    case C.INITIALIZE_VET_REGISTRATION:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default RegistrationVetReducer;
