const METHODS = {
  GET: "GET",
  POST: "POST",
  PATCH: "PATCH",
  PUT: "PUT",
  DELETE: "DELETE",
} as const;

export class APIError<T> extends Error {
  constructor(message: string, public status: number, public data?: T) {
    super(message);
    this.name = "APIError";
  }
}

const rooFetch = async <RequestBody, ResponseBody>(
  path: string,
  method: typeof METHODS[keyof typeof METHODS],
  body?: RequestBody,
  suppressErrors = false
): Promise<ResponseBody> => {
  const url = path.startsWith("http") ? path : `${window.RooConfig.API_URL}${path}`;
  const headers = { "Content-Type": "application/json" };

  // eslint-disable-next-line roo/no-restricted-functions
  const response = await fetch(url, {
    method,
    headers,
    body: body ? JSON.stringify(body) : undefined,
  });

  let data;

  try {
    data = await response.json();
  } catch (error) {
    throw new APIError(error.message, response.status);
  }

  if (!response.ok && !suppressErrors) {
    throw new APIError(response.statusText, response.status, data);
  }

  return data;
};

export const get = async <ResponseBody>(url: string, suppressErrors = false) =>
  rooFetch<void, ResponseBody>(url, METHODS.GET, null, suppressErrors);

export const post = async <RequestBody, ResponseBody>(
  url: string,
  body: RequestBody,
  suppressErrors = false
) => rooFetch<RequestBody, ResponseBody>(url, METHODS.POST, body, suppressErrors);

export const put = async <RequestBody, ResponseBody>(
  url: string,
  body: RequestBody,
  suppressErrors = false
) => rooFetch<RequestBody, ResponseBody>(url, METHODS.PUT, body, suppressErrors);

export const patch = async <RequestBody, ResponseBody>(
  url: string,
  body: RequestBody,
  suppressErrors = false
) => rooFetch<RequestBody, ResponseBody>(url, METHODS.PATCH, body, suppressErrors);

export const del = async <ReturnType>(url: string, suppressErrors = false) =>
  rooFetch<ReturnType, void>(url, METHODS.DELETE, null, suppressErrors);
