import * as Sentry from "@sentry/react";

export interface UtmParams {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_term?: string;
}

export interface AdParams {
  ad_set_id?: string;
  ad_group_id?: string;
  ad_id?: string;
}

export async function trackParamsInSession(utmParams: UtmParams, adParams: AdParams = {}) {
  let requestUrl = "";
  try {
    const startSessionUrl = new URL(window.RooConfig.API_URL + "startSession");
    // Utm params
    for (const [utmKey, utmValue] of Object.entries(utmParams)) {
      startSessionUrl.searchParams.append(utmKey, utmValue);
    }

    // Ad params
    for (const [adKey, adValue] of Object.entries(adParams)) {
      startSessionUrl.searchParams.append(adKey, adValue);
    }

    // Referrer
    if (document.referrer) {
      startSessionUrl.searchParams.append("referrer", document.referrer);
    }

    requestUrl = startSessionUrl.toString();

    // eslint-disable-next-line roo/no-restricted-functions
    await fetch(startSessionUrl.toString(), {
      method: "GET",
      credentials: "include",
    });
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "error init session", url: requestUrl } });
  }
}
