import * as Sentry from "@sentry/react";
import { Auth } from "aws-amplify";
import axios from "axios";
import "isomorphic-fetch";
import { call, put, select, takeEvery } from "redux-saga/effects";
import "../../helpers/InterceptFetch";

import { Schemas } from "@roo-dev/roo-node-types";

import { queryClient } from "../../api/reactQueryClient";
import { showPromoShiftSignUpToast, showToast } from "../../Common/Toasts/action/ToastActions";
import { PROMO_CLAIMED, SHIFT_REQUEST } from "../../constants/brazeEventNameConstants";
import { UPDATE_SHIFT_REQUEST_END_TIMESTAMP } from "../../constants/calendar";
import * as errorMessage from "../../constants/errorMessage";
import { SET_LOADER, UNSET_LOADER, UNSET_MAP_LOADER } from "../../constants/loader";
import {
  HIDE_ALERT_FORM_POP_UP,
  SET_LOCAL_VET_HAS_W9,
  UPDATE_ALERT_FORM_POP_COUNT_FAIL,
  UPDATE_ALERT_FORM_POP_COUNT_SUCCESS,
  UPDATE_USER_PROFILE_INFO_FROM_W9,
} from "../../constants/Login";
import * as actionTypes from "../../constants/vetConstants";
import { logBrazeCustomEvent } from "../../helpers/brazeUtility";
import { getUserPromos } from "../../Promo/actions/userPromoActions";
import { Event, EventTracker } from "../../tracking/service/EventTracker";
import { setUnratedShiftDate } from "../../vet/vetShiftHistory/actions/vetShiftHistoryActions";
import { updateShowPaymentInfoModal } from "../actions/vetDashboardActions";

// --- SELECTORS
export const getTrainingStatus = (state) => state.login.trainingStatus;
export const getIsGridView = (state) => state.vetDashboard.isGridView;
export const getIsCalendarView = (state) => state.vetDashboard.isCalendarView;
export const getHasPaymentSetup = (state) => state.login.hasPaymentSetup;

// --- SAGAS
function* searchShiftAsync(action) {
  try {
    const isGridView = yield select(getIsGridView);
    const isCalendarView = yield select(getIsCalendarView);

    if (isCalendarView) {
      action.payload.startDate = "";
      action.payload.endDate = "";
    }
    const vetId = action?.payload?.vetId || localStorage.getItem("vetId");
    const userId = parseInt(localStorage.getItem("userId"));

    const searchParams = {
      ...action.payload,
      vetId,
      isGridView,
      isCalendarView,
      userId,
    };

    const { searchSource } = searchParams;

    const response = yield call(fetch, window.RooConfig.API_URL + "api/vet/shift/searchShifts", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(searchParams),
    });

    const data = yield response.json();
    yield put({ type: actionTypes.GET_NON_REQUEST_SURVEY_ELIGIBILITY, payload: vetId });
    yield put({ type: actionTypes.INITIALIZE_SEARCH_RESULTS });
    yield put({ type: UNSET_LOADER });
    yield put({ type: UNSET_MAP_LOADER });

    const defaultShiftSort = "date";

    /** @type {import("@roo-dev/event-tracking").EventName}*/
    let eventName;
    if (searchSource == Schemas.Search.SearchSource.SAVE_BUTTON) {
      eventName = Event.Name.SAVE_ADVANCED_FILTERS;
    } else if (searchSource == Schemas.Search.SearchSource.CLEAR_BUTTON) {
      eventName = Event.Name.CLEAR_ADVANCED_FILTERS;
    } else if (searchSource == Schemas.Search.SearchSource.LAST_SEARCH_BUTTON) {
      eventName = Event.Name.LAST_SAVED_SEARCH;
    } else {
      eventName = Event.Name.VET_SHIFT_SEARCH;
    }
    EventTracker.send(eventName, Event.Type.CLICK, Event.Entity.VET, searchParams.vetId, {
      ...searchParams,
      statusCode: data?.status_code,
      results: data?.data?.totalCount,
      defaultShiftSort: defaultShiftSort,
    });

    if (data.status_code === 200) {
      const searchShiftResult = data.data.data;
      const searchMessage = data.message;
      const userStatus = localStorage.getItem("isActive");
      if (searchMessage == "User blocked" && userStatus != "4") {
        localStorage.setItem("isActive", "4");
        window.location.reload();
      } else {
        yield put({
          type: actionTypes.SEARCH_SHIFT_SUCCESS,
          payload: {
            status: data.status_code,
            searchShiftResult: searchShiftResult,
            totalCount: data.data.totalCount,
            latitude: data.data.latitude,
            longitude: data.data.longitude,
            isSearchClicked: action.payload.isSearchClicked,
            isFromSearchThisArea: action.payload.isFromSearchThisArea,
            selectedZoom: action.payload.selectedZoom,
          },
        });
      }
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.SEARCH_SHIFT_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while searching shifts" } });
    yield put({
      type: actionTypes.SEARCH_SHIFT_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* getLinkedShiftAsync(action) {
  try {
    const shiftGroupId = action.payload;
    const vetId = localStorage.getItem("vetId");
    const response = yield call(
      fetch,
      `${window.RooConfig.API_URL}api/vet/shift/getLinkedShift?shiftGroupId=${shiftGroupId}&vetId=${vetId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const responseData = yield response.json();

    const data = [...responseData.data.data];

    if (responseData.status_code === 200) {
      yield put({ type: actionTypes.GET_LINKED_SHIFT_SUCCESS, payload: data });
    } else {
      yield put({
        type: actionTypes.GET_LINKED_SHIFT_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting linked shifts" } });
    yield put({
      type: actionTypes.GET_LINKED_SHIFT_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* requestShiftAsync(action) {
  try {
    yield put({
      type: actionTypes.VET_REQUEST_SHIFT_SUCCESS,
      payload: {
        status: 200,
        shiftRequestResponse: action.payload,
        shiftData: action.shiftData,
        availableShifts: action.availableShifts,
        isFromAllDone: action.isFromAllDone,
        flexStartTimeRange: action.flexStartTimeRange,
        isFlexibleSurgery: action.isFlexibleSurgery,
        isInstantBook: action.isInstantBook,
      },
    });

    logBrazeCustomEvent(SHIFT_REQUEST, { shift_id: action.payload.shiftId });

    const response = yield call(fetch, window.RooConfig.API_URL + "api/vet/shift/requestShift", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();

    if (data.status_code === 200) {
      const requestMessage = data.message;
      if (requestMessage == "User blocked") {
        localStorage.setItem("isActive", 4);
        window.location.reload();
      } else {
        if (data.data.added === 0) {
          const { hospitalName, shiftDate } = action.payload;
          yield put({
            type: actionTypes.VET_REQUEST_SHIFT_NOT_AVAILABLE,
            payload: { hospitalName, shiftDate, message: data.data.message },
          });
        }
        if (data.data.added !== 0) {
          const trainingStatus = yield select(getTrainingStatus);
          if (trainingStatus === 1) {
            yield put({
              type: actionTypes.CHECK_VET_LICENSE_REQUEST,
              payload: { vetId: action.payload.vetId },
            });
          }

          yield put(
            updateShowPaymentInfoModal({
              showPaymentInfoReminderModal: data.data.data.showPaymentInfoReminderModal,
            })
          );

          if (data.data.data.promoId) {
            yield put(getUserPromos({ userId: parseInt(localStorage.getItem("userId")) }));
            yield put(showPromoShiftSignUpToast());
          }
          if (data.data.data.isAutoConfirmed) {
            yield put(
              showToast({
                variant: "success",
                message: "Roo-hoo!",
                description: `Your shift request is confirmed.`,
              })
            );
          }
        }
        yield put({
          type: actionTypes.GET_ALL_MY_SHIFTS_REQUEST,
          payload: { isIncludeCompleted: false },
        });
        yield put({
          type: UPDATE_SHIFT_REQUEST_END_TIMESTAMP,
          payload: data.data.data,
        });
        yield put({
          type: actionTypes.CHECK_VET_LICENSE_EXPIRING_REQUEST,
          payload: { vetId: action.payload.vetId },
        });
      }
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.VET_REQUEST_SHIFT_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while requesting shift" } });
    yield put({
      type: actionTypes.GET_ALL_MY_SHIFTS_REQUEST,
      payload: { isIncludeCompleted: true },
    });
    yield put({
      type: actionTypes.VET_REQUEST_SHIFT_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* checkVetLicenseAsync(action) {
  try {
    const vetId = action.payload.vetId;
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + `api/vet/checkLicense?vetId=${vetId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = yield response.json();
    if (data.status_code === 200) {
      yield put({ type: actionTypes.CHECK_VET_LICENSE_SUCCESS });
      if (!data.data.isLicenseComplete) {
        yield put({ type: actionTypes.SHOW_INCOMPLETE_LICENSE_MODAL });
      }
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.CHECK_VET_LICENSE_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while checking vet license" } });
    yield put({
      type: actionTypes.CHECK_VET_LICENSE_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* fetchFirstTimerInfoAsync(action) {
  try {
    const response = yield call(
      fetch,
      window.RooConfig.API_URL +
        `api/vet/getVetFirstTimerInfo?vetId=${localStorage.getItem(
          "vetId"
        )}&userId=${localStorage.getItem("userId")}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();

    if (data.status_code === 200) {
      yield put({
        type: actionTypes.FETCH_VET_FIRST_TIMER_INFO_SUCCESS,
        payload: { status: data.status_code, vetSignNowData: data.data.vetSignNowData[0] },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.FETCH_VET_FIRST_TIMER_INFO_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while fetching vet first timer info" },
    });
    yield put({
      type: actionTypes.FETCH_VET_FIRST_TIMER_INFO_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* updateVetFirstTimerInfoAsync(action) {
  try {
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/vet/updateVetFirstTimerInfo",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();

    if (data.status_code === 200) {
      yield put({
        type: actionTypes.UPDATE_VET_FIRST_TIMER_INFO_SUCCESS,
        payload: { status: data.status_code },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.UPDATE_VET_FIRST_TIMER_INFO_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating vet first timer info" },
    });
    yield put({
      type: actionTypes.UPDATE_VET_FIRST_TIMER_INFO_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* getPendingRatingsAsync(action) {
  try {
    const { isGetAllReviews } = action?.payload || {};

    const response = yield call(
      fetch,
      window.RooConfig.API_URL +
        `api/vet/shift/getPendingReviewNotification?vetId=${localStorage.getItem(
          "vetId"
        )}&isGetAllReviews=${isGetAllReviews}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = yield response.json();
    if (data.status_code === 200) {
      if (data.data === undefined) {
        yield put({ type: actionTypes.GET_PENDING_VET_RATINGS_FAIL });
      } else {
        const responseData = data.data;
        yield put({
          type: actionTypes.GET_PENDING_VET_RATINGS_SUCCESS,
          payload: { status: data.status_code, responseData },
        });
        if (responseData && responseData.data && responseData.data.length) {
          yield put(setUnratedShiftDate({ shiftDate: responseData.data[0].shiftDate }));
        }
      }
    } else if (data.status_code === 400) {
      yield put({ type: actionTypes.GET_PENDING_VET_RATINGS_FAIL });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting pending vet ratings" },
    });
    yield put({
      type: actionTypes.GET_PENDING_VET_RATINGS_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* submitVetW9Async(action) {
  try {
    const {
      isUpdateProfile,
      signNowAddress,
      signNowAptUnitSuite,
      signNowCity,
      signNowStateId,
      signNowZipcode,
    } = action.payload;
    const response = yield call(fetch, window.RooConfig.API_URL + "api/vet/taxpayerDetails", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();

    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({ type: SET_LOCAL_VET_HAS_W9 });
      const hasPaymentSetup = yield select(getHasPaymentSetup);
      if (hasPaymentSetup) {
        // hide first timer modal since payment already set up
        yield put({ type: actionTypes.HIDE_FIRST_TIMER_MODAL });
      }
      yield put({
        type: actionTypes.SUBMIT_VET_W9_SUCCESS,
        payload: { status: data.status_code, data: data },
      });
      if (isUpdateProfile) {
        yield put({
          type: UPDATE_USER_PROFILE_INFO_FROM_W9,
          payload: {
            address: signNowAddress,
            aptUnitSuite: signNowAptUnitSuite,
            city: signNowCity,
            stateId: signNowStateId,
            zipcode: signNowZipcode,
          },
        });
      }
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.SUBMIT_VET_W9_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while submitting vet w9" } });
    yield put({ type: UNSET_LOADER });
    let w9ErrorMessage = errorMessage.EXCEPTION_MESSAGE;
    if (window.navigator.onLine === false) {
      w9ErrorMessage = errorMessage.OFFLINE;
    }
    yield put({
      type: actionTypes.SUBMIT_VET_W9_FAIL,
      payload: {
        status: error.status_code,
        message: w9ErrorMessage,
      },
    });
  }
}

function* getAllMyShiftsAsync(action) {
  try {
    const isIncludeCompleted = action.payload && action.payload.isIncludeCompleted;
    const response = yield call(
      fetch,
      window.RooConfig.API_URL +
        `api/vet/shift/getAllShiftsByVetId?vetId=${localStorage.getItem("vetId")}${
          isIncludeCompleted ? "&isIncludeCompleted=1" : ""
        }`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      const myShifts = data.data;
      yield put({
        type: actionTypes.GET_ALL_MY_SHIFTS_SUCCESS,
        payload: { status: data.status_code, myShifts },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.GET_ALL_MY_SHIFTS_FAIL,
        payload: {
          status: data.status_code,
          errorMessage:
            "Failed to retrieve your shifts. Please refresh your browser view in a minute to try again.",
        },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting all my shifts" } });
    yield put({
      type: actionTypes.GET_ALL_MY_SHIFTS_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* updateAlertFormPopCountAsync(action) {
  const maxAlertFormPopCount = 3;
  const count = action.payload;
  if (typeof count !== "undefined" && count <= maxAlertFormPopCount + 1) {
    // + 1 needed to synch db for last time
    try {
      const uploadData = { vetId: localStorage.getItem("vetId") };
      const response = yield call(
        fetch,
        window.RooConfig.API_URL + "api/vet/updateAlertFormPopCount",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(uploadData),
        }
      );

      const data = yield response.json();

      if (data.status_code === 200) {
        yield put({
          type: UPDATE_ALERT_FORM_POP_COUNT_SUCCESS,
          payload: { status: data.status_code },
        });
      } else if (data.status_code === 400) {
        yield put({
          type: UPDATE_ALERT_FORM_POP_COUNT_FAIL,
          payload: { status: data.status_code, message: data.message },
        });
      }
    } catch (error) {
      Sentry.captureException(error, {
        extra: { context: "Error while updating alert form pop count" },
      });
      yield put({
        type: UPDATE_ALERT_FORM_POP_COUNT_FAIL,
        payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
      });
    }
  }
}

function* checkVetLicenseExpiringAsync(action) {
  try {
    const { vetId } = action.payload;

    const response = yield call(
      fetch,
      window.RooConfig.API_URL + `api/vet/${vetId}/license/expiring`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = yield response.json();

    if (data.status_code === 200) {
      yield put({
        type: actionTypes.CHECK_VET_LICENSE_EXPIRING_SUCCESS,
        payload: { isLicenseGettingExpired: data.data.isLicenseGettingExpired },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.CHECK_VET_LICENSE_EXPIRING_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while checking vet license expiring" },
    });
    yield put({
      type: actionTypes.CHECK_VET_LICENSE_EXPIRING_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* checkVetLicenseExpiredAsync() {
  try {
    const vetId = localStorage.getItem("vetId");

    const response = yield call(
      fetch,
      window.RooConfig.API_URL + `api/vet/${vetId}/license/expired`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = yield response.json();

    if (data.status_code === 200) {
      yield put({
        type: actionTypes.CHECK_VET_LICENSE_EXPIRED_SUCCESS,
        payload: { isLicenseExpired: data.data.isLicenseExpired },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.CHECK_VET_LICENSE_EXPIRED_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while checking vet license expired" },
    });
    yield put({
      type: actionTypes.CHECK_VET_LICENSE_EXPIRED_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* getShiftDetailsAsync(action) {
  try {
    const response = yield call(
      fetch,
      window.RooConfig.API_URL +
        "api/hospital/shift/getShiftDetailsById?shiftGroupId=" +
        action.shiftGroupId +
        "&vetId=" +
        localStorage.getItem("vetId"),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const responseData = yield response.json();

    const data = [...responseData.data.data];

    if (responseData.status_code === 200) {
      yield put({ type: actionTypes.FETCH_VET_SHIFT_DETAILS_SUCCESS, payload: data });
    } else {
      yield put({
        type: actionTypes.FETCH_VET_SHIFT_DETAILS_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting shift details" } });
  }
}

function* fetchCommonDataAsync(action) {
  try {
    const response = yield call(fetch, window.RooConfig.API_URL + "api/utility/loadCommonData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();
    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      yield put({
        type: actionTypes.FETCH_COMMON_DATA_SUCCESS,
        payload: { commonData: data.data },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.FETCH_COMMON_DATA_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while fetching common data" } });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: actionTypes.FETCH_COMMON_DATA_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* fetchCalculateShiftPricingAsync(action) {
  try {
    yield put({
      type: SET_LOADER,
    });
    const response = yield call(fetch, window.RooConfig.API_URL + "api/pricing/shiftPricing", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(action.payload),
    });
    const data = yield response.json();
    /**
     * TODO: Why 2 SET_LOADER ?
     * We need to fix this! Reason is this is a global loader, and alot of functions
     * set and unset, faster async func will unset the loader for all running calls causing a race
     * between loaders.
     * This is a temporary fix to ensure that the loader is still on.
     */

    yield put({
      type: SET_LOADER,
    });
    if (data.status_code === 200) {
      yield put({
        type: actionTypes.FETCH_CALCULATE_SHIFT_PRICING_SUCCESS,
        payload: data.data,
      });
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.FETCH_CALCULATE_SHIFT_PRICING_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
    yield put({
      type: UNSET_LOADER,
    });
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while fetching calculate shift pricing" },
    });
    yield put({
      type: actionTypes.FETCH_CALCULATE_SHIFT_PRICING_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
    yield put({
      type: UNSET_LOADER,
    });
  }
}

function* getEventForVet(action) {
  const session = yield Auth.currentSession();
  try {
    const response = yield axios.post(
      window.RooConfig.API_URL + "api/vet/event?userType=1",
      action.payload,
      {
        headers: {
          "x-access-token": "Bearer " + session.idToken.jwtToken,
        },
      }
    );
    if (response.data.status_code === 200) {
      yield put({
        type: actionTypes.GET_EVENT_FOR_VET_SUCCESS,
        payload: response.data.data,
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting event for vet" } });
    yield put({
      type: actionTypes.GET_EVENT_FOR_VET_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* getVetPaymentInfoAsync() {
  try {
    const vetId = localStorage.getItem("vetId");
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + `api/vet/getVetPaymentInfo/${vetId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = yield response.json();

    if (data.status_code === 200) {
      yield put({
        type: actionTypes.GET_VET_PAYMENT_INFO_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_VET_PAYMENT_INFO_FAIL,
        payload: { status_code: data.status_code, message: data.data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting vet payment info" } });
    yield put({
      type: actionTypes.GET_VET_PAYMENT_INFO_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* updateVetDetailsPaymentInfo(action) {
  try {
    const response = yield call(fetch, window.RooConfig.API_URL + "api/vet/updateConnectAccount", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(action.payload),
    });
    const data = yield response.json();
    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      yield put({
        type: actionTypes.UPDATE_VET_DETAILS_PAYMENT_INFO_SUCCESS,
        payload: { commonData: data.data },
      });
    } else if (data.status_code === 400 || data.status_code === 500) {
      yield put({
        type: actionTypes.UPDATE_VET_DETAILS_PAYMENT_INFO_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating vet details payment info" },
    });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: actionTypes.FETCH_COMMON_DATA_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* createVetPromoTracking(action) {
  try {
    const response = yield call(fetch, window.RooConfig.API_URL + "api/promoTracking", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(action.payload),
    });
    const data = yield response.json();
    yield put({ type: UNSET_LOADER });

    if (data === true) {
      yield put({
        type: actionTypes.CREATE_VET_PROMO_TRACKING_SUCCESS,
        payload: true,
      });
      logBrazeCustomEvent(PROMO_CLAIMED, { promo_id: action.payload.promo_id });
    } else {
      yield put({
        type: actionTypes.CREATE_VET_PROMO_TRACKING_FAIL,
        payload: JSON.parse(data).message,
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while creating vet promo tracking" },
    });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: actionTypes.CREATE_VET_PROMO_TRACKING_FAIL,
      payload: errorMessage.EXCEPTION_MESSAGE,
    });
  }
}

function* getVetActivePromosByUserId(action) {
  try {
    const userId = action.payload;
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + `api/promoTracking/active/${userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = yield response.json();
    yield put({ type: UNSET_LOADER });

    if (data) {
      yield put({
        type: actionTypes.GET_VET_PROMO_TRACKING_BY_USER_ID_SUCCESS,
        payload: data,
      });
    } else {
      yield put({
        type: actionTypes.GET_VET_PROMO_TRACKING_BY_USER_ID_FAIL,
        payload: { message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting vet active promos by user id" },
    });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: actionTypes.GET_VET_PROMO_TRACKING_BY_USER_ID_FAIL,
      payload: { message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* getVetCompletedPromos(action) {
  try {
    const userId = action.payload.userId;
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + `api/promoTracking/allByUser/${userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = yield response.json();
    yield put({ type: UNSET_LOADER });

    if (data.length) {
      const completedPromos = data.filter((promo) => promo.promoCompletedDate !== null);
      yield put({
        type: actionTypes.GET_VET_COMPLETED_PROMOS_SUCCESS,
        payload: completedPromos,
      });
    } else if (data) {
      yield put({
        type: actionTypes.GET_VET_COMPLETED_PROMOS_SUCCESS,
        payload: [],
      });
    } else {
      yield put({
        type: actionTypes.GET_VET_COMPLETED_PROMOS_FAIL,
        payload: [],
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting vet completed promos" },
    });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: actionTypes.GET_VET_COMPLETED_PROMOS_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* getVetBadgesAsync(action) {
  try {
    const vetId = action.payload;
    const response = yield call(fetch, window.RooConfig.API_URL + `api/vet/getVetBadges/${vetId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const badgesResult = yield response.json();
    if (badgesResult.data) {
      yield put({
        type: actionTypes.GET_VET_BADGES_SUCCESS,
        payload: badgesResult.data,
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting vet badges" } });
    yield put({
      type: actionTypes.GET_VET_BADGES_FAIL,
    });
  }
}

function* getVetConfirmedShiftData(action) {
  try {
    const vetId = action.payload.vetId;
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + `api/vet/getVetConfirmedShiftData/${vetId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = yield response.json();
    yield put({ type: UNSET_LOADER });

    if (data) {
      yield put({
        type: actionTypes.GET_VET_CONFIRMED_SHIFT_DATA_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_VET_CONFIRMED_SHIFT_DATA_FAIL,
        payload: {},
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting vet confirmed shift data" },
    });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: actionTypes.GET_VET_COMPLETED_PROMOS_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* vetSkipW9Modal(action) {
  try {
    const vetId = action.payload.vetId;
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + `api/vet/vetSkipW9Modal/${vetId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = yield response.json();
    yield put({ type: UNSET_LOADER });

    if (data) {
      yield put({
        type: actionTypes.VET_SKIP_W9_MODAL_SUCCESS,
        payload: true,
      });
    } else {
      yield put({
        type: actionTypes.VET_SKIP_W9_MODAL_FAIL,
        payload: false,
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while vet skip w9 modal" } });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: actionTypes.VET_SKIP_W9_MODAL_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* deferHospitalRatingAsync(action) {
  try {
    const vetId = parseInt(localStorage.getItem("vetId"));
    const response = yield call(fetch, window.RooConfig.API_URL + "api/vet/deferRating", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();
    if (data.status_code === 200) {
      EventTracker.send(Event.Name.VET_SKIP_REVIEW, Event.Type.CLICK, Event.Entity.VET, vetId);
      yield put({
        type: actionTypes.UPDATE_CURRENT_HOSPITAL_RATING_BY_VET_INDEX,
      });
      yield put({
        type: actionTypes.GET_PENDING_VET_RATINGS_REQUEST,
        payload: { isGetAllReviews: 1 },
      });
      yield put({ type: UNSET_LOADER });
      yield put({
        type: actionTypes.DEFER_HOSPITAL_RATING_SUCCESS,
        payload: { status: data.status_code },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.DEFER_HOSPITAL_RATING_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while deferring hospital rating" } });
    yield put({
      type: actionTypes.DEFER_HOSPITAL_RATING_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* deferAllHospitalRatingsAsync(action) {
  try {
    const response = yield call(fetch, window.RooConfig.API_URL + "api/vet/deferAllRatings", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();
    if (data.status_code === 200) {
      EventTracker.send(
        Event.Name.VET_SKIP_ALL_REVIEWS,
        Event.Type.CLICK,
        Event.Entity.VET,
        action.payload.vetId
      );

      yield put({
        type: actionTypes.GET_PENDING_VET_RATINGS_REQUEST,
        payload: { isGetAllReviews: 1 },
      });
      yield put({ type: UNSET_LOADER });
      yield put({
        type: actionTypes.DEFER_ALL_HOSPITAL_RATINGS_SUCCESS,
        payload: { status: data.status_code },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.DEFER_ALL_HOSPITAL_RATINGS_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while deferring all hospital ratings for vet" },
    });
    yield put({
      type: actionTypes.DEFER_ALL_HOSPITAL_RATINGS_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* saveShiftTotalCount(action) {
  try {
    const totalCount = action.payload;

    yield put({
      type: actionTypes.SAVE_SHIFT_COUNT_SUCCESS,
      payload: { totalCount },
    });
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while saving shift total count" } });
    yield put({
      type: actionTypes.SAVE_SHIFT_COUNT_FAIL,
      payload: { message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* setDashboardView(action) {
  try {
    if (action.payload) {
      yield put({
        type: actionTypes.SET_DASHBOARD_VIEW_SUCCESS,
        payload: action.payload,
      });
    } else {
      yield put({
        type: actionTypes.SET_DASHBOARD_VIEW_FAIL,
        payload: null,
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while setting dashboard view" } });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: actionTypes.SET_DASHBOARD_VIEW_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* determineNonRequestSurveyEligibility(action) {
  try {
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + `api/vet/getNonRequestSurvey/${action.payload}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = yield response.json();
    if (data.data) {
      yield put({
        type: actionTypes.GET_NON_REQUEST_SURVEY_ELIGIBILITY_SUCCESS,
        payload: data.data.isEligibleForSurvey,
      });
    } else {
      yield put({
        type: actionTypes.GET_NON_REQUEST_SURVEY_ELIGIBILITY_FAIL,
        payload: null,
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while determining non request survey eligibility" },
    });
    yield put({
      type: actionTypes.GET_NON_REQUEST_SURVEY_ELIGIBILITY_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* vetSubmitSurvey(action) {
  try {
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/user/insertUserSurveyResponse",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();

    if (data.status_code === 200) {
      yield put({
        type: actionTypes.VET_SUBMIT_SURVEY_SUCCESS,
        payload: { status: data.status_code },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.VET_SUBMIT_SURVEY_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while vet submitting survey" } });
    yield put({
      type: actionTypes.VET_SUBMIT_SURVEY_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* setIsInitialMapSortComplete() {
  try {
    yield put({
      type: actionTypes.SET_IS_INITIAL_MAP_SORT_COMPLETE_SUCCESS,
    });
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while setting is initial map sort complete" },
    });
    throw error;
  }
}

function* getVetPromoShifts(action) {
  try {
    const { vetId, promoId } = action.payload;
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + `api/promo/${promoId}/shifts?contractorId=${vetId}&isVet=${true}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = yield response.json();
    if (data) {
      yield put({
        type: actionTypes.GET_VET_PROMO_SHIFTS_SUCCESS,
        payload: data,
      });
    } else {
      yield put({
        type: actionTypes.GET_VET_PROMO_SHIFTS_FAIL,
        payload: null,
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting vet promo shifts" } });
    yield put({
      type: actionTypes.GET_VET_PROMO_SHIFTS_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* toggleVetPromoShiftPanel(action) {
  try {
    const { promoName } = action.payload;
    if (promoName) {
      yield put({
        type: actionTypes.TOGGLE_VET_PROMO_SHIFTS_PANEL_OPEN,
        payload: promoName,
      });
    } else {
      yield put({
        type: actionTypes.TOGGLE_VET_PROMO_SHIFTS_PANEL_CLOSED,
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while toggling vet promo shift panel" },
    });
    yield put({
      type: actionTypes.TOGGLE_VET_PROMO_SHIFTS_PANEL_CLOSED,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* deleteVetShiftRequest(action) {
  try {
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/hospital/shift/deleteShiftRequest",
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();
    queryClient.invalidateQueries(["shift", action?.payload?.shiftGroupId]);
    if (data && response.ok) {
      yield put({
        type: actionTypes.DELETE_VET_SHIFT_REQUEST_SUCCESS,
        payload: action.payload.shiftId,
      });
    } else {
      yield put({
        type: actionTypes.DELETE_VET_SHIFT_REQUEST_FAIL,
        payload: {
          status: response.status,
          message: data.message || errorMessage.EXCEPTION_MESSAGE,
        },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while deleting vet shift request" },
    });
    yield put({
      type: actionTypes.DELETE_VET_SHIFT_REQUEST_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* cancelConvirmedVetShift(action) {
  try {
    const response = yield call(fetch, window.RooConfig.API_URL + "api/vet/shift/removeVet", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: actionTypes.CANCEL_CONFIRMED_VET_SHIFT_SUCCESS,
        payload: action.payload.shiftId,
      });
    } else {
      yield put(
        showToast({
          variant: "error",
          message: "Failed to cancel shift",
          description: data?.message || "An unexpected error occured",
        })
      );
      yield put({
        type: actionTypes.CANCEL_CONFIRMED_VET_SHIFT_FAIL,
        payload: null,
      });
    }
  } catch (error) {
    yield put({ type: UNSET_LOADER });
    Sentry.captureException(error, {
      extra: { context: "Error while cancelling confirmed vet shift" },
    });
    yield put({
      type: actionTypes.CANCEL_CONFIRMED_VET_SHIFT_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* toggleVetDeleteShiftRequestPanel(action) {
  try {
    const { deleteShiftRequestDetails } = action.payload;
    delete deleteShiftRequestDetails.techs;
    if (deleteShiftRequestDetails) {
      yield put({
        type: actionTypes.TOGGLE_VET_DELETE_SHIFT_REQUEST_PANEL_OPEN,
        payload: deleteShiftRequestDetails,
      });
    } else {
      yield put({
        type: actionTypes.TOGGLE_VET_DELETE_SHIFT_REQUEST_PANEL_CLOSED,
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while toggling vet delete shift request panel" },
    });
    yield put({
      type: actionTypes.TOGGLE_VET_DELETE_SHIFT_REQUEST_PANEL_CLOSED,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* setDashboardViewStateDetails(action) {
  switch (action.payload) {
    case "recommendations":
      yield put({
        type: actionTypes.SET_VET_DASHBOARD_RECS_VIEW,
      });
      break;
    case "map":
      yield put({
        type: actionTypes.SET_VET_DASHBOARD_MAP_VIEW,
      });
      break;
    case "grid":
      yield put({
        type: actionTypes.SET_VET_DASHBOARD_GRID_VIEW,
      });
      break;
    case "calendar":
      yield put({
        type: actionTypes.SET_VET_DASHBOARD_CALENDAR_VIEW,
      });
      break;
    default:
      break;
  }
}

function* getShiftRecommendationsAsync() {
  try {
    const urlSearchParams = new URLSearchParams({
      vetId: localStorage.getItem("vetId"),
      shiftCount: "9",
    });
    const response = yield call(
      fetch,
      window.RooConfig.API_URL +
        "api/shiftRecommendation/getShiftRecommendations?" +
        urlSearchParams,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = yield response.json();
    if (data.status_code === 200 && data.data.data) {
      const shiftData = data.data.data.map((shift) => ({
        ...shift,
        shiftStartDatetime: shift.shiftStartTime,
        shiftEndDatetime: shift.shiftEndTime,
      }));
      yield put({
        type: actionTypes.GET_SHIFT_RECOMMENDATIONS_SUCCESS,
        payload: shiftData,
      });
    } else {
      Sentry.captureException(
        `Error while getting shift recommendations: ${data.status_code} api error`
      );
      yield put({
        type: actionTypes.GET_SHIFT_RECOMMENDATIONS_FAIL,
        payload: undefined,
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting shift recommendations" },
    });
    yield put({
      type: actionTypes.GET_SHIFT_RECOMMENDATIONS_FAIL,
      payload: undefined,
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootVetDashboardSaga() {
  yield takeEvery(actionTypes.SEARCH_SHIFT_REQUEST, searchShiftAsync);
  yield takeEvery(actionTypes.GET_LINKED_SHIFT_REQUEST, getLinkedShiftAsync);
  yield takeEvery(actionTypes.VET_REQUEST_SHIFT_REQUEST, requestShiftAsync);
  yield takeEvery(actionTypes.FETCH_VET_FIRST_TIMER_INFO_REQUEST, fetchFirstTimerInfoAsync);
  yield takeEvery(actionTypes.UPDATE_VET_FIRST_TIMER_INFO_REQUEST, updateVetFirstTimerInfoAsync);
  yield takeEvery(actionTypes.GET_PENDING_VET_RATINGS_REQUEST, getPendingRatingsAsync);
  yield takeEvery(actionTypes.SUBMIT_VET_W9_REQUEST, submitVetW9Async);
  yield takeEvery(actionTypes.GET_ALL_MY_SHIFTS_REQUEST, getAllMyShiftsAsync);
  yield takeEvery(HIDE_ALERT_FORM_POP_UP, updateAlertFormPopCountAsync);
  yield takeEvery(actionTypes.CHECK_VET_LICENSE_REQUEST, checkVetLicenseAsync);
  yield takeEvery(actionTypes.CHECK_VET_LICENSE_EXPIRING_REQUEST, checkVetLicenseExpiringAsync);
  yield takeEvery(actionTypes.CHECK_VET_LICENSE_EXPIRED_REQUEST, checkVetLicenseExpiredAsync);
  yield takeEvery(actionTypes.FETCH_VET_SHIFT_DETAILS_REQUEST, getShiftDetailsAsync);
  yield takeEvery(actionTypes.FETCH_COMMON_DATA_REQUEST, fetchCommonDataAsync);
  yield takeEvery(actionTypes.FETCH_CALCULATE_SHIFT_PRICING, fetchCalculateShiftPricingAsync);
  yield takeEvery(actionTypes.GET_EVENT_FOR_VET, getEventForVet);
  yield takeEvery(actionTypes.GET_VET_PAYMENT_INFO, getVetPaymentInfoAsync);
  yield takeEvery(actionTypes.UPDATE_VET_DETAILS_PAYMENT_INFO, updateVetDetailsPaymentInfo);
  yield takeEvery(actionTypes.CREATE_VET_PROMO_TRACKING, createVetPromoTracking);
  yield takeEvery(actionTypes.GET_VET_PROMO_TRACKING_BY_USER_ID, getVetActivePromosByUserId);
  yield takeEvery(actionTypes.GET_VET_COMPLETED_PROMOS, getVetCompletedPromos);
  yield takeEvery(actionTypes.GET_VET_BADGES, getVetBadgesAsync);
  yield takeEvery(actionTypes.GET_VET_CONFIRMED_SHIFT_DATA, getVetConfirmedShiftData);
  yield takeEvery(actionTypes.VET_SKIP_W9_MODAL, vetSkipW9Modal);
  yield takeEvery(actionTypes.DEFER_HOSPITAL_RATING, deferHospitalRatingAsync);
  yield takeEvery(actionTypes.DEFER_ALL_HOSPITAL_RATINGS, deferAllHospitalRatingsAsync);
  yield takeEvery(actionTypes.SAVE_SHIFT_COUNT, saveShiftTotalCount);
  yield takeEvery(actionTypes.SET_DASHBOARD_VIEW, setDashboardView);
  yield takeEvery(actionTypes.SET_DASHBOARD_VIEW, setDashboardViewStateDetails);
  yield takeEvery(
    actionTypes.GET_NON_REQUEST_SURVEY_ELIGIBILITY,
    determineNonRequestSurveyEligibility
  );
  yield takeEvery(actionTypes.VET_SUBMIT_SURVEY, vetSubmitSurvey);
  yield takeEvery(actionTypes.SET_IS_INITIAL_MAP_SORT_COMPLETE, setIsInitialMapSortComplete);
  yield takeEvery(actionTypes.GET_VET_PROMO_SHIFTS, getVetPromoShifts);
  yield takeEvery(actionTypes.TOGGLE_VET_PROMO_SHIFTS_PANEL, toggleVetPromoShiftPanel);
  yield takeEvery(actionTypes.DELETE_VET_SHIFT_REQUEST, deleteVetShiftRequest);
  yield takeEvery(
    actionTypes.TOGGLE_VET_DELETE_SHIFT_REQUEST_PANEL,
    toggleVetDeleteShiftRequestPanel
  );
  yield takeEvery(actionTypes.CANCEL_CONFIRMED_VET_SHIFT, cancelConvirmedVetShift);
  yield takeEvery(actionTypes.FETCH_SHIFT_RECOMMENDATIONS, getShiftRecommendationsAsync);
}
