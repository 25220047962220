export const FETCH_STUDENT_PROFILE_REQUEST = "FETCH_STUDENT_PROFILE_REQUEST";
export const FETCH_STUDENT_PROFILE_SUCCESS = "FETCH_STUDENT_PROFILE_SUCCESS";
export const FETCH_STUDENT_PROFILE_FAIL = "FETCH_STUDENT_PROFILE_FAIL";

export const UPDATE_STUDENT_PROFILE_REQUEST = "UPDATE_STUDENT_PROFILE_REQUEST";
export const UPDATE_STUDENT_PROFILE_SUCCESS = "UPDATE_STUDENT_PROFILE_SUCCESS";
export const UPDATE_STUDENT_PROFILE_FAIL = "UPDATE_STUDENT_PROFILE_FAIL";

export const FETCH_STUDENT_SETTINGS_BASIC_INFO_REQUEST =
  "FETCH_STUDENT_SETTINGS_BASIC_INFO_REQUEST";
export const FETCH_STUDENT_SETTINGS_BASIC_INFO_SUCCESS =
  "FETCH_STUDENT_SETTINGS_BASIC_INFO_SUCCESS";
export const FETCH_STUDENT_SETTINGS_BASIC_INFO_FAIL = "FETCH_STUDENT_SETTINGS_BASIC_INFO_FAIL";

export const UPDATE_STUDENT_SETTINGS_REQUEST = "UPDATE_STUDENT_SETTINGS_REQUEST";
export const UPDATE_STUDENT_SETTINGS_SUCCESS = "UPDATE_STUDENT_SETTINGS_SUCCESS";
export const UPDATE_STUDENT_SETTINGS_FAIL = "UPDATE_STUDENT_SETTINGS_FAIL";

export const RESET_STUDENT_SETTINGS_INFO_UPDATE_SUCCESS =
  "RESET_STUDENT_SETTINGS_INFO_UPDATE_SUCCESS";

export const SEARCH_INTERNSHIPS_REQUEST = "SEARCH_INTERNSHIPS_REQUEST";
export const SEARCH_INTERNSHIPS_SUCCESS = "SEARCH_INTERNSHIPS_SUCCESS";
export const SEARCH_INTERNSHIPS_FAIL = "SEARCH_INTERNSHIPS_FAIL";

export const SEARCH_EXTERNSHIPS_REQUEST = "SEARCH_EXTERNSHIPS_REQUEST";
export const SEARCH_EXTERNSHIPS_SUCCESS = "SEARCH_EXTERNSHIPS_SUCCESS";
export const SEARCH_EXTERNSHIPS_FAIL = "SEARCH_EXTERNSHIPS_FAIL";

export const SET_STUDENT_DASHBOARD_GRID_VIEW = "SET_STUDENT_DASHBOARD_GRID_VIEW";
export const SET_STUDENT_DASHBOARD_MAP_VIEW = "SET_STUDENT_DASHBOARD_MAP_VIEW";

export const SAVE_SEARCH_INTERNSHIP_PARAMETERS = "SAVE_SEARCH_INTERNSHIP_PARAMETERS";
export const SAVE_SEARCH_EXTERNSHIP_PARAMETERS = "SAVE_SEARCH_INTERNSHIP_PARAMETERS";

export const INITIALIZE_INTERNSHIP_SEARCH_RESULTS = "INITIALIZE_INTERNSHIP_SEARCH_RESULTS";
export const INITIALIZE_EXTERNSHIP_SEARCH_RESULTS = "INITIALIZE_EXTERNSHIP_SEARCH_RESULTS";

export const GET_ACTIVE_INTERNSHIPS_REQUEST = "GET_ACTIVE_INTERNSHIPS_REQUEST";
export const GET_ACTIVE_INTERNSHIPS_SUCCESS = "GET_ACTIVE_INTERNSHIPS_SUCCESS";
export const GET_ACTIVE_INTERNSHIPS_FAIL = "GET_ACTIVE_INTERNSHIPS_FAIL";

export const GET_ACTIVE_EXTERNSHIPS_REQUEST = "GET_ACTIVE_EXTERNSHIPS_REQUEST";
export const GET_ACTIVE_EXTERNSHIPS_SUCCESS = "GET_ACTIVE_EXTERNSHIPS_SUCCESS";
export const GET_ACTIVE_EXTERNSHIPS_FAIL = "GET_ACTIVE_EXTERNSHIPS_FAIL";

export const FETCH_HOSPITAL_VIEW_INTERNSHIP_PROFILE_REQUEST =
  "FETCH_HOSPITAL_VIEW_INTERNSHIP_PROFILE_REQUEST";
export const FETCH_HOSPITAL_VIEW_INTERNSHIP_PROFILE_SUCCESS =
  "FETCH_HOSPITAL_VIEW_INTERNSHIP_PROFILE_SUCCESS";
export const FETCH_HOSPITAL_VIEW_INTERNSHIP_PROFILE_FAIL =
  "FETCH_HOSPITAL_VIEW_INTERNSHIP_PROFILE_FAIL";

export const REQUEST_INTERNSHIP_REQUEST = "REQUEST_INTERNSHIP_REQUEST";
export const REQUEST_INTERNSHIP_SUCCESS = "REQUEST_INTERNSHIP_SUCCESS";
export const REQUEST_INTERNSHIP_FAIL = "REQUEST_INTERNSHIP_FAIL";

export const REQUEST_EXTERNSHIP_REQUEST = "REQUEST_EXTERNSHIP_REQUEST";
export const REQUEST_EXTERNSHIP_SUCCESS = "REQUEST_EXTERNSHIP_SUCCESS";
export const REQUEST_EXTERNSHIP_FAIL = "REQUEST_EXTERNSHIP_FAIL";

export const CLEAR_INTERNSHIP_REQUEST_FLAG = "CLEAR_INTERNSHIP_REQUEST_FLAG";

export const CLEAR_EXTERNSHIP_REQUEST_FLAG = "CLEAR_EXTERNSHIP_REQUEST_FLAG";
