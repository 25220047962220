import { APPROVED } from "../../constants/userStatusTypes";
import { USER_TYPE, UserType } from "../../constants/UserTypeConstants";
import { DatabaseUpdate, RooResponse } from "../common/response";
import { put } from "../common/rooFetch";

export type AutoApproveParams = Pick<AutoApproveBody, "userId" | "emailId">;

export type AutoApproveBody = {
  userId: number;
  emailId: string;
  statusType: number;
  userType: UserType;
};

export const autoApprove = async ({ userId, emailId }: AutoApproveParams) =>
  put<AutoApproveBody, RooResponse<DatabaseUpdate[]>>(
    `api/user/auto-approve/${userId}`,
    {
      userId,
      emailId,
      statusType: APPROVED,
      userType: USER_TYPE.VET_USER_TYPE,
    },
    true
  );
