export const CACHE = {
  USER_PROMOS: "userPromos",
  GET_STATES: "getStates",
  GET_PENDING_AUTO_APPROVALS: "getPendingAutoApprovals",
  APPROVE_PENDING_SHIFT_REQUESTS: "approvePendingShiftRequests",
  REJECT_PENDING_SHIFT_REQUESTS: "rejectPendingShiftRequests",
  GET_ENTERPRISES: "getEnterprises",
  GET_HOSPITAL_GROUPS: "getHospitalGroups",
  GET_HOSPITAL_GROUP_ADMINS: "getHospitalGroupAdmins",
  ALL_BY_PROMO: "allByPromo",
  PROMO_DETAILS_BY_ID: "promoDetailsById",
  CREATE_PROMO: "createPromo",
  UPDATE_PROMO: "updatePromo",
  ALL_PROMOS: "allPromos",
  PAST_PROMOS: "pastPromos",
  CONTACT_OWNER: "contactOwner",
  SIGN_IC_AGREEMENT: "signICAgreement",
  ACCEPT_TERMS: "acceptTerms",
  GET_USER_INFO: "getUserInfo",
  RESTRICTED_EMAIL_DOMAINS: "restrictedEmailDomains",
  AVAILABLE_HOSPITAL_SHIFTS: "hospitalAvailableShifts",
  SHIFT: "shift",
  GET_NOTIFICATION_SETTINGS: "getNotificationSettings",
  GET_NOTIFICATION_ATTRIBUTES: "getNotificationAttributes",
} as const;

export const ADMIN_CACHE = {
  CONTACT_OWNERS: "api/admin/contactOwners",
  PENDING_VET_SHIFTS: "api/admin/pendingShifts",
  PAGINATED_USERS: "api/admin/users",
  SEARCH_HISTORY: "api/user/searchHistory",
};
