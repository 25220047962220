import * as C from "../../constants/calendar";

export const getShiftDetailsAction = (data) => {
  return {
    type: C.GET_SHIFT_DETAILS,
    payload: data,
  };
};

export const confirmVet = (data) => {
  return {
    type: C.CONFIRM_VET_SHIFT_REQUEST,
    payload: data,
  };
};

export const confirmVetNoModal = (data) => {
  return {
    type: C.CONFIRM_VET_SHIFT_NO_MODAL,
    payload: data,
  };
};

export function confirmShift(data) {
  return {
    type: C.CONFIRM_VET_SHIFT_REQUEST,
    payload: data,
  };
}

export function renewShiftAction(data) {
  return {
    type: C.RENEW_SHIFT,
    payload: data,
  };
}

export function renewMultipleShiftsAction(data) {
  return {
    type: C.RENEW_MULTIPLE_SHIFTS,
    payload: data,
  };
}

export function deleteShiftAction(data) {
  return {
    type: C.DELETE_SHIFT,
    payload: data,
  };
}

export function initializeState() {
  return {
    type: C.INITIALIZE_STATE,
  };
}

export function initializePopUpState() {
  return {
    type: C.INITIALIZE_POPUP_STATE,
  };
}

export function renderLoader() {
  return {
    type: C.SHOW_LOADER,
  };
}

export function closeShiftActionResultMessageModal() {
  return {
    type: C.CLOSE_SHIFT_ACTION_RESULT_MESSAGE_MODAL,
  };
}

// --- tech related

export const getTechShiftDetailsAction = (shiftGroupId) => {
  return {
    type: C.GET_TECH_SHIFT_DETAILS,
    payload: shiftGroupId,
  };
};

export const confirmTech = (data) => {
  return {
    type: C.CONFIRM_TECH_SHIFT_REQUEST,
    payload: data,
  };
};
export const confirmTechNoModal = (data) => {
  return {
    type: C.CONFIRM_TECH_SHIFT_NO_MODAL,
    payload: data,
  };
};

export function confirmTechShift(data) {
  return {
    type: C.CONFIRM_TECH_SHIFT_REQUEST,
    payload: data,
  };
}

export function deleteTechShiftAction(data) {
  return {
    type: C.DELETE_TECH_SHIFT,
    payload: data,
  };
}

// --- end tech related

export function getCalendarNotesAction(data) {
  return {
    type: C.GET_CALENDAR_NOTES,
    payload: data,
  };
}

export function declineRequestAction(data) {
  return {
    type: C.DECLINE_REQUEST,
    payload: data,
  };
}

export function deleteTechRequestAction(data) {
  return {
    type: C.DECLINE_TECH_REQUEST,
    payload: data,
  };
}

export function clearRenewMultipleShiftsData() {
  return {
    type: C.CLEAR_RENEW_MULTIPLE_SHIFTS_DATA,
  };
}

export function getHospitalLatestShiftDetailsAction(data = {}) {
  return {
    type: C.GET_HOSPITAL_LATEST_SHIFT_DETAILS,
    payload: data,
  };
}

export function resetPanelShiftActionflags() {
  return {
    type: C.RESET_PANEL_SHIFT_ACTION_FLAGS,
  };
}
