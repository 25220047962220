import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import PageLoader from "../../../../Common/PageLoader";
import { useConferenceEvent } from "../../../hooks/useConferenceEvent";

export const EventSignUpRedirectContainer = () => {
  const { eventSlug, isLoading, isError } = useConferenceEvent();
  const history = useHistory();
  const isBooth = new URLSearchParams(window.location.search).get("booth") === "true";

  const urlParamsWithUtm = new URLSearchParams({
    utm_source: "event",
    utm_campaign: eventSlug,
    utm_medium: isBooth ? "booth_device" : "personal_device",
    booth: String(isBooth),
  }).toString();

  useEffect(() => {
    if (!isLoading) {
      history.push(isError ? "/login" : `/e/${eventSlug}?${urlParamsWithUtm}`);
    }
  }, [isLoading, isError, eventSlug, urlParamsWithUtm, history]);

  return <PageLoader />;
};
