import React from "react";

import { getCalendlyLinkPrefilled, useCalendlyEventListener } from "./service";
import { CalendlyIframe, CalendlyWrapper } from "./styles";

export type CalendlyViewProps = {
  calendlyLink: string;
  email?: string;
  name?: string;
  phone?: string;
  hospitalName?: string;
  utmSource?: string;
  loading?: boolean;
  onEventScheduled?: () => void;
  onDateAndTimeSelected?: () => void;
  onProfilePageViewed?: () => void;
  onEventTypeViewed?: () => void;
};

export const CalendlyView = React.forwardRef<HTMLIFrameElement, CalendlyViewProps>(
  (
    {
      calendlyLink,
      email,
      name,
      phone,
      hospitalName,
      loading,
      utmSource,
      onEventScheduled,
      onDateAndTimeSelected,
      onEventTypeViewed,
      onProfilePageViewed,
    },
    ref
  ) => {
    useCalendlyEventListener({
      onEventScheduled,
      onDateAndTimeSelected,
      onEventTypeViewed,
      onProfilePageViewed,
    });
    const calendlyLinkPrefilled = getCalendlyLinkPrefilled({
      calendlyLink,
      email,
      phone,
      name,
      hospitalName,
      utmSource,
    });

    if (loading) {
      return <div />;
    }
    return (
      <CalendlyWrapper>
        <CalendlyIframe src={calendlyLinkPrefilled} ref={ref} id="calendyIframe" />
      </CalendlyWrapper>
    );
  }
);
CalendlyView.displayName = "CalendlyView";
