import * as Sentry from "@sentry/react";
import "isomorphic-fetch";
import { call, put, takeEvery } from "redux-saga/effects";

import { GET, POST } from "@roo-dev/roo-node-types";

import { identifyPosthog } from "../../../Common/Wrappers/identifyPosthog";
import * as errorMessage from "../../../constants/errorMessage";
import { UNSET_LOADER } from "../../../constants/loader";
import { UPDATE_USER_PERSONAL_INFO_SUCCESS } from "../../../constants/Login";
import * as C from "../../../constants/registerStudent";
import "../../../helpers/InterceptFetch";
import {
  DeleteStudentPhotoRequestAction,
  DeleteStudentResumeAction,
  FetchEducationExperienceAction,
  FetchGetToKnowAction,
  FetchStudentBasicInformationAction,
  FetchStudentPhotoAndResumeAction,
  SaveEducationExperienceAction,
  SaveGetToKnowAction,
  SaveStudentBasicInformationAction,
  SaveStudentPhotoResLinksAction,
  UploadStudentPhotoRequestAction,
  UploadStudentResumeAction,
} from "../Actions/RegisterStudentActions";

export function* saveStudentBasicInformationAsync(action: SaveStudentBasicInformationAction) {
  try {
    const studentData = {
      ...action.payload,
      stateId: action.payload.stateId,
    };
    if (action.payload.isFromRegistration) {
      studentData.hearAboutRoo = action.payload.hearAboutRoo;
    }
    const response: Response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/student/updateStudentProfile",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(studentData),
      }
    );
    const data: POST.Student.UpdateStudentProfileResponse = yield response.json();
    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      if (data?.data?.metroAreaId) {
        localStorage.setItem("metroAreaId", data.data.metroAreaId?.toString());
        identifyPosthog({ userId: action.payload.userId, metroAreaId: data.data.metroAreaId });
      }

      if (!action.payload.isFromRegistration) {
        yield put({
          type: UPDATE_USER_PERSONAL_INFO_SUCCESS,
          payload: studentData,
        });
      }

      yield put({ type: C.SAVE_STUDENT_BASIC_INFO_SUCCESS, payload: action.payload });
    } else {
      yield put({
        type: C.SAVE_STUDENT_BASIC_INFO_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while saving student basic info: " },
    });
    yield put({
      type: C.SAVE_STUDENT_BASIC_INFO_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* saveStudentEducationExpAsync(action: SaveEducationExperienceAction) {
  try {
    const studentData = action.payload;
    const response: Response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/student/updateStudentProfile",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(studentData),
      }
    );
    const data: POST.Student.UpdateStudentProfileResponse = yield response.json();
    if (data.status_code === 200) {
      yield put({ type: C.SAVE_STUDENT_EDUCATION_EXP_SUCCESS, payload: action.payload });
    } else {
      yield put({
        type: C.SAVE_STUDENT_EDUCATION_EXP_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while saving student education exp: " },
    });
    yield put({
      type: C.SAVE_STUDENT_EDUCATION_EXP_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* saveStudentGetToKnowAsync(action: SaveGetToKnowAction) {
  try {
    const payload = {
      ...action.payload,
      subspecialityInterest: action.payload.subspecialityInterest.join(","),
    };
    const response: Response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/student/updateStudentProfile",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    const data: POST.Student.UpdateStudentProfileResponse = yield response.json();
    if (data.status_code === 200) {
      yield put({ type: C.SAVE_STUDENT_GET_TO_KNOW_SUCCESS, payload: action.payload });
    } else {
      yield put({
        type: C.SAVE_STUDENT_GET_TO_KNOW_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while saving student get to know: " },
    });
    yield put({
      type: C.SAVE_STUDENT_GET_TO_KNOW_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* saveStudentPhotoResLinksAsync(action: SaveStudentPhotoResLinksAction) {
  try {
    const studentData = {
      ...action.payload,
      studentId: action.payload.studentId,
    };
    const response: Response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/student/updateStudentProfile",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(studentData),
      }
    );
    const data: POST.Student.UpdateStudentProfileResponse = yield response.json();
    if (data.status_code === 200) {
      yield put({ type: C.SAVE_STUDENT_PHOTO_RES_LINKS_SUCCESS, payload: action.payload });
    } else {
      yield put({
        type: C.SAVE_STUDENT_PHOTO_RES_LINKS_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while saving student photo and resume links: " },
    });
  }
}

export function* uploadStudentPhotoAsync(action: UploadStudentPhotoRequestAction) {
  const formData = new FormData();
  for (let i = 0; i < action.payload.data.length; i++) {
    formData.append("document", action.payload.data[i] as any);
    formData.append("userId", localStorage.getItem("studentId"));
    formData.append("userType", "student");
    formData.append("fileName", action.payload.fileName);
  }
  try {
    const response: Response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/user/uploadDocument",
      {
        method: "POST",
        body: formData,
      }
    );
    const data: POST.User.UploadDocumentResponse<{
      document: string;
      userId: number;
      userType: "student";
      fileName: string;
    }> = yield response.json();

    if (data.status_code === 200) {
      yield put({
        type: C.UPLOAD_STUDENT_IMAGE_SUCCESS,
        payload: {
          status: data.status_code,
          studentImage: data.data.data,
        },
      });
    } else {
      yield put({
        type: C.UPLOAD_STUDENT_IMAGE_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while uploading student photo: " } });
    yield put({
      type: C.UPLOAD_STUDENT_IMAGE_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* deleteStudentPhotoAsync(action: DeleteStudentPhotoRequestAction) {
  try {
    const response: Response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/user/deleteProfileImages",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload),
      }
    );
    const data: POST.User.DeleteProfileImagesResponse = yield response.json();
    if (data.status_code === 200) {
      yield put({
        type: C.DELETE_STUDENT_IMAGE_SUCCESS,
        payload: { status: data.status_code, studentImage: data.data.data },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: C.DELETE_STUDENT_IMAGE_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while deleting student photo: " } });
    yield put({
      type: C.DELETE_STUDENT_IMAGE_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* getStudentBasicInformationAsync(action: FetchStudentBasicInformationAction) {
  try {
    const response: Response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/student/studentBasicInformation?userId=" + action.userId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data: GET.Student.StudentBasicInformationResponse = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({ type: C.FETCH_STUDENT_BASIC_INFO_SUCCESS, payload: data.data.data[0] });
    } else if (data.status_code === 400) {
      yield put({
        type: C.FETCH_STUDENT_BASIC_INFO_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting student basic info: " },
    });
    yield put({
      type: C.FETCH_STUDENT_BASIC_INFO_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* getStudentEducationExperienceAsync(action: FetchEducationExperienceAction) {
  try {
    const response: Response = yield call(
      fetch,
      window.RooConfig.API_URL +
        "api/student/studentEducationExperience?studentId=" +
        action.studentId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data: GET.Student.StudentEducationExperienceResponse = yield response.json();

    if (data.status_code === 200) {
      yield put({
        type: C.FETCH_STUDENT_EDUCATION_EXP_SUCCESS,
        payload: {
          ...data.data,
        },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: C.FETCH_STUDENT_EDUCATION_EXP_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting student education exp: " },
    });
    yield put({
      type: C.FETCH_STUDENT_EDUCATION_EXP_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* getStudentGetToKnowAsync(action: FetchGetToKnowAction) {
  try {
    const response: Response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/student/studentGetToKnow?userId=" + action.userId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data: GET.Student.StudentGetToKnowResponse = yield response.json();
    if (data.status_code === 200) {
      yield put({ type: C.FETCH_STUDENT_GET_TO_KNOW_SUCCESS, payload: data.data.data[0] });
    } else if (data.status_code === 400) {
      yield put({
        type: C.FETCH_STUDENT_GET_TO_KNOW_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting student get to know: " },
    });
    yield put({
      type: C.FETCH_STUDENT_GET_TO_KNOW_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* getStudentPhotoAndResumeAsync(action: FetchStudentPhotoAndResumeAction) {
  try {
    const response: Response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/student/studentPhotoAndResume?userId=" + action.userId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data: GET.Student.StudentPhotoAndResumeResponse = yield response.json();

    if (data.status_code === 200) {
      yield put({ type: C.FETCH_STUDENT_PHOTO_RES_LINKS_SUCCESS, payload: data.data.data[0] });
    } else if (data.status_code === 400) {
      yield put({
        type: C.FETCH_STUDENT_PHOTO_RES_LINKS_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting student photo and resume links: " },
    });
    yield put({
      type: C.FETCH_STUDENT_PHOTO_RES_LINKS_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* uploadResumeAsync(action: UploadStudentResumeAction) {
  const formData = new FormData();
  const studentId = action.payload.studentId
    ? action.payload.studentId
    : localStorage.getItem("studentId");

  for (let i = 0; i < action.payload.files.length; i++) {
    // check if we have more than 1 dot in file name
    if (action.payload.files[i].name.split(".").length > 2) {
      const actual = action.payload.files[i].name.split(".");
      const extention = actual[actual.length - 1];
      actual.splice(actual.length - 1, 1);
      formData.append("fileName", actual.join("_") + "." + extention);
    }
    formData.append("document", action.payload.files[i]);
    formData.append("userId", studentId?.toString()); // yes studentId , key name "userId" is used for s3 storage folder name
    formData.append("userType", "student");
    formData.append("uploadFileType", "resume");
  }
  try {
    const response: Response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/user/uploadDocument",
      {
        method: "POST",
        body: formData,
      }
    );
    const data: POST.User.UploadDocumentResponse<{
      document: string;
      userId: number;
      userType: "student";
      uploadFileType: "resume";
    }> = yield response.json();

    if (data.status_code === 200) {
      yield put({
        type: C.UPLOAD_STUDENT_RESUME_SUCCESS,
        payload: { status: data.status_code, studentResume: data.data.data },
      });
    } else {
      yield put({
        type: C.UPLOAD_STUDENT_RESUME_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while uploading student resume: " },
    });
    yield put({
      type: C.UPLOAD_STUDENT_RESUME_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* deleteResumeAsync(action: DeleteStudentResumeAction) {
  try {
    const response: Response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/user/deleteResume",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload),
      }
    );
    const data: POST.User.DeleteResumeResponse = yield response.json();
    if (data.status_code === 200) {
      yield put({
        type: C.DELETE_STUDENT_RESUME_SUCCESS,
        payload: { status: data.status_code, studentResume: data.data.data },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: C.DELETE_STUDENT_RESUME_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while deleting student resume: " } });
    yield put({
      type: C.DELETE_STUDENT_RESUME_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootRegisterStudentSaga() {
  yield takeEvery(C.SAVE_STUDENT_BASIC_INFO, saveStudentBasicInformationAsync);
  yield takeEvery(C.SAVE_STUDENT_EDUCATION_EXP, saveStudentEducationExpAsync);
  yield takeEvery(C.SAVE_STUDENT_GET_TO_KNOW, saveStudentGetToKnowAsync);
  yield takeEvery(C.SAVE_STUDENT_PHOTO_RES_LINKS, saveStudentPhotoResLinksAsync);
  yield takeEvery(C.FETCH_STUDENT_BASIC_INFO, getStudentBasicInformationAsync);
  yield takeEvery(C.FETCH_STUDENT_EDUCATION_EXP, getStudentEducationExperienceAsync);
  yield takeEvery(C.FETCH_STUDENT_GET_TO_KNOW, getStudentGetToKnowAsync);
  yield takeEvery(C.UPLOAD_STUDENT_IMAGE_REQUEST, uploadStudentPhotoAsync);
  yield takeEvery(C.DELETE_STUDENT_IMAGE_REQUEST, deleteStudentPhotoAsync);
  yield takeEvery(C.FETCH_STUDENT_PHOTO_RES_LINKS, getStudentPhotoAndResumeAsync);
  yield takeEvery(C.UPLOAD_STUDENT_RESUME_REQUEST, uploadResumeAsync);
  yield takeEvery(C.DELETE_STUDENT_RESUME_REQUEST, deleteResumeAsync);
}
