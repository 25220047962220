import moment from "moment-timezone"; // same as moment, but with additional timezone features needed when shared code with back end

import { TIMEZONE } from "../constants/timeZoneOffset";
import "../Types/date.extensions";

const oMonthNames: Record<number, string> = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

export const monthNameAndAbbreviations = [
  { name: "January", shortName: "Jan" },
  { name: "February", shortName: "Feb" },
  { name: "March", shortName: "Mar" },
  { name: "April", shortName: "Apr" },
  { name: "May", shortName: "May" },
  { name: "June", shortName: "Jun" },
  { name: "July", shortName: "Jul" },
  { name: "August", shortName: "Aug" },
  { name: "September", shortName: "Sept" },
  { name: "October", shortName: "Oct" },
  { name: "November", shortName: "Nov" },
  { name: "December", shortName: "Dec" },
];

export const monthAbreviations = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export const aMonthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const dayAbreviations = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export function getMonthAbreviations() {
  return [...monthAbreviations];
}

export function getMonthNumberLabels() {
  const a = [];
  for (let i = 1; i <= 12; i++) {
    a.push(String(i));
  }
  return a;
}

export function getExtractedDateTime(startDateTime: number, endDateTime?: number) {
  const objStartDateTime = new Date(0);
  const objEndDateTime = new Date(0);

  objStartDateTime.setUTCSeconds(startDateTime);
  objEndDateTime.setUTCSeconds(endDateTime);

  return {
    startDate: objStartDateTime,
    endDate: objEndDateTime,
    startTime: objStartDateTime.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }),
    endTime: objEndDateTime.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }),
  };
}

export function getCurrentTimeZone() {
  return new Date().getTimezoneOffset();
}
type TimeZoneLiteral = keyof typeof TIMEZONE;
export function getTimeZoneOffsetConstant(timeZone: TimeZoneLiteral) {
  return TIMEZONE[timeZone];
}

export function getConvertedTimeZone(
  startDateTime: number | string, // unix timestamps (seconds) only
  endDateTime: number | string,
  timeZone: TimeZoneLiteral
) {
  // converted offset according to timezone

  const region = getTimeZoneOffsetConstant(timeZone);
  const objStartDateTime = region
    ? moment(+startDateTime * 1000).tz(region)
    : moment(+startDateTime * 1000);
  const objEndDateTime = region
    ? moment(+endDateTime * 1000).tz(region)
    : moment(+endDateTime * 1000);

  return {
    startDate: objStartDateTime.format("YYYY-MM-DD"),
    endDate: objEndDateTime.format("YYYY-MM-DD"),
    startTime: objStartDateTime.format("h:mm A"),
    endTime: objEndDateTime.format("h:mm A"),
    startDateTime: objStartDateTime,
    endDateTime: objEndDateTime,
  };
}

export function getExtractDates(
  shiftDates: string,
  sReturnType: string,
  isJSON: boolean = true,
  isConvertTimeZone: boolean = false,
  timeZone = ""
) {
  let extractedShiftStartDatetime = [];
  const shiftDate = isJSON === false ? shiftDates.split(",") : JSON.parse("[" + shiftDates + "]");

  extractedShiftStartDatetime = shiftDate.map((element: { shiftStartDatetime: any }) => {
    const date = element.shiftStartDatetime || element;
    return isConvertTimeZone === false
      ? moment(getExtractedDateTime(date).startDate).format("YYYY-MM-DD")
      : getConvertedTimeZone(date, null, timeZone as TimeZoneLiteral).startDate;
  });

  return sReturnType === "Array"
    ? extractedShiftStartDatetime
    : extractedShiftStartDatetime.join(",");
}

export function checkDST(date: string | number | Date) {
  const actualDate = new Date(date);
  const today = new Date();
  Date.prototype.stdTimezoneOffset = function () {
    const jan = new Date(this.getFullYear(), 0, 1);
    const jul = new Date(this.getFullYear(), 6, 1);
    return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
  };

  Date.prototype.dst = function () {
    return this.getTimezoneOffset() < this.stdTimezoneOffset();
  };

  if (actualDate.dst() && today.dst()) {
    // no need to change the offset
    return 0;
  } else if (actualDate.dst() && !today.dst()) {
    // decrease the offset by an hour
    return 1;
  } else if (!actualDate.dst() && today.dst()) {
    // increase the offset by an hour
    return 2;
  } else {
    return 3;
  }
}

function mk2(n: string | number) {
  return ("0" + n).slice(-2);
}

export function getISODateFromJsDate(d: Date) {
  const y = d.getFullYear();
  const mm = mk2(d.getMonth() + 1); // convert js month (0-11) to (1-12)
  const dm = mk2(d.getDate());
  return `${y}-${mm}-${dm}`;
}

export function getISODateToday() {
  return getISODateFromJsDate(new Date());
}

export function getDisplayDateFromISO(sDateISO: string, bShort?: boolean) {
  const a = sDateISO.split("-");
  const y = a[0];
  const mm = a[1];
  const dm = a[2];

  if (bShort) {
    return `${mm}/${dm}/${y}`;
  } else {
    return `${monthAbreviations[parseInt(mm) - 1]} ${parseInt(dm, 10)} ${y}`;
  }
}

export function getISODateNDaysFromNow(n: number, date?: string | number | Date) {
  const d = date ? new Date(date) : new Date();
  d.setDate(d.getDate() + n);
  const y = d.getFullYear();
  const mm = mk2(d.getMonth() + 1); // convert js month (0-11) to (1-12)
  const dm = mk2(d.getDate());
  return `${y}-${mm}-${dm}`;
}

export function getDaysInMonth(m: number, y: number) {
  // m is 1-12 month, not js month (so js month + 1, gives the next month)
  return new Date(y, m, 0).getDate();
}

export function getISODateEndOfMonth(m: number, y: number) {
  const mm = mk2(m);
  const dm = mk2(getDaysInMonth(m, y));
  return `${y}-${mm}-${dm}`;
}

export function getISODateStartOfMonth(m: string | number, y: string | number) {
  const mm = mk2(m);
  return `${y}-${mm}-01`;
}
export function formatDate(
  sDateISO: string,
  options?: { isNoDayOfWeek?: boolean; isNoYear?: boolean; isAbbrevDay?: boolean }
) {
  if (!sDateISO || sDateISO.length < 10) {
    return "invalid date";
  }
  const a = sDateISO.split("-");
  const y = parseInt(a[0]);
  const mm = a[1];
  const dm = a[2];
  const jsMonth = parseInt(mm) - 1;
  const date = new Date(y, jsMonth, parseInt(dm));
  const nDayOfWeek = date.getDay();
  if (options && options.isNoDayOfWeek) {
    return `${monthAbreviations[parseInt(mm) - 1]} ${parseInt(dm)}, ${y}`;
  } else if (options && options.isNoYear && options?.isAbbrevDay) {
    return `${dayAbreviations[nDayOfWeek]}. ${monthAbreviations[parseInt(mm) - 1]} ${parseInt(dm)}`;
  } else if (options && options.isNoYear) {
    return `${dayAbreviations[nDayOfWeek]} ${monthAbreviations[parseInt(mm) - 1]} ${parseInt(dm)}`;
  } else {
    return `${dayAbreviations[nDayOfWeek]} ${monthAbreviations[parseInt(mm) - 1]} ${parseInt(
      dm
    )}, ${y}`;
  }
}

export function formatShiftTimeShiftCard(time: string, options?: { isSpaceDash?: boolean }) {
  let formattedTime = "";
  if (!!time && time.length > 0) {
    formattedTime = time;
    if (options?.isSpaceDash) {
      formattedTime = time.replace(/-/, " - ");
    }
    return `${formattedTime.replace(/:\w{2}\s/g, "").toLowerCase()}`;
  }

  return time;
}

export function formatMultipleDates(aJSDates: Date[]) {
  const aFormattedGroupedDates = [];
  const oDates = {} as { [y: number]: Record<number, number[]> };
  for (let i = 0; i < aJSDates.length; i++) {
    const date = aJSDates[i];
    const y = date.getFullYear();
    const m = date.getMonth() + 1; // convert js month to 1 - 12
    const d = date.getDate();
    if (!oDates[y]) {
      oDates[y] = {};
    }
    if (oDates[y][m]) {
      oDates[y][m].push(d);
    } else {
      oDates[y][m] = [d];
    }
  }
  for (const yKey in oDates) {
    for (const mKey in oDates[yKey]) {
      for (const dKey of oDates[yKey][mKey]) {
        aFormattedGroupedDates.push(`${oMonthNames[mKey]} ${dKey}`);
      }
      // aFormattedGroupedDates.push(`${oMonthNames[mKey]} ${oDates[yKey][mKey].join(", ")}, ${yKey}`);
    }
  }
  return aFormattedGroupedDates;
}

// param aMonthDates example: [ 1,2,23 ]
export function getMonthDateRanges(aMonthDates: number[]) {
  let nPrevDate: number = null;
  let nPenultimateDate: number = null;
  const aRanges: string[] = [];
  aMonthDates.forEach((n) => {
    if (!(n - 1 === nPrevDate && n - 2 === nPenultimateDate)) {
      aRanges.push(String(n));
    } else {
      if (aRanges[aRanges.length - 1].indexOf("-") === -1) {
        aRanges.pop();
        aRanges[aRanges.length - 1] = aRanges[aRanges.length - 1] + `-${n}`;
      } else {
        const aRange = aRanges[aRanges.length - 1].split("-");
        aRanges[aRanges.length - 1] = aRange[0] + `-${n}`;
      }
    }
    nPenultimateDate = nPrevDate;
    nPrevDate = n;
  });
  return aRanges.join(",");
}

// param aDates: [ yyyy-mm-dd, ... ]
// ***** assumes aDates are sorted
export function getDisplayDateRange(aDates: any[], bLong: any) {
  const aDateRanges = [];
  const oDatesbyYrMth: Record<string, number[]> = {};

  aDates.forEach((sDate) => {
    const keyYrMth = sDate.slice(0, 7);
    const dm = sDate.slice(-2);
    if (!oDatesbyYrMth[keyYrMth]) {
      oDatesbyYrMth[keyYrMth] = [];
    }
    oDatesbyYrMth[keyYrMth].push(parseInt(dm, 10));
  });

  for (const k in oDatesbyYrMth) {
    const a = k.split("-");
    const y = a[0];
    const mm = a[1];
    const sRanges = getMonthDateRanges(oDatesbyYrMth[k]);
    let sMonthAndRange = `${monthAbreviations[parseInt(mm, 10) - 1]} ${sRanges}`;
    if (bLong) {
      sMonthAndRange += ` ${y}`;
    }
    aDateRanges.push(sMonthAndRange);
  }
  return aDateRanges.join(", ");
}

export function getISODateFromDisplay(sDateDisplay: string) {
  const a = sDateDisplay.split("/");
  const y = a[2];
  const mm = a[0];
  const dm = a[1];
  return `${y}-${mm}-${dm}`;
}

export function getDatesForDayOfWeek(date: Date, daysOfWeek: number[], nWeeks = 52) {
  const nDatesTarget = nWeeks * daysOfWeek.length;
  const aDates = [];
  const dynamicDate = new Date(getISODateFromJsDate(date));

  while (aDates.length < nDatesTarget) {
    if (daysOfWeek.includes(dynamicDate.getDay())) {
      aDates.push(getISODateFromJsDate(dynamicDate));
    }

    dynamicDate.setDate(dynamicDate.getDate() + 1);
  }

  return `'${aDates.join("','")}'`;
}

export function createObjMonthArrays() {
  return {
    January: [],
    February: [],
    March: [],
    April: [],
    May: [],
    June: [],
    July: [],
    August: [],
    September: [],
    October: [],
    November: [],
    December: [],
  } as Record<string, Array<any>>;
}

export function getLaborDay() {
  const date = moment().set("month", 8).set("date", 1).isoWeekday(8);
  if (date.date() != 8) {
    return date.format("DD");
  } else {
    return date.isoWeekday(-6).format("DD");
  }
}

export function getThanksgivingDay() {
  const date = new Date();
  const y = date.getFullYear();
  const dateFirstOfNovember = new Date(y, 10, 1); // js months 0 - 11, 10 for November
  const dayOfWeekFirstOfNovember = dateFirstOfNovember.getDay(); // 0 - 6
  let dayOfWeek = dayOfWeekFirstOfNovember;
  let nCountThursdays = 0;
  let nDayCount = 0;
  while (nCountThursdays < 4) {
    if (dayOfWeek === 4) {
      nCountThursdays += 1;
    }
    nDayCount += 1;
    if (dayOfWeek === 6) {
      dayOfWeek = 0;
    } else {
      dayOfWeek += 1;
    }
  }
  return String(nDayCount);
}

export function getQuarterMonth(month: number, year: number) {
  const d = new Date();
  month = month || d.getMonth() + 1;
  year = year || d.getFullYear();
  const obj = {} as Record<"startMonth" | "endMonth", number>;

  if (month >= 1 && month <= 3) {
    obj.startMonth = 1;
    obj.endMonth = 3;
  } else if (month >= 4 && month <= 5) {
    obj.startMonth = 4;
    obj.endMonth = 5;
  } else if (month >= 6 && month <= 8) {
    obj.startMonth = 6;
    obj.endMonth = 8;
  } else {
    obj.startMonth = 9;
    obj.endMonth = 12;
  }

  return {
    startDate: getISODateStartOfMonth(obj.startMonth, year),
    endDate: getISODateEndOfMonth(obj.endMonth, year),
  };
}

export function getQuarterNewMonthDate(isNext: boolean, sCurrentEndDate: string) {
  let newMonthYear = parseInt(sCurrentEndDate.slice(0, 4), 10);
  const month = parseInt(sCurrentEndDate.slice(5, 7), 10);
  let decrement = 3,
    increment = 3;
  let newMonth;
  if (isNext) {
    // is next

    if (month == 3) {
      //Quarter months are based on end date
      //As tax month difference is of 2 when its calculated from march(increment: 3+2 =5)
      increment = 2;
    } else if (month == 8) {
      //As tax month difference is of 4 when its calculated from Dec(increment 8+4 = 12)
      increment = 4;
    }
    newMonth = month + increment;

    if (newMonth >= 13) {
      newMonth = 1;
      newMonthYear += 1;
    }
  } else {
    // is previous

    if (month == 5) {
      //Quarter months are based on end date
      //As tax month difference is of 2 when its calculated from may(decrement: 5-2 = 3)
      decrement = 2;
    } else if (month == 12) {
      //As tax month difference is of 4 when its calculated from Dec(decrement 12-4 = 8)
      decrement = 4;
    }
    newMonth = month - decrement;

    if (newMonth <= 0) {
      newMonth = 12 - newMonth * -1;
      newMonthYear -= 1;
    }
  }

  return {
    newMonth: newMonth,
    newMonthYear: newMonthYear,
  };
}

export function getNewMonthDate(isNext: boolean, sCurrentStartDate: string) {
  let newMonthYear = parseInt(sCurrentStartDate.slice(0, 4), 10);
  let newMonth;
  if (isNext) {
    // is next
    newMonth = parseInt(sCurrentStartDate.slice(5, 7), 10) + 1;
    if (newMonth === 13) {
      newMonth = 1;
      newMonthYear += 1;
    }
  } else {
    // is previous
    newMonth = parseInt(sCurrentStartDate.slice(5, 7), 10) - 1;
    if (newMonth === 0) {
      newMonth = 12;
      newMonthYear -= 1;
    }
  }

  return {
    newMonth: newMonth,
    newMonthYear: newMonthYear,
  };
}

export function getQuarterStartAndEndDate(
  newMonthObj: Record<"newMonth" | "newMonthYear", number>,
  monthTomorrow: number,
  monthYearTomorrow: number
) {
  const objDate = getQuarterMonth(newMonthObj.newMonth, newMonthObj.newMonthYear);
  const endDate = objDate.endDate;
  const startDate = objDate.startDate;

  const isCurrentQuarter =
    parseInt(startDate.slice(5, 7), 10) >= monthTomorrow &&
    monthTomorrow <= parseInt(endDate.slice(5, 7), 10);

  const isCurrentYear =
    parseInt(endDate.slice(0, 4), 10) == monthYearTomorrow ||
    parseInt(startDate.slice(0, 4), 10) == monthYearTomorrow;

  const isNextDateActive = !(isCurrentQuarter && isCurrentYear);

  return {
    startDate,
    endDate,
    isNextDateActive,
  };
}

export function getMonthStartAndEndDate(
  newMonthObj: { newMonth: number; newMonthYear: number },
  monthTomorrow: number,
  monthYearTomorrow: number
) {
  const { newMonth, newMonthYear } = newMonthObj;
  const isCurrentMonth = newMonth === monthTomorrow;
  const isCurrentYear = newMonthYear === monthYearTomorrow;
  let endDate, startDate;

  if (isCurrentMonth && isCurrentYear) {
    endDate = getISODateToday();
    startDate = `${endDate.slice(0, 7)}-01`;
  } else {
    startDate = `${newMonthYear}-${("0" + newMonth).slice(-2)}-01`;
    endDate = `${getISODateEndOfMonth(newMonth, newMonthYear)}`;
  }
  const isNextDateActive = !(isCurrentMonth && isCurrentYear);

  return {
    startDate,
    endDate,
    isNextDateActive,
  };
}

export function calucaleTimeDiffInSeconds(
  startTime: moment.MomentInput,
  selectedDate: moment.MomentInput,
  hospitalTimezone: TimeZoneLiteral,
  isCheckAfterTime = false
) {
  const currentTimeStamp = +(new Date().getTime() / 1000 + "").split(".")[0];
  const shiftStartTime24Hours = moment(startTime, ["h:mm A"]).format("HH:mm");
  const formatedShiftStartDateTime = `${moment(selectedDate).format(
    "YYYY-MM-DD"
  )} ${shiftStartTime24Hours}`;

  const shiftStartTimeStamp = moment
    .tz(formatedShiftStartDateTime, getTimeZoneOffsetConstant(hospitalTimezone))
    .unix();

  return isCheckAfterTime
    ? currentTimeStamp - shiftStartTimeStamp
    : shiftStartTimeStamp - currentTimeStamp;
}

export const getMonthwiseFormattedShiftDate = (shiftDate: string, monthToCheckWith: string) => {
  const currMonthNum = monthToCheckWith || mk2(new Date().getMonth() + 1);
  const shiftMonthNum = shiftDate.split("-")[1];
  return currMonthNum === shiftMonthNum
    ? moment(shiftDate, "YYYY-MM-DD").format("ddd DD")
    : moment(shiftDate, "YYYY-MM-DD").format("MM/DD");
};

export const formatShiftTime = (time: string) => {
  const timeArr = time.split(":");
  const hour = timeArr[0];
  const minutes = timeArr[1].split(" ")[0];
  const timeLabel = timeArr[1].split(" ")[1];
  const newTimeLabel = timeLabel === "AM" ? "am" : "pm";
  if (minutes === "00") return `${hour}${newTimeLabel}`;
  else return `${hour}:${minutes}${newTimeLabel}`;
};

export const getShiftType = (startTime: any, endTime: any): string => {
  // startTime and endTime values are 0 - 47;
  let shiftType = "FullShift";
  if (endTime > startTime) {
    if (endTime - startTime <= 8) {
      shiftType = "HalfShift";
    }
  } else if (endTime + 48 - startTime <= 8) {
    shiftType = "HalfShift";
  }
  return shiftType;
};

export const getShiftHrsCount = (start: any, end: any) => {
  let nHours: number = 0;
  if (end > start) {
    nHours = (end - start) / 2;
  } else if (end < start) {
    nHours = (47 - start + 1 + end) / 2; // + 1 accounts for crossing 0 hour
  }
  return nHours;
};

export const getAfterThreeMonthsDate = (date: string | number | Date) => {
  return moment(new Date(date)).add(3, "months").format("YYYY-MM-DD");
};

export const getBeforeThreeMonthsDate = (date: string | number | Date) => {
  return moment(new Date(date)).subtract(3, "months").format("YYYY-MM-DD");
};

export const getDefaultSearchStartDate = () => {
  return new Date();
};

export const getDefaultSearchEndDate = () => {
  const endDate = new Date();
  endDate.setDate(endDate.getDate() + 45);
  return endDate;
};

export const calculateDaysUntil = (shiftDate: string) => {
  const today = moment().startOf("day");
  const targetDate = moment(shiftDate, "YYYY-MM-DD");
  return targetDate.diff(today, "days");
};

export const getAbrvDate = (date: string) => {
  return moment(date, "YYYY-MM-DD").format("ddd, MMM D");
};
