import * as C from "../../constants/Unsubscribe";

const initialState = {
  isShowLoader: false,
  isUnsubscribeSuccess: false,
  errorMessage: "",
};

// eslint-disable-next-line default-param-last
const UnsubscribeReducer = (state = initialState, action) => {
  switch (action.type) {
    case C.UNSUBSCRIBE_VET_WEEKLY_EMAIL:
    case C.UNSUBSCRIBE_NEW_EVENTS_EMAIL:
    case C.UNSUBSCRIBE_SHIFT_POSTED_ALERT_EMAIL:
      return {
        ...state,
        isShowLoader: true,
        errorMessage: "",
      };

    case C.UNSUBSCRIBE_VET_WEEKLY_EMAIL_SUCCESS:
    case C.UNSUBSCRIBE_NEW_EVENTS_EMAIL_SUCCESS:
    case C.UNSUBSCRIBE_SHIFT_POSTED_ALERT_EMAIL_SUCCESS:
      return {
        ...state,
        isUnsubscribeSuccess: true,
        isShowLoader: false,
      };

    case C.UNSUBSCRIBE_VET_WEEKLY_EMAIL_FAIL:
    case C.UNSUBSCRIBE_NEW_EVENTS_EMAIL_FAIL:
    case C.UNSUBSCRIBE_SHIFT_POSTED_ALERT_EMAIL_FAIL:
      return {
        ...state,
        isShowLoader: false,
        errorMessage: action.payload,
        isUnsubscribeSuccess: false,
      };

    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default UnsubscribeReducer;
