import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Avatar,
  BoldText,
  Icon,
  MediumText,
  Popover,
  SmallMediumText,
  SmallText,
  purpleBase,
  whiteBackground,
} from "@roo-dev/ui-components";

import { Conversation, getChatMessages } from "../../api/messages/messages";
import defaultHospitalImage from "../../static/images/default-hosp-img.png";
import vetDefaultImage from "../../static/images/default-vet-img.png";
import { Event, EventTracker } from "../../tracking/service/EventTracker";
import { openChatPanel } from "../Chat/actions/chatActions";

import { EmptyState } from "./EmptyState";
import { LoadingState } from "./LoadingState";
import { MessageDate } from "./MesageDate";
import {
  IconContainer,
  IconInner,
  Message,
  MessageContainer,
  MessageHeading,
  MessageText,
  Messages,
  NavItem,
  TotalUnreadCount,
  UnreadMessageCount,
} from "./styles";

type DecoratedConversation = Conversation & {
  unreadMessageCount: number;
  senderName: string;
};

export const ChatNotifications = () => {
  const dispatch = useDispatch();
  const { email, hospitalId, userId } = useSelector((state: any) => state.login);
  const isChatPanelOpen = useSelector((state: any) => state.chatData?.isChatPanelOpen);
  const iconRef = useRef<HTMLDivElement>(null);
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [totalCountDisplay, setTotalCountDisplay] = useState(0);

  const onOpenChange = useCallback(
    (isOpen: boolean) => {
      setOpen(isOpen);

      if (!isOpen) {
        EventTracker.send(
          Event.Name.CHAT_NOTIFICATIONS_DISMISSED,
          Event.Type.CLICK,
          Event.Entity.CONVERSATION,
          userId,
          { hospitalId }
        );
      }
    },
    [userId, hospitalId]
  );

  const onClick = useCallback(
    (conversation?: DecoratedConversation) => {
      onOpenChange(false);

      if (!conversation) {
        return;
      }

      const event = conversation.unreadMessageCount
        ? Event.Name.CHAT_NOTIFICATIONS_UNREAD_MESSAGE_CLICK
        : Event.Name.CHAT_NOTIFICATIONS_READ_MESSAGE_CLICK;

      EventTracker.send(event, Event.Type.CLICK, Event.Entity.CONVERSATION, conversation.id, {
        userId,
        hospitalId,
        unreadMessageCount: conversation.unreadMessageCount,
      });

      if (hospitalId) {
        dispatch(
          openChatPanel({
            contractorUserId: conversation?.participants?.find(
              (participant) => participant.userTypeId !== "1"
            )?.userId,
            hospitalId: hospitalId,
          })
        );
      } else {
        dispatch(
          openChatPanel({
            contractorUserId: userId,
            hospitalId: conversation.hospitalId,
          })
        );
      }
    },
    [onOpenChange, dispatch, hospitalId, userId]
  );

  // @TODO: We should use `useQuery` for this; it has built-in caching and
  // won't need to be debounced.
  const reload = _.debounce(() => {
    if (email || hospitalId) {
      getChatMessages(email, hospitalId).then((conversations) => {
        setConversations(conversations);
      });
    }
  }, 1000);

  useEffect(() => {
    if (email || hospitalId) {
      getChatMessages(email, hospitalId)
        .then((conversations) => {
          setConversations(conversations);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, hospitalId]);

  useEffect(() => {
    // listening for new messages and reload
    window.socket?.on(
      "new-message-notification",
      (data: { vetUserId?: number; hospitalId?: number }) => {
        if (data?.vetUserId === userId || data?.hospitalId === hospitalId) {
          reload();
        }
      }
    );
    window.socket?.on("to-client-message-status", (data: { statusType: string }) => {
      if (data.statusType === "delivered-success") {
        reload();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isChatPanelOpen) {
      // wait for chat panel to call read API before reloading
      setTimeout(() => {
        reload();
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChatPanelOpen]);

  const totalUnreadMessageCount = useMemo(() => {
    return conversations?.reduce(
      (totalUnread: number, conversation) =>
        totalUnread +
        conversation.messages.filter(
          (message) => !message.read && message.participant.userId !== userId
        ).length,
      0
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(conversations)]);

  useEffect(() => {
    if (totalUnreadMessageCount > 0) {
      setTotalCountDisplay(totalUnreadMessageCount);
      EventTracker.send(
        Event.Name.CHAT_NOTIFICATIONS_UNREAD_NOTIFICATIONS,
        Event.Type.IMPRESSION,
        Event.Entity.CONVERSATION,
        userId,
        {
          hospitalId,
          totalUnreadMessageCount,
        }
      );
    } else {
      setTimeout(() => {
        setTotalCountDisplay(totalUnreadMessageCount);
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalUnreadMessageCount]);

  const decoratedConversations = useMemo(
    () =>
      (conversations || []).map((conversation) => {
        const unreadMessageCount = conversation.messages.filter(
          (message) => !message.read && message.participant.userId !== userId
        ).length;

        const senderName =
          conversation.hospitalName ||
          conversation.participants.find((participant) => participant.userTypeId !== "1")?.name;

        return {
          ...conversation,
          unreadMessageCount,
          senderName,
        };
      }),
    [conversations, userId]
  );

  return (
    <NavItem>
      <Popover
        open={open}
        onOpenChange={onOpenChange}
        trigger="click"
        placement="bottom"
        content={
          <Messages>
            {!loading && decoratedConversations.length > 0 ? (
              decoratedConversations.map((conversation) => (
                <Message
                  data-testid={`chatRow:${conversation.id}`}
                  key={conversation.id}
                  onClick={() => onClick(conversation)}
                >
                  <Avatar
                    src={
                      conversation.profileImage ||
                      (conversation.messages[0].participant.userTypeId === "1"
                        ? vetDefaultImage
                        : defaultHospitalImage)
                    }
                    size={48}
                  />
                  <MessageContainer>
                    <div>
                      <MessageHeading>
                        {conversation.unreadMessageCount > 0 ? (
                          <BoldText data-testid="senderName">{conversation.senderName}</BoldText>
                        ) : (
                          <MediumText data-testid="senderName">
                            {conversation.senderName}
                          </MediumText>
                        )}
                        {conversation.unreadMessageCount === 0 && (
                          <MessageDate createdAt={conversation.messages[0].createdAt} />
                        )}
                      </MessageHeading>
                      <MessageText>
                        {conversation.unreadMessageCount > 0 ? (
                          <SmallMediumText data-testid="messagePreview">
                            {conversation.messages[0].text}
                          </SmallMediumText>
                        ) : (
                          <SmallText data-testid="messagePreview">
                            {conversation.messages[0].text}
                          </SmallText>
                        )}
                      </MessageText>
                    </div>
                  </MessageContainer>
                  {conversation.unreadMessageCount > 0 && (
                    <UnreadMessageCount>
                      <SmallMediumText data-testid="messageCount" color={whiteBackground}>
                        {conversation.unreadMessageCount}
                      </SmallMediumText>
                    </UnreadMessageCount>
                  )}
                </Message>
              ))
            ) : loading ? (
              <LoadingState />
            ) : (
              <EmptyState />
            )}
          </Messages>
        }
      >
        <IconContainer
          open={open}
          ref={iconRef}
          onClick={() => {
            EventTracker.send(
              Event.Name.CHAT_NOTIFICATIONS_ICON_CLICK,
              Event.Type.CLICK,
              Event.Entity.CONVERSATION,
              userId,
              {
                userId,
                hospitalId,
                totalUnreadMessageCount,
              }
            );
            setOpen(!open);
          }}
        >
          <IconInner>
            <Icon name="ChatBubbleOutline" color={purpleBase} testId="chatIcon" />
            <TotalUnreadCount
              count={totalUnreadMessageCount}
              data-testid="chat-notifications-unread-count"
            >
              <SmallMediumText color={whiteBackground}>{totalCountDisplay}</SmallMediumText>
            </TotalUnreadCount>
          </IconInner>
        </IconContainer>
      </Popover>
    </NavItem>
  );
};
