import * as braze from "@braze/web-sdk";
import * as Sentry from "@sentry/react";
import { Amplify } from "aws-amplify";
import axios from "axios";
import posthog, { CapturedNetworkRequest } from "posthog-js";

import { identifyPosthog } from "../Common/Wrappers/identifyPosthog";
import * as UserTypes from "../constants/UserTypeConstants";

const userId = localStorage.getItem("userId");
const email = localStorage.getItem("email");

const initAmplify = () => {
  const FEATURE_FLAG_URL = window.RooConfig.FEATURE_FLAG_URL || "";
  const AWS_API_URL = FEATURE_FLAG_URL.substring(0, FEATURE_FLAG_URL.indexOf(".com") + 4);

  Amplify.configure({
    Auth: {
      identityPoolId: "",
      userPoolId: window.RooConfig.AWS_USERPOOL_ID,
      userPoolWebClientId: window.RooConfig.USER_POOL_WEB_CLIENT_ID,
      region: window.RooConfig.AWS_REGION,
    },
    API: {
      endpoints: [
        {
          name: "MyAPIGatewayAPI",
          endpoint: AWS_API_URL,
        },
      ],
    },
  });
};

const initBraze = () => {
  if (userId) {
    const brazeInitialized = braze.initialize(window.RooConfig.BRAZE_API_KEY, {
      baseUrl: window.RooConfig.BRAZE_SDK_ENDPOINT,
      allowUserSuppliedJavascript: true,
      sessionTimeoutInSeconds: 10,
    });

    if (brazeInitialized) {
      braze.automaticallyShowInAppMessages();
      braze.changeUser(userId);
      braze.openSession();
    }
  }
};

const initPosthog = ({ email }: { email: string }) => {
  const userId = localStorage.getItem("userId");
  const fullName = localStorage.getItem("fullName");
  const metroArea = localStorage.getItem("metroAreaId");
  const metroAreaId = metroArea ? parseInt(metroArea) : undefined;
  const userType = localStorage.getItem("userTypeId");
  const userTypeId = userType ? parseInt(userType) : undefined;
  const posthogDebugKey = "posthog_debug";
  const posthogDebug =
    new URLSearchParams(window.location.search).get(posthogDebugKey) === "true" ||
    localStorage.getItem(posthogDebugKey) === "true";
  const property_denylist = ["x-access-token", "x-api-access-key"];

  posthog.init(window.RooConfig.POSTHOG_API_KEY, {
    api_host: window.RooConfig.POSTHOG_API_HOST,
    ui_host: window.RooConfig.POSTHOG_UI_HOST,
    custom_campaign_params: ["ad_set_id", "ad_group_id", "ad_id"],
    persistence: "localStorage+cookie",
    session_recording: {
      maskAllInputs: false,
      maskInputOptions: {
        password: true,
      },
      maskCapturedNetworkRequestFn: (request: CapturedNetworkRequest) => {
        // Only capture requests to roo.vet domains
        if (!request.name.includes("roo.vet")) {
          return null;
        }

        property_denylist.forEach((header) => {
          if (request.requestHeaders?.[header]) {
            request.requestHeaders[header] = "[REDACTED]";
          }
        });
        return request;
      },
    },
    property_denylist,
    loaded: function (posthog) {
      if (userId) {
        identifyPosthog({
          userId: parseInt(userId),
          userType: UserTypes.isUserType(userTypeId) ? userTypeId : undefined,
          email,
          fullName,
          metroAreaId,
        });
      }

      if (posthogDebug) {
        posthog.debug();
      }

      (<any>window).setFeatureFlag = (flag: string, value: boolean | string) => {
        posthog.featureFlags.override({
          [flag]: value,
        });
      };
    },
  });
};

const initSentry = ({ email }: { email: string }) => {
  Sentry.init({
    dsn: window.RooConfig.SENTRY_DSN_JAVASCRIPT_REACT,
    environment: window.RooConfig.WHICH_ENV,
    release: window.RooConfig.GITHUB_SHA,
    integrations: [
      new posthog.SentryIntegration(
        posthog,
        "roo-vet",
        Number(window.RooConfig.SENTRY_PROJECT_ID_JAVASCRIPT_REACT)
      ),
      Sentry.httpClientIntegration({ failedRequestStatusCodes: [[500, 599]] }),
      Sentry.captureConsoleIntegration({ levels: ["error"] }),
    ],
  });

  Sentry.setUser({
    id: userId,
    email,
  });
};

const initAxios = () => {
  axios.defaults.withCredentials = true;
};

export const thirdPartyInit = () => {
  initAmplify();

  initBraze();

  initPosthog({ email });

  initSentry({ email });

  initAxios();
};
