import React, { useState } from "react";
import styled from "styled-components";

import {
  Heading,
  Icon,
  Input,
  Popover,
  SmallText,
  Text,
  greenBase,
  redBase,
  spacingL,
  spacingM,
} from "@roo-dev/ui-components";

import { useDevice } from "../windowUtils";

export const isValidPassword = (password: string) =>
  passwordRequirements.every((requirement) => requirement.regex.test(password));

export const passwordRequirements = [
  {
    label: "At least 8 characters",
    regex: /^[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=\/\(\),\-\w]{8,}$/,
  },
  {
    label: "1 uppercase character",
    regex: /^(?=.*[A-Z])/,
  },
  {
    label: "1 lowercase character",
    regex: /^(?=.*[a-z])/,
  },
  {
    label: "1 number",
    regex: /^(?=.*[0-9])/,
  },
];

export type PasswordInputProps = {
  name?: string;
  label?: string;
  password: string;
  onChange: (value: string) => void;
  error?: string;
};

export const PasswordInput = ({
  name = "password",
  label = "Password",
  password,
  onChange,
  error,
}: PasswordInputProps) => {
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPasword] = useState(false);
  const { isSmallMedia } = useDevice();

  const size = isSmallMedia ? "s" : "l";
  return (
    <Popover
      open={open}
      placement="topRight"
      content={
        <PasswordHintContainer>
          <Heading level={6}>Password must include</Heading>
          <PasswordContainer>
            {passwordRequirements.map((requirement) => (
              <React.Fragment key={requirement.label}>
                {requirement.regex.test(password) ? (
                  <Icon name="Check" color={greenBase} size={size} />
                ) : (
                  <Icon name="Close" color={redBase} size={size} />
                )}
                {isSmallMedia ? (
                  <SmallText>{requirement.label}</SmallText>
                ) : (
                  <Text>{requirement.label}</Text>
                )}
              </React.Fragment>
            ))}
          </PasswordContainer>
        </PasswordHintContainer>
      }
    >
      <Input
        className="ph-no-capture"
        label={label}
        value={password}
        onChange={(e) => onChange(e.target.value)}
        error={error}
        name={name}
        type={showPassword ? "text" : "password"}
        onFocus={() => setOpen(true)}
        onBlur={() => setOpen(false)}
        suffix={
          <Icon
            name={showPassword ? "VisibilityOff" : "Visibility"}
            onClick={() => setShowPasword(!showPassword)}
          />
        }
      />
    </Popover>
  );
};

const PasswordContainer = styled.div`
  margin-top: ${spacingM};
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: ${spacingM};
`;

const PasswordHintContainer = styled.div`
  padding: ${spacingL};
`;
