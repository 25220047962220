import { filterExternships } from "../../../Common/externshipDataHelper";
import * as C from "../../../constants/adminExternships";
import * as ErrorMessage from "../../../constants/errorMessage";
import { ExternshipAction } from "../actions/ExternshipActions";

type ExternshipState = {
  externshipList: any[];
  isExternshipUpdated: boolean | null;
  isExternshipDeleted: boolean | null;
  modalMessage: string;
};

const initialState: ExternshipState = {
  externshipList: [],
  isExternshipUpdated: null,
  isExternshipDeleted: null,
  modalMessage: ErrorMessage.EXCEPTION_MESSAGE,
};

// eslint-disable-next-line default-param-last
const externshipReducer = (state = initialState, action: ExternshipAction) => {
  switch (action.type) {
    case C.GET_EXTERNSHIP_LIST_SUCCESS:
      if (action.payload.data && action.payload.data.length) {
        action.payload.data = filterExternships([...action.payload.data]);
      }
      return {
        ...state,
        externshipList: action.payload.data || [],
        isExternshipListUpdated: true,
      };

    case C.CLEAR_GET_EXTERNSHIP_LIST_SUCCESS_FLAG:
      return {
        ...state,
        isExternshipListUpdated: false,
      };

    case C.UPDATE_EXTERNSHIP_REQUEST:
      return {
        ...state,
        isExternshipUpdated: null,
      };

    case C.UPDATE_EXTERNSHIP_FAIL:
      return {
        ...state,
        isExternshipUpdated: false,
      };

    case C.UPDATE_EXTERNSHIP_SUCCESS:
      return {
        ...state,
        isExternshipUpdated: true,
      };

    case C.DELETE_EXTERNSHIP_REQUEST:
      return {
        ...state,
        isExternshipDeleted: null,
      };

    case C.DELETE_EXTERNSHIP_FAIL:
      return {
        ...state,
        isExternshipDeleted: false,
      };

    case C.DELETE_EXTERNSHIP_SUCCESS:
      return {
        ...state,
        isExternshipDeleted: true,
      };

    case C.CLEAR_EXTERNSHIP_FLAG:
      return {
        ...state,
        isExternshipUpdated: null,
        isExternshipDeleted: null,
      };

    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default externshipReducer;
