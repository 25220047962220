import React from "react";

import { ButtonTabs } from "@roo-dev/ui-components";

import { SHIFTS } from "../../../constants/notificationConstants";
import { NotificationPreference } from "../interfaces";

import NotificationPanelContent from "./NotificationPanelContent";
import { ToggleUpdateParams } from "./NotificationSettings";
import ShiftNotificationPreferencesNew from "./ShiftNotificationPreferences";

interface VetNotificationsProps {
  handleToggleChange: (arg: ToggleUpdateParams) => void;
  processedData: NotificationPreference[];
  areAllNotificationsEnabled: () => number;
  toggleAllSettings: (arg: number) => void;
  shiftPreferencesId?: number;
  frequencyTypeCode?: number;
}

export const VetOrTechNotifications = ({
  handleToggleChange,
  processedData,
  areAllNotificationsEnabled,
  toggleAllSettings,
  shiftPreferencesId,
  frequencyTypeCode,
}: VetNotificationsProps) =>
  processedData.length ? (
    <ButtonTabs
      defaultSelectedTabIndex={0}
      tabNameArr={processedData.map((opt, index) => ({
        index,
        name: opt.label,
        testId: `button-tab-${opt.label.replace(/\s/g, "")}`,
        panelContent:
          opt.label === SHIFTS && !!shiftPreferencesId ? (
            <>
              <NotificationPanelContent
                content={opt}
                handleToggleChange={handleToggleChange}
                renderAllNotifications={true}
                getAllSettingsValue={areAllNotificationsEnabled}
                toggleAllSettings={toggleAllSettings}
              />
              <ShiftNotificationPreferencesNew
                shiftPreferencesId={shiftPreferencesId}
                frequencyTypeCode={frequencyTypeCode}
              />
            </>
          ) : (
            <NotificationPanelContent content={opt} handleToggleChange={handleToggleChange} />
          ),
      }))}
    />
  ) : null;
