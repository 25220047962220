import { ReduxAction } from "../../../Common/typeUtils";
import {
  Badge,
  ShiftHistoryForVetSuccessActionResponse,
  SubmitHospitalRatingByVetPayload,
  fetchVetShiftHistoryDetailsPayload,
} from "../../../api/shiftHistory";
import * as actionTypes from "../../../constants/vetConstants";

type ReduxFailActionPayload = {
  status: number;
  message: string;
};

export type VetShiftHistoryActions =
  | FetchVetShiftHistoryDetailsAction
  | FetchVetShiftHistoryDetailsSuccessAction
  | FetchVetShiftHistoryDetailsFailAction
  | SubmitRateHospitalByVetAction
  | SubmitRateHospitalByVetSuccessAction
  | SubmitRateHospitalByVetFailAction
  | CloseBadgeCongratsModalAction
  | ClearShiftHistoryAction
  | ResetHospitalRatingSubmittedAction
  | HideReferralModalAction
  | SetUnratedShiftDateAction
  | ShowReferralModalAction
  | ShowVetBadgeCongratsModalAction;

export type FetchVetShiftHistoryDetailsAction = ReturnType<typeof fetchShiftHistoryDetails>;
export type FetchVetShiftHistoryDetailsSuccessAction = ReduxAction<
  typeof actionTypes.VET_SHIFT_HISTORY_SUCCESS,
  ShiftHistoryForVetSuccessActionResponse
>;
export type FetchVetShiftHistoryDetailsFailAction = ReduxAction<
  typeof actionTypes.VET_SHIFT_HISTORY_FAIL,
  ReduxFailActionPayload
>;

export function fetchShiftHistoryDetails(data: fetchVetShiftHistoryDetailsPayload) {
  return {
    type: actionTypes.VET_SHIFT_HISTORY_REQUEST,
    payload: data,
  } as const;
}

export type SubmitRateHospitalByVetAction = ReturnType<typeof submitRateHospitalAction>;
export type SubmitRateHospitalByVetSuccessAction = ReduxAction<
  typeof actionTypes.SUBMIT_RATE_HOSPITAL_AS_VET_SUCCESS,
  { status: number }
>;
export type SubmitRateHospitalByVetFailAction = ReduxAction<
  typeof actionTypes.SUBMIT_RATE_HOSPITAL_AS_VET_FAIL,
  ReduxFailActionPayload
>;

export function submitRateHospitalAction(data: SubmitHospitalRatingByVetPayload) {
  return {
    type: actionTypes.SUBMIT_RATE_HOSPITAL_AS_VET,
    payload: data,
  } as const;
}

export type CloseBadgeCongratsModalAction = ReturnType<typeof closeBadgeCongratsModal>;

export function closeBadgeCongratsModal() {
  return {
    type: actionTypes.CLOSE_VET_BADGE_CONGRATS_MODAL,
  } as const;
}

export type ClearShiftHistoryAction = ReturnType<typeof clearShiftHistory>;

export function clearShiftHistory() {
  return {
    type: actionTypes.CLEAR_SHIFT_HISTORY,
  } as const;
}

export type ResetHospitalRatingSubmittedAction = ReturnType<typeof resetHospitalRatingSubmitted>;

export function resetHospitalRatingSubmitted() {
  return {
    type: actionTypes.RESET_RATING_SUBMITTED,
  } as const;
}

export type ShowReferralModalAction = ReturnType<typeof showReferralModal>;

export function showReferralModal() {
  return {
    type: actionTypes.SHOW_REFERRAL_MODAL,
  } as const;
}

export type HideReferralModalAction = ReturnType<typeof hideReferralModal>;

export function hideReferralModal() {
  return {
    type: actionTypes.HIDE_REFERRAL_MODAL,
  } as const;
}

export type SetUnratedShiftDateAction = ReturnType<typeof setUnratedShiftDate>;

export function setUnratedShiftDate(data: { shiftDate: string }) {
  return {
    type: actionTypes.SET_UNRATED_VET_SHIFT_DATE,
    payload: data,
  } as const;
}

export type ShowVetBadgeCongratsModalAction = ReturnType<typeof showVetBadgeCongratsModal>;

export function showVetBadgeCongratsModal(data: Badge) {
  return {
    type: actionTypes.SHOW_VET_BADGE_CONGRATS_MODAL,
    payload: data,
  } as const;
}
