import React, { useState } from "react";

import { Link, Modal, Text } from "@roo-dev/ui-components";

import PrivacyPolicyComponent from "../../../../Common/PrivacyPolicyComponent";
import TermsOfUse from "../../../../Common/termsOfUse";

export const EventSignUpFooter = () => {
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  return (
    <>
      <Text>
        <>
          By clicking "Create My Account", you agree to Roo's{" "}
          <Link
            onClick={(e) => {
              e.preventDefault();
              setShowTerms(true);
            }}
            data-testid="termsOfUseLink"
          >
            Terms & Conditions
          </Link>{" "}
          and acknowledge that you have read our{" "}
          <Link
            onClick={(e) => {
              e.preventDefault();
              setShowPrivacyPolicy(true);
            }}
            data-testid="privacyPolicyLink"
          >
            Privacy Policy
          </Link>
          .
        </>
      </Text>
      <Text>
        By providing your phone number and clicking "Create My Account", you consent to receive text
        messages from Roo. Text messages may be autodialed, and data rates may apply. The frequency
        of text messages varies. You may text STOP to cancel any time.
      </Text>
      {showTerms && (
        <Modal
          showModal={true}
          size="l"
          body={<TermsOfUse />}
          onCloseModal={() => setShowTerms(false)}
          data-testid="termsOfUseModal"
        />
      )}
      {showPrivacyPolicy && (
        <Modal
          showModal={true}
          size="l"
          body={<PrivacyPolicyComponent />}
          onCloseModal={() => setShowPrivacyPolicy(false)}
          data-testid="privacyPolicyModal"
        />
      )}
    </>
  );
};
