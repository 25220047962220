import { APIResponse } from "../common/response";

import { UserIdentifier } from "./userTypes";

type Request = UserIdentifier;

type Response = APIResponse<{
  calendlyLink: string;
}>;

export const getCalendlyLink = async (request: Request) => {
  // eslint-disable-next-line roo/no-restricted-functions
  const response = await fetch(
    `${window.RooConfig.API_URL}api/user/getCalendlyLink?userId=${request.userId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const data: Response = await response.json();
  return data?.data?.calendlyLink;
};
