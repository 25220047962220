import { CLOSE_FEAR_FREE_POPOVER, OPEN_FEAR_FREE_POPOVER } from "../fearFreeConstants";

const initialState = {
  isFearFreePopoverOpen: false,
};

export type ActionModule = typeof import("../fearFreeConstants");

type FearFreeReducerActionType = {
  type: ActionModule[keyof ActionModule];
  payload: any;
};

// eslint-disable-next-line default-param-last
const fearFreeReducer = (state = initialState, action: FearFreeReducerActionType) => {
  switch (action.type) {
    case OPEN_FEAR_FREE_POPOVER:
      return {
        ...state,
        isFearFreePopoverOpen: true,
      };
    case CLOSE_FEAR_FREE_POPOVER:
      return {
        ...state,
        isFearFreePopoverOpen: false,
      };
    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default fearFreeReducer;
