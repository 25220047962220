import * as W9ActionTypes from "../../../constants/w9";

const initialState = {
  w9List: [],
  w9ListForYear: [],
};

// eslint-disable-next-line default-param-last
const W9Reducer = (state = initialState, action) => {
  switch (action.type) {
    case W9ActionTypes.GET_W9_LIST_SUCCESS:
      return {
        ...state,
        w9List: action.payload.w9List || [],
        w9ListForYear: action.payload.w9ListForYear || [],
      };
    case W9ActionTypes.CLEAR_W9_LIST:
      return {
        ...state,
        w9List: [],
        w9ListForYear: [],
      };
    case W9ActionTypes.INITIALIZE_STATE: {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default W9Reducer;
