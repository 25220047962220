import * as Sentry from "@sentry/react";
import { CallEffect, PutEffect, call, put, takeEvery } from "redux-saga/effects";

import { SET_TOAST_DATA } from "../../Common/Toasts/constants";
import { buildQuery } from "../../Common/queryParamsUtils";
import { ReducerAction } from "../../Types/common";
import { GET_USER_PROMOS, GET_USER_PROMO_SUCCESS } from "../constants/UserPromoConstants";

function* getUserPromos(action: ReducerAction): Generator<CallEffect | PutEffect, void, any> {
  try {
    const { userId, filter, requestPeriodActive, shiftGroupId } = action.payload;
    const queryString = buildQuery(window.RooConfig.API_URL + `api/promo/userPromos/${userId}`, {
      filter,
      requestPeriodActive,
      shiftGroupId,
      includeRaffles: true,
    });
    const response = yield call(fetch, queryString, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const responseData = yield response.json();
    const payload = { userPromos: responseData, requestPeriodActive };
    if (!responseData.error) {
      yield put({ type: GET_USER_PROMO_SUCCESS, payload: payload });
    } else {
      yield put({
        type: SET_TOAST_DATA,
        payload: {
          variant: "error",
          message: "Error",
          description: "Can't get promo details",
        },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "getUserPromos :: error : " } });
    yield put({
      type: SET_TOAST_DATA,
      payload: {
        variant: "error",
        message: "Error",
        description: "Can't get promo details",
      },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* userPromoSaga() {
  yield takeEvery(GET_USER_PROMOS, getUserPromos);
}
