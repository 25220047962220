export const ADMIN_GET_PAST_PROMOS = "ADMIN_GET_PAST_PROMOS";
export const ADMIN_GET_PAST_PROMOS_SUCCESS = "ADMIN_GET_PAST_PROMOS_SUCCESS";
export const ADMIN_GET_PAST_PROMOS_FAIL = "ADMIN_GET_PAST_PROMOS_FAIL";

export const ADMIN_GET_USER_LIST_BY_PROMO_ID = "ADMIN_GET_USER_LIST_BY_PROMO_ID";
export const ADMIN_GET_USER_LIST_BY_PROMO_ID_SUCCESS = "ADMIN_GET_USER_LIST_BY_PROMO_ID_SUCCESS";
export const ADMIN_GET_USER_LIST_BY_PROMO_ID_FAIL = "ADMIN_GET_USER_LIST_BY_PROMO_ID_FAIL";

export const ADMIN_GET_PROMO_DETAILS_BY_ID = "ADMIN_GET_PROMO_DETAILS_BY_ID";
export const ADMIN_GET_PROMO_DETAILS_BY_ID_SUCCESS = "ADMIN_GET_PROMO_DETAILS_BY_ID_SUCCESS";
export const ADMIN_GET_PROMO_DETAILS_BY_ID_FAIL = "ADMIN_GET_PROMO_DETAILS_BY_ID_FAIL";
export const ADMIN_CLEAR_PROMO_DETAILS_BY_ID_SUCCESS = "ADMIN_CLEAR_PROMO_DETAILS_BY_ID_SUCCESS";

export const CLEAR_ERROR_MSG = "CLEAR_ERROR_MSG";

export const ADMIN_GET_UPLOADED_USER_LIST = "ADMIN_GET_UPLOADED_USER_LIST";
export const ADMIN_GET_UPLOADED_USER_LIST_SUCCESS = "ADMIN_GET_UPLOADED_USER_LIST_SUCCESS";
export const ADMIN_GET_UPLOADED_USER_LIST_FAIL = "ADMIN_GET_UPLOADED_USER_LIST_FAIL";

export const MANUAL_PROMO_PAYOUT = "MANUAL_PROMO_PAYOUT";
export const MANUAL_PROMO_PAYOUT_SUCCESS = "MANUAL_PROMO_PAYOUT_SUCCESS";
export const MANUAL_PROMO_PAYOUT_FAIL = "MANUAL_PROMO_PAYOUT_FAIL";

export const CLEAR_MANUAL_PAYOUT_SUCCESS = "CLEAR_MANUAL_PAYOUT_SUCCESS";

export const ADD_SHIFTS_TO_PROMO = "ADD_SHIFTS_TO_PROMO";
export const ADD_SHIFTS_TO_PROMO_SUCCESS = "ADD_SHIFTS_TO_PROMO_SUCCESS";
