export const INITIALIZE_STUDENT_REGISTRATION = "INITIALIZE_STUDENT_REGISTRATION";

export const CREATE_STUDENT_ACCOUNT_REQUEST = "CREATE_STUDENT_ACCOUNT_REQUEST";
export const CREATE_STUDENT_ACCOUNT_SUCCESS = "CREATE_STUDENT_ACCOUNT_SUCCESS";
export const CREATE_STUDENT_ACCOUNT_FAIL = "CREATE_STUDENT_ACCOUNT_FAIL";

export const FETCH_STATES_REQUEST = "FETCH_STATES_REQUEST";
export const FETCH_STATES_SUCCESS = "FETCH_STATES_SUCCESS";
export const FETCH_STATES_FAIL = "FETCH_STATES_FAIL";

export const SET_STUDENT_ACCOUNT_INFO = "SET_STUDENT_ACCOUNT_INFO";

export const FETCH_STUDENT_BASIC_INFO = "FETCH_STUDENT_BASIC_INFO";
export const FETCH_STUDENT_BASIC_INFO_SUCCESS = "FETCH_STUDENT_BASIC_INFO_SUCCESS";
export const FETCH_STUDENT_BASIC_INFO_FAIL = "FETCH_STUDENT_BASIC_INFO_FAIL";

export const FETCH_STUDENT_EDUCATION_EXP = "FETCH_STUDENT_EDUCATION_EXP";
export const FETCH_STUDENT_EDUCATION_EXP_SUCCESS = "FETCH_STUDENT_EDUCATION_EXP_SUCCESS";
export const FETCH_STUDENT_EDUCATION_EXP_FAIL = "FETCH_STUDENT_EDUCATION_EXP_FAIL";

export const FETCH_STUDENT_GET_TO_KNOW = "FETCH_STUDENT_GET_TO_KNOW";
export const FETCH_STUDENT_GET_TO_KNOW_SUCCESS = "FETCH_STUDENT_GET_TO_KNOW_SUCCESS";
export const FETCH_STUDENT_GET_TO_KNOW_FAIL = "FETCH_STUDENT_GET_TO_KNOW_FAIL";

export const FETCH_STUDENT_PHOTO_RES_LINKS = "FETCH_STUDENT_PHOTO_RES_LINKS";
export const FETCH_STUDENT_PHOTO_RES_LINKS_SUCCESS = "FETCH_STUDENT_PHOTO_RES_LINKS_SUCCESS";
export const FETCH_STUDENT_PHOTO_RES_LINKS_FAIL = "FETCH_STUDENT_PHOTO_RES_LINKS_FAIL";

export const FETCH_STUDENT_QUESTIONNAIRE = "FETCH_STUDENT_QUESTIONNAIRE";
export const FETCH_STUDENT_QUESTIONNAIRE_SUCCESS = "FETCH_STUDENT_QUESTIONNAIRE_SUCCESS";
export const FETCH_STUDENT_QUESTIONNAIRE_FAIL = "FETCH_STUDENT_QUESTIONNAIRE_FAIL";

export const SAVE_STUDENT_BASIC_INFO = "SAVE_STUDENT_BASIC_INFO";
export const SAVE_STUDENT_BASIC_INFO_SUCCESS = "SAVE_STUDENT_BASIC_INFO_SUCCESS";
export const SAVE_STUDENT_BASIC_INFO_FAIL = "SAVE_STUDENT_BASIC_INFO_FAIL";

export const SAVE_STUDENT_EDUCATION_EXP = "SAVE_STUDENT_EDUCATION_EXP";
export const SAVE_STUDENT_EDUCATION_EXP_SUCCESS = "SAVE_STUDENT_EDUCATION_EXP_SUCCESS";
export const SAVE_STUDENT_EDUCATION_EXP_FAIL = "SAVE_STUDENT_EDUCATION_EXP_FAIL";

export const SAVE_STUDENT_GET_TO_KNOW = "SAVE_STUDENT_GET_TO_KNOW";
export const SAVE_STUDENT_GET_TO_KNOW_SUCCESS = "SAVE_STUDENT_GET_TO_KNOW_SUCCESS";
export const SAVE_STUDENT_GET_TO_KNOW_FAIL = "SAVE_STUDENT_GET_TO_KNOW_FAIL";

export const SAVE_STUDENT_PHOTO_RES_LINKS = "SAVE_STUDENT_PHOTO_RES_LINKS";
export const SAVE_STUDENT_PHOTO_RES_LINKS_SUCCESS = "SAVE_STUDENT_PHOTO_RES_LINKS_SUCCESS";
export const SAVE_STUDENT_PHOTO_RES_LINKS_FAIL = "SAVE_STUDENT_PHOTO_RES_LINKS_FAIL";

export const UPLOAD_STUDENT_IMAGE_REQUEST = "UPLOAD_STUDENT_IMAGE_REQUEST";
export const UPLOAD_STUDENT_IMAGE_SUCCESS = "UPLOAD_STUDENT_IMAGE_SUCCESS";
export const UPLOAD_STUDENT_IMAGE_FAIL = "UPLOAD_STUDENT_IMAGE_FAIL";

export const DELETE_STUDENT_IMAGE_REQUEST = "DELETE_STUDENT_IMAGE_REQUEST";
export const DELETE_STUDENT_IMAGE_SUCCESS = "DELETE_STUDENT_IMAGE_SUCCESS";
export const DELETE_STUDENT_IMAGE_FAIL = "DELETE_STUDENT_IMAGE_FAIL";

export const UPLOAD_STUDENT_RESUME_REQUEST = "UPLOAD_STUDENT_RESUME_REQUEST";
export const UPLOAD_STUDENT_RESUME_SUCCESS = "UPLOAD_STUDENT_RESUME_SUCCESS";
export const UPLOAD_STUDENT_RESUME_FAIL = "UPLOAD_STUDENT_RESUME_FAIL";

export const DELETE_STUDENT_RESUME_REQUEST = "DELETE_STUDENT_RESUME_REQUEST";
export const DELETE_STUDENT_RESUME_SUCCESS = "DELETE_STUDENT_RESUME_SUCCESS";
export const DELETE_STUDENT_RESUME_FAIL = "DELETE_STUDENT_RESUME_FAIL";

export const SIGN_UP_REQUESTED = "SIGN_UP_REQUESTED";

export const SAVE_STUDENT_EDUCATION_EXP_REDUCER = "SAVE_STUDENT_EDUCATION_EXP_REDUCER";
export const FETCH_STUDENT_EDUCATION_EXP_REDUCER = "FETCH_STUDENT_EDUCATION_EXP_REDUCER";

export const SAVE_STUDENT_GET_TO_KNOW_REDUCER = "SAVE_STUDENT_GET_TO_KNOW_REDUCER";
export const FETCH_STUDENT_GET_TO_KNOW_REDUCER = "FETCH_STUDENT_GET_TO_KNOW_REDUCER";

export const SAVE_STUDENT_PHOTO_REDUCER = "SAVE_STUDENT_PHOTO_REDUCER";
export const FETCH_STUDENT_PHOTO_REDUCER = "FETCH_STUDENT_PHOTO_REDUCER";

export const CLEAR_LOCAL_STORAGE = "CLEAR_STUDENT_LOCAL_STORAGE";
