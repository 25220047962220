import { useEffect } from "react";

import { identifyPosthog } from "../../../Common/Wrappers/identifyPosthog";
import { USER_TYPE, UserType } from "../../../constants/UserTypeConstants";
import { AuthUtility } from "../../../helpers/AuthUtility";
import { useAppSelector } from "../../../store/service";

import { useRedirect } from "./hooks/useRedirect";
import { type RegistrationContextStateType, useRegistrationContext } from "./registrationContext";

const registrationSteps = [
  "Login",
  "Landing",
  "SignUp",
  "Location",
  "License",
  "VetPreferences",
  "Education",
  "Calendly",
  "Hireflix",
  "Referral",
  "Continue",
] as const;

export type RegistrationStep = typeof registrationSteps[number];

export const REGISTRATION_PATHS: Record<RegistrationStep, string> = {
  Login: "/login",
  Landing: "/register",
  SignUp: "/register/sign-up",
  Location: "/register/location",
  License: "/register/license",
  VetPreferences: "/register/vetPreferences",
  Education: "/register/education",
  Calendly: "/register/calendly",
  Hireflix: "/register/hireflix",
  Referral: "/register/referral",
  Continue: "/register/continue",
};

export const REGISTRATION_PROGRESS_PERCENT = {
  Landing: 20,
  SignUp: 40,
  Location: 60,
  License: 80,
  VetPreferences: 90,
  Education: 95,
};

export enum RegistrationPageKey {
  LOCATION = "REGISTRATION_LOCATION_PAGE",
  LICENSE = "REGISTRATION_LICENSE_PAGE",
  VET_PREFERENCES = "REGISTRATION_VET_PREFERENCES_PAGE",
  EDUCATION = "REGISTRATION_EDUCATION_PAGE",
  CALENDLY = "REGISTRATION_CALENDLY_PAGE",
  HIREFLIX = "REGISTRATION_HIREFLIX_PAGE",
}

export const RegistrationPageKeyToPath = {
  [RegistrationPageKey.EDUCATION]: REGISTRATION_PATHS.Education,
  [RegistrationPageKey.LICENSE]: REGISTRATION_PATHS.License,
  [RegistrationPageKey.LOCATION]: REGISTRATION_PATHS.Location,
  [RegistrationPageKey.CALENDLY]: REGISTRATION_PATHS.Calendly,
  [RegistrationPageKey.HIREFLIX]: REGISTRATION_PATHS.Hireflix,
  [RegistrationPageKey.VET_PREFERENCES]: REGISTRATION_PATHS.VetPreferences,
};

export const getIsEntity = (userType: UserType) => {
  return {
    isVet: userType === USER_TYPE.VET_USER_TYPE,
    isTech: userType === USER_TYPE.TECH_USER_TYPE,
    isHospital: userType === USER_TYPE.HOSPITAL_USER_TYPE,
    isStudent: userType === USER_TYPE.STUDENT_USER_TYPE,
  };
};

export const useIsEntity = () => {
  const {
    registrationContext: { userType },
  } = useRegistrationContext();
  return getIsEntity(userType);
};

export const saveRegistrationContextToLocalStorage = ({
  userType,
  userId,
  email,
  entityId,
  zipcode,
}: RegistrationContextStateType["registrationContext"]) => {
  const { isVet, isTech } = getIsEntity(userType);

  AuthUtility.setIsAuthorisedUser(true);

  // Save data to local storage for integration with the rest of the app.
  localStorage.setItem("isShowOnboarding", "0");

  if (userType) {
    localStorage.setItem("userTypeId", userType.toString());
  }

  if (userId && userId > 0) {
    localStorage.setItem("userId", String(userId));
  }

  if (email) {
    localStorage.setItem("email", email);
  }

  if (isVet && entityId && entityId > 0) {
    localStorage.setItem("vetId", String(entityId));
  } else if (isTech && entityId && entityId > 0) {
    localStorage.setItem("techId", String(entityId));
  }

  if (zipcode) {
    localStorage.setItem("zipcode", zipcode);
  }
};

export const useIsProfileCreatedEffect = () => {
  const isVetProfileCreated = useAppSelector((state: any) => state.registerVet.isProfileCreated);
  const isTechProfileCreated = useAppSelector(
    (state: any) => state.registerTech.isProfileCompleted
  );
  const { redirectToReferral, redirectToTechHomepage } = useRedirect();
  const {
    registrationContext: { userId, userType, metroAreaId, email, firstName, lastName },
  } = useRegistrationContext();

  useEffect(() => {
    if (isVetProfileCreated || isTechProfileCreated) {
      identifyPosthog({ userId, userType, metroAreaId, email, firstName, lastName });
    }

    if (isVetProfileCreated) {
      redirectToReferral();
    } else if (isTechProfileCreated) {
      redirectToTechHomepage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVetProfileCreated, isTechProfileCreated]);
};
