export const GET_HOSPITAL_USER_LIST_REQUEST = "GET_HOSPITAL_USER_LIST_REQUEST";
export const GET_HOSPITAL_USER_LIST_SUCCESS = "GET_HOSPITAL_USER_LIST_SUCCESS";
export const GET_HOSPITAL_USER_LIST_FAIL = "GET_HOSPITAL_USER_LIST_FAIL";

export const ADD_HOSPITAL_USER_REQUEST = "ADD_HOSPITAL_USER_REQUEST";
export const ADD_HOSPITAL_USER_SUCCESS = "ADD_HOSPITAL_USER_SUCCESS";
export const ADD_HOSPITAL_USER_FAIL = "ADD_HOSPITAL_USER_FAIL";

export const GET_HOSPITAL_USER_DETAILS_REQUEST = "GET_HOSPITAL_USER_DETAILS_REQUEST";
export const GET_HOSPITAL_USER_DETAILS_SUCCESS = "GET_HOSPITAL_USER_DETAILS_SUCCESS";
export const GET_HOSPITAL_USER_DETAILS_FAIL = "GET_HOSPITAL_USER_DETAILS_FAIL";

export const EDIT_HOSPITAL_USER_DETAILS_REQUEST = "EDIT_HOSPITAL_USER_DETAILS_REQUEST";
export const EDIT_HOSPITAL_USER_DETAILS_SUCCESS = "EDIT_HOSPITAL_USER_DETAILS_SUCCESS";
export const EDIT_HOSPITAL_USER_DETAILS_FAIL = "EDIT_HOSPITAL_USER_DETAILS_FAIL";

export const ADD_HOSPITAL_GROUP_ADMIN = "ADD_HOSPITAL_GROUP_ADMIN";
export const ADD_HOSPITAL_GROUP_ADMIN_SUCCESS = "ADD_HOSPITAL_GROUP_ADMIN_SUCCESS";
export const ADD_HOSPITAL_GROUP_ADMIN_FAIL = "ADD_HOSPITAL_GROUP_ADMIN_FAIL";

export const BLOCK_HOSPITAL_USER = "BLOCK_HOSPITAL_USER";
export const BLOCK_HOSPITAL_USER_SUCCESS = "BLOCK_HOSPITAL_USER_SUCCESS";
export const BLOCK_HOSPITAL_USER_FAIL = "BLOCK_HOSPITAL_USER_FAIL";

export const CHECK_IS_USER_CAN_BE_DELETED = "CHECK_IS_USER_CAN_BE_DELETED";
export const CHECK_IS_USER_CAN_BE_DELETED_SUCCESS = "CHECK_IS_USER_CAN_BE_DELETED_SUCCESS";
export const CHECK_IS_USER_CAN_BE_DELETED_FAIL = "CHECK_IS_USER_CAN_BE_DELETED_FAIL";

export const DELETE_HOSPITAL_USER = "DELETE_HOSPITAL_USER";
export const DELETE_HOSPITAL_USER_SUCCESS = "DELETE_HOSPITAL_USER_SUCCESS";
export const DELETE_HOSPITAL_USER_FAIL = "DELETE_HOSPITAL_USER_FAIL";

export const DELETE_HOSPITAL_GROUP_ADMIN = "DELETE_HOSPITAL_GROUP_ADMIN";
export const DELETE_HOSPITAL_GROUP_ADMIN_SUCCESS = "DELETE_HOSPITAL_GROUP_ADMIN_SUCCESS";
export const DELETE_HOSPITAL_GROUP_ADMIN_FAIL = "DELETE_HOSPITAL_GROUP_ADMIN_FAIL";

export const INITIALIZE_EDIT_HOSPITAL_USER_LIST = "INITIALIZE_EDIT_HOSPITAL_USER_LIST";

export const INITIALIZE_HOSPITAL_USER_LIST = "INITIALIZE_HOSPITAL_USER_LIST";
