import * as FirstTimerActionTypes from "../../../constants/techConstants";
const initialState = {
  IAVToken: null,
  isRequireSsnLast4: false,
  hasPaymentSetup: false,
  isIAVTokenFetchSuccess: false,
  isIAVTokenFetchFailed: false,
  deleteTechBankAccountSuccess: null,
  defaultTechbankAccountSelected: false,
  deleteTechBankAccountMessage: "",
  isIAVTokenRequested: false,
  bankConnectErrorMessage: null,
  isSwitchDefaultBankSuccess: false,
  isAddBankSuccess: false,
  isAddBankFailed: false,
  isSetMicroDepositsVerifiedSuccess: false,
  isSetMicroDepositsVerifiedFail: false,
  isSetMicroDepositsFailedSuccess: false,
  isSetMicroDepositsFailedFail: false,
};

// eslint-disable-next-line default-param-last
const techFirstTimerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FirstTimerActionTypes.FETCH_TECH_IAV_TOKEN:
      return {
        ...state,
        isIAVTokenFetchSuccess: false,
        isIAVTokenFetchFailed: false,
        isIAVTokenRequested: true,
        bankConnectErrorMessage: null,
      };
    case FirstTimerActionTypes.FETCH_TECH_IAV_TOKEN_SUCCESS:
      return {
        ...state,
        IAVToken: action.payload.IAVToken,
        isRequireSsnLast4: action.payload.isRequireSsnLast4,
        isIAVTokenFetchSuccess: true,
      };
    case FirstTimerActionTypes.FETCH_TECH_IAV_TOKEN_FAIL:
      return {
        ...state,
        defaultTechbankAccountSelected: false,
        isIAVTokenFetchFailed: true,
        bankConnectErrorMessage: action.payload.message,
      };
    case FirstTimerActionTypes.SET_TECH_DEFAULT_BANK:
      return {
        ...state,
        IAVToken: null,
        isRequireSsnLast4: false,
        isIAVTokenFetchSuccess: false,
        isIAVTokenFetchFailed: false,
        isAddBankSuccess: false,
        isAddBankFailed: false,
      };
    case FirstTimerActionTypes.SET_TECH_DEFAULT_BANK_SUCCESS:
      return {
        ...state,
        IAVToken: null,
        isIAVTokenFetchSuccess: false,
        isIAVTokenFetchFailed: false,
        isAddBankSuccess: true,
        defaultTechbankAccountSelected:
          action.payload !== undefined ? action.payload.isFromSettings : false,
      };
    case FirstTimerActionTypes.SET_TECH_DEFAULT_BANK_FAIL:
      return {
        ...state,
        IAVToken: null,
        isAddBankFailed: true,
        isIAVTokenFetchSuccess: false,
        isIAVTokenFetchFailed: false,
      };
    case FirstTimerActionTypes.RESET_ADD_BANK:
      return {
        ...state,
        isAddBankSuccess: false,
        isAddBankFailed: false,
        isIAVTokenFetchSuccess: false,
        isIAVTokenFetchFailed: false,
        isIAVTokenRequested: false,
        bankConnectErrorMessage: null,
        isSetMicroDepositsVerifiedSuccess: false,
        isSetMicroDepositsVerifiedFail: false,
        isSetMicroDepositsFailedSuccess: false,
        isSetMicroDepositsFailedFail: false,
      };

    case FirstTimerActionTypes.SWITCH_TECH_DEFAULT_BANK_SUCCESS:
      return {
        ...state,
        isSwitchDefaultBankSuccess: true,
      };
    case FirstTimerActionTypes.INITIALIZE_TECH_FIRST_TIMER:
      return {
        ...state,
        isIAVTokenFetchSuccess: false,
        isIAVTokenFetchFailed: false,
        isAddBankSuccess: false,
        isAddBankFailed: false,
        deleteTechBankAccountSuccess: null,
        defaultTechbankAccountSelected: false,
        deleteTechBankAccountMessage: "",
        isIAVTokenRequested: false,
        isSwitchDefaultBankSuccess: false,
      };
    case FirstTimerActionTypes.DELETE_TECH_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        deleteTechBankAccountSuccess: true,
        deleteTechBankAccountMessage: action.payload.message,
      };
    case FirstTimerActionTypes.DELETE_TECH_BANK_ACCOUNT_FAIL:
      return {
        ...state,
        deleteTechBankAccountSuccess: false,
        deleteTechBankAccountMessage: action.payload.message,
      };
    case FirstTimerActionTypes.UPDATE_TECH_MICRO_DEPOSITS_VERIFIED_SUCCESS:
      return {
        ...state,
        IAVToken: null,
        isIAVTokenFetchSuccess: false,
        isIAVTokenFetchFailed: false,
        isSetMicroDepositsVerifiedSuccess: true,
      };
    case FirstTimerActionTypes.UPDATE_TECH_MICRO_DEPOSITS_VERIFIED_FAIL:
      return {
        ...state,
        IAVToken: null,
        isSetMicroDepositsVerifiedFailed: true,
        isIAVTokenFetchSuccess: false,
        isIAVTokenFetchFailed: false,
      };
    case FirstTimerActionTypes.UPDATE_TECH_MICRO_DEPOSITS_FAILED_SUCCESS:
      return {
        ...state,
        IAVToken: null,
        isIAVTokenFetchSuccess: false,
        isIAVTokenFetchFailed: false,
        isSetMicroDepositsFailedSuccess: true,
      };
    case FirstTimerActionTypes.UPDATE_TECH_MICRO_DEPOSITS_FAILED_FAIL:
      return {
        ...state,
        IAVToken: null,
        isSetMicroDepositsFailedFailed: true,
        isIAVTokenFetchSuccess: false,
        isIAVTokenFetchFailed: false,
      };
    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default techFirstTimerReducer;
