import * as Sentry from "@sentry/react";
import { call, put, takeEvery } from "redux-saga/effects";

import { showToast } from "../../../Common/Toasts/action/ToastActions";
import * as PromoActionTypes from "../../../constants/adminPromos";
import * as ErrorMessages from "../../../constants/errorMessage";
import { UNSET_LOADER } from "../../../constants/loader";
import "../../../helpers/InterceptFetch";

export function* getUserListByPromoId(action) {
  try {
    const promoId = action.payload;
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + `api/promoTracking/allByPromo/${promoId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const responseData = yield response.json();
    if (responseData) {
      yield put({
        type: PromoActionTypes.ADMIN_GET_USER_LIST_BY_PROMO_ID_SUCCESS,
        payload: responseData,
      });
    } else {
      yield put({
        type: PromoActionTypes.ADMIN_GET_USER_LIST_BY_PROMO_ID_FAIL,
        payload: { message: ErrorMessages.EXCEPTION_MESSAGE },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "getUserListByPromoId :: error : " } });
    yield put({ type: UNSET_LOADER });
  }
}

export function* getPromoDetailsById(action) {
  try {
    const promoId = action.payload;
    const response = yield call(fetch, window.RooConfig.API_URL + `api/promo/${promoId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const responseData = yield response.json();
    if (responseData) {
      yield put({
        type: PromoActionTypes.ADMIN_GET_PROMO_DETAILS_BY_ID_SUCCESS,
        payload: responseData,
      });
    } else {
      yield put({
        type: PromoActionTypes.ADMIN_GET_PROMO_DETAILS_BY_ID_FAIL,
        payload: { message: ErrorMessages.EXCEPTION_MESSAGE },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "getPromoDetailsById :: error : " } });
    yield put({ type: UNSET_LOADER });
  }
}

export function* getUploadedUserList(action) {
  try {
    const promoId = action.payload;
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + `api/promoEligibleUser/allByPromo/${promoId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const responseData = yield response.json();
    if (responseData) {
      yield put({
        type: PromoActionTypes.ADMIN_GET_UPLOADED_USER_LIST_SUCCESS,
        payload: responseData,
      });
    } else {
      yield put({
        type: PromoActionTypes.ADMIN_GET_UPLOADED_USER_LIST_FAIL,
        payload: { message: ErrorMessages.EXCEPTION_MESSAGE },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "getUploadedUserList :: error : " } });
    yield put({ type: UNSET_LOADER });
  }
}

export function* manualPromoPayout(action) {
  try {
    const promoTrackingId = action.payload;
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + `api/promoTracking/submitManualPayment/${promoTrackingId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const responseData = yield response.json();
    if (response.status == 200) {
      yield put({ type: UNSET_LOADER });
      yield put({
        type: PromoActionTypes.MANUAL_PROMO_PAYOUT_SUCCESS,
        payload: responseData,
      });
    } else {
      yield put({ type: UNSET_LOADER });
      yield put({
        type: PromoActionTypes.MANUAL_PROMO_PAYOUT_FAIL,
        payload: { message: responseData },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "submitManualPayment :: error : " } });
    yield put({ type: UNSET_LOADER });
  }
}

export function* addShiftsToPromo(action) {
  try {
    const { onComplete, ...inputData } = action.payload;
    const response = yield call(fetch, window.RooConfig.API_URL + "api/admin/addShiftsToPromo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(inputData),
    });

    const responseData = yield response.json();
    if (responseData?.success) {
      yield put(
        showToast({
          variant: "success",
          message: "Added Shifts Successfully!",
          description: `Successfully added ${inputData.shiftIds.length} shifts to the promo`,
        })
      );
    } else if (responseData) {
      yield put(
        showToast({
          variant: "error",
          message: "Could Not Add Shifts",
          description: responseData.message,
        })
      );
    } else {
      yield put(
        showToast({
          variant: "error",
          message: "Could Not Add Shifts",
          description: "An unexpected error occurred",
        })
      );
    }
    yield put(onComplete());
    yield put({ type: UNSET_LOADER });
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "addShiftsToPromo :: error : " } });
    yield put({ type: UNSET_LOADER });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootAdminPromosSaga() {
  yield takeEvery(PromoActionTypes.ADMIN_GET_USER_LIST_BY_PROMO_ID, getUserListByPromoId);
  yield takeEvery(PromoActionTypes.ADMIN_GET_PROMO_DETAILS_BY_ID, getPromoDetailsById);
  yield takeEvery(PromoActionTypes.ADMIN_GET_UPLOADED_USER_LIST, getUploadedUserList);
  yield takeEvery(PromoActionTypes.MANUAL_PROMO_PAYOUT, manualPromoPayout);
  yield takeEvery(PromoActionTypes.ADD_SHIFTS_TO_PROMO, addShiftsToPromo);
}
