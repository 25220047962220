import { CustomToastProps } from "@roo-dev/ui-components/dist/ui-components/Toast";

export const SUCCESS_MESSAGE: CustomToastProps = {
  variant: "success",
  message: "Success",
  description: "Your profile was updated!",
};

export const DEFAULT_ERROR_MESSAGE: CustomToastProps = {
  variant: "error",
  message: "Error",
  description: "There was an error updating your profile.",
};

export const ACCOUNT_EXISTS_ERROR_MESSAGE: CustomToastProps = {
  variant: "error",
  message: "Error",
  description: "An account with that email already exists.",
};
