import * as C from "../../../constants/userStatusTypes";

export const getUpdateStatusLabel = (previousStatusType: number, newStatusType: number) => {
  if (newStatusType === C.APPROVED) {
    return "approved";
  } else if (newStatusType === C.NONRESPONSIVE) {
    return "rejected";
  } else if (newStatusType === C.BLOCKED) {
    return "blocked";
  } else if (newStatusType === C.ACTIVE) {
    return previousStatusType === C.POSTING_PAUSED ? "unpaused" : "unblocked";
  } else if (newStatusType === C.POSTING_PAUSED) {
    return "paused";
  } else return "";
};
