import { VetTechShiftPreferenceForm } from "../../api/notifications/notificationTypes";
import {
  EMAIL,
  HOSPITAL_SHIFTS,
  NO_SECTIONS,
  PUSH_NOTIFICATIONS,
  SMS,
  VET_TECH_MARKETING,
  VET_TECH_SHIFTS,
} from "../../constants/notificationConstants";
import { showToast } from "../Toasts/action/ToastActions";

import {
  NotificationChannelInfo,
  NotificationPreference,
  NotificationShiftSettingAttribute,
  ToggleOptionType,
  UserNotificationSettingFromAPI,
} from "./interfaces";

// UTILS FILE FOR TRANSFORMING DATA
export const processNotificationSettings = (
  settings: UserNotificationSettingFromAPI[]
): Map<number, { email: boolean; sms: boolean; push: boolean }> => {
  const settingsMap = new Map<number, { email: boolean; sms: boolean; push: boolean }>();
  settings.forEach((setting) => {
    settingsMap.set(setting.notification_type_code, {
      email: setting.email_enabled === 1,
      sms: setting.sms_enabled === 1,
      push: setting.push_enabled === 1,
    });
  });
  return settingsMap;
};

export const transformToToggleOptions = (
  channelStatus: NotificationChannelInfo,
  typeCode: number,
  disabled: boolean
): ToggleOptionType[] => {
  const labelMapping = { email: "Email", sms: "SMS", push: "Push Notifications" };
  const retVal = (Object.keys(channelStatus) as (keyof NotificationChannelInfo)[]).map((key) => {
    return {
      label: labelMapping[key],
      value: channelStatus[key] ? 1 : 0,
      disabled,
      notificationTypeId: typeCode,
    };
  });

  return retVal;
};

export const determineChannelStatuses = (
  preferences: any, // TODO: FIX
  settingsMap: Map<number, NotificationChannelInfo>,
  isPrimaryHospital: boolean
): NotificationPreference[] => {
  return preferences.map((preference: any) => {
    if (
      preference.content === VET_TECH_SHIFTS ||
      preference.content === HOSPITAL_SHIFTS ||
      preference.content === VET_TECH_MARKETING
    ) {
      // Multiple sections
      return {
        label: preference.label,
        title: preference.title,
        shifts: Object.entries(preference.content).map(
          ([key, shift]: [
            string,
            { label: string; channels: string[]; typeCode: number; disabledOnPrimary?: boolean }
          ]) => {
            const setting = settingsMap.get(shift.typeCode); // Directly use typeCode for this shift
            const channelStatus: NotificationChannelInfo = {};
            if (shift.channels.includes(EMAIL)) {
              channelStatus.email = setting?.email;
            }
            if (shift.channels.includes(SMS)) {
              channelStatus.sms = setting?.sms;
            }
            if (shift.channels.includes(PUSH_NOTIFICATIONS)) {
              channelStatus.push = setting?.push;
            }

            return {
              typeCode: shift.typeCode,
              title: key,
              label: shift.label,
              toggleOptions: transformToToggleOptions(
                channelStatus,
                shift.typeCode,
                shift.disabledOnPrimary && isPrimaryHospital
              ),
            };
          }
        ),
      };
    } else {
      const { channels, typeCode, disabledOnPrimary } = preference.content[NO_SECTIONS];
      const setting = settingsMap.get(typeCode);
      const channelStatus: { email?: boolean; sms?: boolean; push?: boolean } = {};
      if (channels.includes(EMAIL)) {
        channelStatus.email = setting?.email;
      }
      if (channels.includes(SMS)) {
        channelStatus.sms = setting?.sms;
      }
      if (channels.includes(PUSH_NOTIFICATIONS)) {
        channelStatus.push = setting?.push;
      }

      return {
        label: preference.label,
        title: preference.title,
        toggleOptions: transformToToggleOptions(
          channelStatus,
          typeCode,
          disabledOnPrimary && isPrimaryHospital
        ),
        typeCode: typeCode,
      };
    }
  });
};

const capitalizationMap = {
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",
};

type DayOfWeek = keyof typeof capitalizationMap;

export const mapShiftPreferencesForm = (
  preferences: NotificationShiftSettingAttribute[],
  frequencyTypeCode: number
): VetTechShiftPreferenceForm => ({
  distance: preferences.find((p) => p.attribute === "miles")?.value.toString() || "",
  shiftsOnCertainDays: preferences
    .filter((p) => p.attribute in capitalizationMap)
    .map((p) => ({
      label: capitalizationMap[p.attribute as DayOfWeek],
      id: String(p.id),
      checked: Boolean(p.value),
    })),
  shiftsWithPriceIncrease:
    preferences.find((p) => p.attribute === "shift_price_increase")?.value === "true",
  emergencyShifts: preferences.find((p) => p.attribute === "emergency_shifts")?.value === "true",
  shiftsWithin24Hour: preferences.find((p) => p.attribute === "shift_within_24h")?.value === "true",
  frequency: frequencyTypeCode,
});

export const updateShiftPreferencesFromForm = (
  preferenceSettings: NotificationShiftSettingAttribute[],
  vetTechShiftPreferenceForm: VetTechShiftPreferenceForm
) =>
  preferenceSettings.map(({ createdAt, updatedAt, ...setting }) => {
    if (setting.attribute === "miles") {
      return { ...setting, value: vetTechShiftPreferenceForm.distance };
    }
    if (setting.attribute in capitalizationMap) {
      const daySetting = vetTechShiftPreferenceForm.shiftsOnCertainDays.find(
        (day) => day.label === capitalizationMap[setting.attribute as DayOfWeek]
      );
      return { ...setting, value: daySetting ? daySetting.checked : setting.value };
    }
    if (setting.attribute === "shift_price_increase") {
      return { ...setting, value: vetTechShiftPreferenceForm.shiftsWithPriceIncrease.toString() };
    }
    if (setting.attribute === "emergency_shifts") {
      return { ...setting, value: vetTechShiftPreferenceForm.emergencyShifts.toString() };
    }
    if (setting.attribute === "shift_within_24h") {
      return { ...setting, value: vetTechShiftPreferenceForm.shiftsWithin24Hour.toString() };
    }
    return setting;
  });

export const showToastUtil = (statusCode: number, dispatch: any) => {
  if (statusCode === 200) {
    dispatch(
      showToast({
        variant: "success",
        message: "Roo-hoo!",
        description: "Your settings have been saved.",
      })
    );
  } else {
    dispatch(
      showToast({
        variant: "error",
        message: "Uh-oh, something went wrong",
        description: "Your settings have failed to saved",
      })
    );
  }
};
