import * as C from "../../../constants/enterpriseConstants";

const initialState = {
  email: "",
  password: "",
  cognitoId: "",
  enterpriseId: "",
  enterpriseName: "",
  isSignedUp: false,
  signUpResponse: null,
};

// eslint-disable-next-line default-param-last
const RegistrationEnterpriseReducer = (state = initialState, action) => {
  switch (action.type) {
    case C.REGISTER_ENTERPRISE_HOSPITAL_USER_SUCCESS:
      return {
        ...state,
        userId: action.payload.userId,
        email: action.payload.email,
        // password: "",
        // cognitoId: "",
        enterpriseId: action.payload.enterpriseId,
        enterpriseName: action.payload.enterpriseName,
        isSignedUp: true,
      };

    case C.REGISTER_ENTERPRISE_HOSPITAL_USER_FAIL:
    case C.REGISTER_ENTERPRISE_HOSPITAL_FAIL:
      return {
        ...state,
        isSignedUp: false,
        signUpResponse: action.payload,
      };

    case C.REGISTER_ENTERPRISE_HOSPITAL_SUCCESS:
      localStorage.setItem("hospitalId", action.payload.hospitalId);
      localStorage.setItem("userId", action.payload.userId);

      return { ...state, isSignedUp: true };

    // case C.INITIALIZE_REGISTER_ENTERPRISE_STATE:
    //   return {
    //     ...initialState,
    //   };

    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default RegistrationEnterpriseReducer;
