import moment from "moment-timezone"; // same as moment, but with additional timezone features needed when shared code with back end

import { oProcedureTypesById } from "../constants/checkBoxConstants";

import { shiftRequestTimeLeft } from "./ShiftRequestTimeLeft";
import { getConvertedTimeZone, getISODateNDaysFromNow, getISODateToday } from "./dateUtils";

export function getProcedureTypeLabels(sProcedureTypeIds) {
  const aProcedureTypeIds = sProcedureTypeIds.split(",");
  const fProcedureTypeIds = aProcedureTypeIds.filter((id) => id?.length > 0);
  const aProcedureLabels = fProcedureTypeIds.map((id) => oProcedureTypesById[id]?.label);
  return aProcedureLabels.join(", ");
}

export function processShiftDBdata(oShiftsDB, isShiftPostsExpire) {
  const fullShiftListData = [];
  const trimmedShiftListData = [];
  const isoDateToday = getISODateToday();
  for (const shiftId in oShiftsDB) {
    const oShiftDB = oShiftsDB[shiftId][0];
    const { lastRenewShiftDate, postingExpiresOn, shiftDate, confirmedVetId } = oShiftDB;
    const isPastShift = shiftDate < isoDateToday;
    let title = "";
    let price = "";
    const extractDateTime = getConvertedTimeZone(
      oShiftDB.shiftStartDatetime,
      oShiftDB.shiftEndDatetime,
      oShiftDB.hospitalTimezone
    );

    let requestCount = 0;
    let isPostExpired = false;
    let isPostExpiresSoon = false;
    const isoDate17DaysAgo = getISODateNDaysFromNow(-17); // -17
    const isoDate60DaysFromNow = getISODateNDaysFromNow(60);

    let requestedShiftDetails = [];
    if (oShiftDB.shiftRequestDetails !== undefined) {
      requestCount = JSON.parse("[" + oShiftDB.shiftRequestDetails + "]")[0].length;
      requestedShiftDetails = JSON.parse(oShiftDB.shiftRequestDetails);
    }

    if (requestCount > 0 && !confirmedVetId) {
      const isAllRequestsExpired = checkIsAllShiftRequestsExpired(requestedShiftDetails);
      if (isAllRequestsExpired) title = `Expired Requests`;
      else {
        const activeRequestCount = requestedShiftDetails.filter(
          (itm) => !shiftRequestTimeLeft(null, false, itm.shiftRequestEndTimestamp).requestExpired
        )?.length;
        title = `${activeRequestCount} Request${requestCount > 1 ? "s" : ""}`;
      }
    } else if (confirmedVetId) {
      title = oShiftDB.confirmedVetName;
    } else if (
      isShiftPostsExpire &&
      postingExpiresOn <= isoDateToday &&
      shiftDate >= isoDateToday
    ) {
      isPostExpired = true;
      // title = "Posting Expired"; // expired post
      title = "Expired Shift"; // expired post
      oShiftDB.isPostExpired = true;
    } else if (
      isShiftPostsExpire &&
      lastRenewShiftDate <= isoDate17DaysAgo &&
      shiftDate <= isoDate60DaysFromNow &&
      shiftDate > isoDateToday
    ) {
      isPostExpiresSoon = true;
      title = "Unfilled Shift?"; // expiring post
      oShiftDB.isPostExpiresSoon = true;
    } else {
      title = "Posted";
    }
    price = oShiftDB.shiftAmount;
    // refactor: so not separating trimmed and full data, just use one array ???

    trimmedShiftListData.push({
      title: title,
      price: price,
      shiftDate: shiftDate,
      shiftTime: oShiftDB.startTime + "-" + oShiftDB.endTime,
      localStartDateTime: oShiftDB.shiftStartDatetime,
      startTime: oShiftDB.startTime, // formatted
      endTime: oShiftDB.endTime, // formatted
      shiftStartDatetime: oShiftDB.shiftStartDatetime,
      shiftEndDatetime: oShiftDB.shiftEndDatetime,
      // NOTE extractDateTime not always handling daylight savings time correctly,
      // keep the string startTime and endTime from the db for all display times and
      // and shiftDate string from db for all date displays
      start: new Date(
        moment(extractDateTime.startDate).format("YYYY/MM/DD") + " " + extractDateTime.startTime
      ).getTime(),
      end: new Date(
        moment(extractDateTime.startDate).format("YYYY/MM/DD") + " " + extractDateTime.endTime
      ).getTime(),
      confirmedVetName: oShiftDB.confirmedVetName,
      confirmedVetId: confirmedVetId,
      requestCount: requestCount,
      shiftId: oShiftDB.shiftId,
      shiftGroupId: oShiftDB.shiftGroupId,
      hospitalTimezone: oShiftDB.hospitalTimezone,
      hospitalId: oShiftDB.hospitalId,
      expectedNoOfAppointments: oShiftDB.expectedNoOfAppointments,
      expectedAppointmentTypeId: oShiftDB.expectedAppointmentTypeId,
      surgeryTier: oShiftDB.surgeryTier,
      isSurgeryExpected: oShiftDB.isSurgeryExpected,
      additionalDoctor: oShiftDB.additionalDoctor,
      isWalkInsOrDropOffs: oShiftDB.isWalkInsOrDropOffs,
      additionalComments: oShiftDB.additionalComments,
      vetUserId: oShiftDB.vetUserId,
      isVetShift: true,
      isVetBid: oShiftDB.isVetBid,
      cancellationReasonId: oShiftDB.cancellationReasonId,
      isPostExpiresSoon: isPostExpiresSoon,
      isPostExpired: isPostExpired,
      isHidePastShift: isPastShift && !(confirmedVetId || requestCount > 0),
      isPastShift: isPastShift,
      totalShiftBoostAmount: oShiftDB.totalShiftBoostAmount,
      latestShiftAmount: oShiftDB.latestShiftAmount,
      latestVetShiftAmount: oShiftDB.latestVetShiftAmount,
      profileImage: oShiftDB?.profileImage ?? null,
      instantBookable: oShiftDB?.instantBookable ?? null,
      isDEALicenseRequired: oShiftDB?.isDEALicenseRequired,
      yearsOfPractice: oShiftDB?.yearsOfPractice ?? null,
      isPrefSurgery: oShiftDB?.isPrefSurgery ?? null,
      shiftCreatedOn: oShiftDB?.shiftCreatedOn ?? null,
      requestedShiftDetails,
    });

    if (oShiftDB.procedureTypeIds) {
      // if shift has surgeries, then convert procedure type ids to labels
      oShiftDB.procedureTypes = getProcedureTypeLabels(oShiftDB.procedureTypeIds);
    }
    fullShiftListData.push(oShiftDB);
  }

  return {
    fullShiftListData,
    trimmedShiftListData,
  };
}

export function processShiftDetailsDBdata(oShiftDetailsDB) {
  const aShiftData = [];
  let requestedShiftDetails = [];
  const shiftDetails = [...oShiftDetailsDB];
  const oShiftDB = shiftDetails[0];

  if (oShiftDetailsDB[0].shiftRequestDetails !== undefined) {
    requestedShiftDetails = JSON.parse("[" + oShiftDetailsDB[0].shiftRequestDetails + "]");
    if (requestedShiftDetails[0].length > 0) {
      requestedShiftDetails[0].map((o) => {
        o["expired"] = shiftRequestTimeLeft(null, false, o.shiftRequestEndTimestamp).requestExpired;
      });
    }
  }

  aShiftData.push({
    appointmentTypes: oShiftDB.appointmentTypes,
    startTime: oShiftDB.startTime, // formatted ***
    endTime: oShiftDB.endTime,
    shiftDate: oShiftDB.shiftDate,
    shiftAmount: oShiftDB.shiftAmount,
    totalShiftBoostAmount: oShiftDB.totalShiftBoostAmount,
    surgeryTier: oShiftDB.surgeryTier,
    isSurgeryExpected: oShiftDB.isSurgeryExpected,
    isFlexibleSurgery: oShiftDB.isFlexibleSurgery,
    expectedNoOfAppointments: oShiftDB.expectedNoOfAppointments,
    shiftId: oShiftDB.shiftId,
    shiftGroupId: oShiftDB.shiftGroupId,
    confirmedVetId: oShiftDB.confirmedVetId,
    confirmedVetUserId: oShiftDB.vetUserId,
    isAutoConfirmed: oShiftDB.isAutoConfirmed === 1,
    confirmedVetName: oShiftDB.confirmedVetName,
    profileImage: oShiftDB.imagePath,
    expectedLunchTime: oShiftDB.expectedLunchTime,
    hospitalTimezone: oShiftDB.hospitalTimezone,
    procedureTypes: oShiftDB.procedureTypes,
    additionalDoctor: oShiftDB.additionalDoctor,
    isWalkInsOrDropOffs: oShiftDB.isWalkInsOrDropOffs,
    additionalComments: oShiftDB.additionalComments,
    isVetShift: true,
    shiftCreatedBy: oShiftDB.shiftCreatedBy,
    stateId: oShiftDB.stateId,
    metroAreaId: oShiftDB.metroAreaId,
    expectedAppointmentTypeId: oShiftDB.expectedAppointmentTypeId,
    flexStartTimeRange: oShiftDB.flexStartTimeRange,
    requestedShiftStartTime: oShiftDB.requestedShiftStartTime,
    requestedShiftEndTime: oShiftDB.requestedShiftEndTime,
    isRequestWithoutSurgery: oShiftDB.isRequestWithoutSurgery,
    requestedHospitalShiftAmount: oShiftDB.requestedHospitalShiftAmount,
    requestedVetShiftAmount: oShiftDB.requestedVetShiftAmount,
    shiftRequestEndTimestamp: oShiftDB.shiftRequestEndTimestamp,
    isDeductBoostAmount: oShiftDB.isDeductBoostAmount,
    latestShiftAmount: oShiftDB.latestShiftAmount,
    latestVetShiftAmount: oShiftDB.latestVetShiftAmount,
    instantBookable: oShiftDB?.instantBookable ?? null,
    isDEALicenseRequired: oShiftDB?.isDEALicenseRequired,
    yearsOfPractice: oShiftDB?.yearsOfPractice ?? null,
    isPrefSurgery: oShiftDB?.isPrefSurgery ?? null,
    shiftStartDatetime: oShiftDB?.shiftStartDatetime ?? null,
    shiftCreatedOn: oShiftDB?.shiftCreatedOn ?? null,
    cancellationReasonId: oShiftDB?.cancellationReasonId,
  });

  return {
    aShiftData,
    requestedShiftDetails,
  };
}

// --- tech related

export function processTechShiftDBdata(oShiftsDB) {
  const fullShiftListData = [];
  const trimmedShiftListData = [];
  const isoDateToday = getISODateToday();

  for (const shiftId in oShiftsDB) {
    const oShiftDB = oShiftsDB[shiftId][0];
    const { shiftDate, confirmedTechId } = oShiftDB;
    const isPastShift = shiftDate < isoDateToday;

    let title = "";
    let price = "";
    const extractDateTime = getConvertedTimeZone(
      oShiftDB.shiftStartDatetime,
      oShiftDB.shiftEndDatetime,
      oShiftDB.hospitalTimezone
    );

    let requestCount = 0;

    let requestedShiftDetails = [];
    if (oShiftDB.shiftRequestDetails) {
      requestCount = JSON.parse("[" + oShiftDB.shiftRequestDetails + "]")[0].length;
      requestedShiftDetails = JSON.parse(oShiftDB.shiftRequestDetails);
    }

    if (requestCount > 0 && !confirmedTechId) {
      const isAllRequestsExpired = checkIsAllShiftRequestsExpired(requestedShiftDetails);
      if (isAllRequestsExpired) title = `Expired Requests`;
      else {
        const activeRequestCount = requestedShiftDetails.filter(
          (itm) => !shiftRequestTimeLeft(null, false, itm.shiftRequestEndTimestamp).requestExpired
        )?.length;
        title = `${activeRequestCount} Request${requestCount > 1 ? "s" : ""}`;
      }
    } else if (confirmedTechId) {
      title = oShiftDB.confirmedTechName;
    } else {
      title = "Posted";
    }
    price = oShiftDB.shiftAmount;

    trimmedShiftListData.push({
      title: title,
      price: price,
      startTime: oShiftDB.startTime,
      endTime: oShiftDB.endTime,
      shiftTime: oShiftDB.startTime + "-" + oShiftDB.endTime,
      localStartDateTime: oShiftDB.shiftStartDatetime,
      shiftEndDatetime: oShiftDB.shiftEndDatetime,
      shiftDate: oShiftDB.shiftDate,
      start: new Date(
        moment(extractDateTime.startDate).format("YYYY/MM/DD") + " " + extractDateTime.startTime
      ).getTime(),
      end: new Date(
        moment(extractDateTime.startDate).format("YYYY/MM/DD") + " " + extractDateTime.endTime
      ).getTime(),
      confirmedTechName: oShiftDB.confirmedTechName,
      confirmedTechId: confirmedTechId,
      requestCount: requestCount,
      shiftGroupId: oShiftDB.shiftGroupId,
      hospitalTimezone: oShiftDB.hospitalTimezone,
      isVetShift: false,
      isHidePastShift: isPastShift && !(confirmedTechId || requestCount > 0),
      isPastShift: isPastShift,
      isSurgeryExpected: oShiftDB.isSurgeryExpected,
      procedureTypeIds: oShiftDB.procedureTypeIds,
      isDEALicenseRequired: oShiftDB?.isDEALicenseRequired,
      shiftAmount: oShiftDB.shiftAmount,
      totalShiftBoostAmount: oShiftDB.totalShiftBoostAmount,
      shiftStartDatetime: oShiftDB.shiftStartDatetime,
      profileImage: oShiftDB?.profileImage ?? null,
      yearsOfPractice: oShiftDB?.yearsOfPractice ?? null,
      shiftCreatedOn: oShiftDB?.shiftCreatedOn ?? null,
      requestedShiftDetails,
    });

    fullShiftListData.push(oShiftDB);
  }

  return {
    fullShiftListData,
    trimmedShiftListData,
  };
}

export function processTechShiftDetailsDBdata(oShiftDetailsDB) {
  const aShiftData = [];
  let requestedShiftDetails = [];
  const shiftDetails = [...oShiftDetailsDB];
  const oShiftDB = shiftDetails[0];

  if (oShiftDetailsDB[0].shiftRequestDetails !== undefined) {
    requestedShiftDetails = JSON.parse("[" + oShiftDetailsDB[0].shiftRequestDetails + "]");
    if (requestedShiftDetails[0].length > 0) {
      requestedShiftDetails[0].map((o) => {
        o["expired"] = shiftRequestTimeLeft(null, false, o.shiftRequestEndTimestamp).requestExpired;
      });
    }
  }

  aShiftData.push({
    shiftId: oShiftDB.shiftId,
    startTime: oShiftDB.startTime, // formatted ***
    endTime: oShiftDB.endTime,
    shiftDate: oShiftDB.shiftDate,
    tier: oShiftDB.tier,
    shiftAmount: oShiftDB.shiftAmount,
    shiftGroupId: oShiftDB.shiftGroupId,
    confirmedTechId: oShiftDB.confirmedTechId,
    confirmedTechUserId: oShiftDB.techUserId,
    isAutoConfirmed: oShiftDB.isAutoConfirmed === 1,
    confirmedTechName: oShiftDB.confirmedTechName,
    profileImage: oShiftDB.imagePath,
    hospitalTimezone: oShiftDB.hospitalTimezone,
    isVetShift: false,
    additionalComments: oShiftDB.additionalComments,
    shiftCreatedBy: oShiftDB.shiftCreatedBy,
    expectedBreakTime: oShiftDB.expectedBreakTime,
    isDEALicenseRequired: oShiftDB.isDEALicenseRequired,
    isSurgeryExpected: oShiftDB.isSurgeryExpected,
    procedureTypeIds: oShiftDB.procedureTypeIds,
    yearsOfPractice: oShiftDB?.yearsOfPractice ?? null,
    shiftStartDatetime: oShiftDB?.shiftStartDatetime ?? null,
    shiftCreatedOn: oShiftDB?.shiftCreatedOn ?? null,
    shiftRequestEndTimestamp: oShiftDB.shiftRequestEndTimestamp,
  });

  return {
    aShiftData,
    requestedShiftDetails,
  };
}

export function checkIsAllShiftRequestsExpired(shiftRequestDetails) {
  let isAllRequestsExpired = false;
  if (shiftRequestDetails?.length > 0) {
    const shiftRequestsTimeLeft = shiftRequestDetails.map((o) => {
      return shiftRequestTimeLeft(null, false, o.shiftRequestEndTimestamp);
    });
    isAllRequestsExpired = shiftRequestsTimeLeft.every((o) => Boolean(o.requestExpired));
  }
  return isAllRequestsExpired;
}

// --- end tech related

export function getShiftStatusClass(shift) {
  const localShiftEndDateTs = shift.shiftEndDatetime;
  const nowDateTs = moment().unix();
  const nowDateTsConverted = getConvertedTimeZone(
    nowDateTs,
    null,
    shift.hospitalTimezone
  ).startDateTime.unix();
  const {
    confirmedVetId,
    confirmedTechId,
    requestCount,
    requestedShiftDetails,
    isPostExpired,
    isPostExpiresSoon,
  } = shift;

  const isAllRequestsExpired = requestedShiftDetails?.length
    ? checkIsAllShiftRequestsExpired(requestedShiftDetails)
    : false;

  if ((confirmedVetId || confirmedTechId) && localShiftEndDateTs <= nowDateTsConverted) {
    return "completed";
  } else if (confirmedVetId || confirmedTechId) {
    return "confirmed";
  } else if (requestCount !== 0 && !isAllRequestsExpired) {
    return "requested";
  } else if (requestCount !== 0 && isAllRequestsExpired) {
    return "expired-requests";
  } else if (localShiftEndDateTs <= nowDateTsConverted || isPostExpired) {
    return "expired"; // expired request or expired posting
  } else if (isPostExpiresSoon) {
    return "expires-soon";
  } else if (requestCount === 0) {
    return "unfilled";
  }
  return "";
}
