import { ErrorBoundary } from "@sentry/react";
import { PostHogFeatureProps } from "posthog-js/react";
import React from "react";

import { FeatureFlagName } from "../../constants/postHogFeatureFlags";

import { useRooFeatureFlagEnabled } from "./useRooFeatureFlagEnabled";

type Props = Pick<PostHogFeatureProps, "match" | "children"> & { flag: FeatureFlagName };

export const PostHogWrapper: React.FC<Props> = (props) => {
  return (
    <ErrorBoundary fallback={<FallBackComponent {...props} />}>
      <FeatureComponent {...props} />
    </ErrorBoundary>
  );
};

const FeatureComponent: React.FC<Props> = (props) => {
  const isFlagEnabled = useRooFeatureFlagEnabled(props.flag);
  return <>{!!isFlagEnabled === !!props.match ? props.children : null}</>;
};

const FallBackComponent: React.FC<Props> = (props) => {
  if (!props.match) {
    // If matched against false, show the children in the event of a posthog failure
    // Note: if the error was in the children, then this fallback render will rethrow the same error
    return <>{props.children}</>;
  }
  return null;
};
