import * as Sentry from "@sentry/react";
import "isomorphic-fetch";
import lodash from "lodash";
import { call, put, takeEvery } from "redux-saga/effects";

import * as ErrorMessages from "../../../constants/errorMessage";
import * as LoaderActions from "../../../constants/loader";
import * as ActionTypes from "../../../constants/referNewUser";

export function* referNewUserAsync(action) {
  try {
    const url = action.payload.fromRegistration
      ? "api/referUser/fromRegistration"
      : "api/referUser";
    delete action.payload.fromRegistration;
    const response = yield call(fetch, window.RooConfig.API_URL + url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(action.payload),
    });

    const responseData = yield response.json();

    const data = lodash.cloneDeep(responseData.data);

    yield put({ type: LoaderActions.UNSET_LOADER });

    if (responseData.status_code === 200) {
      if (data.isUserExist) {
        yield put({ type: ActionTypes.REFER_USER_EXIST });
      } else {
        yield put({ type: ActionTypes.REFER_NEW_USER_SUCCESS, payload: data });
      }
    } else {
      yield put({
        type: ActionTypes.REFER_NEW_USER_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while referring new user: " } });
    yield put({ type: LoaderActions.UNSET_LOADER });
    yield put({
      type: ActionTypes.REFER_NEW_USER_FAIL,
      payload: { status: error.status_code, message: ErrorMessages.EXCEPTION_MESSAGE },
    });
  }
}

export function* fetchReferredUserListAsync(action) {
  try {
    const response = yield call(
      fetch,
      window.RooConfig.API_URL +
        "api/referUser/id/" +
        action.payload.id +
        "/userType/" +
        action.payload.userType,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const responseData = yield response.json();

    const data = lodash.cloneDeep(responseData);

    yield put({ type: LoaderActions.UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: ActionTypes.FETCH_REFERRED_USER_SUCCESS,
        payload: {
          status: data.status_code,
          userList: data.data.result.data,
          amount: data.data.amount.referralPromoAmount,
          totalAmount: data.data.amount.totalReferralPromoAmount,
          inviteCount: data.data.inviteCount,
        },
      });
    } else {
      yield put({
        type: ActionTypes.FETCH_REFERRED_USER_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while fetching referred user list: " },
    });
    yield put({ type: LoaderActions.UNSET_LOADER });
    yield put({
      type: ActionTypes.FETCH_REFERRED_USER_FAIL,
      payload: { status: error.status_code, message: ErrorMessages.EXCEPTION_MESSAGE },
    });
  }
}

export function* sendReminderEmailAsync(action) {
  try {
    const response = yield call(fetch, window.RooConfig.API_URL + "api/referuser/reminderEmail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(action.payload),
    });
    const data = yield response.json();

    if (data.status_code === 200) {
      yield put({
        type: ActionTypes.SEND_REMINDER_EMAIL_SUCCESS,
        payload: { status_code: data.data.status_code, data: data.data.data },
      });
    } else {
      yield put({
        type: ActionTypes.SEND_REMINDER_EMAIL_FAIL,
        payload: { status_code: data.status_code },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while sending reminder email: " } });
    yield put({
      type: ActionTypes.SEND_REMINDER_EMAIL_FAIL,
      payload: { status_code: error.status_code, data: ErrorMessages.EXCEPTION_MESSAGE },
    });
  }
}

export function* getReferralPromoAmountAsync(action) {
  try {
    const url =
      action.payload && action.payload.fromRegistration
        ? "api/referUser/referralPromoAmount/fromRegistration"
        : "api/referUser/referralPromoAmount";
    const response = yield call(fetch, window.RooConfig.API_URL + url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = yield response.json();

    if (data.status_code === 200) {
      yield put({
        type: ActionTypes.GET_REFERRAL_PROMO_AMOUNT_SUCCESS,
        payload: {
          currentReferralPromoAmount: data.data.referralPromoAmount,
        },
      });
    } else {
      yield put({
        type: ActionTypes.GET_REFERRAL_PROMO_AMOUNT_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting referral promo amount: " },
    });
    yield put({ type: LoaderActions.UNSET_LOADER });
    yield put({
      type: ActionTypes.GET_REFERRAL_PROMO_AMOUNT_FAIL,
      payload: { status: error.status_code, message: ErrorMessages.EXCEPTION_MESSAGE },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootReferNewUserSaga() {
  yield takeEvery(ActionTypes.REFER_NEW_USER_REQUEST, referNewUserAsync);
  yield takeEvery(ActionTypes.FETCH_REFERRED_USER_REQUEST, fetchReferredUserListAsync);
  yield takeEvery(ActionTypes.SEND_REMINDER_EMAIL_REQUEST, sendReminderEmailAsync);
  yield takeEvery(ActionTypes.GET_REFERRAL_PROMO_AMOUNT, getReferralPromoAmountAsync);
}
