import React from "react";

import { Toggle } from "@roo-dev/ui-components";

import { ToggleOptionType } from "../interfaces";

interface ToggleRowNewProps {
  handleToggleChange?: (params: {
    label: string;
    value: boolean | number;
    notificationTypeId?: number;
  }) => void;

  customToggle?: {
    label: string;
    isTitle: boolean;
    value?: boolean;
  };
  toggleOptions?: ToggleOptionType[];
}

const ToggleRowNew: React.FC<ToggleRowNewProps> = ({
  customToggle,
  toggleOptions,
  handleToggleChange,
}) => {
  let options: Array<{
    label: string;
    value?: boolean | number;
    disabled?: boolean;
  }> = [
    {
      label: "Email",
    },
    {
      label: "SMS",
    },
    {
      label: "Push Notifications",
    },
  ];
  if (customToggle) {
    options = [{ label: customToggle.label, value: customToggle.value ?? false }];
  }
  if (toggleOptions) {
    options = toggleOptions;
  }

  const renderOptions = options.map((option: any) => {
    return (
      <div key={option.label}>
        <Toggle
          //@ts-expect-error TS2322
          onChange={(e) => {
            if (handleToggleChange) {
              handleToggleChange({
                label: option.label,
                value: e.target.checked ? 1 : 0,
                notificationTypeId: option.notificationTypeId, // Ensure this is passed correctly
              });
            }
          }}
          checked={Boolean(option?.value)}
          name={null}
          disabled={Boolean(option?.value) && option.disabled}
          isFullWidth
          label={option.label}
        />
      </div>
    );
  });

  return <div>{renderOptions}</div>;
};

// eslint-disable-next-line no-restricted-exports
export default ToggleRowNew;
