import { Action, Reducer } from "redux";

import * as C from "../../constants/loader";

type LoaderInitialState = {
  loader: boolean;
  webAppVersion: number;
  mapLoader: boolean;
};

type CalendarActionType =
  | typeof C.SET_LOADER
  | typeof C.UNSET_LOADER
  | typeof C.GET_WEB_APP_VERSION_SUCCESS
  | typeof C.SET_MAP_LOADER
  | typeof C.UNSET_MAP_LOADER;

const initialState: LoaderInitialState = {
  loader: false,
  webAppVersion: null,
  mapLoader: false,
};

interface LoaderAction extends Action<CalendarActionType> {
  payload: any;
}

// eslint-disable-next-line default-param-last
const loaderReducer: Reducer<LoaderInitialState, LoaderAction> = (state = initialState, action) => {
  switch (action.type) {
    case C.SET_LOADER: {
      return {
        ...state,
        loader: true,
      };
    }
    case C.UNSET_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }
    case C.SET_MAP_LOADER: {
      return {
        ...state,
        mapLoader: true,
      };
    }
    case C.UNSET_MAP_LOADER: {
      return {
        ...state,
        mapLoader: false,
      };
    }
    case C.GET_WEB_APP_VERSION_SUCCESS: {
      return {
        ...state,
        webAppVersion: action.payload,
      };
    }

    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default loaderReducer;
