import React from "react";
import { useHistory } from "react-router-dom";

import { Button, Container, Link } from "@roo-dev/ui-components";

import { OnHospitalHubSpotFormSubmitted } from "../common/EventSignUp";
import { HubSpotForm } from "../common/HubSpotForm";
import { BigText } from "../styles";

export const HospitalSignUpContainer = ({
  onSuccess,
  hospitalHubspotFormId,
}: {
  onSuccess: OnHospitalHubSpotFormSubmitted;
  hospitalHubspotFormId: string | null;
}) => {
  const history = useHistory();
  const redirectToRegisterHospitalContainer = (
    <Container>
      <BigText>
        We’re excited to have you! At this time, hospital managers must use our site to create an
        account. You can register at{" "}
        <Link onClick={() => history.push("/registerHospital")}>roo.vet/registerHospital</Link>
      </BigText>
      <Button
        buttonType="primary"
        title="Go To Roo.Vet"
        onClick={() => history.push("/registerHospital")}
      />
    </Container>
  );

  return hospitalHubspotFormId ? (
    <HubSpotForm formId={hospitalHubspotFormId} onSuccess={onSuccess} />
  ) : (
    redirectToRegisterHospitalContainer
  );
};
