import * as Sentry from "@sentry/react";
import "isomorphic-fetch";
import { call, put, takeEvery } from "redux-saga/effects";
import "../../../helpers/InterceptFetch";

import { DeleteExternshipAction } from "../../../Admin/Externships/actions/ExternshipActions";
import { APIResponse } from "../../../api/common/response";
import * as CONFIRM_MESSAGES from "../../../constants/confirmMessage";
import * as errorMessage from "../../../constants/errorMessage";
import { EXCEPTION_MESSAGE } from "../../../constants/errorMessage";
import * as externshipActions from "../../../constants/externship";
import * as internshipActions from "../../../constants/internship";
import { SET_LOADER, UNSET_LOADER } from "../../../constants/loader";
import {
  AddExternshipAction,
  AddInternshipAction,
  ContactStudentAction,
  ContactStudentExternshipAction,
  DeclineStudentAction,
  DeclineStudentExternshipAction,
  GetExternshipDetailsAction,
  GetInternshipDetailsAction,
  GetMyExternshipsAction,
  GetMyInternshipsAction,
  GetStudentDetailsAction,
  HireStudentAction,
  HireStudentExternshipAction,
  UpdateExternshipHospitalAction,
} from "../actions/rooUniversityHospitalActions";
import {
  AddExternshipResponse,
  ContactStudentExternshipResponse,
  DeclineStudentExternshipResponse,
  DeleteExternshipResponse,
  GetExternshipDetailsByIdResponse,
  GetExternshipsByHospitalIdResponse,
  HireStudentExternshipResponse,
  UpdateExternshipResponse,
} from "../types";

const confirmMessages = CONFIRM_MESSAGES as Record<string, string>;

function* getMyInternshipsAsync(action: GetMyInternshipsAction) {
  try {
    const { hospitalId } = action.payload;
    const response: Response = yield call(
      fetch,
      window.RooConfig.API_URL + `api/hospital/internship/getInternshipsByHospitalId/${hospitalId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data: APIResponse<any> = yield response.json();
    if (data && data.status_code === 200) {
      yield put({
        type: internshipActions.GET_MY_INTERNSHIPS_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: internshipActions.GET_MY_INTERNSHIPS_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
    yield put({ type: UNSET_LOADER });
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting my internships: " } });
    yield put({
      type: internshipActions.GET_MY_INTERNSHIPS_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* addInternshipAsync(action: AddInternshipAction) {
  try {
    const response: Response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/hospital/internship/addInternship",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload),
      }
    );

    const data: APIResponse<any> = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: internshipActions.ADD_INTERNSHIP_SUCCESS,
        payload: { status: data.status_code },
      });
    } else {
      yield put({
        type: internshipActions.ADD_INTERNSHIP_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while adding internship: " } });
    yield put({
      type: internshipActions.ADD_INTERNSHIP_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* contactStudentAsync(action: ContactStudentAction) {
  try {
    yield put({ type: SET_LOADER });
    const response: Response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/hospital/internship/contactStudent",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload),
      }
    );

    const data: APIResponse<any> = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: internshipActions.CONTACT_STUDENt_SUCCESS,
        payload: action.payload,
      });
    } else {
      yield put({
        type: internshipActions.CONTACT_STUDENt_FAIL,
        payload: {
          status: data.status_code,
          message:
            data.status_code === 412 && confirmMessages[data.message as string]
              ? confirmMessages[data.message as string]
              : errorMessage.EXCEPTION_MESSAGE,
        },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while contacting student: " } });
    yield put({
      type: internshipActions.CONTACT_STUDENt_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* contactStudentExternshipAsync(action: ContactStudentExternshipAction) {
  try {
    yield put({ type: SET_LOADER });
    const response: Response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/student/externships/contactStudent",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload),
      }
    );

    const data: ContactStudentExternshipResponse = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: externshipActions.CONTACT_STUDENT_EXTERNSHIP_SUCCESS,
        payload: action.payload,
      });
      yield put({
        type: externshipActions.GET_MY_EXTERNSHIPS,
        payload: action.payload,
      });
    } else {
      yield put({
        type: externshipActions.CONTACT_STUDENT_EXTERNSHIP_FAIL,
        payload: {
          status: data.status_code,
          message:
            data.status_code === 412 && confirmMessages[data.message as string]
              ? confirmMessages[data.message as string]
              : errorMessage.EXCEPTION_MESSAGE,
        },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while contacting student: " } });
    yield put({
      type: externshipActions.CONTACT_STUDENT_EXTERNSHIP_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* hireStudentAsync(action: HireStudentAction) {
  try {
    yield put({ type: SET_LOADER });
    const response: Response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/hospital/internship/hireStudent",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload),
      }
    );

    const data: APIResponse<any> = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: internshipActions.HIRE_STUDENT_SUCCESS,
        payload: action.payload,
      });
    } else {
      yield put({
        type: internshipActions.HIRE_STUDENT_FAIL,
        payload: {
          status: data.status_code,
          message:
            data.status_code === 412 && confirmMessages[data.message]
              ? confirmMessages[data.message]
              : errorMessage.EXCEPTION_MESSAGE,
        },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while hiring student: " } });
    yield put({
      type: internshipActions.HIRE_STUDENT_FAIL,
      payload: {
        status: error.status_code,
        message: error.status_code === 412 ? error.message : errorMessage.EXCEPTION_MESSAGE,
      },
    });
  }
}

function* hireStudentExternshipAsync(action: HireStudentExternshipAction) {
  try {
    yield put({ type: SET_LOADER });
    const response: Response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/student/externships/hireStudent",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload),
      }
    );

    const data: HireStudentExternshipResponse = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: externshipActions.HIRE_STUDENT_EXTERNSHIP_SUCCESS,
        payload: action.payload,
      });
      yield put({
        type: externshipActions.GET_MY_EXTERNSHIPS,
        payload: action.payload,
      });
    } else {
      yield put({
        type: externshipActions.HIRE_STUDENT_EXTERNSHIP_FAIL,
        payload: {
          status: data.status_code,
          message:
            data.status_code === 412 && confirmMessages[data.message as string]
              ? confirmMessages[data.message as string]
              : errorMessage.EXCEPTION_MESSAGE,
        },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while hiring student: " } });
    yield put({
      type: externshipActions.HIRE_STUDENT_EXTERNSHIP_FAIL,
      payload: {
        status: error.status_code,
        message: error.status_code === 412 ? error.message : errorMessage.EXCEPTION_MESSAGE,
      },
    });
  }
}

function* declineStudentAsync(action: DeclineStudentAction) {
  try {
    yield put({ type: SET_LOADER });
    const response: Response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/hospital/internship/declineStudent",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload),
      }
    );

    const data: APIResponse<any> = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: internshipActions.DECLINE_STUDENT_SUCCESS,
        payload: action.payload,
      });
    } else {
      yield put({
        type: internshipActions.DECLINE_STUDENT_FAIL,
        payload: {
          status: data.status_code,
          message: errorMessage.EXCEPTION_MESSAGE,
        },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while declining student: " } });
    yield put({
      type: internshipActions.DECLINE_STUDENT_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* declineStudentExternshipAsync(action: DeclineStudentExternshipAction) {
  try {
    yield put({ type: SET_LOADER });
    const response: Response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/student/externships/declineStudent",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload),
      }
    );

    const data: DeclineStudentExternshipResponse = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: externshipActions.DECLINE_STUDENT_EXTERNSHIP_SUCCESS,
        payload: action.payload,
      });
      yield put({
        type: externshipActions.GET_MY_EXTERNSHIPS,
        payload: action.payload,
      });
    } else {
      yield put({
        type: externshipActions.DECLINE_STUDENT_EXTERNSHIP_FAIL,
        payload: {
          status: data.status_code,
          message: errorMessage.EXCEPTION_MESSAGE,
        },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while declining student: " } });
    yield put({
      type: externshipActions.DECLINE_STUDENT_EXTERNSHIP_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* getStudentDetailsByIdAsync(action: GetStudentDetailsAction) {
  try {
    const { studentId } = action.payload;
    const response: Response = yield call(
      fetch,
      window.RooConfig.API_URL + `api/student/getStudentInfoById/${studentId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data: APIResponse<any> = yield response.json();
    if (data && data.status_code === 200) {
      yield put({
        type: internshipActions.GET_STUDENT_DETAILS_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: internshipActions.GET_STUDENT_DETAILS_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
    yield put({ type: UNSET_LOADER });
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting student details: " } });
    yield put({
      type: internshipActions.GET_STUDENT_DETAILS_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* getInternshipDetailsByIdAsync(action: GetInternshipDetailsAction) {
  try {
    const { internshipId } = action.payload;
    const { isGetHospitalDetails, isGetRequestDetails } = action.payload;
    let searchParams = "";
    if (isGetHospitalDetails) {
      searchParams += `?isGetHospitalDetails=1`;
    }
    if (isGetRequestDetails) {
      searchParams += `&isGetRequestDetails=1`;
    }

    yield put({ type: SET_LOADER });
    const response: Response = yield call(
      fetch,
      window.RooConfig.API_URL +
        `api/hospital/internship/getInternshipDetailsById/${internshipId}${searchParams}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data: APIResponse<any> = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data && data.status_code === 200) {
      yield put({
        type: internshipActions.GET_INTERNSHIP_DETAILS_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: internshipActions.GET_INTERNSHIP_DETAILS_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
    yield put({ type: UNSET_LOADER });
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting internship details: " },
    });
    yield put({
      type: internshipActions.GET_INTERNSHIP_DETAILS_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* getExternshipDetailsByIdAsync(action: GetExternshipDetailsAction) {
  try {
    const { externshipId } = action.payload;
    const { isGetHospitalDetails, isGetRequestDetails } = action.payload;
    let searchParams = "";
    if (isGetHospitalDetails && isGetRequestDetails)
      searchParams += "?isGetHospitalDetails=1&isGetRequestDetails=1";
    else if (isGetHospitalDetails) {
      searchParams += `?isGetHospitalDetails=1`;
    } else if (isGetRequestDetails) {
      searchParams += `?isGetRequestDetails=1`;
    }

    yield put({ type: SET_LOADER });
    const response: Response = yield call(
      fetch,
      window.RooConfig.API_URL +
        `api/student/externships/getExternshipDetailsById/${externshipId}${searchParams}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data: GetExternshipDetailsByIdResponse = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data && data.status_code === 200) {
      yield put({
        type: externshipActions.GET_EXTERNSHIP_DETAILS_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: externshipActions.GET_EXTERNSHIP_DETAILS_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
    yield put({ type: UNSET_LOADER });
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting externship details: " },
    });
    yield put({
      type: externshipActions.GET_EXTERNSHIP_DETAILS_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* getMyExternshipsAsync(action: GetMyExternshipsAction) {
  try {
    const { hospitalId } = action.payload;
    const response: Response = yield call(
      fetch,
      window.RooConfig.API_URL + `api/student/externships/getExternshipsByHospitalId/${hospitalId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data: GetExternshipsByHospitalIdResponse = yield response.json();
    if (data && data.status_code === 200) {
      yield put({
        type: externshipActions.GET_MY_EXTERNSHIPS_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: externshipActions.GET_MY_EXTERNSHIPS_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
    yield put({ type: UNSET_LOADER });
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting my externships: " } });
    yield put({
      type: externshipActions.GET_MY_EXTERNSHIPS_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* addExternshipAsync(action: AddExternshipAction) {
  try {
    const response: Response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/student/externships/addExternship",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload),
      }
    );

    const data: AddExternshipResponse = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: externshipActions.ADD_EXTERNSHIP_SUCCESS,
        payload: { status: data.status_code },
      });
    } else {
      yield put({
        type: externshipActions.ADD_EXTERNSHIP_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while adding externship: " } });
    yield put({
      type: externshipActions.ADD_EXTERNSHIP_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* updateExternshipHospitalAsync(action: UpdateExternshipHospitalAction) {
  try {
    const { externshipId } = action.payload;
    yield put({ type: SET_LOADER });
    const response: Response = yield call(
      fetch,
      window.RooConfig.API_URL + `api/student/externships/updateExternship/${externshipId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload),
      }
    );
    const data: UpdateExternshipResponse = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: externshipActions.UPDATE_EXTERNSHIP_HOSPITAL_SUCCESS,
        payload: { status: data.status_code },
      });
    } else {
      yield put({
        type: externshipActions.UPDATE_EXTERNSHIP_HOSPITAL_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while updating externship: " } });
    yield put({
      type: externshipActions.UPDATE_EXTERNSHIP_HOSPITAL_FAIL,
      payload: { status: error.status_code, message: EXCEPTION_MESSAGE },
    });
  }
}

function* deleteExternshipHospitalAsync(action: DeleteExternshipAction) {
  try {
    yield put({ type: SET_LOADER });
    const response: Response = yield call(
      fetch,
      window.RooConfig.API_URL + `api/student/externships/deleteExternship`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload),
      }
    );

    const data: DeleteExternshipResponse = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: externshipActions.DELETE_EXTERNSHIP_HOSPITAL_SUCCESS,
        payload: { status: data.status_code },
      });
    } else {
      yield put({
        type: externshipActions.DELETE_EXTERNSHIP_HOSPITAL_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while deleting externship: " } });
    yield put({
      type: externshipActions.DELETE_EXTERNSHIP_HOSPITAL_FAIL,
      payload: { status: error.status_code, message: EXCEPTION_MESSAGE },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rooUniveristyHospitalSaga() {
  yield takeEvery(internshipActions.GET_MY_INTERNSHIPS, getMyInternshipsAsync);
  yield takeEvery(externshipActions.GET_MY_EXTERNSHIPS, getMyExternshipsAsync);

  yield takeEvery(internshipActions.ADD_INTERNSHIP, addInternshipAsync);
  yield takeEvery(externshipActions.ADD_EXTERNSHIP, addExternshipAsync);

  yield takeEvery(internshipActions.CONTACT_STUDENt, contactStudentAsync);
  yield takeEvery(internshipActions.HIRE_STUDENT, hireStudentAsync);
  yield takeEvery(internshipActions.DECLINE_STUDENT, declineStudentAsync);

  yield takeEvery(internshipActions.GET_STUDENT_DETAILS, getStudentDetailsByIdAsync);
  yield takeEvery(internshipActions.GET_INTERNSHIP_DETAILS, getInternshipDetailsByIdAsync);
  yield takeEvery(externshipActions.GET_EXTERNSHIP_DETAILS, getExternshipDetailsByIdAsync);
  yield takeEvery(externshipActions.CONTACT_STUDENT_EXTERNSHIP, contactStudentExternshipAsync);
  yield takeEvery(externshipActions.HIRE_STUDENT_EXTERNSHIP, hireStudentExternshipAsync);
  yield takeEvery(externshipActions.DECLINE_STUDENT_EXTERNSHIP, declineStudentExternshipAsync);

  yield takeEvery(
    externshipActions.UPDATE_EXTERNSHIP_HOSPITAL_REQUEST,
    updateExternshipHospitalAsync
  );
  yield takeEvery(
    externshipActions.DELETE_EXTERNSHIP_HOSPITAL_REQUEST,
    deleteExternshipHospitalAsync
  );
}
