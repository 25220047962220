import React from "react";

import { Loading, blueLight400 } from "@roo-dev/ui-components";

import { StateContainer } from "./styles";

export const LoadingState = () => {
  return (
    <StateContainer>
      <Loading color={blueLight400} />
    </StateContainer>
  );
};
