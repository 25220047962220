import * as C from "../../../constants/hospitalConstants";

const initialState = {
  shiftHistory: [],
  techShiftHistory: [],
  isVetRatingSubmittedSuccess: false,
  vetRatingCount: 1,
  isMoreVetReviews: null,
  isVetRatingModalClosed: false,
  isTechRatingSubmitSuccess: false,
  techRatingCount: 1,
  isMoreTechReviews: null,
  isTechRatingModalClosed: false,
};

// eslint-disable-next-line default-param-last
const shiftRatingByHospitalReducer = (state = initialState, action) => {
  switch (action.type) {
    case C.SUBMIT_VET_RATING_SUCCESS: {
      return {
        ...state,
        isVetRatingSubmittedSuccess: true,
        isMoreVetReviews: action.payload.isMoreReviews,
        vetRatingCount: action.payload.vetRatingCount,
        isVetRatingModalClosed: true,
      };
    }

    case C.INITIALIZE_DEFAULT_VET_RATING_COUNT: {
      return {
        ...state,
        vetRatingCount: 1,
        isVetRatingModalClosed: false,
      };
    }

    case C.INITIALIZE_VET_RATING_SUBMITTED_FLAG: {
      return {
        ...state,
        isVetRatingSubmittedSuccess: false,
      };
    }

    case C.CLOSE_VET_RATING_MODAL: {
      return {
        ...state,
        isVetRatingModalClosed: true,
      };
    }

    case C.SUBMIT_TECH_RATING_SUCCESS: {
      return {
        ...state,
        isTechRatingSubmitSuccess: true,
        isMoreTechReviews: action.payload.isMoreReviews,
        techRatingCount: action.payload.techRatingCount,
        isTechRatingModalClosed: true,
      };
    }
    case C.INITIALIZE_DEFAULT_TECH_RATING_COUNT: {
      return {
        ...state,
        techRatingCount: 1,
        isTechRatingModalClosed: false,
      };
    }

    case C.INITIALIZE_TECH_RATING_SUBMITTED_FLAG: {
      return {
        ...state,
        isTechRatingSubmitSuccess: false,
      };
    }

    case C.CLOSE_TECH_RATING_MODAL: {
      return {
        ...state,
        isTechRatingModalClosed: true,
      };
    }

    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default shiftRatingByHospitalReducer;
