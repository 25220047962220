import { useEffect } from "react";

export function toggleZendeskWidget(show: boolean) {
  if (window.zE) {
    window.zE("messenger", show ? "show" : "hide");
  }
}

export const useShowZendeskWidget = (show: boolean) => {
  useEffect(() => {
    toggleZendeskWidget(show);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
