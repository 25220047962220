import styled from "styled-components";

import {
  grayBase,
  h3Headlines,
  smallMediaBreakpoint,
  spacingL,
  spacingXL,
  spacingXXL,
} from "@roo-dev/ui-components";

export const HeaderContainer = styled.div`
  margin-bottom: ${spacingXXL};
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: ${spacingXXL};
  @media (max-width: ${smallMediaBreakpoint}) {
    flex-direction: column;
  }
`;

export const FooterGroup = styled.div`
  display: grid;
  gap: ${spacingXL};
  margin-top: ${spacingXL};
`;

export const TechCredContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${spacingL};
`;

export const BigText = styled.p`
  ${h3Headlines};
  color: ${grayBase};
  a {
    ${h3Headlines};
  }
  margin-bottom: ${spacingXXL};
`;

export const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacingXXL};
`;
