import { GET } from "@roo-dev/roo-node-types";

type GetAllConferenceEventsResponse = GET.Admin.ConferenceEvent.GetAllConferenceEventsResponse;
type GetConferenceEventResponse = GET.Admin.ConferenceEvent.GetConferenceEventResponse;

const getAllConferenceEventsUrl = `${window.RooConfig.API_URL}api/admin/getAllConferenceEvents`;
const getConferenceEventUrl = `${window.RooConfig.API_URL}api/admin/getConferenceEvent`;
const getConferenceEventBySlugUrl = `${window.RooConfig.API_URL}api/admin/getConferenceEventBySlug`;

export const getAllConferenceEvents = async (): Promise<GetAllConferenceEventsResponse> => {
  // eslint-disable-next-line roo/no-restricted-functions
  const response = await fetch(getAllConferenceEventsUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response.json();
};

export const getConferenceEvent = async (
  conferenceEventId: string
): Promise<GetConferenceEventResponse> => {
  // eslint-disable-next-line roo/no-restricted-functions
  const response = await fetch(`${getConferenceEventUrl}/${conferenceEventId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response.json();
};

export const getConferenceEventBySlug = async (
  slug: string
): Promise<GetConferenceEventResponse> => {
  // eslint-disable-next-line roo/no-restricted-functions
  const response = await fetch(`${getConferenceEventBySlugUrl}/${slug}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response.json();
};
