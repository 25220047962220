import styled from "styled-components";

import {
  mediumMediaBreakpoint,
  smallMediaBreakpoint,
  spacingL,
  spacingM,
  spacingXL,
  spacingXXL,
} from "@roo-dev/ui-components";

export const StyledHr = styled.hr`
  margin: ${spacingXL} 0;
`;

export const LinkContainer = styled.div`
  margin-top: ${spacingXL};
  margin-bottom: ${spacingXL};
`;

export const ButtonContainer = styled.div`
  margin-top: ${spacingXL};
  display: flex;
  button:first-child {
    margin-right: ${spacingM};
  }
  @media (max-width: 500px) {
    flex-direction: column;
    button:first-child {
      margin-right: 0;
      margin-bottom: ${spacingM};
    }
  }
`;

export const LicensesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacingM};
  @media (max-width: ${mediumMediaBreakpoint}) {
    gap: ${spacingL};
  }
`;

export const LicenseRow = styled.div`
  display: grid;
  gap: ${spacingM};
  align-items: start;
  grid-template-columns: 1fr auto;

  @media (max-width: ${smallMediaBreakpoint}) {
    gap: 0px;
  }
`;

export const LicenseFields = styled.div<{ hideExpiration: boolean }>`
  display: grid;
  gap: ${spacingM};
  grid-template-columns: ${(props) =>
    props.hideExpiration ? "repeat(2, minmax(0, 1fr))" : "repeat(4, minmax(0, 1fr))"};

  @media (max-width: ${mediumMediaBreakpoint}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${smallMediaBreakpoint}) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

export const CloseCell = styled.div<{ error?: boolean }>`
  display: flex;
  justify-content: end;
  align-self: center;
  margin-top: ${(props) => `${props.error ? "2px" : spacingXXL}`};

  @media (max-width: ${smallMediaBreakpoint}) {
    margin-top: 2px;
    align-self: start;
  }
`;

export const SubHeaderContainer = styled.div`
  margin-bottom: ${spacingXL};
`;

export const HeaderContainer = styled.div`
  margin-bottom: ${spacingXXL};
`;
