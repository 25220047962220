export const REFER_NEW_USER_REQUEST = "REFER_NEW_USER_REQUEST";
export const REFER_NEW_USER_SUCCESS = "REFER_NEW_USER_SUCCESS";
export const REFER_NEW_USER_FAIL = "REFER_NEW_USER_FAIL";

export const FETCH_REFERRED_USER_REQUEST = "FETCH_REFERRED_USER_REQUEST";
export const FETCH_REFERRED_USER_SUCCESS = "FETCH_REFERRED_USER_SUCCESS";
export const FETCH_REFERRED_USER_FAIL = "FETCH_REFERRED_USER_FAIL";

export const SEND_REMINDER_EMAIL_REQUEST = "SEND_REMINDER_EMAIL_REQUEST";
export const SEND_REMINDER_EMAIL_SUCCESS = "SEND_REMINDER_EMAIL_SUCCESS";
export const SEND_REMINDER_EMAIL_FAIL = "SEND_REMINDER_EMAIL_FAIL";

export const GET_REFERRAL_PROMO_AMOUNT = "GET_REFERRAL_PROMO_AMOUNT";
export const GET_REFERRAL_PROMO_AMOUNT_SUCCESS = "GET_REFERRAL_PROMO_AMOUNT_SUCCESS";
export const GET_REFERRAL_PROMO_AMOUNT_FAIL = "GET_REFERRAL_PROMO_AMOUNT_FAIL";

export const REFER_USER_EXIST = "REFER_USER_EXIST";

export const INITIALIZE_REFER_USER_STATE = "INITIALIZE_REFER_USER_STATE";

export const REFER_STATUS_TYPE = {
  INVITED: 0,
  SIGNED_UP: 1,
  TO_BE_PAID: 2,
  PAID: 3,
};

export const REFER_STATUS = {
  INVITED: "Invited",
  SIGNED_UP: "Signed up",
  TO_BE_PAID: "To be paid",
  PAID: "Paid",
};

export const DISCOUNT_SHIFT_NUMBER = 10;
