import * as braze from "@braze/web-sdk";
import * as Sentry from "@sentry/react";

import { PROMO_CLAIMED, SHIFT_REQUEST } from "../constants/brazeEventNameConstants";

interface BrazeEventProperties {
  external_id: string | number; // roo user id
}

interface ShiftRequestProperties {
  promo_id?: string | number;
  time_of_shift_request?: Date;
  shift_request_platform?: "web";
  shift_id?: string | number;
  user_type?: "Vet" | "Tech" | "Admin";
}

interface PromoClaimedProperty {
  promo_id?: string | number;
  date_time_of_promo_claimed?: Date;
}

export function logBrazeCustomEvent(
  eventName: string,
  eventProperties: BrazeEventProperties & ShiftRequestProperties & PromoClaimedProperty
) {
  eventProperties.external_id = localStorage.getItem("userId");
  if (eventName === SHIFT_REQUEST) {
    eventProperties.time_of_shift_request = new Date();
    eventProperties.shift_request_platform = "web";
    const userType = localStorage.getItem("userTypeId");
    if (userType == "2") {
      eventProperties.user_type = "Vet";
    } else if (userType == "4") {
      eventProperties.user_type = "Tech";
    } else eventProperties.user_type = "Admin";
  }
  if (eventName === PROMO_CLAIMED) {
    eventProperties.date_time_of_promo_claimed = new Date();
  }
  try {
    braze.logCustomEvent(eventName, eventProperties);
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error logging braze event" } });
  }
}
