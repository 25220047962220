import * as C from "../../constants/loader";

export function setLoaderAction() {
  return {
    type: C.SET_LOADER,
  };
}

export function unsetLoaderAction() {
  return {
    type: C.UNSET_LOADER,
  };
}

export function setMapLoaderAction() {
  return {
    type: C.SET_MAP_LOADER,
  };
}

export function unsetMapLoaderAction() {
  return {
    type: C.UNSET_MAP_LOADER,
  };
}

export function getWebAppVersionAction() {
  return {
    type: C.GET_WEB_APP_VERSION,
  };
}

export type SetLoaderAction = ReturnType<typeof setLoaderAction>;
export type UnsetLoaderAction = ReturnType<typeof unsetLoaderAction>;
export type GetWebAppVersionAction = ReturnType<typeof getWebAppVersionAction>;
