import * as Sentry from "@sentry/react";
import "isomorphic-fetch";
import { call, put, takeEvery } from "redux-saga/effects";

import { RESET_LAST_SHIFT_ACTION_DATA } from "../../../constants/adminShiftsList";
import { EXCEPTION_MESSAGE } from "../../../constants/errorMessage";
import * as C from "../../../constants/feedbackForm";
import { UNSET_LOADER } from "../../../constants/loader";
import { ADMIN_USER_TYPE, ROO_SUPER_USER_TYPE } from "../../../constants/UserTypeConstants";

export function* submitFeedback(action) {
  try {
    const { shiftAction, isPerformShiftAction, isVetShift } = action.payload;
    let shiftData = action.payload.shiftData;
    shiftData.contractorRemovalReason = action.payload.contractorRemovalReason;
    shiftData.removalReasonId = action.payload.removalReasonId;
    delete action.payload.shiftData;
    delete action.payload.isPerformShiftAction;

    const oSave = {
      ...action.payload,
      adminUserId: localStorage.getItem("userId"),
    };

    let actionResponse;
    let isRemoveConfirmedTech = false;
    let isRemoveConfirmedVet = false;
    if (isPerformShiftAction) {
      let apiEndpoint = false;
      if (shiftAction === "deleteShift") {
        apiEndpoint = isVetShift
          ? "api/hospital/shift/deleteShift"
          : "api/hospital/tech_shift/deleteShift";
      } else {
        isRemoveConfirmedVet =
          isVetShift && shiftAction === "removeConfirmedContractor" ? true : false;
        const contractor = isVetShift ? "vet" : "tech";
        if (!isVetShift && shiftAction === "removeConfirmedContractor") {
          // This is a remove confirmed tech call with hospital initiated callout process
          isRemoveConfirmedTech = true;
          apiEndpoint = "api/tech/shift/removeTech";
          shiftData.isRooAdmin =
            ADMIN_USER_TYPE == localStorage.getItem("userTypeId") ||
            ROO_SUPER_USER_TYPE == localStorage.getItem("userTypeId");
        } else {
          apiEndpoint =
            shiftAction === "removeConfirmedContractor"
              ? `api/admin/shift/${contractor}/remove`
              : `api/admin/shift/${contractor}`;
        }

        if (isRemoveConfirmedVet) {
          shiftData = { ...oSave, ...shiftData };
          delete shiftData.shiftAction;
          delete shiftData.currContractorConfirmed;
          delete shiftData.isVetShift;
        }

        if (isRemoveConfirmedTech) {
          shiftData = {
            ...shiftData,
          };
        }
      }

      if (Object.keys(action?.payload).includes("amountCredit")) {
        shiftData.amountCredit = action.payload.amountCredit;
      }
      if (Object.keys(action?.payload).includes("amountRefund")) {
        shiftData.amountRefund = action.payload.amountRefund;
      }

      const response = yield call(fetch, window.RooConfig.API_URL + apiEndpoint, {
        method: !isRemoveConfirmedTech ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(shiftData),
      });
      actionResponse = yield response.json();
    }
    let feedbackResponseData;
    if (
      !isRemoveConfirmedVet &&
      (!isPerformShiftAction || (isPerformShiftAction && actionResponse.status_code === 200))
    ) {
      const response = yield call(fetch, window.RooConfig.API_URL + "api/admin/feedbackform", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(oSave),
      });

      feedbackResponseData = yield response.json();
    }
    yield put({ type: UNSET_LOADER });

    if (isRemoveConfirmedVet) {
      feedbackResponseData = actionResponse;
    }

    if (feedbackResponseData && feedbackResponseData.status_code === 200) {
      yield put({
        type: C.SUBMIT_FEEDBACK_SUCCESS,
        // payload: feedbackResponseData.data.data,
        shiftAction,
        isVetShift,
      });
      yield put({
        type: RESET_LAST_SHIFT_ACTION_DATA,
      });
    } else {
      let payload = {
        message: EXCEPTION_MESSAGE,
      };
      if (feedbackResponseData) {
        payload = {
          status: feedbackResponseData.status_code,
          message: feedbackResponseData.message,
        };
      }
      yield put({
        type: C.SUBMIT_FEEDBACK_FAIL,
        payload: payload,
      });
      yield put({
        type: RESET_LAST_SHIFT_ACTION_DATA,
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while submitting feedback: " } });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: C.SUBMIT_FEEDBACK_FAIL,
      payload: { status: error.status_code, message: EXCEPTION_MESSAGE },
    });
    yield put({
      type: RESET_LAST_SHIFT_ACTION_DATA,
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootFeedbackSaga() {
  yield takeEvery(C.SUBMIT_FEEDBACK_REQUEST, submitFeedback);
}
