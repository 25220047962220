export const hospitalSettings = [
  {
    id: "myAaccount",
    title: "My Account",
    desc: `Manage settings related to your own account. 
    This will not affect settings within your hospital’s account.`,
    url: `/hospital/manageSettings/myAccount`,
  },
  {
    id: "hospitalAaccount",
    title: "Hospital Account",
    desc: `Manage settings related to your hospital’s account. `,
    url: `/hospital/manageSettings/hospitalAccount`,
  },
  {
    id: "notification",
    title: "Notifications",
    desc: `Manage notifications for your account.`,
    url: `/notification`,
  },
];

export const vetSettings = [
  {
    id: "myAaccount",
    title: "My Account",
    desc: `Manage personal information and banking information settings. View your Independent Contractor Agreement.`,
    url: `/vet/settings`,
  },
  {
    id: "notification",
    title: "Notifications",
    desc: `Manage notifications for your account.`,
    url: `/notification`,
  },
];

export const techSettings = [
  {
    id: "myAaccount",
    title: "My Account",
    desc: `Manage personal information and banking information settings. View your Independent Contractor Agreement.`,
    url: `/tech/settings`,
  },

  {
    id: "notification",
    title: "Notifications",
    desc: `Manage notifications for your account.`,
    url: `/notification`,
  },
];

export const studentSettings = [
  {
    id: "myAccount",
    title: "My Account",
    desc: `Edit your personal information.`,
    url: `/student/settings`,
  },
  {
    id: "notification",
    title: "Notifications",
    desc: `Manage notifications for your account.`,
    url: `/notification`,
  },
];

export const myAccountSettingsMenus = [
  {
    id: "personalInformation",
    title: "Personal information",
    isDefault: true,
  },
];

export const hospitalAccountSettingsMenus = [
  {
    id: "manageUsers",
    title: "Manage account users",
    isDefault: true,
  },
  {
    id: "paymentInformation",
    title: "Payment information",
    isDefault: false,
  },
  {
    id: "managedvm",
    title: "Manage vets/techs",
    isDefault: false,
  },
  {
    id: "shiftDetails",
    title: "Shift Details",
    isDefault: false,
  },
];
