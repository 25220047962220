import _ from "lodash";

import * as regC from "../../../constants/registerVetConstants";
import * as C from "../../../constants/vetConstants";

const getHospitalProfileInitalState = () => ({
  hospitalName: "",
  hospitalWebsite: "",
  medicalDirector: "",
  roleId: null,

  typeOfHospital: null,
  isAahaAccredited: "",
  averageNoOfDVMWorkPerDay: "",
  averageNoOfTechPerDvmPerDay: "",
  typicalLunchBreakSetup: "",

  practiceManagementSoftware: "",

  hospitalReliefWork: "",
  hospitalMaintainRecords: "",
  hospitalDressCodes: "",

  hasApptGeneral: "",
  hasApptWellness: "",
  hasApptWalkin: "",
  hasApptNonwell: "",
  hasApptEmergency: "",
  hasApptHighVol: "",
  hasApptLargeAnimal: "",
  hasApptShelter: "",
  hasApptSurgery: "",
  hasApptSpecialty: "",
  hasApptDental: "",
  hasApptAcupuncture: "",
  hasApptChiropractic: "",
  hasApptLaser: "",
  hasApptUrgentCare: "",

  apptFlow: "",
  clientInteract: "",
  staffInteract: "",

  amenitiesNotes: "",
  parkingNotes: "",
});

const getHospitalUserInitialState = () => ({
  additionalSkills: "",
  characterTypeId: null,
  city: "",
  fullName: "",
  hospitalAddress: "",
  hospitalPhoneNumber: "",
  mobilePhoneNumber: "",
  stateId: null,
  zipcode: null,
});

const initialState = {
  isVetProfileFetched: false,
  isRooAdminVetProfileFetched: false,
  isRooAdminVetBasicInfoUpdateSuccess: false,
  isRooAdminVetProfessionalInfoUpdateSuccess: false,
  isUpdateVetOfflinePaymentSettingSuccess: false,
  openChat: false,
  isHospitalProfileOpen: false,
  hospitalProfileData: {},
  hospitalViewDetails: {
    hospital: getHospitalProfileInitalState(),
    user: getHospitalUserInitialState(),
    profileImage: [],
    availableShifts: [],
  },

  vetEditProfileDetails: {
    profileImage: [],
    vetResume: [],
    user: {
      aptUnitSuite: "",
      city: "",
      emailId: "",
      fullName: "",
      address: "",
      mobilePhoneNumber: "",
      stateId: "",
      zipcode: "",
      birthday: "",
      pronouns: "",
    },
    vet: {
      routingNumber: "",
      accountNumber: "",
      accountType: "",
      accountCreatedOn: "",
      plaidMicroDepositStatus: null,
      plaidMDStatusUpdatedOn: null,
    },
    aStripeConnectBankAccounts: [],
    vetAnimalType: [],
    vetLicenses: [],
    vetProcedure: [],
    vetSpecialization: [],
    vetTaxpayerInfo: [], // left in for admin
  },
};

// eslint-disable-next-line default-param-last
const vetProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case C.FETCH_HOSPITAL_VIEW_PROFILE_SUCCESS:
      return {
        ...state,
        hospitalViewDetails: {
          hospital: action.payload.hospital[0],
          user: action.payload.user[0],
          profileImage: action.payload.profileImage,
          availableShifts: [],
        },
      };

    case C.CLEAR_AVAILBLE_SHIFT:
    case C.FETCH_HOSPITAL_VIEW_PROFILE_FAIL:
      return {
        ...state,
        hospitalViewDetails: {
          hospital: getHospitalProfileInitalState(),
          user: getHospitalUserInitialState(),
          profileImage: [],
          availableShifts: [],
        },
      };
    case C.FETCH_HOSPITAL_AVAILABLE_SHIFT_SUCCESS:
      if (action.payload.data !== undefined) {
        return {
          ...state,
          hospitalViewDetails: {
            ...state.hospitalViewDetails,
            availableShifts: [...action.payload.data],
          },
        };
      } else {
        return {
          ...state,
          hospitalViewDetails: {
            ...state.hospitalViewDetails,
            availableShifts: [],
          },
        };
      }

    case C.REQUEST_AVAILABLE_SHIFT_SUCCESS:
      let shiftIndex = null;
      const updatedAvailableShift = _.cloneDeep(state.hospitalViewDetails.availableShifts);
      updatedAvailableShift.map((shift, index) => {
        if (shift.shiftGroupId === action.payload.data.shiftGroupId) {
          shiftIndex = index;
        }
      });
      updatedAvailableShift.splice(shiftIndex, 1);
      return {
        ...state,
        hospitalViewDetails: {
          ...state.hospitalViewDetails,
          availableShifts: updatedAvailableShift,
        },
      };

    case C.FETCH_VET_PROFILE_SUCCESS:
      return {
        ...state,
        vetEditProfileDetails: {
          ...state.vetEditProfileDetails,
          profileImage: action.payload.profileImage,
          vetResume: action.payload.vet[0].resumePath
            ? [
                {
                  resumeName: action.payload.vet[0].resumeName,
                  resumePath: action.payload.vet[0].resumePath,
                },
              ]
            : [],
          user: action.payload.user[0],
          vet: action.payload.vet[0],
          vetAnimalType: action.payload.vetAnimalType,
          vetLicenses: action.payload.vetLicences,
          vetProcedure: action.payload.vetProcedure,
          vetSpecialization: action.payload.vetSpecialization,
        },
        isVetProfileFetched: true,
      };

    case C.FETCH_ADMIN_VET_PROFILE_REQUEST:
      return {
        ...state,
        isRooAdminVetProfileFetched: false,
      };

    case C.FETCH_ADMIN_VET_PROFILE_SUCCESS:
      return {
        ...state,
        vetEditProfileDetails: {
          ...state.vetEditProfileDetails,
          profileImage: action.payload.profileImage,
          vetResume: action.payload.vet[0].resumePath
            ? [
                {
                  resumeName: action.payload.vet[0].resumeName,
                  resumePath: action.payload.vet[0].resumePath,
                },
              ]
            : [],
          user: action.payload.user[0],
          vet: action.payload.vet[0],
          aStripeConnectBankAccounts: action.payload.aStripeConnectBankAccounts,
          vetAnimalType: action.payload.vetAnimalType,
          vetLicenses: action.payload.vetLicences,
          vetProcedure: action.payload.vetProcedure,
          vetSpecialization: action.payload.vetSpecialization,
          vetTaxpayerInfo: action.payload.vetTaxpayerInfo,
          userAutoApproval: action.payload.userAutoApproval,
        },
        isRooAdminVetProfileFetched: true,
      };

    case C.FETCH_VET_PROFILE_FAIL:
      return {
        ...state,
      };

    case C.FETCH_ADMIN_VET_PROFILE_FAIL:
      return {
        ...state,
      };

    case C.UPDATE_ADMIN_VET_PROFILE_SUCCESS:
      return {
        ...state,
        isRooAdminVetBasicInfoUpdateSuccess: !!action.payload.isBasicInfoUpdate,
        isRooAdminVetProfessionalInfoUpdateSuccess: !!action.payload.isProfessionalInfoUpdate,
      };

    case C.RESET_VET_PLAID_MD_STATUS_SUCCESS:
      return {
        ...state,
        vetEditProfileDetails: {
          ...state.vetEditProfileDetails,
          vet: {
            ...state.vetEditProfileDetails.vet,
            plaidMicroDepositStatus: null,
            plaidMDStatusUpdatedOn: null,
          },
        },
      };

    case C.RESET_ADMIN_VET_PROFILE_SAVED_FLAGS:
      return {
        ...state,
        isRooAdminVetBasicInfoUpdateSuccess: false,
        isRooAdminVetProfessionalInfoUpdateSuccess: false,
        isUpdateVetOfflinePaymentSettingSuccess: false,
      };

    case C.UPDATE_VET_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        vetEditProfileDetails: {
          ...state.vetEditProfileDetails,
          profileImage: action.payload.vetImage,
        },
      };

    case C.DELETE_VET_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        vetEditProfileDetails: {
          ...state.vetEditProfileDetails,
          profileImage: [],
        },
      };

    case regC.UPLOAD_VET_RESUME_SUCCESS:
      return {
        ...state,
        vetEditProfileDetails: {
          ...state.vetEditProfileDetails,
          vetResume: action.payload.vetResume ? [{ ...action.payload.vetResume[0] }] : [], // vet registration also holds this value, so assume it needs to be cloned
        },
      };

    case regC.DELETE_VET_RESUME_SUCCESS:
      return {
        ...state,
        vetEditProfileDetails: {
          ...state.vetEditProfileDetails,
          vetResume: [],
        },
      };

    case C.UPDATE_VET_OFFLINE_PAYMENT_SETTING_SUCCESS:
      return {
        ...state,
        isUpdateVetOfflinePaymentSettingSuccess: true,
      };

    case C.GET_REQUESTS_BY_VET_ID_SUCCESS:
      return {
        ...state,
        vetRequestedShifts: action.payload,
      };

    case C.OPEN_HOSPITAL_PROFILE_PANEL_SUCCESS:
      return {
        ...state,
        hospitalProfileData: action.payload.hospitalProfileData,
        isHospitalProfileOpen: true,
        openChat: action.payload.openChat,
      };

    case C.OPEN_HOSPITAL_PROFILE_PANEL_FAIL:
      return {
        ...state,
        hospitalProfileData: {},
        isHospitalProfileOpen: false,
      };

    case C.CLOSE_HOSPITAL_PROFILE_PANEL:
      return {
        ...state,
        hospitalProfileData: {},
        isHospitalProfileOpen: false,
        openChat: false,
        hospitalViewDetails: {
          ...state.hospitalViewDetails,
          availableShifts: [],
        },
      };

    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default vetProfileReducer;
