import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Select } from "@roo-dev/ui-components";

import { setEnterpriseHospitalSession } from "../../../../../Enterprise/actions/enterpriseActions";
import { setLoaderAction } from "../../../../../loader/actions/loaderAction";

import { HospitalSelectContainer } from "./styles";

export const HospitalsSelect = ({
  onSelect,
  onChange,
}: {
  onSelect?: (hospitalId: number) => void;
  onChange?: (text: string) => void;
}) => {
  const dispatch = useDispatch();
  const { hospitalId, myHospitalsList } = useSelector((state: any) => state.login);

  const [allSelected, setAllSelected] = useState(false);

  const setHospitalSession = (hospitalId: number) => {
    if (hospitalId > -1) {
      setAllSelected(false);
      dispatch(setLoaderAction());
      dispatch(setEnterpriseHospitalSession({ hospitalId }));
      if (onSelect) {
        onSelect(hospitalId);
      }
    } else {
      setAllSelected(true);
      onSelect(null);
    }
  };

  const options = myHospitalsList.map((hospital: { name: string; id: number }) => ({
    label: hospital.name,
    value: hospital.id,
  }));
  if (onChange) {
    options.unshift({ label: "All Hospitals", value: -1 });
  }
  let value = hospitalId ? hospitalId : myHospitalsList[0]?.id;
  if (allSelected) {
    value = -1;
  }
  return (
    <HospitalSelectContainer>
      <Select
        label="Hospital"
        value={value}
        testId="hospitalSelect"
        onChange={(opt) => {
          setHospitalSession(opt.value as number);
        }}
        options={options}
        onSearch={onChange}
        searchable
      />
    </HospitalSelectContainer>
  );
};
