import * as Sentry from "@sentry/react";

const callSendResetPasswordEmail = async (email: string) => {
  try {
    // eslint-disable-next-line roo/no-restricted-functions
    return await fetch(`${window.RooConfig.API_URL}api/user/canSendResetPasswordEmail/${email}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error updating hospital fear free certification: " },
    });

    throw new Error(error);
  }
};

export const canSendResetPasswordEmail = async (email: string) => {
  const response = await callSendResetPasswordEmail(email);
  const responseData = await response.json();
  const { canSendResetPasswordEmail } = responseData;
  return canSendResetPasswordEmail;
};
