import { SET_REPUTATION_LOCATION_FAVORABILITY_SUCCESS } from "../../../constants/adminUsersList";
import * as regC from "../../../constants/constants";
import * as C from "../../../constants/hospitalConstants";
const getInitVetViewProfileDetails = () => ({
  profileImage: "",
  vetUserDetails: {
    additionalSkills: "",
    characterTypeId: null,
    city: null,
    fullName: "",
    hearAboutRoo: 1,
    hospitalAddress: null,
    hospitalPhoneNumber: null,
    mobilePhoneNumber: "",
    stateId: null,
    zipcode: null,
  },
  vetLicences: [],
  vetData: {
    graduationYear: null,
    vetSchool: "",
    yearsOfPractice: null,
  },
  vetAnimalType: [],
  vetProcedure: [],
  vetSpecialization: [],
  vetBadges: {
    currentBadges: [],
    upComingBadges: [],
  },
});

const getInitTechViewProfileDetails = () => ({
  profileImage: "",
  techUserDetails: {
    additionalSkills: "",
    characterTypeId: null,
    city: null,
    fullName: "",
    hearAboutRoo: 1,
    hospitalAddress: null,
    hospitalPhoneNumber: null,
    mobilePhoneNumber: "",
    stateId: null,
    zipcode: null,
  },
  techLicences: [],
  techData: {
    graduationYear: null,
    techSchool: "",
    yearsOfPractice: null,
  },
  techAnimalType: [],
  techProcedure: [],
  techSpecilization: [],
});

const initialState = {
  hospitalData: {},
  userData: {},
  hospitalImages: [],
  hospitalShiftImageObj: null,
  vetViewProfileDetails: getInitVetViewProfileDetails(),
  techViewProfileDetails: getInitTechViewProfileDetails(),
  states: [],
  isShowPhotoLoader: false,
  hospital501c3Letter: [],
  hospitalActivityDetails: {},
};

// eslint-disable-next-line default-param-last
const hospitalProfileReducer = (state = initialState, action) => {
  let hospitalShiftImageObj = null;
  switch (action.type) {
    case C.FETCH_HOSPITAL_DETAILS_SUCCESS:
      // check if image is shift image
      hospitalShiftImageObj = null;
      if (action.payload.profileImage) {
        for (let index = 0; index < action.payload.profileImage.length; index++) {
          action.payload.profileImage[index];
          if (action.payload.profileImage[index].isShiftPhoto) {
            hospitalShiftImageObj = action.payload.profileImage[index];
            action.payload.profileImage.splice(index, 1);
            break;
          }
        }
      }
      if (hospitalShiftImageObj) {
        localStorage.setItem("profileImage", hospitalShiftImageObj.imagePath);
      }
      return {
        ...state,
        hospitalData: {
          ...action.payload.hospital[0],
        },
        userData: {
          ...action.payload.user[0],
        },
        hospitalImages: action.payload.profileImage,
        hospital501c3Letter: action.payload.hospital[0].letterPath
          ? [
              {
                letterName: action.payload.hospital[0].letterName,
                letterPath: action.payload.hospital[0].letterPath,
              },
            ]
          : [],
        hospitalShiftImageObj: hospitalShiftImageObj,
        hospitalActivityDetails: {
          lastLoginDate: action.payload.hospital[0].lastLoginDate,
          lastPostedDate: action.payload.hospital[0].lastPostedDate,
          reputation: action.payload.hospital[0].reputation,
          locationFavorability: action.payload.hospital[0].locationFavorability,
          hospitalName: action.payload.hospital[0].hospitalName,
          companyId: action.payload?.user?.length ? action.payload.user[0].companyId : null,
          emailId: action.payload.user?.length ? action.payload.user[0].emailId : null,
        },
      };

    case C.UPDATE_HOSPITAL_SETTING_INFORMATION_SUCCESS:
      localStorage.setItem("hospitalStateId", action.payload.updatedInfo.stateId);
      return {
        ...state,
        hospitalData: {
          ...state.hospitalData,
          aptUnitSuite: action.payload.updatedInfo.aptUnitSuite,
          city: action.payload.updatedInfo.city,
          hospitalAddress: action.payload.updatedInfo.hospitalAddress,
          hospitalName: action.payload.updatedInfo.hospitalName,
          hospitalWebsite: action.payload.updatedInfo.hospitalWebsite,
          phoneNumber: action.payload.updatedInfo.phoneNumber,
          roleId: action.payload.updatedInfo.roleId,
          stateId: action.payload.updatedInfo.stateId,
          zipcode: action.payload.updatedInfo.zipcode.toString(),
        },
      };

    case SET_REPUTATION_LOCATION_FAVORABILITY_SUCCESS:
      const data = {};
      if (action.payload.data.reputation) {
        data.reputation = action.payload.data.reputation;
      }
      if (action.payload.data.location_favorability) {
        data.locationFavorability = action.payload.data.location_favorability;
      }
      return {
        ...state,
        hospitalData: {
          ...state.hospitalData,
          ...data,
        },
        hospitalActivityDetails: {
          ...state.hospitalActivityDetails,
          ...data,
        },
      };

    case C.FETCH_VET_VIEW_PROFILE_DETAILS_REQUEST:
      return {
        ...state,
        vetViewProfileDetails: getInitVetViewProfileDetails(),
      };

    case C.FETCH_VET_VIEW_PROFILE_DETAILS_SUCCESS:
      return {
        ...state,
        vetViewProfileDetails: {
          ...state.vetViewProfileDetails,
          profileImage:
            action.payload.profileImage.length === 0
              ? ""
              : action.payload.profileImage[0].imagePath,
          vetUserDetails: {
            ...action.payload.user[0],
          },
          vetData: {
            ...action.payload.vet[0],
          },
          vetLicences: action.payload.vetLicences,
          vetAnimalType: action.payload.vetAnimalType,
          vetProcedure: action.payload.vetProcedure,
          vetSpecialization: action.payload.vetSpecialization,
          vetBadges: action.payload.vetBadges,
        },
      };

    case C.FETCH_TECH_VIEW_PROFILE_DETAILS_REQUEST:
      return {
        ...state,
        techViewProfileDetails: getInitTechViewProfileDetails(),
      };

    case C.FETCH_TECH_VIEW_PROFILE_DETAILS_SUCCESS:
      return {
        ...state,
        techViewProfileDetails: {
          ...state.techViewProfileDetails,
          profileImage:
            action.payload.profileImage.length === 0
              ? ""
              : action.payload.profileImage[0].imagePath,
          techUserDetails: {
            ...action.payload.user[0],
          },
          techData: {
            ...action.payload.tech[0],
          },
          techLicences: action.payload.tech[0].licenses,
        },
      };

    case C.FETCH_STATES_HOSPITAL_PROFILE_SUCCESS:
      return {
        ...state,
        states: action.payload.data,
      };

    case C.UPDATE_HOSPITAL_IMAGES_REQUEST:
    case C.DELETE_HOSPITAL_PROFILE_IMAGE_REQUEST:
      return {
        ...state,
        isShowPhotoLoader: true,
      };

    case C.UPDATE_HOSPITAL_IMAGES_SUCCESS:
    case C.DELETE_HOSPITAL_PROFILE_IMAGE_SUCCESS:
      // check if image is shift image
      hospitalShiftImageObj = null;
      if (action.payload.hospitalImages) {
        for (let index = 0; index < action.payload.hospitalImages.length; index++) {
          if (action.payload.hospitalImages[index].isShiftPhoto) {
            hospitalShiftImageObj = action.payload.hospitalImages[index];
            action.payload.hospitalImages.splice(index, 1);
            break;
          }
        }
      }
      if (hospitalShiftImageObj) {
        localStorage.setItem("profileImage", hospitalShiftImageObj.imagePath);
      }
      return {
        ...state,
        isShowPhotoLoader: false,
        hospitalImages: action.payload.hospitalImages,
        hospitalShiftImageObj: hospitalShiftImageObj,
      };

    case C.UPDATE_HOSPITAL_IMAGES_FAIL:
    case C.DELETE_HOSPITAL_PROFILE_IMAGE_FAIL:
      return {
        isShowPhotoLoader: false,
        ...state,
      };
    case C.INITIALIZE_HOSPITAL_PROFILE_STATE:
      return {
        ...state,
        hospitalData: {},
      };

    case regC.UPLOAD_HOSPITAL_501C3_LETTER_SUCCESS:
      return {
        ...state,
        hospital501c3Letter: action.payload.hospital501c3Letter
          ? [{ ...action.payload.hospital501c3Letter[0] }]
          : [],
      };

    case regC.DELETE_HOSPITAL_501C3_LETTER_SUCCESS:
      return {
        ...state,
        hospital501c3Letter: [],
      };

    case C.ARRANGE_HOSPITAL_IMAGES:
      return {
        ...state,
        hospitalImages: action.payload.hospitalImages,
      };

    case C.SHIFT_IDS_BY_HOSPITAL_ID_SUCCESS:
      return {
        ...state,
        shiftIds: action.payload,
      };

    case C.TECH_SHIFT_IDS_BY_HOSPITAL_ID_SUCCESS:
      return {
        ...state,
        techShiftIds: action.payload,
      };

    case C.SHIFT_IDS_BY_HOSPITAL_ID_FAIL:
      return {
        ...state,
        shiftIds: [],
      };

    case C.TECH_SHIFT_IDS_BY_HOSPITAL_ID_FAIL:
      return {
        ...state,
        techShiftIds: [],
      };

    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default hospitalProfileReducer;
