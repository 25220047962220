import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { useUserPromos } from "../api/utility/getUserPromos";
import giftCard from "../static/images/gift_card.svg";

export const PromoNavLink = (props) => {
  const { userId } = useSelector((state) => state.login);
  const { promos } = useUserPromos(userId);
  const toRoute = props.route.promos + (promos.eligible?.length ? "" : "/active");

  return (
    <NavLink activeClassName="is-active" to={toRoute}>
      <img alt="Gift" src={giftCard} className="mr-1" /> Promos
    </NavLink>
  );
};
