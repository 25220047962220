import { z } from "zod";

import { createForm } from "../../../Common/EditProfile/formContext";
import { isValidPassword } from "../../../Common/Form/PasswordInput";

export const resetPasswordSchema = z
  .object({
    password: z.string(),
    passwordConfirm: z.string(),
  })
  .superRefine(({ password, passwordConfirm }, ctx) => {
    if (!isValidPassword(password)) {
      ctx.addIssue({
        code: "custom",
        message: "The password format is invalid",
        path: ["password"],
      });
    } else if (passwordConfirm !== password) {
      ctx.addIssue({
        code: "custom",
        message: "The passwords did not match",
        path: ["passwordConfirm"],
      });
    }
  });

export type ResetPasswordForm = z.infer<typeof resetPasswordSchema>;

const { useFormContext, FormProvider } = createForm<ResetPasswordForm>();

export { FormProvider, useFormContext };
