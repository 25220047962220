import * as Sentry from "@sentry/react";
import "isomorphic-fetch";
import { call, put, takeEvery } from "redux-saga/effects";
import "../../helpers/InterceptFetch";

import * as ErrorMessage from "../../constants/errorMessage";
import { SET_LOADER, UNSET_LOADER } from "../../constants/loader";
import { UPDATE_USER_PERSONAL_INFO_SUCCESS } from "../../constants/Login";
import * as ActionTypes from "../../constants/student";

function* fetchStudentProfileAsync(action) {
  try {
    const { studentId } = action.payload;
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + `api/student/getStudentInfoById/${studentId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = yield response.json();
    if (data && data.status_code === 200) {
      yield put({
        type: ActionTypes.FETCH_STUDENT_PROFILE_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: ActionTypes.FETCH_STUDENT_PROFILE_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
    yield put({ type: UNSET_LOADER });
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while fetching student profile: " },
    });
    yield put({
      type: ActionTypes.FETCH_STUDENT_PROFILE_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* studentUpdateProfileAsync(action) {
  try {
    const response = yield call(fetch, window.RooConfig.API_URL + "api/student/studentProfile", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: ActionTypes.UPDATE_STUDENT_PROFILE_SUCCESS,
      });
      yield put({ type: SET_LOADER });
      yield put({
        type: ActionTypes.FETCH_STUDENT_PROFILE_REQUEST,
        payload: { studentId: action.payload.studentId },
      });
    } else {
      yield put({
        type: ActionTypes.UPDATE_STUDENT_PROFILE_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating student profile: " },
    });
    yield put({
      type: ActionTypes.UPDATE_STUDENT_PROFILE_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* getStudentBasicInformationAsync(action) {
  try {
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/student/studentBasicInformation?userId=" + action.userId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: ActionTypes.FETCH_STUDENT_SETTINGS_BASIC_INFO_SUCCESS,
        payload: data.data.data[0],
      });
    } else if (data.status_code === 400) {
      yield put({
        type: ActionTypes.FETCH_STUDENT_SETTINGS_BASIC_INFO_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while fetching student basic information: " },
    });
    yield put({
      type: ActionTypes.FETCH_STUDENT_SETTINGS_BASIC_INFO_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* studentUpdateSettingsAsync(action) {
  try {
    const studentData = {
      ...action.payload,
      stateId: action.payload.stateId.value || action.payload.stateId,
      stateName: action.payload.stateId.label || action.payload.stateName,
    };
    const response = yield call(fetch, window.RooConfig.API_URL + "api/student/studentSettings", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(studentData),
    });

    const data = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: UPDATE_USER_PERSONAL_INFO_SUCCESS,
        payload: studentData,
      });
      yield put({
        type: ActionTypes.UPDATE_STUDENT_SETTINGS_SUCCESS,
        payload: action.payload,
      });
    } else {
      yield put({
        type: ActionTypes.UPDATE_STUDENT_SETTINGS_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating student settings: " },
    });
    yield put({
      type: ActionTypes.UPDATE_STUDENT_SETTINGS_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootVetProfileSaga() {
  yield takeEvery(ActionTypes.FETCH_STUDENT_PROFILE_REQUEST, fetchStudentProfileAsync);
  yield takeEvery(ActionTypes.UPDATE_STUDENT_PROFILE_REQUEST, studentUpdateProfileAsync);
  yield takeEvery(
    ActionTypes.FETCH_STUDENT_SETTINGS_BASIC_INFO_REQUEST,
    getStudentBasicInformationAsync
  );
  yield takeEvery(ActionTypes.UPDATE_STUDENT_SETTINGS_REQUEST, studentUpdateSettingsAsync);
}
