export const PENDING = 0;
export const APPROVED = 1;
export const NONRESPONSIVE = 2;
export const DISABLED = 3;
export const BLOCKED = 4;
export const ACTIVE = 5;
export const REGISTERED_R4U = 6;
export const INVITED = 7;
export const INCOMPLETE = 8;
export const SUSPENDED = 9;
export const POSTING_PAUSED = 10;
export const NO_ASSIGNED_HOSPITALS = 11;
export const PENDING_AUTO_APPROVAL = 12;
