export const TIMEZONE: { [key: string]: string } = {
  IST: "Asia/Kolkata",
  EST: "America/New_York",
  CST: "America/Chicago",
  MST: "America/Denver",
  PST: "America/Los_Angeles",
};

export const TIMEZONE_PROD = [
  {
    value: "EST",
    label: "EST",
    name: "time_zone",
  },
  {
    value: "CST",
    label: "CST",
    name: "time_zone",
  },
  {
    value: "MST",
    label: "MST",
    name: "time_zone",
  },
  {
    value: "PST",
    label: "PST",
    name: "time_zone",
  },
];
