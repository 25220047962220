import * as ActionTypes from "../chatConstants";

export function startChatLoading(message: string) {
  return {
    type: ActionTypes.START_CHAT_WINDOW_LOADING,
    payload: { message },
  };
}

export function stopChatLoading() {
  return {
    type: ActionTypes.STOP_CHAT_WINDOW_LOADING,
  };
}

export function getConvoInfo(data: {
  hospitalId: number;
  vetUserId: number;
  hospitalUserId?: number;
}) {
  return {
    type: ActionTypes.GET_CONVO_INFO,
    payload: data,
  };
}

export function setChatUnvailableStatus(status: boolean) {
  return {
    type: ActionTypes.TOGGLE_CHAT,
    payload: status,
  };
}

export function createConversation(data: { shiftGroupId: number | string }) {
  return {
    type: ActionTypes.CREATE_CONVERSATION,
    payload: data,
  };
}

export function resetChatState() {
  return {
    type: ActionTypes.CHAT_RESET_STATE,
  };
}

export function openChatPanelFromBanner(payload: {
  shiftId?: string | number;
  userTypeId?: string | number;
  hospitalId?: string | number;
  vetUserId?: string | number;
  techUserId?: string | number;
}) {
  return {
    type: ActionTypes.OPEN_CHAT_PANEL_FROM_BANNER,
    payload,
  };
}

export function fetchChatBannersData(payload: { userEmail: string; hospitalId?: string }) {
  return {
    type: ActionTypes.FETCH_CHAT_BANNERS_DATA,
    payload,
  };
}

export function openChatPanel(payload: {
  shiftId?: string | number;
  hospitalId?: string | number;
  contractorUserId?: string | number;
  shiftGroupId?: string | number;
  vetId?: string | number;
  techId?: string | number;
}) {
  return {
    type: ActionTypes.OPEN_CHAT_PANEL,
    payload,
  };
}

export function closeChatPanel() {
  return {
    type: ActionTypes.CLOSE_CHAT_PANEL,
  };
}
