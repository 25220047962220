import { Auth } from "aws-amplify";

import { identifyPosthog } from "../Common/Wrappers/identifyPosthog";
import { TECH_USER_TYPE, UserType } from "../constants/UserTypeConstants";

import { License, ProfileImage, User } from "./vet";

export interface TechProfile {
  graduationYear: number;
  yearsOfPractice: number;
  freeDaysInMonthToWork: number;
  wantsFullTime: number;
  isPrefGeneral: boolean;
  isPrefEmergency: boolean;
  isPrefHighVol: boolean;
  isPrefLargeAnimal: boolean;
  isPrefShelter: boolean;
  isPrefSurgery: boolean;
  isPrefSpecialty: boolean;
  isPrefChiropractic: boolean;
  isPrefLaserTherapy: boolean;
  isPrefAcupunture: boolean;
  isPrefUrgentCare: boolean;
  isPrefOther: boolean;
  prefOtherHospType?: string;
  appointmentFlow?: number;
  resumeName?: string;
  resumePath?: string;
  licenses?: License[];
  techSchool?: string;
  degreeCertificate?: string;
  skillIds?: string;
  specializationIds?: string;
  softwareIds?: string;
  isCertified?: number;
  isLicensed?: number;
  isRegistered?: number;
  isVTS?: number;
  credentialStateIds?: string;
  equipmentIds?: string;
  techAvailability: number;
  otherEquipment?: string;
  practiceManagementSoftware?: string;
  hasFearFreeCertification?: number;
}

export interface TechSkill {
  id: any;
  label: string;
  type: string;
  checked?: boolean;
}
export interface TechSkills {
  [key: string]: TechSkill[];
}

export interface TechSpecialization {
  id: number;
  label?: string;
  specialization_type?: string;
  checked?: boolean;
}

export interface Software {
  id: number;
  label: string;
  checked?: boolean;
}
export interface Equipment {
  id: number;
  label: string;
  checked?: boolean;
}
export interface TechProfileFrom extends TechProfile, User {
  profileImage?: ProfileImage[];
  licenses?: License[];
  deletedLicenses?: [];
  isLicenseModified?: {
    isAdded: boolean;
    isUpdated: boolean;
  };
  techSkills: TechSkills;
  techSpecializations: TechSpecialization[];
  software: Software[];
  equipment: Equipment[];
  credentialStates: number[];
}

export interface TechProfileResponse {
  user: User[];
  tech: TechProfile[];
  profileImage: ProfileImage[];
}
export const fetchTechProfile = async (
  userTypeId: UserType,
  techId: string
): Promise<TechProfileResponse> => {
  // eslint-disable-next-line roo/no-restricted-functions
  const response = await fetch(
    `${window.RooConfig.API_URL}api/tech/getTechInfoById?userType=${userTypeId}&techId=${techId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const { data } = await response.json();
  return data;
};

interface UpdateTechProfileRequest extends TechProfileFrom {
  userId: string;
  techId: string;
}
export const updateTechProfile = async (
  request: UpdateTechProfileRequest
): Promise<TechProfileResponse> => {
  // eslint-disable-next-line roo/no-restricted-functions
  const response = await fetch(`${window.RooConfig.API_URL}api/tech/techProfile`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(request),
  });
  if (response.status !== 200) {
    throw new Error("Api failed");
  }
  const { data } = await response.json();
  return data;
};

export interface EventTechSignUpForm {
  userTypeId: UserType;
  firstName?: string;
  lastName?: string;
  email: string;
  password: string;
  phoneNumber?: string;
  zipcode?: string;
  travelDistance?: number;
  isLicensed: boolean;
  isCertified: boolean;
  isRegistered: boolean;
  isVTS: boolean;
  licenseNumber?: string;
  utmSource?: string;
  utmCampaign?: string;
  utmMedium?: string;
}

export interface CreateTechResponseData extends EventTechSignUpForm {
  userId: string;
}

export interface CreateTechDataAccountResponse {
  success: boolean;
  error: string;
  data: CreateTechResponseData;
  signInRequest: ReturnType<typeof Auth.signIn>;
}

export const createTechDataAccount = async (
  tech: EventTechSignUpForm
): Promise<CreateTechDataAccountResponse> => {
  const email = tech.email.toLowerCase();
  const password = tech.password;

  const { userSub: cognitoId } = await Auth.signUp({
    username: email,
    password,
    attributes: {
      "custom:usertype": tech.userTypeId.toString(),
    },
  });

  const signInRequest = await Auth.signIn(email, password);

  const url = `${window.RooConfig.API_URL}api/user/createAccountWithData`;
  const headers = { "Content-Type": "application/json" };
  const { password: _, ...rest } = tech;
  const body = JSON.stringify({ ...rest, cognitoId });

  // eslint-disable-next-line roo/no-restricted-functions
  const response = await fetch(url, { method: "POST", headers, body });

  if (!response.ok) {
    throw Error("api error");
  }

  const { data } = await response.json();

  // Ideally we'd set metroAreaId here.
  identifyPosthog({
    userId: data.data.userId,
    email,
    userType: TECH_USER_TYPE,
  });

  return { signInRequest, ...data };
};

export const resetPlaidMDStatus = async (techId: number): Promise<boolean> => {
  // eslint-disable-next-line roo/no-restricted-functions
  const response = await fetch(`${window.RooConfig.API_URL}api/tech/resetPlaidMDStatus/${techId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
  });
  let result = false;
  if (response.status === 200) {
    result = await response.json();
  }
  return result;
};
