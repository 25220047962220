import * as Sentry from "@sentry/react";
import "isomorphic-fetch";
import { call, put, takeEvery } from "redux-saga/effects";
import "../../../helpers/InterceptFetch";

import { showPromoShiftSignUpToast } from "../../../Common/Toasts/action/ToastActions";
import { SHIFT_REQUEST } from "../../../constants/brazeEventNameConstants";
import * as ErrorMessage from "../../../constants/errorMessage";
import { SET_LOADER, UNSET_LOADER } from "../../../constants/loader";
import { SET_SESSION_REQUEST } from "../../../constants/Login";
import * as ActionTypes from "../../../constants/vetConstants";
import { logBrazeCustomEvent } from "../../../helpers/brazeUtility";

function* fetchHospitalViewDetailsAsync(action) {
  try {
    const response = yield call(
      fetch,
      window.RooConfig.API_URL +
        `api/hospital/getHopsitalProfileInfoById?userType=${action.payload.userTypeId}&hospitalId=${action.payload.hospitalId} `,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = yield response.json();
    if (data.status_code === 200) {
      yield put({ type: ActionTypes.FETCH_HOSPITAL_VIEW_PROFILE_SUCCESS, payload: data.data });
      yield fetchHospitalAvailableShiftsAsync(action);
      yield put({ type: UNSET_LOADER });
    } else {
      yield put({ type: ActionTypes.FETCH_HOSPITAL_VIEW_PROFILE_FAIL });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while fetching hospital view details" },
    });
    yield put({
      type: ActionTypes.FETCH_HOSPITAL_VIEW_PROFILE_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* fetchHospitalAvailableShiftsAsync(action) {
  try {
    const isExcludeBlockedShifts = action.payload.isExcludeBlockedShifts || false;
    const response = yield call(
      fetch,
      window.RooConfig.API_URL +
        `api/vet/shift/getShiftsByHospitalId?hospitalId=${action.payload.hospitalId}&vetId=${action.payload.vetId}&isExcludeBlockedShifts=${isExcludeBlockedShifts}&latitude=${action.payload.latitude}&longitude=${action.payload.longitude}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = yield response.json();
    if (data.status_code === 200) {
      yield put({ type: ActionTypes.FETCH_HOSPITAL_AVAILABLE_SHIFT_SUCCESS, payload: data.data });
    } else {
      yield put({ type: ActionTypes.FETCH_HOSPITAL_AVAILABLE_SHIFT_FAIL });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while fetching hospital available shifts" },
    });
    yield put({
      type: ActionTypes.FETCH_HOSPITAL_AVAILABLE_SHIFT_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* requestHospitalShiftAsync(action) {
  try {
    const response = yield call(fetch, window.RooConfig.API_URL + "api/vet/shift/requestShift", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(action.payload),
    });

    logBrazeCustomEvent(SHIFT_REQUEST, { shift_id: action.payload.shiftId });

    const data = yield response.json();
    if (data.status_code === 200) {
      const requestMessage = data.message;
      if (requestMessage == "User blocked") {
        localStorage.setItem("isActive", 4);
        window.location.reload();
      } else {
        if (data.data.added === 0) {
          const { hospitalName, shiftDate } = action.payload;
          yield put({
            type: ActionTypes.VET_REQUEST_SHIFT_NOT_AVAILABLE,
            payload: { hospitalName, shiftDate, message: data.data.message },
          });
        } else {
          yield fetchHospitalAvailableShiftsAsync({
            type: ActionTypes.FETCH_HOSPITAL_AVAILABLE_SHIFT_REQUEST,
            payload: data.data.data,
          });
          if (data.data.data.promoId) yield put(showPromoShiftSignUpToast());
        }
        yield put({
          type: ActionTypes.CHECK_VET_LICENSE_EXPIRING_REQUEST,
          payload: { vetId: action.payload.vetId },
        });
      }
    } else {
      yield put({
        type: ActionTypes.REQUEST_AVAILABLE_SHIFT_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
    yield put({ type: UNSET_LOADER });
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while requesting hospital shift" },
    });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: ActionTypes.REQUEST_AVAILABLE_SHIFT_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* fetchVetProfileAsync(action) {
  try {
    const response = yield call(
      fetch,
      window.RooConfig.API_URL +
        `api/vet/getVetInfoById?userType=${action.payload.userTypeId}&vetId=${action.payload.vetId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({ type: ActionTypes.FETCH_VET_PROFILE_SUCCESS, payload: data.data });
    } else {
      yield put({ type: ActionTypes.FETCH_VET_PROFILE_FAIL });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while fetching vet profile" } });
    yield put({
      type: ActionTypes.FETCH_VET_PROFILE_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* fetchAdminVetEditProfileInfoAsync(action) {
  try {
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + `api/admin/users/vets/${action.payload.vetId}/profile?offset=1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = yield response.json();

    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      yield put({ type: ActionTypes.FETCH_ADMIN_VET_PROFILE_SUCCESS, payload: data.data });
    } else {
      yield put({ type: ActionTypes.FETCH_ADMIN_VET_PROFILE_FAIL });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while fetching admin vet edit profile info" },
    });
    yield put({
      type: ActionTypes.FETCH_ADMIN_VET_PROFILE_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* vetUpdateProfilePhotoAsync(action) {
  const vetId = localStorage.getItem("vetId")
    ? localStorage.getItem("vetId")
    : action.payload.vetId;

  const formData = new FormData();

  for (let i = 0; i < action.payload.files.length; i++) {
    formData.append("document", action.payload.files[i]);
    formData.append("userId", vetId);
    formData.append("userType", "vet");
    formData.append("userId", action.payload.userId);
    formData.append("fileName", action.payload.fileName);
  }

  try {
    const response = yield call(fetch, window.RooConfig.API_URL + "api/user/uploadDocument", {
      method: "POST",
      body: formData,
    });

    const data = yield response.json();
    if (data.status_code === 200) {
      localStorage.setItem("profileImage", data.data.data[0].imagePath || "");
      yield put({ type: SET_SESSION_REQUEST });
      yield put({
        type: ActionTypes.UPDATE_VET_PROFILE_IMAGE_SUCCESS,
        payload: { status: data.status_code, vetImage: data.data.data },
      });
    } else {
      yield put({
        type: ActionTypes.UPDATE_VET_PROFILE_IMAGE_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating vet profile photo" },
    });
    yield put({
      type: ActionTypes.UPDATE_VET_PROFILE_IMAGE_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* deleteVetProfilePhotoAsync(action) {
  try {
    const response = yield call(fetch, window.RooConfig.API_URL + "api/user/deleteProfileImages", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();
    if (data.status_code === 200) {
      yield put({
        type: ActionTypes.DELETE_VET_PROFILE_IMAGE_SUCCESS,
        payload: { status: data.status_code, vetImage: data.data.data },
      });
    } else {
      yield put({
        type: ActionTypes.DELETE_VET_PROFILE_IMAGE_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while deleting vet profile photo" },
    });
    yield put({
      type: ActionTypes.DELETE_VET_PROFILE_IMAGE_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* adminVetUpdateProfileAsync(action) {
  try {
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + `api/admin/users/vets/${action.payload.vetId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();

    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({ type: SET_LOADER });
      yield fetchAdminVetEditProfileInfoAsync({
        payload: { vetId: action.payload.vetId, userTypeId: 2 },
      });
      yield put({
        type: ActionTypes.UPDATE_ADMIN_VET_PROFILE_SUCCESS,
        payload: action.payload,
      });
    } else {
      yield put({
        type: ActionTypes.UPDATE_ADMIN_VET_PROFILE_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating admin vet profile" },
    });
    yield put({
      type: ActionTypes.UPDATE_ADMIN_VET_PROFILE_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* deleteVetLicenseAsync(action) {
  try {
    const response = yield call(fetch, window.RooConfig.API_URL + "api/vet/vetLicense", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({ type: SET_LOADER });
      yield put({
        type: ActionTypes.CHECK_VET_LICENSE_EXPIRING_REQUEST,
        payload: { vetId: action.payload.vetId },
      });
      yield put({
        type: ActionTypes.CHECK_VET_LICENSE_EXPIRED_REQUEST,
        payload: { vetId: action.payload.vetId },
      });
      yield fetchVetProfileAsync({ payload: { vetId: action.payload.vetId, userTypeId: 2 } });
    } else {
      yield put({
        type: ActionTypes.DELETE_VET_LICENSE_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while deleting vet license" } });
    yield put({
      type: ActionTypes.DELETE_VET_LICENSE_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* deleteVetSpecializationAsync(action) {
  try {
    const response = yield call(fetch, window.RooConfig.API_URL + "api/vet/vetSpecialization", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({ type: SET_LOADER });
      yield fetchVetProfileAsync({ payload: { vetId: action.payload.vetId, userTypeId: 2 } });
    } else {
      yield put({
        type: ActionTypes.DELETE_VET_SPECIALIZATION_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while deleting vet specialization" },
    });
    yield put({
      type: ActionTypes.DELETE_VET_SPECIALIZATION_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* updateVetOfflinePaymentSettingAsync(action) {
  try {
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/admin/users/vets/updateVetOfflinePaymentSetting",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: ActionTypes.UPDATE_VET_OFFLINE_PAYMENT_SETTING_SUCCESS,
      });
    } else {
      yield put({
        type: ActionTypes.UPDATE_VET_OFFLINE_PAYMENT_SETTING_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating vet offline payment setting" },
    });
    yield put({
      type: ActionTypes.UPDATE_VET_OFFLINE_PAYMENT_SETTING_FAIL,
      payload: {
        status: error.status_code,
        message: ErrorMessage.EXCEPTION_MESSAGE,
      },
    });
  }
}

function* getShiftRequestsByVetId() {
  try {
    const vetId = localStorage.getItem("vetId");
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + `api/hospital/shift/getShiftRequestsByVetId?vetId=${vetId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const responseData = yield response.json();
    if (responseData.status_code === 200) {
      yield put({
        type: ActionTypes.GET_REQUESTS_BY_VET_ID_SUCCESS,
        payload: responseData.data,
      });
    } else {
      yield put({
        type: ActionTypes.GET_REQUESTS_BY_VET_ID_FAIL,
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while fetching vet shift requests" },
    });
    yield put({ type: UNSET_LOADER });
  }
}

export function* openHospitalProfilePanel(action) {
  try {
    const shiftData = action.payload.shiftData;
    const userId = action.payload.userId;
    const hospitalId = action.payload.hospitalId;
    const openChat = Boolean(action?.payload?.openChat);

    yield put({
      type: ActionTypes.OPEN_HOSPITAL_PROFILE_PANEL_SUCCESS,
      payload: { hospitalProfileData: { shiftData, userId, hospitalId }, openChat },
    });
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while opening hospital profile panel" },
    });
    yield put({
      type: ActionTypes.OPEN_HOSPITAL_PROFILE_PANEL_FAIL,
      payload: { message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* updateDEALicenseInfo(action) {
  try {
    const response = yield call(fetch, window.RooConfig.API_URL + `api/vet/updateDEAInfo`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(action.payload),
    });
    const responseData = yield response.json();
    if (responseData.status_code === 200) {
      yield put({ type: UNSET_LOADER });
      yield put({
        type: ActionTypes.UPDATE_DEA_LICENSE_INFO_SUCCESS,
        payload: action.payload,
      });
    } else {
      yield put({
        type: ActionTypes.UPDATE_DEA_LICENSE_INFO_FAIL,
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "vetProfileSagas: updateDEALicenseInfo::" },
    });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: ActionTypes.UPDATE_DEA_LICENSE_INFO_FAIL,
    });
  }
}

export function* uploadResumeAsync(action) {
  const formData = new FormData();
  const vetId = action.payload.vetId ? action.payload.vetId : localStorage.getItem("vetId");

  for (let i = 0; i < action.payload.files.length; i++) {
    // check if we have more than 1 dot in file name
    if (action.payload.files[i].name.split(".").length > 2) {
      let actual = action.payload.files[i].name.split(".");
      const extention = actual[actual.length - 1];
      actual.splice(actual.length - 1, 1);
      actual = actual.join("_");
      formData.append("fileName", actual + "." + extention);
    }
    formData.append("document", action.payload.files[i]);
    formData.append("userId", vetId); // yes vetId , key name "userId" is used for s3 storage folder name
    formData.append("userType", "vet");
    formData.append("uploadFileType", "resume");
  }
  try {
    const response = yield call(fetch, window.RooConfig.API_URL + "api/user/uploadDocument", {
      method: "POST",
      body: formData,
    });
    const data = yield response.json();
    if (data.status_code === 200) {
      yield put({
        type: ActionTypes.UPLOAD_VET_RESUME_SUCCESS,
        payload: { status: data.status_code, vetResume: data.data.data },
      });
    } else {
      yield put({
        type: ActionTypes.UPLOAD_VET_RESUME_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while uploading vet resume" } });
    yield put({
      type: ActionTypes.UPLOAD_VET_RESUME_FAIL,
      payload: { status: error.status_code, message: error.message },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootVetProfileSaga() {
  yield takeEvery(ActionTypes.FETCH_HOSPITAL_VIEW_PROFILE_REQUEST, fetchHospitalViewDetailsAsync);
  yield takeEvery(
    ActionTypes.FETCH_HOSPITAL_AVAILABLE_SHIFT_REQUEST,
    fetchHospitalAvailableShiftsAsync
  );
  yield takeEvery(ActionTypes.REQUEST_AVAILABLE_SHIFT_REQUEST, requestHospitalShiftAsync);
  yield takeEvery(ActionTypes.FETCH_VET_PROFILE_REQUEST, fetchVetProfileAsync);
  yield takeEvery(ActionTypes.FETCH_ADMIN_VET_PROFILE_REQUEST, fetchAdminVetEditProfileInfoAsync);
  yield takeEvery(ActionTypes.UPDATE_VET_PROFILE_IMAGE_REQUEST, vetUpdateProfilePhotoAsync);
  yield takeEvery(ActionTypes.DELETE_VET_PROFILE_IMAGE_REQUEST, deleteVetProfilePhotoAsync);
  yield takeEvery(ActionTypes.UPDATE_ADMIN_VET_PROFILE_REQUEST, adminVetUpdateProfileAsync);
  yield takeEvery(ActionTypes.DELETE_VET_LICENSE_REQUEST, deleteVetLicenseAsync);
  yield takeEvery(ActionTypes.DELETE_VET_SPECIALIZATION_REQUEST, deleteVetSpecializationAsync);
  yield takeEvery(
    ActionTypes.UPDATE_VET_OFFLINE_PAYMENT_SETTING,
    updateVetOfflinePaymentSettingAsync
  );
  yield takeEvery(ActionTypes.UPLOAD_VET_RESUME_REQUEST, uploadResumeAsync);
  yield takeEvery(ActionTypes.GET_REQUESTS_BY_VET_ID, getShiftRequestsByVetId);
  yield takeEvery(ActionTypes.OPEN_HOSPITAL_PROFILE_PANEL, openHospitalProfilePanel);
  yield takeEvery(ActionTypes.UPDATE_DEA_LICENSE_INFO, updateDEALicenseInfo);
}
