import * as CONSTANTS from "../constants/UserPromoConstants";

export function getUserPromos(params: {
  userId: number;
  filter?: string;
  requestPeriodActive?: boolean;
  shiftGroupId?: number;
}) {
  return {
    type: CONSTANTS.GET_USER_PROMOS,
    payload: params,
  };
}
