import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Toast } from "@roo-dev/ui-components";

import { hideToast } from "../action/ToastActions";

export const ToastContainer = () => {
  const dispatch = useDispatch();
  const toastData = useSelector((state: { toast: any }) => state.toast.data);
  return (
    <>
      {toastData && (
        <Toast
          {...toastData}
          style={{ zIndex: 1060, ...(toastData.style ?? {}) }}
          onHide={() => dispatch(hideToast())}
        />
      )}
    </>
  );
};
