import {
  anyOptionValue,
  centerUsLatLng,
  countryZoom,
  onlyNoneOptionValue,
} from "../../../constants/adminForms";
import * as C from "../../../constants/adminUsersList";
import * as ConfirmMessages from "../../../constants/confirmMessage";
import * as ErrorMessages from "../../../constants/errorMessage";
import {
  createDictionary,
  getMetroAreasOptions,
  getRooStatesOptions,
} from "../../../helpers/jsUtility";

const initialState = {
  usersList: [],
  studentList: [],
  oStatesById: {},
  aStateOptions: [
    {
      value: onlyNoneOptionValue,
      label: "Non-Roo States",
      name: "stateSelection",
      center: centerUsLatLng,
      zoom: countryZoom,
    },
    {
      value: anyOptionValue,
      label: "Any State",
      name: "stateSelection",
      center: centerUsLatLng,
      zoom: countryZoom,
    },
  ],
  oStateOptions: {},
  aMetroAreaOptions: [
    {
      value: onlyNoneOptionValue,
      label: "No Metro Area Assigned",
      name: "metroAreaSelection",
    },
    {
      value: anyOptionValue,
      label: "Any Metro Area",
      name: "metroAreaSelection",
    },
  ],
  oMetroAreaOptions: {},
  oMetroAreaIdsByStateId: {},
  changeStatusSent: false,
  ratingSummary: [],
  averageRating: null,
  exportData: [],
  isExportRequested: null,
  hospitalList: [],
  rooAdminList: [],
  rooSuperUserList: [],

  isCreateRooInternalUserSuccess: false,
  isDeleteRooInternalUserSuccess: false,
  hospitalSearchTotal: null, // ***
  hospitalRequestSent: false,
  hospitalExportData: [],
  allStudents: [],
  paymentMethodAdded: null,
  totalRatingCount: null,
  fetchRatingStarted: null,
  isTierUpdated: null,
  techProfileData: [],
  isTechProfileUpdated: null,
  techProfileImage: [],
  techTaxpayerInfo: [],
  isTechProfilePhotoUpdated: null,
  isHospitalContactUpdated: false,
  isHospitalDeleted: false,
  isContractorDeleted: false,
  isStudentDeleted: false,
  delayPaymentUpdated: null,
  totalRating: null,
  isTechProfileFetched: false,
  vetSearchParam: "",
  techSearchParam: "",
  studentSearchParam: "",
  searchParam: "", // hospital users
  isError: false,
  errorMessage: null,
  isTechProbStatusUpdated: false,
  isUnsuspendedDateUpdated: false,
  isActionCompleted: false,
  modalMessage: ErrorMessages.EXCEPTION_MESSAGE,
  downloadedFile: null,
  reliability: null,
  showTreeShiftAcknowledgePrompt: localStorage.getItem("showTreeShiftAcknowledgePrompt") == "true",
  isInvalidAddress: false,
  isCityNotMatching: null,
  isDataloadingForFulltimePlacement: false,
  errorLoadingDataForFulltimePlacement: false,
  hospitalContractorsForFulltimePlacement: null,
  fulltimePlacementList: null,
  submitFulltimePlacementStatus: null,
  isInternshipAvaialibityUpdated: false,
  isStripeConnectAccountUpdateRequest: false,
  isStripeConnectAccountUpdateSuccess: false,
  isStripeConnectAccountUpdateFail: false,
  paymentMethodAddedSuccess: false,
  userTypeUpdateTo: null,
};

// eslint-disable-next-line default-param-last
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case C.GET_USER_LIST_SUCCESS:
      return {
        ...state,
        usersList: action.payload.data,
      };
    case C.CLEAR_USER_LIST:
      return {
        ...state,
        usersList: [],
      };
    case C.GET_CONTRACTOR_LIST:
      return {
        ...state,
        usersList: [],
        changeStatusSent: false,
        isExportRequested: false,
        exportData: [],
        isTierUpdated: null,
        isTechProfileUpdated: null,
        techProfileData: [],
        techProfileImage: [],
        techTaxpayerInfo: [],
        isTechProfilePhotoUpdated: null,
        vetSearchParam: action.userType === 2 ? action.contractorSearchParam : "",
        techSearchParam: action.userType === 4 ? action.contractorSearchParam : "",
        searchParam: "", // to clear hospital user filter
        isActionCompleted: false,
        isTechProbStatusUpdated: false,
        isUnsuspendedDateUpdated: false,
        modalMessage: ErrorMessages.EXCEPTION_MESSAGE,
      };
    case C.GET_CONTRACTOR_LIST_SUCCESS:
      return {
        ...state,
        usersList: action.payload.data,
        totalCount: action.payload.totalCount,
      };

    case C.GET_STUDENT_LIST:
      return {
        ...state,
        studentList: [],
        changeStatusSent: false,
        isExportRequested: false,
        exportData: [],
        studentSearchParam: action.studentSearchParam,
        searchParam: "", // to clear hospital user filter
        isActionCompleted: false,
        modalMessage: ErrorMessages.EXCEPTION_MESSAGE,
      };
    case C.GET_STUDENT_LIST_SUCCESS:
      return {
        ...state,
        studentList: action.payload.data,
        totalCount: action.payload.totalCount,
      };

    case C.FETCH_ADMIN_COMMON_DATA_SUCCESS:
      return {
        ...state,
        // animalTypes: [...action.payload.commonData.animalType.data],
        // characterType: [...action.payload.commonData.characterType.results],
        // hospitalUserSignUpSelfDefinedRole: [...action.payload.commonData.hospitalUserSignUpSelfDefinedRole.data],
        // mediaSource: [...action.payload.commonData.mediaSource.data],
        // procedureType: [...action.payload.commonData.procedureType.data],
        // specilizationType: [...action.payload.commonData.specilizationType.data],
      };

    case C.FETCH_ADMIN_STATES_SUCCESS:
      const filteredStateData = getRooStatesOptions(action.payload);
      return {
        ...state,
        oStatesById: createDictionary(action.payload, "id"),
        aStateOptions: filteredStateData,
        oStateOptions: createDictionary(filteredStateData, "value"),
      };

    case C.FETCH_METRO_AREAS_SUCCESS:
      const filteredData = getMetroAreasOptions(action.payload);
      return {
        ...state,
        aMetroAreaOptions: filteredData.formattedMetroAreaList,
        oMetroAreaOptions: createDictionary(filteredData.formattedMetroAreaList, "value"),
        oMetroAreaIdsByStateId: filteredData.metroAreasByStateId,
      };

    case C.GET_HOSPITAL_LIST:
      return {
        ...state,
        hospitalList: [],
        changeStatusSent: false,
        isExportRequested: false,
        paymentMethodAdded: null,
        delayPaymentUpdated: null,
        searchParam: action.searchParam || "",
        vetSearchParam: "", // to clear vet user filter
        techSearchParam: "", // to clear tech user filter
      };

    case C.GET_HOSPITAL_LIST_SUCCESS:
      return {
        ...state,
        hospitalList: action.payload.data,
        hospitalSearchTotal: action.payload.totalCount,
        changeStatusSent: false,
        isExportRequested: false,
        paymentMethodAdded: null,
        delayPaymentUpdated: null,
        searchParam: action.searchParam || "",
        vetSearchParam: "", // to clear vet user filter
        techSearchParam: "", // to clear tech user filter
      };

    case C.GET_ROO_ADMIN_LIST_SUCCESS:
      return {
        ...state,
        rooAdminList: action.payload.data,
      };

    case C.GET_ROO_SUPER_USER_LIST_SUCCESS:
      return {
        ...state,
        rooSuperUserList: action.payload.data,
      };

    case C.CREATE_ROO_INTERNAL_USER_SUCCESS:
      return {
        ...state,
        isCreateRooInternalUserSuccess: true,
      };

    case C.DELETE_ROO_INTERNAL_USER_SUCCESS:
      return {
        ...state,
        isDeleteRooInternalUserSuccess: true,
      };

    case C.REQUEST_CHANGE_USER_STATUS_SUCCESS:
      return {
        ...state,
        changeStatusSent: true,
        isExportRequested: false,
        userTypeUpdateTo: action.payload.userTypeUpdateTo,
      };

    case C.GET_RATING_SUMMARY_SUCCESS:
      return {
        ...state,
        ratingSummary: action.payload.summary,
        average: action.payload.average,
        totalRatingCount: action.payload.totalRatingCount,
        totalRating: action.payload.totalRating,
        changeStatusSent: true,
        isExportRequested: false,
        reliability:
          action.payload.reliability || action.payload.reliability == 0
            ? action.payload.reliability
            : null,
      };

    case C.FETCH_VET_DATA:
    case C.FETCH_HOSPITAL_DATA:
      return {
        ...state,
        isExportRequested: null,
      };

    case C.FETCH_VET_DATA_SUCCESS:
    case C.FETCH_TECH_VET_DATA_SUCCESS:
      return {
        ...state,
        exportData: action.payload,
        isExportRequested: true,
      };

    case C.FETCH_VET_DATA_FAIL:
    case C.FETCH_HOSPITAL_DATA_FAIL:
      return {
        ...state,
        isExportRequested: false,
      };

    case C.FETCH_HOSPITAL_DATA_SUCCESS:
      return {
        ...state,
        hospitalExportData: action.payload,
        isExportRequested: true,
      };

    case C.FETCH_STUDENT_DATA_SUCCESS:
      return {
        ...state,
        allStudents: action.payload,
        isExportRequested: true,
      };

    case C.RESET_RATINNGS_SUMMARY:
      return {
        ...state,
        ratingSummary: [],
        fetchRatingStarted: null,
        averageRating: null,
        totalRatingCount: null,
        average: undefined,
        reliability: null,
      };
    case C.ADMIN_SET_HOSPITAL_OFFILNE_PAYMENT_SUCCESS:
      return {
        ...state,
        paymentMethodAdded: true,
        paymentMethodAddedSuccess: true,
      };
    case C.ADMIN_SET_HOSPITAL_OFFILNE_PAYMENT_FAIL:
      return {
        ...state,
        paymentMethodAdded: false,
      };
    case C.UPDATE_HOSPITAL_CONTACT_SUCCESS:
      return {
        ...state,
        isHospitalContactUpdated: true,
      };
    case C.UPDATE_HOSPITAL_CONTACT_FAIL:
      return {
        ...state,
        isHospitalContactUpdated: false,
        isError: true,
        errorMessage: action.payload,
      };
    case C.GET_RATING_SUMMARY:
      return {
        ...state,
        fetchRatingStarted: true,
      };
    case C.UDPATE_TECH_TIER_SUCCESS:
      return {
        ...state,
        isTierUpdated: true,
      };
    case C.UDPATE_TECH_TIER_FAIL:
      return {
        ...state,
        isTierUpdated: false,
      };
    case C.INITIALIZE_FLAG:
      return {
        ...state,
        isTierUpdated: null,
        isTechProfileUpdated: null,
        isTechProfilePhotoUpdated: null,
        isHospitalContactUpdated: false,
        isHospitalDeleted: false,
        isContractorDeleted: false,
        isStudentDeleted: false,
        isActionCompleted: false,
        isTechProbStatusUpdated: false,
        isUnsuspendedDateUpdated: false,
        isCreateRooInternalUserSuccess: false,
        isDeleteRooInternalUserSuccess: false,
        isStripeConnectAccountUpdateRequest: false,
        isStripeConnectAccountUpdateSuccess: false,
        isStripeConnectAccountUpdateFail: false,
        isError: false,
        errorMessage: null,
        modalMessage: ErrorMessages.EXCEPTION_MESSAGE,
      };
    case C.FETCH_ADMIN_TECH_PROFILE_DATA_REQUEST:
      return {
        ...state,
        isTechProfileFetched: false,
      };
    case C.FETCH_ADMIN_TECH_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        techProfileData: action.payload.techData,
        techProfileImage: action.payload.techProfileImage,
        techTaxpayerInfo: action.payload.techTaxpayerInfo,
        isTechProfileFetched: true,
      };
    case C.UDPATE_TECH_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        isTechProfileUpdated: true,
      };
    case C.UDPATE_TECH_PROFILE_DATA_FAIL:
      return {
        ...state,
        isTechProfileUpdated: false,
      };

    case C.RESET_TECH_PLAID_MD_STATUS_SUCCESS:
      return {
        ...state,
        techProfileData: {
          ...state.techProfileData,
          plaidMicroDepositStatus: null,
          plaidMDStatusUpdatedOn: null,
        },
      };

    case C.UDPATE_TECH_PROFILE_PHOTO_SUCCESS:
      return {
        ...state,
        isTechProfilePhotoUpdated: true,
        techProfileImage: action.payload.techImage,
      };
    case C.UDPATE_TECH_PROFILE_PHOTO_FAIL:
      return {
        ...state,
        isTechProfilePhotoUpdated: false,
      };
    case C.ADMIN_UPDATE_HOSPITAL_DELAY_PAYMENT_SETTING_SUCCESS:
      return {
        ...state,
        delayPaymentUpdated: true,
      };
    case C.ADMIN_UPDATE_HOSPITAL_DELAY_PAYMENT_SETTING_FAIL:
      return {
        ...state,
        delayPaymentUpdated: false,
      };
    case C.UDPATE_TECH_PROB_STATUS_SUCCESS:
      return {
        ...state,
        isActionCompleted: true,
        isTechProbStatusUpdated: true,
        modalMessage: ConfirmMessages.ADMIN_TECH_PROB_UPDATE_SUCCESS,
      };
    case C.UDPATE_TECH_PROB_STATUS_FAIL:
      return {
        ...state,
        isActionCompleted: true,
      };
    case C.SET_UNSPENDED_DATE_SUCCESS:
      return {
        ...state,
        isActionCompleted: true,
        isUnsuspendedDateUpdated: true,
        modalMessage: ConfirmMessages.ADMIN_TECH_UNSUSPENDED_DATE_UPDATE_SUCCESS,
      };
    case C.SET_UNSPENDED_DATE_FAIL:
      return {
        ...state,
        isActionCompleted: true,
      };
    case C.DELETE_HOSPITAL_SUCCESS:
      return {
        ...state,
        isHospitalDeleted: true,
      };
    case C.DELETE_HOSPITAL_FAIL:
      return {
        ...state,
        isError: true,
        errorMessage: action.payload,
      };
    case C.DELETE_CONTRACTOR_SUCCESS:
      return {
        ...state,
        isContractorDeleted: true,
      };
    case C.DELETE_CONTRACTOR_FAIL:
      return {
        ...state,
        isError: true,
        errorMessage: action.payload,
      };

    case C.DELETE_STUDENT_SUCCESS:
      return {
        ...state,
        isStudentDeleted: true,
      };
    case C.DELETE_STUDENT_FAIL:
      return {
        ...state,
        isError: true,
        errorMessage: action.payload,
      };

    case C.FILE_DOWNLOAD:
    case C.FILE_DOWNLOAD_FAILED:
    case C.FILE_REMOVE:
      return {
        ...state,
        downloadedFile: null,
      };

    case C.FILE_DOWNLOAD_SUCCESS:
      const array = action.payload;
      let res = "";
      const chunk = 8 * 1024;
      let i;
      for (i = 0; i < array.length / chunk; i++) {
        res += String.fromCharCode.apply(null, array.slice(i * chunk, (i + 1) * chunk));
      }
      res += String.fromCharCode.apply(null, array.slice(i * chunk));
      const imageurl = "data:image/jpeg;base64," + btoa(res);
      return {
        ...state,
        downloadedFile: imageurl,
      };

    case C.SHOW_INVALID_ADRESS_MODAL:
      return {
        ...state,
        isInvalidAddress: action.payload.isInvalidAddress,
        isCityNotMatching: action.payload.isInvalidAddress
          ? action.payload.isCityNotMatching
          : null,
      };

    case C.GET_VET_TECH_BY_HOSPITAL_ID:
      // reset all data as data started loading
      return {
        ...state,
        isDataloadingForFulltimePlacement: true,
        errorLoadingDataForFulltimePlacement: false,
        hospitalContractorsForFulltimePlacement: null,
        submitFulltimePlacementStatus: null,
      };

    case C.GET_VET_TECH_BY_HOSPITAL_ID_SUCCESS:
      return {
        ...state,
        isDataloadingForFulltimePlacement: false,
        errorLoadingDataForFulltimePlacement: false,
        hospitalContractorsForFulltimePlacement: action.payload,
      };

    case C.GET_VET_TECH_BY_HOSPITAL_ID_ERROR:
      return {
        ...state,
        isDataloadingForFulltimePlacement: false,
        errorLoadingDataForFulltimePlacement: true,
        hospitalContractorsForFulltimePlacement: null,
      };

    case C.CLEAR_VET_TECH_BY_HOSPITAL_ID:
      return {
        ...state,
        isDataloadingForFulltimePlacement: false,
        errorLoadingDataForFulltimePlacement: false,
        hospitalContractorsForFulltimePlacement: null,
        submitFulltimePlacementStatus: null,
      };

    case C.GET_FULLTIME_PLACEMENT_LOGS_SUCCESS:
      return {
        ...state,
        fulltimePlacementList: action.payload,
      };

    case C.GET_FULLTIME_PLACEMENT_LOGS_ERROR:
      return {
        ...state,
        fulltimePlacementList: null,
      };

    case C.SUBMIT_FULLTIME_PLACEMENT:
      return {
        ...state,
        submitFulltimePlacementStatus: "processing",
      };

    case C.SUBMIT_FULLTIME_PLACEMENT_SUCCESS:
      return {
        ...state,
        submitFulltimePlacementStatus: "success",
      };

    case C.SUBMIT_FULLTIME_PLACEMENT_ERROR:
      return {
        ...state,
        submitFulltimePlacementStatus: "error",
      };

    case C.UPDATE_PLACEMENT_PAYMENT_STATUS_SUCCESS:
      return {
        ...state,
        submitFulltimePlacementStatus: "success",
      };

    case C.UPDATE_PLACEMENT_PAYMENT_STATUS_ERROR:
      return {
        ...state,
        submitFulltimePlacementStatus: "error",
      };

    case C.UPDATE_STRIPE_CONNECT_ACCOUNT_ID:
      return {
        ...state,
        isStripeConnectAccountUpdateRequest: true,
      };

    case C.UPDATE_STRIPE_CONNECT_ACCOUNT_ID_SUCCESS:
      return {
        ...state,
        isStripeConnectAccountUpdateRequest: false,
        isStripeConnectAccountUpdateSuccess: true,
      };

    case C.UPDATE_STRIPE_CONNECT_ACCOUNT_ID_FAIL:
      return {
        ...state,
        isStripeConnectAccountUpdateRequest: false,
        isStripeConnectAccountUpdateFail: true,
      };

    case C.ADMIN_UPDATE_HOSPITAL_INTERNSHIPS_AVAILABILITY_SUCCESS:
      return {
        ...state,
        isInternshipAvaialibityUpdated: true,
      };

    case C.ADMIN_UPDATE_HOSPITAL_INTERNSHIPS_AVAILABILITY_REQUEST:
    case C.ADMIN_UPDATE_HOSPITAL_INTERNSHIPS_AVAILABILITY_FAIL:
      return {
        ...state,
        isInternshipAvaialibityUpdated: false,
      };

    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default userReducer;
