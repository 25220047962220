import moment from "moment/moment";
import React from "react";

import { SmallText } from "@roo-dev/ui-components";

export const MessageDate = ({ createdAt }: { createdAt: string }) => {
  let formattedDate = "";
  const createdDate = moment(createdAt);
  const isYesterday = createdDate.isSame(moment().subtract(1, "day"), "d");
  const isThisWeek = createdDate.week() === moment().week();
  const isToday = createdDate.isSame(moment(), "d");
  if (isToday) {
    formattedDate = createdDate.format("h:mmA");
  } else if (isYesterday) {
    formattedDate = "Yesterday";
  } else if (isThisWeek) {
    formattedDate = createdDate.format("dddd");
  } else {
    formattedDate = createdDate.format("M/D/YY");
  }
  return <SmallText data-testid="messageDate">{formattedDate}</SmallText>;
};
