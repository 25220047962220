import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Container, Heading } from "@roo-dev/ui-components";

import {
  saveShiftPreferences,
  useNotificationAttributes,
} from "../../../api/notifications/notifications";
import { VetTechShiftPreferenceForm } from "../../../api/notifications/notificationTypes";
import { SubHeaderContainer } from "../../EditProfile/styles";
import { NotificationShiftSettingAttribute } from "../interfaces";
import { mapShiftPreferencesForm, showToastUtil, updateShiftPreferencesFromForm } from "../utils";

import { NewShiftPreferencesForm } from "./NewShiftPreferencesForm";
import { FormProvider } from "./vetTechShiftSettingsEditContext";

const ShiftNotificationPreferencesNew = ({
  shiftPreferencesId,
  frequencyTypeCode,
}: {
  shiftPreferencesId: number;
  frequencyTypeCode: number;
}) => {
  const [localShiftPreferenceSettings, setLocalShiftPreferenceSettings] = useState<
    NotificationShiftSettingAttribute[]
  >([]);

  const [defaultForm, setDefaultForm] = useState<VetTechShiftPreferenceForm>();
  const [localFrequencyTypeCode, setLocalFrequencyTypeCode] = useState(frequencyTypeCode);

  const { isSuccess, data } = useNotificationAttributes({
    notificationSettingsId: shiftPreferencesId,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (isSuccess && data) {
      setLocalShiftPreferenceSettings(data);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (localShiftPreferenceSettings) {
      setDefaultForm(mapShiftPreferencesForm(localShiftPreferenceSettings, localFrequencyTypeCode));
    }
  }, [localShiftPreferenceSettings, localFrequencyTypeCode]);

  const save = async (form: VetTechShiftPreferenceForm): Promise<void> => {
    const shiftPreferenceSettings = updateShiftPreferencesFromForm(
      localShiftPreferenceSettings,
      form
    );

    const response = await saveShiftPreferences({
      frequencyId: Number(form.frequency),
      notificationId: shiftPreferencesId,
      shiftPreferenceSettings,
    });

    showToastUtil(response.status, dispatch);

    setLocalShiftPreferenceSettings(shiftPreferenceSettings);
    setLocalFrequencyTypeCode(Number(form.frequency));
  };

  return (
    <Container data-testid="new-shift-preferences-form-container">
      <SubHeaderContainer>
        <Heading level={5}>New shift alert preferences</Heading>
      </SubHeaderContainer>
      <FormProvider defaultForm={defaultForm} save={save} validate={() => undefined}>
        <NewShiftPreferencesForm />
      </FormProvider>
    </Container>
  );
};

// eslint-disable-next-line no-restricted-exports
export default ShiftNotificationPreferencesNew;
