export const UNSUBSCRIBE_VET_WEEKLY_EMAIL = "UNSUBSCRIBE_VET_WEEKLY_EMAIL";
export const UNSUBSCRIBE_VET_WEEKLY_EMAIL_SUCCESS = "UNSUBSCRIBE_VET_WEEKLY_EMAIL_SUCCESS";
export const UNSUBSCRIBE_VET_WEEKLY_EMAIL_FAIL = "UNSUBSCRIBE_VET_WEEKLY_EMAIL_FAIL";

export const UNSUBSCRIBE_NEW_EVENTS_EMAIL = "UNSUBSCRIBE_NEW_EVENTS_EMAIL";
export const UNSUBSCRIBE_NEW_EVENTS_EMAIL_SUCCESS = "UNSUBSCRIBE_NEW_EVENTS_EMAIL_SUCCESS";
export const UNSUBSCRIBE_NEW_EVENTS_EMAIL_FAIL = "UNSUBSCRIBE_NEW_EVENTS_EMAIL_FAIL";

export const UNSUBSCRIBE_SHIFT_POSTED_ALERT_EMAIL = "UNSUBSCRIBE_SHIFT_POSTED_ALERT_EMAIL";
export const UNSUBSCRIBE_SHIFT_POSTED_ALERT_EMAIL_SUCCESS =
  "UNSUBSCRIBE_SHIFT_POSTED_ALERT_EMAIL_SUCCESS";
export const UNSUBSCRIBE_SHIFT_POSTED_ALERT_EMAIL_FAIL =
  "UNSUBSCRIBE_SHIFT_POSTED_ALERT_EMAIL_FAIL";
