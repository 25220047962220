import { GET } from "@roo-dev/roo-node-types";

import { STUDENT_USER_TYPE } from "../../../constants/UserTypeConstants";
import * as C from "../../../constants/registerStudent";
import { convertNumberListToArray } from "../../../helpers/jsUtility";
import { RegisterStudentAction } from "../Actions/RegisterStudentActions";
import { StudentImage, StudentResume } from "../updateStudentProfileTypes";

type RegistrationStudentState = {
  studentData: {
    email: string;
    referralCodeId: string;
    userTypeId: number | null;
    fullName: string;
    firstName: string;
    lastName: string;
    address: string;
    aptUnitSuite: string;
    city: string;
    stateId: string;
    zipcode: number | null;
    mobilePhoneNumber: string;
    hearAboutRoo: string;
    heardFromWordOfMouth: string;
    studentSchool: string;
    graduationYear: string | number;
    gpa: string;
    specialAwards: string;
    licenseStateId?: number;
    trackInSchool: string;
    prefLearningMethod: string;
    prefMentorshipType: string;
    subspecialityInterest: number[];
    apprenticeshipReason: string;
    characterTypeId: number;
    emailId?: string;
    professionalLinks?: string;
    isPhotoResLinksCompleted?: boolean;
  };
  createAccountServer: {
    statusCode: number | null;
    message: string;
  };
  states: State[];
  password: string;
  email: string | null;
  accountCreated: boolean;
  isBasicInfoFetched: boolean;
  isEducationExpFetched: boolean;
  isGetToKnowFetched: boolean;
  isPhotoResLinksFetched: boolean;
  studentResume: StudentResume[];
  imageUploaded: boolean;
  studentImage: StudentImage[];
  isBasicInfoCompleted: boolean;
  isEducationExpCompleted: boolean;
  isGetToKnowCompleted: boolean;
  isPhotoResLinksCompleted: boolean;
  isProfileCreated: boolean;
  isReducerEduSave: boolean;
  isReducerGetToKnowSave: boolean;
  isReducerStudentPhotoSave: boolean;
  isAccountInfoUpdated: boolean;
  isFourthYear?: boolean;
};

export type State = GET.Utility.GetStatesResponse["data"]["data"][number];

const initialState: RegistrationStudentState = {
  studentData: {
    email: "",
    referralCodeId: "",
    userTypeId: null,
    fullName: "",
    firstName: "",
    lastName: "",
    address: "",
    aptUnitSuite: "",
    city: "",
    stateId: "",
    zipcode: null,
    mobilePhoneNumber: "",
    hearAboutRoo: "",
    heardFromWordOfMouth: "",
    studentSchool: "",
    graduationYear: "",
    gpa: "",
    specialAwards: "",
    trackInSchool: "",
    prefLearningMethod: null,
    prefMentorshipType: "",
    subspecialityInterest: [],
    apprenticeshipReason: "",
    characterTypeId: null,
  },
  createAccountServer: {
    statusCode: null,
    message: "",
  },
  states: [],
  password: "",
  email: null,
  accountCreated: false,
  isBasicInfoFetched: false,
  isEducationExpFetched: false,
  isGetToKnowFetched: false,
  isPhotoResLinksFetched: false,
  studentResume: [],
  imageUploaded: false,
  studentImage: [],

  isBasicInfoCompleted: false,
  isEducationExpCompleted: false,
  isGetToKnowCompleted: false,
  isPhotoResLinksCompleted: false,
  isProfileCreated: false,
  isReducerEduSave: false,
  isReducerGetToKnowSave: false,
  isReducerStudentPhotoSave: false,
  isAccountInfoUpdated: false,
};

// eslint-disable-next-line default-param-last
const RegistrationStudentReducer = (state = initialState, action: RegisterStudentAction) => {
  switch (action.type) {
    case C.CREATE_STUDENT_ACCOUNT_SUCCESS:
      localStorage.setItem("studentId", action.payload.studentId.toString());
      localStorage.setItem("userId", action.payload.userId.toString());
      localStorage.setItem("zipcode", action.payload.zipcode);
      localStorage.setItem("userTypeId", STUDENT_USER_TYPE.toString());
      localStorage.setItem("email", action.payload.email);
      return {
        ...state,
        studentData: {
          ...state.studentData,
          email: action.payload.email,
          zipcode: action.payload.zipcode,
        },
        accountCreated: true,
      };

    case C.CREATE_STUDENT_ACCOUNT_FAIL:
      return {
        ...state,
        createAccountServer: {
          ...state.createAccountServer,
          statusCode: action.payload.status,
          message: action.payload.message,
        },
        accountCreated: false,
      };

    case C.FETCH_STATES_SUCCESS:
      return {
        ...state,
        states: [...action.payload.states],
      };

    case C.FETCH_STUDENT_BASIC_INFO:
      return {
        ...state,
        isBasicInfoFetched: false,
        isBasicInfoCompleted: false,
      };

    case C.FETCH_STUDENT_BASIC_INFO_SUCCESS:
      return {
        ...state,
        studentData: {
          ...state.studentData,
          fullName: action.payload.fullName,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          address: action.payload.address,
          aptUnitSuite: action.payload.aptUnitSuite,
          city: action.payload.city,
          stateId: action.payload.stateId,
          zipcode: action.payload.zipcode,
          mobilePhoneNumber: action.payload.mobilePhoneNumber,
          hearAboutRoo: action.payload.hearAboutRoo,
          heardFromWordOfMouth: action.payload.heardFromWordOfMouth,
          email: action.payload.emailId,
        },
        isBasicInfoFetched: true,
      };

    case C.SAVE_STUDENT_BASIC_INFO:
      return {
        ...state,
        isBasicInfoCompleted: false,
      };

    case C.SAVE_STUDENT_BASIC_INFO_SUCCESS:
      localStorage.setItem("fullName", action.payload.fullName);
      return {
        ...state,
        studentData: {
          ...state.studentData,
          fullName: action.payload.fullName,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          address: action.payload.address,
          aptUnitSuite: action.payload.aptUnitSuite,
          city: action.payload.city,
          stateId: action.payload.stateId,
          zipcode: action.payload.zipcode,
          mobilePhoneNumber: action.payload.mobilePhoneNumber,
          hearAboutRoo: action.payload.hearAboutRoo,
          heardFromWordOfMouth: action.payload.heardFromWordOfMouth,
          email: action.payload.emailId,
        },
        isBasicInfoCompleted: true,
      };

    case C.SAVE_STUDENT_BASIC_INFO_FAIL:
      return {
        ...state,
        isBasicInfoCompleted: false,
      };

    case C.FETCH_STUDENT_EDUCATION_EXP:
      return {
        ...state,
        isEducationExpFetched: false,
        isEducationExpCompleted: false,
      };

    case C.SAVE_STUDENT_EDUCATION_EXP:
      return {
        ...state,
        isEducationExpCompleted: false,
      };
    case C.FETCH_STUDENT_EDUCATION_EXP_REDUCER:
      return {
        ...state,
        isEducationExpFetched: true,
      };
    case C.FETCH_STUDENT_EDUCATION_EXP_SUCCESS:
      return {
        ...state,
        studentData: {
          ...state.studentData,
          studentSchool: action.payload.studentSchool,
          graduationYear: action.payload.graduationYear,
          gpa: action.payload.gpa,
          specialAwards: action.payload.specialAwards,
          licenseStateId: action.payload.licenseStateId,
          trackInSchool: action.payload.trackInSchool,
        },
        isEducationExpFetched: true,
      };

    case C.SAVE_STUDENT_EDUCATION_EXP_FAIL:
      return {
        ...state,
        isEducationExpCompleted: false,
      };
    case C.SAVE_STUDENT_EDUCATION_EXP_REDUCER:
    case C.SAVE_STUDENT_EDUCATION_EXP_SUCCESS:
      return {
        ...state,
        studentData: {
          ...state.studentData,
          studentSchool: action.payload.studentSchool,
          graduationYear: action.payload.graduationYear,
          gpa: action.payload.gpa,
          specialAwards: action.payload.specialAwards,
          licenseStateId: action.payload.licenseStateId,
          trackInSchool: action.payload.trackInSchool,
        },
        isEducationExpCompleted: !action.payload.isReducerEduSave, // isReducerEduSave true means data is not saved to DB
        isReducerEduSave: action.payload.isReducerEduSave,
      };

    case C.FETCH_STUDENT_GET_TO_KNOW:
      return {
        ...state,
        isGetToKnowFetched: false,
        isGetToKnowCompleted: false,
      };

    case C.FETCH_STUDENT_GET_TO_KNOW_REDUCER:
      return {
        ...state,
        isGetToKnowFetched: true,
      };
    case C.FETCH_STUDENT_GET_TO_KNOW_SUCCESS:
      return {
        ...state,
        studentData: {
          ...state.studentData,
          prefLearningMethod: action.payload.prefLearningMethod,
          prefMentorshipType: action.payload.prefMentorshipType,
          subspecialityInterest: convertNumberListToArray(action.payload.subspecialityInterest),
          apprenticeshipReason: action.payload.apprenticeshipReason,
          characterTypeId: action.payload.characterTypeId,
        },
        isGetToKnowFetched: true,
      };

    case C.SAVE_STUDENT_GET_TO_KNOW:
      return {
        ...state,
        isGetToKnowCompleted: false,
      };

    case C.SAVE_STUDENT_GET_TO_KNOW_REDUCER:
    case C.SAVE_STUDENT_GET_TO_KNOW_SUCCESS:
      return {
        ...state,
        studentData: {
          ...state.studentData,
          prefLearningMethod: action.payload.prefLearningMethod,
          prefMentorshipType: action.payload.prefMentorshipType,
          subspecialityInterest: action.payload.subspecialityInterest,
          apprenticeshipReason: action.payload.apprenticeshipReason,
          characterTypeId: action.payload.characterTypeId,
        },
        isGetToKnowCompleted: !action.payload.isReducerGetToKnowSave,
        isReducerGetToKnowSave: action.payload.isReducerGetToKnowSave,
        isGetToKnowFetched: false,
      };

    case C.FETCH_STUDENT_PHOTO_RES_LINKS:
      return {
        ...state,
        isPhotoResLinksFetched: false,
        isPhotoResLinksCompleted: false,
      };
    case C.SAVE_STUDENT_PHOTO_REDUCER:
    case C.SAVE_STUDENT_PHOTO_RES_LINKS_SUCCESS:
      const payload = { ...action.payload };

      let formattedData;
      if (payload.isReducerStudentPhotoSave) {
        formattedData = {
          ...state,
          studentData: {
            ...state.studentData,
            professionalLinks: payload.professionalLinks,
            isPhotoResLinksCompleted: !payload.isReducerStudentPhotoSave, // isReducerStudentPhotoSave is true means not save to DB
          },
          isReducerStudentPhotoSave: payload.isReducerStudentPhotoSave,
          isPhotoResLinksFetched: false,
        };
      } else {
        formattedData = {
          ...state,
          isPhotoResLinksCompleted: true,
          isReducerStudentPhotoSave: payload.isReducerStudentPhotoSave,
        };
      }
      return formattedData;

    case C.SAVE_STUDENT_PHOTO_RES_LINKS_FAIL:
      return {
        ...state,
        isPhotoResLinksCompleted: false,
        isReducerStudentPhotoSave: false,
      };

    case C.FETCH_STUDENT_PHOTO_REDUCER:
      return {
        ...state,
        isPhotoResLinksFetched: true,
      };
    case C.FETCH_STUDENT_PHOTO_RES_LINKS_SUCCESS:
      return {
        ...state,
        studentData: {
          ...state.studentData,
          emailId: action.payload.emailId,
          professionalLinks: action.payload.professionalLinks,
        },
        isPhotoResLinksFetched: true,
        studentResume: action.payload.resumePath
          ? [{ resumePath: action.payload.resumePath, resumeName: action.payload.resumeName }]
          : [],
        studentImage: action.payload.profilePhotoPath
          ? [{ imagePath: action.payload.profilePhotoPath }]
          : [],
      };

    // --- IMAGE
    case C.UPLOAD_STUDENT_IMAGE_FAIL:
      return {
        ...state,
        imageUploaded: false,
        studentImage: [...state.studentImage],
      };

    case C.UPLOAD_STUDENT_IMAGE_SUCCESS:
    case C.DELETE_STUDENT_IMAGE_SUCCESS:
      return {
        ...state,
        imageUploaded: true,
        studentImage: action.payload.studentImage,
      };

    case C.DELETE_STUDENT_IMAGE_FAIL:
      return {
        ...state,
        imageUploaded: false,
        studentImage: [],
      };

    // --- RESUME
    case C.DELETE_STUDENT_RESUME_FAIL:
    case C.UPLOAD_STUDENT_RESUME_FAIL:
      return {
        ...state,
        studentResume: [...state.studentResume],
      };

    case C.UPLOAD_STUDENT_RESUME_SUCCESS:
    case C.DELETE_STUDENT_RESUME_SUCCESS:
      return {
        ...state,
        studentResume: action.payload.studentResume,
      };

    case C.CLEAR_LOCAL_STORAGE:
      localStorage.clear();
      return {
        ...state,
      };

    // --- INITIALIZE
    case C.INITIALIZE_STUDENT_REGISTRATION:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default RegistrationStudentReducer;
