export const OPEN_PROFILE_PANEL_REQUEST = "OPEN_PROFILE_PANEL_REQUEST";
export const OPEN_PROFILE_PANEL = "OPEN_PROFILE_PANEL";
export const CLOSE_PROFILE_PANEL = "CLOSE_PROFILE_PANEL";
export const CHANGE_PANEL_TYPE = "CHANGE_PANEL_TYPE";
export const CHANGE_SHIFT_STATUS_TYPE = "CHANGE_SHIFT_STATUS_TYPE";
export const OPEN_PROFILE_PANEL_FROM_CALENDAR = "OPEN_PROFILE_PANEL_FROM_CALENDAR";
export const SET_CONTRACTOR_PROFILE_DATA = "SET_CONTRACTOR_PROFILE_DATA";
export const RESET_CONTRACTOR_PROFILE_DATA = "RESET_CONTRACTOR_PROFILE_DATA";
export const SET_SELECTED_SHIFT_REQUEST = "SET_SELECTED_SHIFT_REQUEST";
export const SHOW_RATING_MODAL = "SHOW_RATING_MODAL";
export const CLOSE_RATING_MODAL = "CLOSE_RATING_MODAL";
export const SHOW_BREADCRUMB = "SHOW_BREADCRUMB";
export const GET_SHIFT_DETAILS_FOR_PANEL_REQUEST = "GET_SHIFT_DETAILS_FOR_PANEL_REQUEST";
export const GET_SHIFT_DETAILS_FOR_PANEL_SUCCESS = "GET_SHIFT_DETAILS_FOR_PANEL_SUCCESS";
export const GET_SHIFT_DETAILS_FOR_PANEL_FAIL = "GET_SHIFT_DETAILS_FOR_PANEL_FAIL";
export const SHOW_MOBILE_ACTION_SHEET = "SHOW_MOBILE_ACTION_SHEET";
export const CLOSE_MOBILE_ACTION_SHEET = "CLOSE_MOBILE_ACTION_SHEET";
export const CONFIRM_SHIFT_REQUEST = "CONFIRM_SHIFT_REQUEST";
export const SET_PANEL_SHIFT_DATA = "SET_PANEL_SHIFT_DATA";
export const UPDATE_UNFILLED_SHIFT_DETAILS = "UPDATE_UNFILLED_SHIFT_DETAILS";
export const RELOAD_SHIFTS_AFTER_PANEL_CLOSED = "RELOAD_SHIFTS_AFTER_PANEL_CLOSED";
export const RESET_RELOAD_SHIFTS_AFTER_PANEL_CLOSED = "RESET_RELOAD_SHIFTS_AFTER_PANEL_CLOSED";
export const SHOW_TOAST_FOR_PANEL = "SHOW_TOAST_FOR_PANEL";
export const SHOW_BOOST_WARNING_MODAL = "SHOW_BOOST_WARNING_MODAL";
export const CLOSE_BOOST_WARNING_MODAL = "CLOSE_BOOST_WARNING_MODAL";
export const RESET_BOOST_WARNING_DATA = "RESET_BOOST_WARNING_DATA";
