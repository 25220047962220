export function extractUrlInfo(url: string) {
  if (!url) {
    return null;
  }

  const searchParams: Record<string, string> = {};
  const urlObj = new URL(url);
  urlObj.searchParams.forEach((value, key) => {
    searchParams[key] = value;
  });

  return { hostname: urlObj.hostname, searchParams };
}

export function getRootUrl() {
  return `${location.protocol}//${location.host}`;
}
