import styled from "styled-components";

import { spacingM } from "@roo-dev/ui-components";

export const TechCredentialItemsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CompetenciesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 710px;
  @media (max-width: 1200px) {
    height: 780px;
  }
  @media (max-width: 780px) {
    height: auto;
  }
`;

export const CompetencyContainer = styled.div`
  margin-top: ${spacingM};
  width: 50%;
  @media (max-width: 780px) {
    width: 100%;
  }
`;

export const TechCredentialsContainer = styled.div``;
