import React from "react";

import { Heading } from "@roo-dev/ui-components";

import { StateContainer } from "./styles";

export const EmptyState = () => {
  return (
    <StateContainer>
      <Heading level={5}>&#x1F4AC;</Heading>
      <Heading level={5}>No chats yet!</Heading>
    </StateContainer>
  );
};
