import * as C from "../../constants/constants";

const initialState = {
  animalTypes: [],
  characterType: [],
  hospitalUserSignUpSelfDefinedRole: [],
  mediaSource: [],
  procedureType: [],
  specilizationType: [],
};

// eslint-disable-next-line default-param-last
const RegistrationReducer = (state = initialState, action) => {
  if (action.type === C.FETCH_COMMON_DATA_SUCCESS) {
    return {
      ...state,
      animalTypes: [...action.payload.commonData.animalType.data],
      characterType: [...action.payload.commonData.characterType.results],
      hospitalUserSignUpSelfDefinedRole: [
        ...action.payload.commonData.hospitalUserSignUpSelfDefinedRole.data,
      ],
      mediaSource: [...action.payload.commonData.mediaSource.data],
      procedureType: [...action.payload.commonData.procedureType.data],
      specilizationType: [...action.payload.commonData.specilizationType.data],
      techSkills: [...action.payload.commonData.techSkills.data],
    };
  }

  return state;
};

// eslint-disable-next-line no-restricted-exports
export default RegistrationReducer;
