import * as Sentry from "@sentry/react";
import { Auth } from "aws-amplify";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import {
  EXCEPTION_MESSAGE,
  FORGOT_PASSWORD_CODE_EXPIRED,
  FORGOT_PASSWORD_LIMIT_EXCEEDED,
} from "../../../constants/errorMessage";
import { useAppDispatch, useAppSelector } from "../../../store/service";
import { sendResetPasswordSuccessEmail } from "../actions/ForgotPasswordActions";

import { ResetPasswordComponent } from "./ResetPasswordComponent";
import { FormProvider, ResetPasswordForm, resetPasswordSchema } from "./resetPasswordFormContext";

export const ResetPasswordContainer = () => {
  const [success, setSuccess] = useState(false);

  const {
    isNewPasswordRequired,
    signInRequest: cognitoUser,
    ...login
  } = useAppSelector(({ login }) => login);

  const dispatch = useAppDispatch();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const email = login.email || params.get("email");
  const confirmationCode = params.get("confirmation_code");

  const save = async ({ password }: ResetPasswordForm) => {
    const context = isNewPasswordRequired ? "completeNewPassword: " : "forgotPasswordSubmit: ";

    try {
      if (isNewPasswordRequired) {
        await Auth.completeNewPassword(cognitoUser, password);
      } else {
        await Auth.forgotPasswordSubmit(email, confirmationCode, password);
      }

      dispatch(sendResetPasswordSuccessEmail(email));
      setSuccess(true);
    } catch (error) {
      Sentry.captureException(error, { extra: { context } });

      throw new Error(
        error.code === "LimitExceededException"
          ? FORGOT_PASSWORD_LIMIT_EXCEEDED
          : error.code === "ExpiredCodeException"
          ? FORGOT_PASSWORD_CODE_EXPIRED
          : EXCEPTION_MESSAGE
      );
    }
  };

  const validate = (form: ResetPasswordForm) => {
    const result = resetPasswordSchema.safeParse(form);
    const errors: Record<string, string> = {};

    if (!result.success) {
      for (const error of result.error.errors) {
        errors[error.path[0]] = error.message;
      }
    }

    return errors;
  };

  return (
    <FormProvider
      defaultForm={{
        password: "",
        passwordConfirm: "",
      }}
      save={save}
      validate={validate}
    >
      <ResetPasswordComponent success={success} />
    </FormProvider>
  );
};
