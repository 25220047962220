import { ICache } from "@aws-amplify/cache/lib-esm/types";
import { Cache } from "aws-amplify";
import React from "react";
import { LazyLoadImage, LazyLoadImageProps } from "react-lazy-load-image-component";

import { EntityType, EventName, EventType } from "@roo-dev/event-tracking";

import { EventTracker } from "../service/EventTracker";

let trackingCache: ICache | undefined = undefined;

const getTrackingCache = () => {
  if (!trackingCache) {
    trackingCache = Cache.createInstance({
      keyPrefix: "rtp",
      storage: window.sessionStorage,
    });
  }

  return trackingCache;
};

export type TrackingPixelProps = {
  eventName: EventName;
  eventType: EventType;
  entityType: EntityType;
  entityId: number;
  context?: unknown;
} & Omit<LazyLoadImageProps, "onLoad" | "className">;

const TrackingPixel = ({
  eventName,
  eventType,
  entityType,
  entityId,
  context,
  src = "https://assets.roo.vet/util/pixel.gif",
  delayTime = 3000,
  ...rest
}: TrackingPixelProps) => (
  <LazyLoadImage
    onLoad={() => {
      /*
       * NOTE: Ideally we'd use LazyLoadImage to send events via the https://events.roo.vet/pixel.gif
       * endpoint. However, often times our front-end triggers multiple re-renders and causes the <img>
       * fetch to be triggered multiple times. As a workaround, we'll hook into the onLoad handler to
       * manually send the event, and allows us to handle any duplicates.
       */
      const eventKey = eventName + "_" + eventType + "_" + entityType + "_" + entityId;
      const cachedEntity = getTrackingCache().getItem(eventKey);

      if (!cachedEntity) {
        EventTracker.send(eventName, eventType, entityType, entityId, context);
        const expiration = new Date();
        expiration.setSeconds(expiration.getSeconds() + 5);
        getTrackingCache().setItem(eventKey, true, { expires: expiration.getTime() });
      }
    }}
    className="position-absolute"
    delayTime={delayTime}
    src={src}
    {...rest}
  />
);

// eslint-disable-next-line no-restricted-exports
export default TrackingPixel;
