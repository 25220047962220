import Joi from "joi";
import parsePhoneNumber from "libphonenumber-js";
import React from "react";
import validator from "validator";
import Validator from "validatorjs";
import { ZodError } from "zod";

import { validateZipcode } from "../Common/validateZipcode";

import { getMinimumBoostAmount } from "./boostUtility";

Validator.register(
  "valid_phone_number",
  (value) => typeof value === "string" && typeof parsePhoneNumber(value, "US") !== "undefined",
  "Phone number is not valid."
);

export class Validation {
  required = (value: any) => {
    if (validator.isEmpty(value)) {
      return <span className="error-message">*Required</span>;
    } else {
      return undefined;
    }
  };

  isEmail = (value: any) => {
    if (!validator.isEmail(value)) {
      return <span className="text-danger message">Enter valid email</span>;
    } else {
      return undefined;
    }
  };

  isEmailDomainAllowed = (email: string, restrictedEmailDomains: string[]) => {
    if (restrictedEmailDomains.length === 0) {
      return true;
    }

    const emailDomain = email.toLowerCase().split("@")[1];
    if (emailDomain) {
      return !restrictedEmailDomains.includes(emailDomain);
    }
    return true;
  };

  passwordMatch = (value: string, confirmPassword: string) => {
    if (value !== confirmPassword) {
      return <span className="text-danger message">Password does not match</span>;
    } else {
      return undefined;
    }
  };

  inValidPassword = (value: string) => {
    if (!validator.matches(value, /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{7,})/) || !value) {
      return (
        <span className="text-danger message pass-note position-relative" style={{ right: 0 }}>
          Password must have at least 8 characters with one uppercase and lowercase letter and one
          number.
        </span>
      );
    } else {
      return undefined;
    }
  };

  checkValidZipcode = (value: string, bZIndex: boolean) => {
    if (!validator.matches(value, /^[0-9]{5}(?:-[0-9]{4})?$/) || !value || validateZipcode(value)) {
      return <span className={`text-danger message zip${bZIndex ? " z-2" : ""}`}>Required</span>;
    } else {
      return undefined;
    }
  };

  zipcodeMatch = (value: string, confirmZipcode: string) => {
    if (value !== confirmZipcode) {
      return <span className="text-danger message">ZIP code does not match</span>;
    } else {
      return undefined;
    }
  };

  getValidEmailsArray = (sList: string) => {
    let sEmailListValidationError;

    // put emails in array
    const aNewEmails = sList.replace(/\s/g, "").split(",");
    // test if emails are valid
    if (
      aNewEmails.find((testEmail) => {
        const indexOfAtSymbol = testEmail.indexOf("@");
        const nEmailLength = testEmail.length;
        return (
          nEmailLength < 6 ||
          indexOfAtSymbol < 2 ||
          indexOfAtSymbol > nEmailLength - 3 ||
          nEmailLength > 60
        );
      })
    ) {
      sEmailListValidationError = "Invalid email";
    }
    return { aNewEmails, sEmailListValidationError };
  };

  isNumeric = (value: string) => {
    if (!validator.isNumeric(value)) {
      return <span className="text-danger message">Please enter numeric values</span>;
    } else {
      return undefined;
    }
  };

  is9digits = (value: string) => {
    if (value.length < 9) {
      return <span className="text-danger message">Please Enter atleast 9 digits</span>;
    } else {
      return undefined;
    }
  };

  isCharacter = (value: string) => {
    if (!validator.matches(value.trim(), /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/)) {
      return <span className="text-danger message">Please enter valid Account Holder Name</span>;
    } else {
      return undefined;
    }
  };

  isValidAccountNumber = (value: string) => {
    if (value.length > 0 && value.length <= 12) {
      return true;
    } else {
      return (
        <span className="text-danger message">
          Please enter your bank account number ( 12 digits max ) correctly.
        </span>
      );
    }
  };

  isValidRoutingNumber = (value: string) => {
    if (value.length > 0 && value.length <= 9) {
      return true;
    } else {
      return (
        <span className="text-danger message">
          Please enter your routing number ( 9 digits max ) correctly.
        </span>
      );
    }
  };

  checkGetToKnowHospitalData = (value: any): boolean => {
    if (value.characterTypeId === null) {
      return false;
    } else {
      return true;
    }
  };

  checkGetToKnowTechData = (value: any): boolean => {
    if (!value.accountability) {
      return false;
    } else if (!value.integrity) {
      return false;
    } else if (!value.takeDirection) {
      return false;
    } else if (!value.adaptibility) {
      return false;
    } else if (!value.punctuality) {
      return false;
    } else if (!value.professionalism) {
      return false;
    } else if (!value.communication) {
      return false;
    } else if (!value.characterTypeId) {
      return false;
    } else {
      return true;
    }
  };

  checkShiftBoostAmount = (boostAmount: any, isTechShift: boolean): boolean => {
    const minimumBoostAmount = getMinimumBoostAmount(!isTechShift);
    if (
      boostAmount?.length &&
      (isNaN(boostAmount) || boostAmount < minimumBoostAmount || boostAmount > 9999)
    ) {
      return false;
    } else {
      return true;
    }
  };

  checkHospitalAddShiftData = (shiftData: any): boolean => {
    if (shiftData.startTime.length === 0) {
      return false;
    } else if (shiftData.expectedAppointmentTypeId.length === 0) {
      return false;
    } else if (shiftData.expectedLunchTime.length === 0) {
      return false;
    } else if (shiftData.expectedNoOfAppointments.length === 0) {
      return false;
    } else if (shiftData.isSurgeryExpected.length === 0) {
      return false;
    } else if (!shiftData.instantBookable) {
      return false;
    } else if (shiftData.selectedDays.length === 0) {
      return false;
    } else if (shiftData.additionalDoctor === 0) {
      return false;
    } else if (!shiftData.isWalkInsOrDropOffs || shiftData.isWalkInsOrDropOffs === 0) {
      return false;
    } else if (shiftData.selectedDays.length === 1) {
      if (shiftData.shiftRepeat.value !== 0) {
        if (!shiftData.endDay || shiftData.individualShift === 0) {
          return false;
        } else {
          return true;
        }
      } else if (
        shiftData.isSurgeryExpected === "1" &&
        ((!shiftData.isSurgeryTier && shiftData.typeOfSurgeryIds.length === 0) ||
          (shiftData.isSurgeryTier &&
            (!shiftData.surgeryTier || shiftData.surgeryTier.length === 0)))
      ) {
        return false;
      } else {
        return true;
      }
    } else if (shiftData.selectedDays.length > 1 && shiftData.individualShift === 0) {
      return false;
    } else if (
      shiftData.isSurgeryExpected === "1" &&
      ((!shiftData.isSurgeryTier && shiftData.typeOfSurgeryIds.length === 0) ||
        (shiftData.isSurgeryTier && (!shiftData.surgeryTier || shiftData.surgeryTier.length === 0)))
    ) {
      return false;
    } else if (!this.checkShiftBoostAmount(shiftData.shiftPriceIncreaseAmount, false)) {
      return false;
    } else {
      return true;
    }
  };

  checkHospitalAddTechShiftData = (shiftData: any): boolean => {
    if (shiftData.startTime.length === 0) {
      return false;
    } else if (shiftData.selectedDays.length === 0) {
      return false;
    } else if (!shiftData.tier) {
      return false;
    } else if (shiftData.isSurgeryExpected === false) {
      return false;
    } else if (shiftData.isSurgeryExpected == 1 && shiftData.typeOfSurgeryIds.length === 0) {
      return false;
    } else if (shiftData.selectedDays.length === 1) {
      if (shiftData.shiftRepeat.value !== 0) {
        if (!shiftData.endDay || shiftData.individualShift === 0) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else if (shiftData.selectedDays.length > 1 && shiftData.individualShift === 0) {
      return false;
    } else if (!this.checkShiftBoostAmount(shiftData.techShiftPriceIncreaseAmount, true)) {
      return false;
    } else {
      return true;
    }
  };

  checkHospitalEditShiftData = (shiftData: any): boolean => {
    if (
      shiftData.startTime.length === 0 ||
      shiftData.expectedAppointmentTypeId.length === 0 ||
      shiftData.expectedLunchTime.length === 0 ||
      shiftData.expectedNoOfAppointments.length === 0 ||
      shiftData.isSurgeryExpected.length === 0 ||
      !shiftData.instantBookable ||
      shiftData.additionalDoctor === 0
    ) {
      return false;
    } else if (
      shiftData.isSurgeryExpected === "1" &&
      ((!shiftData.isSurgeryTier && shiftData.typeOfSurgeryIds.length === 0) ||
        (shiftData.isSurgeryTier && (!shiftData.surgeryTier || shiftData.surgeryTier.length === 0)))
    ) {
      return false;
    } else if (!this.checkShiftBoostAmount(shiftData.shiftPriceIncreaseAmount, false)) {
      return false;
    } else {
      return true;
    }
  };

  checkHospitalEditTechShiftData = (shiftData: any): boolean => {
    if (
      shiftData.startTime.length === 0 ||
      shiftData.expectedBreakTime.length === 0 ||
      !parseInt(shiftData.tier)
    ) {
      return false;
    } else if (!this.checkShiftBoostAmount(shiftData.techShiftPriceIncreaseAmount, true)) {
      return false;
    } else {
      return true;
    }
  };

  checkShiftAdditionalCommentData = (shiftData: any): boolean => {
    if (shiftData.additionalComments !== "" && shiftData.additionalComments.length > 200) {
      return false;
    }
    return true;
  };

  checkHospitalPostInternshipData = (data: any): boolean => {
    const {
      title,
      startDate,
      expectedProgramLength,
      expectedNoOfDoctors,
      contactName,
      contactPhoneNumber,
      estimatedWeeklyHrs,
      estimatedCompensation,
      totalNoOfPositions,
      noOfVetsInPractice,
      noOfPractices,
      estimatedBenefits,
      learningObjectives,
      teachingMethod,
      specialInterest,
    } = data;
    if (!startDate || startDate.length === 0) {
      return false;
    } else if (!title || title.length === 0) {
      return false;
    } else if (!expectedProgramLength || expectedProgramLength <= 0) {
      return false;
    } else if (
      !expectedNoOfDoctors ||
      expectedNoOfDoctors == 0 ||
      parseInt(expectedNoOfDoctors) < 0 ||
      parseInt(expectedNoOfDoctors) > 99
    ) {
      return false;
    } else if (
      !estimatedWeeklyHrs ||
      estimatedWeeklyHrs == 0 ||
      parseInt(estimatedWeeklyHrs) < 0 ||
      parseInt(estimatedWeeklyHrs) > 999
    ) {
      return false;
    } else if (!totalNoOfPositions || totalNoOfPositions.length === 0) {
      return false;
    } else if (!contactName || contactName.length === 0) {
      return false;
    } else if (!contactPhoneNumber || contactPhoneNumber.length === 0) {
      return false;
    } else if (!noOfPractices || noOfPractices.length === 0) {
      return false;
    } else if (!noOfVetsInPractice || noOfVetsInPractice.length === 0) {
      return false;
    } else if (!estimatedCompensation || estimatedCompensation == 0) {
      return false;
    } else if (!estimatedBenefits || estimatedBenefits.length === 0) {
      return false;
    } else if (!learningObjectives || learningObjectives.length === 0) {
      return false;
    } else if (!teachingMethod || teachingMethod.length === 0) {
      return false;
    } else if (!specialInterest || specialInterest.length === 0) {
      return false;
    } else {
      return true;
    }
  };

  checkHospitalPostExternshipData = (data: any): boolean => {
    const {
      expectedProgramLength,
      expectedNoOfDoctors,
      contactName,
      contactPhoneNumber,
      noOfVetsInPractice,
      learningObjectives,
      handsOnLearning,
      travelStipend,
      accommodations,
      specialInterest,
    } = data;

    if (!expectedProgramLength || expectedProgramLength <= 0) {
      return false;
    } else if (
      !expectedNoOfDoctors ||
      expectedNoOfDoctors == 0 ||
      parseInt(expectedNoOfDoctors) < 0 ||
      parseInt(expectedNoOfDoctors) > 99
    ) {
      return false;
    } else if (parseInt(travelStipend) < 0 || parseInt(travelStipend) > 9999) {
      return false;
    } else if (!handsOnLearning || handsOnLearning.length === 0) {
      return false;
    } else if (!contactName || contactName.length === 0) {
      return false;
    } else if (!contactPhoneNumber || contactPhoneNumber.length === 0) {
      return false;
    } else if (!noOfVetsInPractice || noOfVetsInPractice.length === 0) {
      return false;
    } else if (!accommodations || accommodations.length === 0) {
      return false;
    } else if (!learningObjectives || learningObjectives.length === 0) {
      return false;
    } else if (!specialInterest || specialInterest.length === 0) {
      return false;
    } else {
      return true;
    }
  };

  validateDOBForPayment = (value: string): boolean => {
    const date_regex = /^(0?[1-9]|1[0-2])\-(0?[1-9]|1\d|2\d|3[01])\-(19[00-99]\d|20[00-10]\d)$/;
    return date_regex.test(value);
  };

  checkEventData = (data: any): boolean => {
    const addEventBaseSchema = {
      id: Joi.number().optional(),
      event_date: Joi.string().required(),
      event_description: Joi.string().max(500).required(),
      event_end_time: Joi.object().required(),
      event_location: Joi.string().required(),
      event_nop: Joi.number().required(),
      event_owner_type: Joi.number().required(),
      event_start_time: Joi.object().required(),
      event_title: Joi.string().required(),
      event_user_type: Joi.number().required(),
      event_owner_hospital: Joi.number().optional(),
      event_owner_name: Joi.string().optional(),
      event_owner_email: Joi.string().optional(),
      event_unix_date: Joi.number().required(),
      time_zone: Joi.string().required(),
      image_path: Joi.string().optional(),
    };
    let addEventSchema: Joi.ObjectSchema;
    if (data.event_owner_type == 1) {
      addEventSchema = Joi.object().keys({
        ...addEventBaseSchema,
        event_owner_hospital: Joi.number().required(),
      });
    } else {
      addEventSchema = Joi.object().keys({
        ...addEventBaseSchema,
        event_owner_name: Joi.string().required(),
        event_owner_email: Joi.string()
          .required()
          .email({ tlds: { allow: false } }),
      });
    }

    const joiValidate = addEventSchema.validate(data);
    if (joiValidate.error) {
      return false;
    } else {
      return true;
    }
  };

  /**
   * If error is a ZodError, convert it to user-friendly text string. Otherwise, return message attribute of Error
   * @param error
   * @param invalidItemIndexOffset validating CSVs might require an error message to have additional index (row) offset
   */
  convertZodErrorToHumanReadableString = (
    error: Error,
    invalidItemIndexOffset: number = 0,
    itemString: string = "Item"
  ) => {
    if (error instanceof ZodError || error.name === "ZodError") {
      return (error as ZodError).errors
        .map((err) => {
          const path = err.path;
          if (path.length > 1 && typeof path[0] === "number") {
            // assume we're validating an array, include index of invalid item
            const invalidItemIndex = (err.path[0] as number) + invalidItemIndexOffset;
            return `${itemString} ${invalidItemIndex} ${err.path[1]}: ${err.message}`;
          } else {
            return `${err.path.join(" > ")}: ${err.message}`;
          }
        })
        .join("\n ------- \n");
    } else {
      return error.message;
    }
  };
}

// eslint-disable-next-line no-restricted-exports
export default new Validation();
