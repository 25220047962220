import { QueryClientProvider } from "@tanstack/react-query";
import posthog from "posthog-js";
import React, { Suspense, lazy } from "react";
import { connect } from "react-redux";
import { Route, withRouter } from "react-router-dom";
import _refiner from "refiner-js";

import { Theme } from "@roo-dev/ui-components";

import LicenseViewer from "./Admin/LicenseViewer/LicenseViewer";
import { queryClient } from "./api/reactQueryClient";
import { SocketSource, initSocketConnection } from "./Common/Chat/service";
import Modal from "./Common/ModalComponent";
import { NavigationReasonContainer } from "./Common/NavigationReason/components/NavigationReasonContainer";
import NotificationSettingsContainer from "./Common/NotificationSettings/NotificationSettingsContainer";
import PoliciesPage from "./Common/Policies/PoliciesPage";
import ProtectedRoute from "./Common/ProtectedRoute";
import { RooRoute } from "./Common/RooRoute";
import { trackParamsInSession } from "./Common/sessionUtils";
import { ToastContainer } from "./Common/Toasts/components/ToastContainer";
import { extractUrlInfo } from "./Common/urlUtils";
import { isRooFeatureEnabled } from "./Common/Wrappers/isRooFeatureEnabled";
import { isRooFeatureFlagVariant } from "./Common/Wrappers/isRooFeatureFlagVariant";
import * as ErrorMessages from "./constants/errorMessage";
import { FEATURE_FLAGS, FEATURE_FLAG_VARIANTS } from "./constants/postHogFeatureFlags";
import * as userStatusTypes from "./constants/userStatusTypes";
import { PENDING } from "./constants/userStatusTypes";
import * as UserTypes from "./constants/UserTypeConstants";
import { EventSignUpContainer, EventSignUpRedirectContainer } from "./EventSignUp/components";
import { AuthUtility } from "./helpers/AuthUtility";
import SettingsPage from "./Hospital/ManageSettings/SettingsPage";
import { setLoaderAction } from "./loader/actions/loaderAction";
import Loader from "./loader/components/loaderComponent";
import * as LoginActionCreators from "./Login/Action/LoginActions";
import { ForgotPasswordComponent as ForgotPassword } from "./Login/forgotPassword/components/ForgotPasswordComponent";
import { ResetPasswordContainer as ResetPassword } from "./Login/forgotPassword/components/ResetPasswordContainer";
import Login from "./Login/Login";
import "./static/css/main-spa.scss";
import { Event, EventTracker } from "./tracking/service/EventTracker";
import Unsubscribe from "./Unsubscribe/Unsubscribe";

const AdminContainer = lazy(() => import("./Admin/AdminContainer"));
const RooSuperUserContainer = lazy(() => import("./Admin/RooSuperUserSelectUser"));
const RegisterRooInternalUser = lazy(() =>
  import("./Registration/RegistrationRooInternalUser/RegistrationRooInternalUserContainer")
);
const RegisterEnterpriseHospitalUser = lazy(() =>
  import("./Registration/RegistrationEnterprise/RegistrationEnterpriseContainer")
);
const RegistrationHospital = lazy(() =>
  import("./Registration/RegistrationHospital/RegistrationHospitalContainer")
);
const RegisterStudent = lazy(() =>
  import("./Registration/RegistrationStudent/RegistrationStudentContainer")
);
const RegisterNew = lazy(() => import("./RegistrationNew/RegistrationNew"));
const Enterprise = lazy(() => import("./Enterprise/EnterpriseContainer"));
const Hospital = lazy(() => import("./Hospital/HospitalContainer"));
const Vet = lazy(() => import("./vet/VetContainer"));
const Tech = lazy(() => import("./tech/TechContainer"));
const RooUniStudent = lazy(() => import("./student/StudentContainer"));

const initSession = async () => {
  const params = new URLSearchParams(window.location.search);
  const utmParameterKeys = ["utm_source", "utm_medium", "utm_campaign", "utm_content", "utm_term"];
  const adParameterKeys = [
    "ad_set_id", // Facebook
    "ad_group_id", // Google
    "ad_id", // Facebook, Google
  ];
  /** @type import("./Common/sessionUtils").UtmParams */
  const utmParams = {};
  /** @type import("./Common/sessionUtils").AdParams */
  const adParams = {};

  const referrerUrlInfo = extractUrlInfo(document.referrer);

  [
    [utmParameterKeys, utmParams],
    [adParameterKeys, adParams],
  ].forEach(([parameterKeys, paramsObj]) => {
    for (const idx in parameterKeys) {
      const parameterKey = parameterKeys[idx];
      const referrerParameterValue =
        referrerUrlInfo?.hostname === window.location?.hostname
          ? referrerUrlInfo.searchParams[parameterKey]
          : null;
      const parameterValue = params.get(parameterKey) || referrerParameterValue;
      if (parameterValue) {
        const sanitizedParameterValue = parameterValue.trim();
        if (sanitizedParameterValue) {
          paramsObj[parameterKey] = sanitizedParameterValue;
        }
      }
    }
  });

  await trackParamsInSession(utmParams, adParams);
};

class App extends React.Component {
  constructor(props) {
    super();
    this.oURLSearchParams = new URLSearchParams(props.location.search);
    if (props.location.search) {
      // set redirectUrl in login.state if query string in url
      // This is required in case we are coming from outside(email link) to application and we are not logged in.
      // So we need to redirect again to the original email link.
      props.setRedirect(props.location.pathname + props.location.search);
    }
    this.isRoutingDone = false;
    this.state = {
      zeLoaded: false,
      addShiftDropdownClassName: "dropdown-menu dropdown-menu-right",
    };
  }

  componentDidMount() {
    initSocketConnection(SocketSource.APP_INIT);
    initSession();
    const { pathname, search } = window.location;
    const redirectUrl = search ? pathname + search : null;

    if (pathname === "/unsubscribe") {
      // skip all logic for email unsubscribe page
      return;
    }
    this.routeUser(redirectUrl);
  }

  onClickBody = () => {
    const addShiftDropdown = document.getElementById("add-shift-dropdown");
    const currentClassName = addShiftDropdown?.className;
    if (currentClassName && currentClassName !== this.state.addShiftDropdownClassName) {
      this.setState({ addShiftDropdownClassName: currentClassName }, () => {
        if (currentClassName.indexOf("show") > -1) {
          EventTracker.send(
            Event.Name.HOSPITAL_ADD_SHIFT,
            Event.Type.CLICK,
            Event.Entity.HOSPITAL,
            this.props.hospitalId,
            {
              shiftType: null,
              location: "add_shift_navbar",
            }
          );
        }
      });
    }
  };

  componentDidUpdate(prevProps) {
    const userTypeId = parseInt(localStorage.getItem("userTypeId"));
    const isHospital = userTypeId === UserTypes.HOSPITAL_USER_TYPE;
    const isAuthorised = this.props.isAuthorisedUser;

    const body = document.getElementById("root");
    body.onclick = this.onClickBody;

    const {
      isSessionInfoFetched,
      isRegistrationComplete,
      vetRatingCount,
      techRatingCount,
      history,
      isPendingVetRatings,
      isPendingTechRatings,
      loginMessage,
      isActive,
      termsAccepted,
      hasICAgreementSkipped,
      userId,
      firstName,
      lastName,
      email,
      approvedOnDate,
      isEligibleForNPS,
      fullName,
      location,
    } = this.props;

    // send pageview event to posthog on page change (excluding the first page load, which is already captured)
    if (location.pathname !== prevProps?.location?.pathname) {
      posthog.capture("$pageview");
    }

    const { pathname } = history.location;

    if (pathname === "/unsubscribe") {
      // skip all logic for email unsubscribe page
      return;
    }
    const localStorageRedirectUrl = localStorage.getItem("redirectUrl");
    const redirectUrl = this.props.redirectUrl || localStorageRedirectUrl;
    const isVet = userTypeId === UserTypes.VET_USER_TYPE;
    const isTech = userTypeId === UserTypes.TECH_USER_TYPE;
    // reload page for demo users
    const isReloadAfterDemo = localStorage.getItem("isReloadAfterDemo");
    if (isReloadAfterDemo && (isVet || isTech)) {
      localStorage.removeItem("isReloadAfterDemo");
      if (isVet) history.push("/vet");
      else history.push("/tech");
    }
    // for existing CA vets, mandate IC contract
    const userStatus = isActive ? isActive : localStorage.getItem("isActive");
    const isPending = userStatus == userStatusTypes.PENDING;
    const isBlocked = userStatus == userStatusTypes.BLOCKED;

    if (userId && email) {
      _refiner("setProject", window.RooConfig.REFINER_ID);

      const name = firstName || lastName ? `${firstName} ${lastName}` : fullName;

      _refiner("identifyUser", {
        id: userId,
        email: email,
        name,
        approved_on_date: new Date(approvedOnDate),
        user_type_id: userTypeId,
        is_eligible_for_nps: isEligibleForNPS,
      });
    }

    if (
      isSessionInfoFetched &&
      isVet &&
      parseInt(isRegistrationComplete) === 1 &&
      pathname !== "/vet/icAgreement" &&
      !termsAccepted &&
      !hasICAgreementSkipped &&
      !isPending &&
      !isBlocked &&
      !isRooFeatureFlagVariant(FEATURE_FLAGS.SMOOTH_ONBOARDING_V1, FEATURE_FLAG_VARIANTS.TEST)
    ) {
      history.push("/vet/icAgreement" + history.location.search);
    }

    if (
      isSessionInfoFetched &&
      isTech &&
      parseInt(isRegistrationComplete) === 1 &&
      pathname !== "/tech/icAgreement" &&
      !termsAccepted &&
      !hasICAgreementSkipped &&
      !isPending &&
      !isBlocked
    ) {
      history.push("/tech/icAgreement");
    }

    if (isAuthorised && loginMessage && loginMessage.statusCode === 200 && !this.isRoutingDone) {
      // This is after login
      this.routeUser(redirectUrl, true);
    } else if (!isAuthorised) {
      this.isRoutingDone = false;
    }
    const isDoVetRatings = isPendingVetRatings && vetRatingCount !== null && vetRatingCount <= 15;
    const isDoTechRatings =
      isPendingTechRatings && techRatingCount !== null && techRatingCount <= 15;
    if (isHospital && isAuthorised) {
      if (
        pathname !== "/hospital" &&
        pathname !== "/hospital/externships" &&
        pathname !== "/hospital/postExternship"
      ) {
        if (isDoVetRatings || isDoTechRatings) {
          history.push("/hospital");
        }
      }
    }
    if (window.zE) {
      // setting the zendesk chat widget's zindex one point lower to that of right-panel-container which is 100 so that it wont overlap with chat's send btn
      window.zE("messenger:set", "zIndex", 99);
      if (!this.state.zeLoaded && isActive) {
        this.setState({
          zeLoaded: true,
        });
      }
    }
  }

  routeUser = (redirectUrl, isPostLogin = false) => {
    const {
      history,
      hospitalRegistrationPageNo,
      studentRegistrationPageNo,
      setSession,
      clearRedirect,
      termsAccepted,
      hasICAgreementSkipped,
    } = this.props;

    const url = history.location.pathname.split("/");
    const urlPath = url[1];
    const userTypeId = parseInt(localStorage.getItem("userTypeId"));
    const isHospital = userTypeId === UserTypes.HOSPITAL_USER_TYPE;
    const isVet = userTypeId === UserTypes.VET_USER_TYPE;
    const isTech = userTypeId === UserTypes.TECH_USER_TYPE;
    const isRooUniStudent = userTypeId === UserTypes.STUDENT_USER_TYPE;
    const isProfileComplete = localStorage.getItem("isRegistrationComplete") === "1";
    const isGroupAdmin = localStorage.getItem("isGroupAdmin");
    const isEnterpriseAdmin = localStorage.getItem("isEnterpriseAdmin");
    const isMultiHospitalAccess = localStorage.getItem("isMultiHospitalAccess");
    const isPending = Number(localStorage.getItem("isActive")) === PENDING;
    const isRooAdmin =
      userTypeId === UserTypes.ADMIN_USER_TYPE || userTypeId === UserTypes.ROO_SUPER_USER_TYPE;

    this.isVet = isVet;
    if (AuthUtility.isAuthorisedUser()) {
      this.isRoutingDone = true;
      if (!isPostLogin) {
        //When user refresh the screen
        this.props.setLoader();
        setSession();
      }

      const bypassHomepageRerouteLogicList = [
        "notification",
        "policies",
        "manageSettings",
        "licenseViewer",
        "superuser",
        "resetpassword",
      ];

      if (bypassHomepageRerouteLogicList.includes(urlPath)) {
        history.push("/" + url[1] + history.location.search);
        return;
      }
      const urlPathNoQueryString = redirectUrl ? redirectUrl.split("?")[0] : null;
      if (
        redirectUrl &&
        ((isHospital && urlPathNoQueryString.startsWith("/hospital") && !isPending) ||
          (isVet && urlPathNoQueryString.startsWith("/vet")) ||
          (isTech && urlPathNoQueryString.startsWith("/tech")) ||
          (isRooAdmin && urlPathNoQueryString.startsWith("/admin")) ||
          (isRooUniStudent && urlPathNoQueryString.startsWith("/student")))
      ) {
        clearRedirect(); // clear redirectUrl from redux Store
        localStorage.removeItem("redirectUrl"); // also clear redirectUrl from localStorage (used to pass along from login)
        history.push(redirectUrl);
      } else if (isHospital) {
        const hospitalId = localStorage.getItem("hospitalId");
        if ((isEnterpriseAdmin || isGroupAdmin || isMultiHospitalAccess) && !hospitalId) {
          if (url[1] === "enterprise") {
            history.replace(history.location.pathname);
          } else {
            history.replace("/enterprise");
          }
        } else if (isProfileComplete) {
          if (isRooFeatureEnabled(FEATURE_FLAGS.NEW_HOSPITAL_REGISTRATION)) {
            if (isPending) {
              history.push("/register/success");
            } else {
              if (url[1] === "hospital") {
                history.replace(history.location.pathname + history.location.search);
              } else {
                history.replace("/hospital" + history.location.search);
              }
            }
          } else if (url[1] === "hospital") {
            history.replace(history.location.pathname + history.location.search);
          } else {
            history.replace("/hospital" + history.location.search);
          }
        } else {
          if (isRooFeatureEnabled(FEATURE_FLAGS.NEW_HOSPITAL_REGISTRATION)) {
            history.push("/register/hospitalContinue");
          }
          if (hospitalRegistrationPageNo === 0) {
            history.push("/registerHospital/basicInformation");
          } else if (hospitalRegistrationPageNo === 1) {
            history.push("/registerHospital/hospitalData");
          } else if (hospitalRegistrationPageNo === 2) {
            history.push("/registerHospital/knowYourHospital");
          } else if (hospitalRegistrationPageNo === 3) {
            history.push("/registerHospital/photoUpload");
          } else if (hospitalRegistrationPageNo === 4) {
            history.push("/registerHospital/photoUpload");
          }
        }
      } else if (isVet) {
        if (isProfileComplete) {
          const { isActive } = this.props;
          const userStatus = isActive ? isActive : localStorage.getItem("isActive");
          const isPending = userStatus == userStatusTypes.PENDING;

          // for existing CA vets, mandate IC contract
          if (
            !termsAccepted &&
            termsAccepted != undefined &&
            !hasICAgreementSkipped &&
            hasICAgreementSkipped != undefined &&
            !isPending &&
            !isRooFeatureFlagVariant(FEATURE_FLAGS.SMOOTH_ONBOARDING_V1, FEATURE_FLAG_VARIANTS.TEST)
          ) {
            history.push("/vet/icAgreement" + history.location.search);
          } else {
            if (url[1] === "vet") {
              history.replace(history.location.pathname + history.location.search);
            } else {
              history.replace("/vet" + history.location.search);
            }
          }
        } else {
          history.push("/register/continue");
        }
      } else if (isTech) {
        if (isProfileComplete) {
          if (url[1] === "tech") {
            history.replace(history.location.pathname + history.location.search);
          } else {
            history.replace("/tech" + history.location.search);
          }
        } else {
          history.push("/register/continue");
        }
      } else if (isRooUniStudent) {
        if (isProfileComplete) {
          history.replace("/student/externships");

          // if (isFourthYear) {
          //   history.push("/student/internships");
          // } else {
          //   history.push("/student/externships");
          // }
        } else {
          if (studentRegistrationPageNo === 0) {
            history.push("/registerStudent/basicInformation");
          } else if (studentRegistrationPageNo === 1) {
            history.push("/registerStudent/educationExperience");
          } else if (studentRegistrationPageNo === 2) {
            history.push("/registerStudent/knowYourStudent");
          } else if (studentRegistrationPageNo === 3) {
            history.push("/registerStudent/photoUpload");
          } else if (studentRegistrationPageNo === 4) {
            history.push("/registerStudent/profileComplete");
          }
        }
      } else if (isRooAdmin && location.pathname != "/superuser") {
        if (url[1] === "admin") {
          history.replace(history.location.pathname);
        } else {
          history.replace("/admin");
        }
      }
    }
  };

  // checkisRooAdmin(userTypeId) {
  //   let isRooAdmin = false;
  //   const vetId = localStorage.getItem("vetId");
  //   const hospitalId = localStorage.getItem("hospitalId");
  //   const techId = localStorage.getItem("techId");
  //   const studentId = localStorage.getItem("studentId");
  //   if (
  //     userTypeId &&
  //     userTypeId === UserTypes.ADMIN_USER_TYPE &&
  //     !vetId &&
  //     !techId &&
  //     !studentId &&
  //     !hospitalId
  //   ) {
  //     isRooAdmin = true;
  //   }
  //   return isRooAdmin;
  // }

  userBlockedPopUpClose = () => {
    localStorage.clear();
    this.props.hideBlockedUserPopup();
  };

  getModalMessage = () => {
    const { isShowUserBlockedPopup, isShowUserSuspendedPopup } = this.props;
    let message = "";
    // if (isShowUserBlockedPopup) {
    //   message = ErrorMessages.LOGIN_BLOCKED_MESSAGE;
    // }
    if (isShowUserSuspendedPopup) {
      message = ErrorMessages.LOGIN_SUSPENDED_MESSAGE;
    }

    return message;
  };

  render() {
    const { loader, isShowUserBlockedPopup, isShowUserSuspendedPopup } = this.props;
    const modalMessage = this.getModalMessage();
    return (
      <QueryClientProvider client={queryClient}>
        <Theme />
        <ToastContainer />
        <NavigationReasonContainer />
        <Modal
          smModal={true}
          onCloseHandler={this.userBlockedPopUpClose}
          showModal={isShowUserSuspendedPopup}
          modalBody={
            <div className="messages success-message p-0">
              {modalMessage}
              <div>
                <button className="btn btn-primary mt-4" onClick={this.userBlockedPopUpClose}>
                  Okay
                </button>
              </div>
            </div>
          }
        />
        <Loader showLoader={loader} />
        <RooRoute path="/login" title="Log In" component={Login} />
        <RooRoute path="/forgotPassword" title="Forgot Password" component={ForgotPassword} />
        <Route path="/resetpassword" component={ResetPassword} />
        <Route path="/unsubscribe" component={Unsubscribe} />
        <Suspense fallback={<Loader showLoader={true} />}>
          <RooRoute
            path="/registerHospital"
            title="Create Hospital Account"
            component={RegistrationHospital}
          />
          <Route
            path="/registerEnterpriseHospitalUser"
            component={RegisterEnterpriseHospitalUser}
          />
          <RooRoute path="/register" component={RegisterNew} title="Create Account" />
          <Route path="/registerEnterpriseHospital" component={RegisterEnterpriseHospitalUser} />
          <Route path="/registerRooInternalUser" component={RegisterRooInternalUser} />
          <RooRoute path="/registerStudent" title="Create Account" component={RegisterStudent} />
          <ProtectedRoute path="/enterprise" component={Enterprise} />
          <ProtectedRoute path="/hospital" component={Hospital} />
          <ProtectedRoute path="/vet" component={Vet} />
          <ProtectedRoute path="/tech" component={Tech} />
          <ProtectedRoute path="/student" component={RooUniStudent} />
          <ProtectedRoute path="/admin" component={AdminContainer} />
          <ProtectedRoute path="/superuser" component={RooSuperUserContainer} />
          <ProtectedRoute path="/notification" component={NotificationSettingsContainer} />
          <ProtectedRoute path="/policies" component={PoliciesPage} />
          <ProtectedRoute path="/manageSettings" component={SettingsPage} />
          <ProtectedRoute path="/licenseViewer" component={LicenseViewer} />
          <Route path="/e/:eventSlug" component={EventSignUpContainer} />
          <Route path="/event/:eventSlug" component={EventSignUpRedirectContainer} />
        </Suspense>
      </QueryClientProvider>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isSessionInfoFetched: state.login.isSessionInfoFetched,
    isRegistrationComplete: state.login.isRegistrationComplete,
    stateId: state.login.stateId,
    termsAccepted: state.login.termsAccepted,
    hasICAgreementSkipped: state.login.hasICAgreementSkipped,
    loader: state.loader.loader,
    isPendingTechRatings: state.hospital.isPendingTechRatings,
    techRatingCount: state.shiftRatingByHospital.techRatingCount,
    isPendingVetRatings: state.hospital.isPendingVetRatings,
    vetRatingCount: state.shiftRatingByHospital.vetRatingCount,
    isAuthorisedUser: state.login.isAuthorisedUser,
    isShowUserBlockedPopup: state.login.isShowUserBlockedPopup,
    isShowUserSuspendedPopup: state.login.isShowUserSuspendedPopup,
    isShowUserPendingPopup: state.login.isShowUserPendingPopup,
    vetRegistrationPageNo: state.login.vetRegistrationPageNo,
    techRegistrationPageNo: state.login.techRegistrationPageNo,
    studentRegistrationPageNo: state.login.studentRegistrationPageNo,
    hospitalRegistrationPageNo: state.login.hospitalRegistrationPageNo,
    newRegistrationPage: state.login.newRegistrationPage,
    loginMessage: state.login.loginMessage,
    userTypeId: state.login.userTypeId,
    redirectUrl: state.login.redirectUrl,
    studentGradYear: state.login.studentGradYear,
    isActive: state.login.isActive,
    isShowOnboarding: state.login.isShowOnboarding,
    firstName: state.login.firstName,
    lastName: state.login.lastName,
    phoneNumber: state.login.phoneNumber,
    fullName: state.login.fullName,
    email: state.login.email,
    userId: state.login.userId,
    approvedOnDate: state.login.approvedOnDate,
    isEligibleForNPS: state.login.isEligibleForNPS,
    hospitalId: state.login.hospitalId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoader: () => dispatch(setLoaderAction()),
    setRedirect: (value) => dispatch(LoginActionCreators.setRedirect(value)),
    hideBlockedUserPopup: () => dispatch(LoginActionCreators.hideBlockedUserPopup()),
    setSession: () => dispatch(LoginActionCreators.setSessionOnRefresh()),
    clearRedirect: () => dispatch(LoginActionCreators.clearRedirect()),
  };
};

// eslint-disable-next-line no-restricted-exports
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
