export const GET_INTERNSHIP_LIST = "GET_INTERNSHIP_LIST";
export const GET_INTERNSHIP_LIST_SUCCESS = "GET_INTERNSHIP_LIST_SUCCESS";
export const GET_INTERNSHIP_LIST_FAIL = "GET_INTERNSHIP_LIST_FAIL";

export const INITIALIZE_ADMIN_INTERNSHIP_STATE = "INITIALIZE_ADMIN_INTERNSHIP_STATE";

export const UPDATE_INTERNSHIP_REQUEST = "UPDATE_INTERNSHIP_REQUEST";
export const UPDATE_INTERNSHIP_SUCCESS = "UPDATE_INTERNSHIP_SUCCESS";
export const UPDATE_INTERNSHIP_FAIL = "UPDATE_INTERNSHIP_FAIL";

export const DELETE_INTERNSHIP_REQUEST = "DELETE_INTERNSHIP_REQUEST";
export const DELETE_INTERNSHIP_SUCCESS = "DELETE_INTERNSHIP_SUCCESS";
export const DELETE_INTERNSHIP_FAIL = "DELETE_INTERNSHIP_FAIL";

export const CLEAR_INTERNSHIP_FLAG = "CLEAR_INTERNSHIP_FLAG";
