import React from "react";

import CountDown from "../helpers/countDown";

const getTimeLeftCounter = (
  handleTimeExpired,
  isShowFormattedCounter,
  isFromMapView,
  currentTimeStamp,
  expiresTimeStamp = null
) => {
  const object = {};
  // const mapViewStyle = { position: "relative", top: "-15px" };
  if (expiresTimeStamp === null) {
    object.requestExpired = true;
    object.message = <p className="font-s-12 text-red">Your previous request has expired</p>;
    object.counter = null;
  } else {
    object.requestExpired = false;
    object.message = (
      <p className="font-s-12 font-w-500 mb-0" data-testid="requestMsg">
        Your request has been submitted.{" "}
        <span>
          <CountDown
            countDownDate={expiresTimeStamp}
            currentTimeStamp={currentTimeStamp}
            additionalClassName="text-green-dark font-w-500"
          />{" "}
        </span>
        left for hospital to confirm.{" "}
      </p>
    );
    object.counter = (
      <CountDown
        countDownDate={expiresTimeStamp}
        currentTimeStamp={currentTimeStamp}
        handleTimeExpired={handleTimeExpired}
        isShowFormattedCounter={isShowFormattedCounter}
      />
    );
    object.expiresTimeStamp = expiresTimeStamp;
  }
  return object;
};

export const shiftRequestTimeLeft = (
  handleTimeExpired,
  isFromMapView,
  expiresTimeStamp,
  isShowFormattedCounter = false
) => {
  const currentTimeStamp = Math.floor(new Date().getTime() / 1000); // UTC time

  if (!expiresTimeStamp) {
    return {
      requestExpired: false,
      message: "",
      counter: null,
    };
  }

  if (expiresTimeStamp < currentTimeStamp) {
    return getTimeLeftCounter();
  } else if (expiresTimeStamp >= currentTimeStamp) {
    return getTimeLeftCounter(
      handleTimeExpired,
      isShowFormattedCounter,
      isFromMapView,
      currentTimeStamp,
      expiresTimeStamp
    );
  }

  return {
    requestExpired: false,
    message: <p className="message">Something went wrong.</p>,
    counter: null,
  };
};

// eslint-disable-next-line no-restricted-exports
export default shiftRequestTimeLeft;
