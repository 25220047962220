import * as Sentry from "@sentry/react";
import { Auth, Cache } from "aws-amplify";
import fetchIntercept from "fetch-intercept";

import { getCurrentTimeZone } from "../Common/dateUtils";
import { INITIALIZE_RATING_MODAL_FLAG } from "../constants/hospitalConstants";
import {
  INIT_AUTHORISE_USER,
  SHOW_USER_BLOCKED_POP_UP,
  SHOW_USER_PENDING_POP_UP,
  SHOW_USER_SUSPENDED_POP_UP,
} from "../constants/Login";
import * as UserTypes from "../constants/UserTypeConstants";
import store from "../store/index";

import { AuthUtility } from "./AuthUtility";

const isJsonString = (str) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};
const shouldIgnoreUrl = (url) => {
  return (
    url.indexOf(window.RooConfig.API_URL) === -1 &&
    url.indexOf(window.RooConfig.EXPERIMENT_API_URL) === -1 &&
    url.indexOf(window.RooConfig.FEATURE_FLAG_URL) === -1 &&
    url.indexOf(window.RooConfig.MESSAGING_API_URL) === -1 &&
    url.indexOf(window.RooConfig.MESSAGING_SOCKET_URL) === -1 &&
    url.indexOf(window.RooConfig.NOTIFICATION_SETTINGS_API_URL) === -1 &&
    url.indexOf(window.RooConfig.SMS_OUTREACH_URL) === -1
  );
};

const unregister = fetchIntercept.register({
  request: async function (urlOrRequest, config) {
    let url = urlOrRequest;
    let requestConfig = config;

    // @ts-expect-error Posthog is passing a Request object when doing network capture
    let request = urlOrRequest instanceof Request ? urlOrRequest : null;

    if (request) {
      url = request.url;

      // Patch for Safari per https://github.com/wheresrhys/fetch-mock/commit/e3f2edbcc7e8f2bfd302f14e9f21b32f158449c2
      if (
        navigator.vendor === "Apple Computer, Inc." &&
        ["POST", "PUT", "PATCH"].includes(request.method)
      ) {
        const body = await request.clone().text();
        const {
          cache,
          credentials,
          destination,
          headers,
          integrity,
          keepalive,
          method,
          mode,
          redirect,
          referrer,
          referrerPolicy,
          signal,
        } = request;
        requestConfig = {
          body,
          cache,
          credentials,
          destination,
          headers,
          integrity,
          keepalive,
          mode,
          redirect,
          referrer,
          referrerPolicy,
          signal,
          method,
        };
        request = null;
      }
    }

    if (shouldIgnoreUrl(url)) {
      // skip adding session auth header
      return [urlOrRequest, requestConfig];
    } else {
      const isFormData = request
        ? request.body instanceof FormData
        : requestConfig?.body instanceof FormData;

      if (request) {
        requestConfig = {
          method: request.method,
          headers: new Headers(request.headers),
          body: request.body ? request.clone().body : null,
          mode: request.mode,
          credentials: request.credentials,
          cache: request.cache,
          redirect: request.redirect,
          referrer: request.referrer,
          integrity: request.integrity,
        };
      }

      const session = Auth.currentSession();
      return session
        .then((sess) => {
          if (!requestConfig.headers) {
            requestConfig.headers = new Headers();
          } else if (!(requestConfig.headers instanceof Headers)) {
            requestConfig.headers = new Headers(requestConfig.headers);
          }

          requestConfig.headers.set("x-access-token", "Bearer " + sess.idToken.jwtToken);

          if (!isFormData) {
            requestConfig.headers.set(
              "Content-Type",
              requestConfig.headers.get("Content-Type") || "application/json"
            );
          }

          requestConfig.headers.set("offset", getCurrentTimeZone());
          requestConfig.credentials = "include";

          // set super user headers
          try {
            const authContext = AuthUtility.getAuthContextFromToken(sess.idToken.jwtToken);
            if (authContext?.userTypeId === UserTypes.ROO_SUPER_USER_TYPE) {
              requestConfig.headers.set("X-Impersonated-UserId", localStorage.getItem("userId"));
            }
          } catch {
            // don't throw on JWT errors here
          }

          return request
            ? [new Request(request, { ...requestConfig, duplex: "half" }), undefined]
            : [url, requestConfig];
        })
        .catch((e) => {
          Sentry.captureException(e, {
            extra: { context: "Error in request interceptor: " },
          });

          if (!requestConfig.headers) {
            requestConfig.headers = new Headers();
          } else if (!(requestConfig.headers instanceof Headers)) {
            requestConfig.headers = new Headers(requestConfig.headers);
          }

          if (localStorage.getItem("aws-amplify-cachefederatedInfo")) {
            const { token } = Cache.getItem("federatedInfo");
            if (token) {
              requestConfig.headers.set("x-access-token", "Bearer " + token);

              if (!isFormData) {
                requestConfig.headers.set("Content-Type", "application/json");
              }
            }
          }

          requestConfig.headers.set("offset", getCurrentTimeZone());

          return request
            ? [new Request(request, { ...requestConfig, duplex: "half" }), undefined]
            : [url, requestConfig];
        });
    }
  },

  requestError: function (error) {
    // Called when an error occurred during another 'request' interceptor call
    return Promise.reject(error);
  },

  response: async function (response) {
    if (!shouldIgnoreUrl(response.url)) {
      // Modify the response object
      if (response.status === 401 && !store.getState().login.jwtToken) {
        localStorage.clear();
        store.dispatch({
          type: INIT_AUTHORISE_USER,
        });
        store.dispatch({
          type: INITIALIZE_RATING_MODAL_FLAG,
        });
      } else {
        let responseData;
        const responseClone = await response.clone().text();
        // Some of our APIs return text not JSON objects this makes prevents json parsing errors
        if (isJsonString(responseClone)) {
          try {
            responseData = await response.clone().json();
          } catch (error) {
            // throws error for images
            Sentry.captureException(error, {
              extra: { context: "Error while parsing response: " },
            });
            responseData = await response;
          }
          let url = response.url;

          url = url.substring(url.indexOf("/user"), url.length);

          if (url !== "/user/login" && responseData.data && responseData.data.results) {
            //This is for blocked users.
            if (responseData.data.results[0].isActive === 4) {
              store.dispatch({
                type: SHOW_USER_BLOCKED_POP_UP,
              });
            } else if (responseData.data.results[0].isActive === 9) {
              store.dispatch({
                type: SHOW_USER_SUSPENDED_POP_UP,
              });
            } else if (
              // isActive = 2 (rejected), is_active = 0( not approved )
              responseData.data.results[0].isRegistrationComplete === 1 &&
              (responseData.data.results[0].isActive === 0 ||
                responseData.data.results[0].isActive === 2) &&
              (responseData.data.results[0].userTypeId === UserTypes.VET_USER_TYPE ||
                responseData.data.results[0].userTypeId === UserTypes.TECH_USER_TYPE)
            ) {
              store.dispatch({
                type: SHOW_USER_PENDING_POP_UP,
              });
            }
          }
        }
      }
    }

    return response;
  },

  responseError: function (error) {
    // Handle an fetch error
    return Promise.reject(error);
  },
});
