import { useFeatureFlagEnabled, useFeatureFlagVariantKey } from "posthog-js/react";

import { FeatureFlagName } from "../../constants/postHogFeatureFlags";

// Wrapper around PostHog's hooks with stronger typing.

export const useRooFeatureFlagEnabled = (flagName: FeatureFlagName) =>
  useFeatureFlagEnabled(flagName); // eslint-disable-line roo/no-restricted-functions

export const useRooFeatureFlagVariantKey = (flagName: FeatureFlagName) =>
  useFeatureFlagVariantKey(flagName); // eslint-disable-line roo/no-restricted-functions
