import i18n from "../config/i18n";
import enTranslations from "../locales/en.json";
import enOnboardingTranslations from "../locales/en/onboarding.json";
import enPromoTranslations from "../locales/en/promos.json";

const initI18n = () => {
  // We're not using the browser language detector.
  const debugMode = process.env.NODE_ENV === "development";
  i18n.init({
    fallbackLng: "en",
    debug: debugMode,
    resources: {
      en: {
        translation: {
          ...enTranslations,
          promos: enPromoTranslations,
          onboarding: enOnboardingTranslations,
        },
      },
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });
};

export const configInit = () => {
  initI18n();
};
