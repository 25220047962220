import * as C from "./constants";

const initialState: { checkForUnsavedChanges: boolean; continueFromUnsavedChanges: boolean } = {
  checkForUnsavedChanges: false,
  continueFromUnsavedChanges: true,
};
const UnsavedChangesReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: {
    payload: { checkForUnsavedChanges: boolean; continueFromUnsavedChanges: boolean };
    type: string;
  }
) => {
  switch (action.type) {
    case C.CHECK_FOR_UNSAVED_CHANGES:
      return {
        ...state,
        checkForUnsavedChanges: action.payload.checkForUnsavedChanges,
      };
    case C.SET_CONTINUE_FROM_UNSAVED_CHANGES:
      return {
        ...state,
        continueFromUnsavedChanges: action.payload.continueFromUnsavedChanges,
      };
    case C.CLEAR_UNSAVED_CHANGES_STATE:
      return {
        checkForUnsavedChanges: false,
        continueFromUnsavedChanges: true,
      };
    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default UnsavedChangesReducer;
