import React from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import Header from "../../Common/HeaderLoggedIn";
import { USER_TYPE } from "../../constants/UserTypeConstants";

import { hospitalSettings, studentSettings, techSettings, vetSettings } from "./settingMenus";

const SettingsPage = () => {
  const history = useHistory();
  const userData = useSelector((state) => state.login);
  let settingOptionsUI = [];
  let backLink = "/login";

  switch (userData.userTypeId) {
    case USER_TYPE.HOSPITAL_USER_TYPE:
      settingOptionsUI = hospitalSettings;
      backLink = "/hospital";
      break;

    case USER_TYPE.VET_USER_TYPE:
      settingOptionsUI = vetSettings;
      backLink = "/vet";
      break;

    case USER_TYPE.TECH_USER_TYPE:
      settingOptionsUI = techSettings;
      backLink = "/tech";
      break;

    case USER_TYPE.STUDENT_USER_TYPE:
      settingOptionsUI = studentSettings;
      backLink = "/student";
      break;

    default:
      break;
  }

  const redirectToSetting = (url) => {
    history.push(url);
  };

  const renderSettingItems = () => {
    return (
      <div className="settings-menus-container">
        {settingOptionsUI.map((itm) => {
          const { id, title, desc, url } = itm;
          return (
            <div
              id={id}
              key={id}
              className="settings-menus text-blue"
              onClick={() => redirectToSetting(url)}
            >
              <div
                className="h3-header d-flex justify-content-between align-items-center"
                name="settingsSubMenu"
              >
                {title}
                <div className="chevron-right"></div>
              </div>
              <div className="body-text pt-1">{desc}</div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <div className="inner-container sm-top">
        <Header />
        <div className="container position-relative">
          <Link
            to={backLink}
            className="lft-arrow-green-dark text-decoration-underline action-link"
          >
            Back to Dashboard
          </Link>
          <h1 className="page-header">Settings</h1>
          {renderSettingItems()}
        </div>
      </div>
    </>
  );
};

// eslint-disable-next-line no-restricted-exports
export default SettingsPage;
