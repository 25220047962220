import * as C from "../constants";
import { NavReasonModal } from "../reducer/NavigationReasonReducer";

export function setNavigationReasonModal(originalPath: string, modal: NavReasonModal) {
  return {
    type: C.SET_NAVIGATION_REASON_MODAL,
    payload: { originalPath, modal },
  };
}

export function clearNavigationReasonModal() {
  return {
    type: C.CLEAR_NAVIGATION_REASON_MODAL,
  };
}
