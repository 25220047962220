import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import PageLoader from "../../../../Common/PageLoader";
import { useShowZendeskWidget } from "../../../../Common/zendeskUtils";
import HeaderLogIn from "../../../../Login/HeaderLogIn";
import { useConferenceEvent } from "../../../hooks/useConferenceEvent";

import { EventSignUp } from "./EventSignUp";

export const EventSignUpContainer = () => {
  const { isLoading, isError } = useConferenceEvent();
  const history = useHistory();
  useShowZendeskWidget(false);

  useEffect(() => {
    if (isError) {
      history.push("/login");
    }
  }, [isError, history]);

  return isLoading || isError ? (
    <PageLoader />
  ) : (
    <div className="inner-container sm-top">
      <HeaderLogIn />
      <div className="container">
        <EventSignUp />
      </div>
    </div>
  );
};
