export const hospitalAccountBidEnablement = (hospitalId: number) =>
  `api/hospital/${hospitalId}/setting/account/shiftDetail/bidding`;

export const shiftBidEnablement = (shiftId: number) => `api/shifts/${shiftId}/biddingEnablement`;

export const getUserPromos = (userId: number) => `api/promo/userPromos/${userId}`;

export const promoTracking = "api/promoTracking";

export const dismissPromoTracking = (promoTrackingId: number) =>
  `api/promoTracking/updatePromoMessageSeen/${promoTrackingId}`;

export const approvePendingShiftRequests = () => `api/admin/users/approvePendingShiftRequests`;
export const rejectPendingShiftRequests = () => `api/admin/users/rejectPendingShiftRequests`;

export const allByPromo = (promoId: number) => `api/promoEligibleUser/allByPromo/${promoId}
`;

export const promoDetailsById = (promoId: number) => `api/promo/${promoId}`;

export const createPromo = () => `api/promo/`;

export const updatePromo = (promoId: number) => `api/promo/${promoId}`;

export const getAllPromos = () => `api/promo/all`;

export const getPastPromos = () => `api/promo/past`;

export const publishPromo = () => `api/promo/publish`;

export const stopPromo = () => `api/promo/stop`;

export const getVetShiftDetails = (shiftGroupId: number, vetId: number) =>
  `api/vet/shift/getLinkedShift?shiftGroupId=${shiftGroupId}&vetId=${vetId}`;

export const getTechShiftDetails = (shiftGroupId: number, techId: number) =>
  `api/tech/shift/getLinkedShift?shiftGroupId=${shiftGroupId}&techId=${techId}`;

export const sendTestCommunication = () => `api/promoEligibleUser/sendTestCommunication`;

export const pendingVetShifts = (vetId: number) => `api/admin/getPendingShifts/${vetId}`;

export const dismissPromoReminder = () => `api/promoTracking/dismissPromoReminder`;

export const setUserTestAccount = (userId: number) => `api/admin/user/${userId}/setTestAccount`;

export const getHospitalUserDetails = () => `api/enterprise/getHospitalUserDetails`;

export const getSearchHistory = (userId: number) => `api/user/${userId}/searchHistory`;
