import * as C from "../experimentConstants";

const initialState = {
  assignedExperimentVariants: null,
};

// eslint-disable-next-line default-param-last
const ExperimentReducer = (state = initialState, action) => {
  switch (action.type) {
    case C.GET_ASSIGNED_EXPERIMENT_VARIANTS:
      return {
        ...state,
      };

    case C.GET_ASSIGNED_EXPERIMENT_VARIANTS_SUCCESS:
      return {
        ...state,
        assignedExperimentVariants: action.payload,
      };

    case C.GET_ASSIGNED_EXPERIMENT_VARIANTS_FAIL:
      return {
        ...state,
        assignedExperimentVariants: action.payload,
      };

    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default ExperimentReducer;
