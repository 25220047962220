export const ADD_INTERNSHIP = "ADD_INTERNSHIP_REQUEST";
export const ADD_INTERNSHIP_SUCCESS = "ADD_INTERNSHIP_SUCCESS";
export const ADD_INTERNSHIP_FAIL = "ADD_INTERNSHIP_FAIL";

export const GET_MY_INTERNSHIPS = "GET_MY_INTERNSHIPS";
export const GET_MY_INTERNSHIPS_SUCCESS = "GET_MY_INTERNSHIPS_SUCCESS";
export const GET_MY_INTERNSHIPS_FAIL = "GET_MY_INTERNSHIPS_FAIL";

export const CONTACT_STUDENt = "CONTACT_STUDENt";
export const CONTACT_STUDENt_SUCCESS = "CONTACT_STUDENt_SUCCESS";
export const CONTACT_STUDENt_FAIL = "CONTACT_STUDENt_FAIL";

export const HIRE_STUDENT = "HIRE_STUDENT";
export const HIRE_STUDENT_SUCCESS = "HIRE_STUDENT_SUCCESS";
export const HIRE_STUDENT_FAIL = "HIRE_STUDENT_FAIL";

export const DECLINE_STUDENT = "DECLINE_STUDENT";
export const DECLINE_STUDENT_SUCCESS = "DECLINE_STUDENT_SUCCESS";
export const DECLINE_STUDENT_FAIL = "DECLINE_STUDENT_FAIL";

export const CLEAR_ROO_UNI_FEEDBACK_FLAGS = "CLEAR_ROO_UNI_FEEDBACK_FLAGS";

export const GET_STUDENT_DETAILS = "GET_STUDENT_DETAILS";
export const GET_STUDENT_DETAILS_SUCCESS = "GET_STUDENT_DETAILS_SUCCESS";
export const GET_STUDENT_DETAILS_FAIL = "GET_STUDENT_DETAILS_FAIL";

export const GET_INTERNSHIP_DETAILS = "GET_INTERNSHIP_DETAILS";
export const GET_INTERNSHIP_DETAILS_SUCCESS = "GET_INTERNSHIP_DETAILS_SUCCESS";
export const GET_INTERNSHIP_DETAILS_FAIL = "GET_INTERNSHIP_DETAILS_FAIL";
