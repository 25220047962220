import * as C from "../../../constants/vetConstants";

export function fetchVetSettingInformationAction(vetId, userTypeId) {
  return {
    type: C.GET_VET_SETTING_INFORMATION,
    vetId: vetId,
    userTypeId: userTypeId,
  };
}

export function updateVetBasicInfoAction(vetInformation) {
  return {
    type: C.UPDATE_VET_BASIC_INFO,
    payload: {
      vetInformation: vetInformation,
    },
  };
}

export function initializeVetSettingsAction() {
  return {
    type: C.INITIALIZE_VET_SETTINGS,
  };
}

export function getW9InfoAction(vetId, userId) {
  return {
    type: C.GET_VET_W9_INFO,
    vetId: vetId,
    userId: userId,
  };
}

export function updateW9Action(oW9) {
  return {
    type: C.UPDATE_VET_W9,
    payload: oW9,
  };
}

export function resetVetBasicInfoUpdateSuccessAction() {
  return {
    type: C.RESET_VET_BASIC_INFO_UPDATE_SUCCESS,
  };
}

export function resetVetW9UpdateSuccessAction() {
  return {
    type: C.RESET_VET_W9_UPDATE_SUCCESS,
  };
}

export function getBlockedHospitalListAction(data) {
  return {
    type: C.GET_BLOCKED_HOSPITAL_LIST,
    payload: data,
  };
}

export function removeBlockedHospitalAction(data) {
  return {
    type: C.REMOVE_BLOCKED_HOSPITAL,
    payload: data,
  };
}

export function blockHospitalAction(data) {
  return {
    type: C.BLOCK_HOSPITAL,
    payload: data,
  };
}
