import React from "react";

import { Button } from "@roo-dev/ui-components";

const RooButton = (props) => {
  const {
    title,
    name,
    type,
    className,
    primary,
    secondary,
    small,
    onClick,
    disabled,
    style,
    testId = "",
  } = props;

  return (
    <div className={`d-flex ${className || ""}`} style={style}>
      <Button
        title={title}
        name={name}
        type={type}
        data-testid={testId}
        buttonType={primary ? "primary" : "secondary"}
        size={small ? "small" : "regular"}
        disabled={disabled}
        onClick={onClick}
      />
    </div>
  );
};

// eslint-disable-next-line no-restricted-exports
export default RooButton;
