import * as C from "../../../constants/hospitalConstants";

export function fetchHospitalSettingInformationAction(payload) {
  return {
    type: C.FETCH_HOSPITAL_SETTING_INFO,
    payload,
  };
}

export function fetchMyAccountSettingInfoAction(payload) {
  return {
    type: C.FETCH_MY_ACCOUNT_SETTING_INFO,
    payload,
  };
}

export function updateMyAccountSettingInfoAction(payload) {
  return {
    type: C.UPDATE_MY_ACCOUNT_SETTING_INFO,
    payload,
  };
}

export function updateBillingEmails(data) {
  return {
    type: C.UPDATE_BILLING_EMAILS,
    payload: data,
  };
}

export function addHospitalCardAction(data) {
  return {
    type: C.ADD_HOSPITAL_CARD_REQUEST,
    payload: data,
  };
}

export function deleteHospitalCardAction(data) {
  return {
    type: C.DELETE_HOSPITAL_CARD_REQUEST,
    payload: data,
  };
}

export function updateHospitalSettingsAction(data) {
  return {
    type: C.UPDATE_HOSPITAL_SETTING_INFORMATION,
    payload: data,
  };
}

export function changePrimaryContactAction(data) {
  return {
    type: C.CHANGE_PRIMARY_CONTACT,
    payload: data,
  };
}

export function setHospitalDefaultCardAction(data) {
  return {
    type: C.SET_DEFAULT_CARD_REQUEST,
    payload: data,
  };
}

export function initializeHospitalSettingsAction() {
  return {
    type: C.INITIALIZE_HOSPITAL_SETTINGS,
  };
}

export function resetUpdateBillingEmailsFlag() {
  return {
    type: C.RESET_UPDATE_BILLING_EMAILS_FLAG,
  };
}
