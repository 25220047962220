import * as C from "../constants";

interface PanelReducerStateType {
  isShowPanel: boolean;
  panelType: string;
  data: any;
}

const initialState: PanelReducerStateType = {
  isShowPanel: false,
  panelType: "",
  data: null,
};
const PanelReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: { payload: { panel: string }; type: string }
) => {
  switch (action.type) {
    case C.SHOW_PANEL:
      return {
        ...state,
        isShowPanel: true,
        panelType: action.payload.panel,
        data: action.payload,
      };

    case C.HIDE_PANEL:
      return {
        ...state,
        isShowPanel: false,
        panelType: "",
        data: null,
      };
    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default PanelReducer;
