import * as C from "../../../constants/rooAdminConstants";

const initialState = {
  userId: null,
  email: "",
  isSignedUp: false,
  signUpResponse: null,
};

// eslint-disable-next-line default-param-last
const RegistrationRooAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case C.REGISTER_ROO_INTERNAL_USER_SUCCESS:
      return {
        ...state,
        userId: action.payload.userId,
        email: action.payload.email,
        isSignedUp: true,
      };

    case C.REGISTER_ROO_INTERNAL_USER_FAIL:
      return {
        ...state,
        isSignedUp: false,
        signUpResponse: action.payload.signUpResponse,
      };

    // case C.INITIALIZE_REGISTER_ROO_INTERNAL_USER_STATE:
    //   return {
    //     ...initialState,
    //   };

    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default RegistrationRooAdminReducer;
