import React from "react";

import { CheckboxGroup, FormFields, RadioGroup, Select, Toggle } from "@roo-dev/ui-components";

import { distanceFromHome } from "../../../constants/dropDownConstants";
import { ALERT_FREQUENCY_RADIOS } from "../../../constants/radiobuttonConstants";
import { Buttons } from "../../EditProfile/Buttons";

import { useFormContext } from "./vetTechShiftSettingsEditContext";

export const NewShiftPreferencesForm = () => {
  const {
    form: {
      frequency,
      distance,
      shiftsOnCertainDays,
      shiftsWithPriceIncrease,
      emergencyShifts,
      shiftsWithin24Hour,
    },
    onCancel,
    onSave,
    onChange,
  } = useFormContext();

  return (
    <>
      <FormFields columns={1}>
        <RadioGroup
          label="Alert frequency"
          value={frequency}
          radios={ALERT_FREQUENCY_RADIOS}
          onChange={(value) => onChange("frequency", parseInt(value))}
          singleColumn={true}
        />
        <Select
          label="Distance from home"
          options={distanceFromHome}
          value={distance}
          onChange={({ value }) => {
            onChange("distance", value);
          }}
          searchable
          testId="notifications-select-distanceFromHome"
        />
        <CheckboxGroup
          label="Shifts on certain days"
          onChange={(_e, updated) => {
            onChange("shiftsOnCertainDays", updated);
          }}
          checkboxes={shiftsOnCertainDays} // TODO
          name="isPreference"
        />
        <div>
          <Toggle
            label="Shifts with price increases"
            onChange={() => onChange("shiftsWithPriceIncrease", !shiftsWithPriceIncrease)}
            checked={shiftsWithPriceIncrease}
            testId="notifications-toggle-priceIncrease"
            isFullWidth
          />
          <Toggle
            label="Emergency shifts"
            onChange={() => onChange("emergencyShifts", !emergencyShifts)}
            checked={emergencyShifts}
            testId="notifications-toggle-emergencyShifts"
            isFullWidth
          />
          <Toggle
            label="Shifts within 24 hours"
            onChange={() => onChange("shiftsWithin24Hour", !shiftsWithin24Hour)}
            checked={shiftsWithin24Hour}
            testId="notifications-toggle-shiftsWithin24Hours"
            isFullWidth
          />
        </div>
      </FormFields>
      <Buttons onSave={onSave} onCancel={onCancel} testId="hospitalInfo" loading={false} />
    </>
  );
};
