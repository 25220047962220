import { useHistory, useLocation } from "react-router-dom";

import { REGISTRATION_PATHS } from "../service";

export const useRedirect = () => {
  const history = useHistory();
  const { search } = useLocation();

  const redirectToLogin = () => {
    history.push(REGISTRATION_PATHS.Login);
  };

  const redirectToLanding = () => {
    history.push({ pathname: REGISTRATION_PATHS.Landing, search });
  };

  const redirectToSignUp = () => {
    history.push({ pathname: REGISTRATION_PATHS.SignUp, search });
  };

  const redirectToLocation = () => {
    history.push({ pathname: REGISTRATION_PATHS.Location, search });
  };

  const redirectToLicense = () => {
    history.push({ pathname: REGISTRATION_PATHS.License, search });
  };

  const redirectToEducation = () => {
    history.push({ pathname: REGISTRATION_PATHS.Education, search });
  };

  const redirectToCalendly = () => {
    history.push({ pathname: REGISTRATION_PATHS.Calendly, search });
  };

  const redirectToVetPreferences = () => {
    history.push({ pathname: REGISTRATION_PATHS.VetPreferences, search });
  };

  const redirectToHireflix = () => {
    history.push({ pathname: REGISTRATION_PATHS.Hireflix, search });
  };

  const redirectToReferral = () => {
    history.push({ pathname: REGISTRATION_PATHS.Referral, search });
  };

  const redirectToVetHomepage = () => {
    history.push("/vet");
  };

  const redirectToTechHomepage = () => {
    history.push("/tech");
  };

  const redirectToOldStudentFlow = () => {
    history.push({ pathname: "/registerStudent", search });
  };

  const redirectToOldHospitalFlow = () => {
    history.push({ pathname: "/registerHospital", search });
  };

  return {
    redirectToLogin,
    redirectToLanding,
    redirectToSignUp,
    redirectToLocation,
    redirectToLicense,
    redirectToEducation,
    redirectToCalendly,
    redirectToVetPreferences,
    redirectToHireflix,
    redirectToReferral,
    redirectToVetHomepage,
    redirectToTechHomepage,
    redirectToOldStudentFlow,
    redirectToOldHospitalFlow,
  };
};
