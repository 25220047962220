import React from "react";
import { useSelector } from "react-redux";

import { ButtonTabs, Container, Heading, Text } from "@roo-dev/ui-components";

import HeaderLoggedIn from "../HeaderLoggedIn";

import { generatePoliciesPageStructure } from "./initPoliciesPage";

const PoliciesPage = () => {
  const user = useSelector((state: any) => state.login);
  const policiesPageStructure = generatePoliciesPageStructure(user.userTypeId);

  const panelContentArr: any = [];
  policiesPageStructure?.forEach((section: any, index) => {
    const { body, tabTitle } = section;
    const panelContent = (
      <>
        {body.map((bodyContent: any) => {
          const { title, content } = bodyContent;
          return (
            <>
              <Container>
                <Heading level={6}>{title}</Heading>
                {content?.map((paragraphAndBullets: any) => (
                  <>
                    <Text>
                      {paragraphAndBullets?.paragraph ? paragraphAndBullets.paragraph : null}
                      {paragraphAndBullets?.bullets && (
                        <ul className="mb-0">{paragraphAndBullets.bullets}</ul>
                      )}
                    </Text>
                  </>
                ))}
              </Container>
            </>
          );
        })}
      </>
    );
    panelContentArr.push({ index, name: tabTitle, panelContent });
  });

  return (
    <div className="inner-container sm-top">
      <HeaderLoggedIn />
      <div className="container">
        <article className="policies-wrapper">
          <Heading level={1}>Our Policies</Heading>
          <ButtonTabs defaultSelectedTabIndex={0} tabNameArr={panelContentArr} />
        </article>
      </div>
    </div>
  );
};

// eslint-disable-next-line no-restricted-exports
export default PoliciesPage;
