import * as Sentry from "@sentry/react";
import { call, put, takeEvery } from "redux-saga/effects";

import * as errorMessage from "../../../constants/errorMessage";
import "../../../helpers/InterceptFetch";
import * as actionTypes from "../experimentConstants";

function* getAssignedExperimentVariants(action) {
  try {
    // yield put({ type: SET_LOADER });
    const eCode = action.payload.eCode;
    const metroAreaId = action.payload.metroAreaId ? action.payload.metroAreaId : 0;
    const userId = action.payload.userId;
    const response = yield call(
      fetch,
      window.RooConfig.EXPERIMENT_API_URL + "/getAssignedExperimentVariants",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload),
      }
    );
    const data = yield response.json();
    // yield put({ type: UNSET_LOADER });
    if (data.assignedExperimentVariants) {
      yield put({
        type: actionTypes.GET_ASSIGNED_EXPERIMENT_VARIANTS_SUCCESS,
        payload: data.assignedExperimentVariants,
      });
    } else {
      yield put({
        type: actionTypes.GET_ASSIGNED_EXPERIMENT_VARIANTS_FAIL,
        payload: null,
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting experiment variants: " },
    });
    yield put({
      type: actionTypes.GET_ASSIGNED_EXPERIMENT_VARIANTS_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootExperimentSaga() {
  yield takeEvery(actionTypes.GET_ASSIGNED_EXPERIMENT_VARIANTS, getAssignedExperimentVariants);
}
