export const breakpoints = {
  mobile: 428,
  mobileLandscape: 480,
  tablet: 768,
  midTablet: 991,
  tabletLandscape: 1040,
  desktop: 1200,
  desktopLarge: 1500,
  desktopWide: 1920,
  smallMediaBreakpoint: 576,
  mediumMediaBreakpoint: 768,
  smallHeightBreakpoint: 800,
};
