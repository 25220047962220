import * as Sentry from "@sentry/react";
import posthog from "posthog-js";

import { UserType, UserTypeName, userTypeNames } from "../../constants/UserTypeConstants";

export type PosthogProperties = {
  userType?: UserTypeName;
  email?: string;
  fullName?: string;
  metroAreaId?: number;
  enterpriseId?: number;
  hospitalId?: number;
};

export type IdentifyPosthog = {
  userId: number;
  userType?: UserType;
  firstName?: string;
  lastName?: string;
  propertiesForFlags?: Omit<PosthogProperties, "userType">;
} & Omit<PosthogProperties, "userType">;

export const identifyPosthog = ({
  userId,
  userType,
  email,
  firstName,
  lastName,
  fullName,
  metroAreaId,
  enterpriseId,
  hospitalId,
  propertiesForFlags,
}: IdentifyPosthog) => {
  const properties: PosthogProperties = {};

  if (userType) {
    properties.userType = userTypeNames[userType];
  }

  if (email) {
    properties.email = email;
  }

  if (firstName && lastName) {
    properties.fullName = `${firstName} ${lastName}`;
  }

  if (fullName) {
    properties.fullName = fullName;
  }

  if (metroAreaId) {
    properties.metroAreaId = metroAreaId;
  }

  if (enterpriseId) {
    properties.enterpriseId = enterpriseId;
  }

  if (hospitalId) {
    properties.hospitalId = hospitalId;
  }

  try {
    if (userId <= 0) {
      throw new Error("identifyPosthog: userId is not valid");
    }

    posthog.identify(userId.toString(), properties);

    if (propertiesForFlags) {
      posthog.setPersonPropertiesForFlags(propertiesForFlags);
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { userId, properties, propertiesForFlags } });
  }
};
