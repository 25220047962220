import { useGetStates } from "../../../api/utility/getStates";
import { getStateOptions } from "../../EditProfile/service";

export const useStates = () => {
  const { isLoading, isSuccess, data } = useGetStates();

  return {
    stateOptions: isSuccess ? getStateOptions(data) : [],
    loading: isLoading,
  };
};
