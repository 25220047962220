import * as Sentry from "@sentry/react";
import "isomorphic-fetch";
import { call, put, takeEvery } from "redux-saga/effects";

import { checkDST } from "../../Common/dateUtils";
import { SET_TOAST_DATA } from "../../Common/Toasts/constants";
import * as calendarActions from "../../constants/calendar";
import * as errorMessage from "../../constants/errorMessage";
import * as hospitalActions from "../../constants/hospitalConstants";
import * as loaderActions from "../../constants/loader";
import { setDefaultShiftDetails } from "../../ProfilePanels/actions/actions";
import { CLOSE_PROFILE_PANEL } from "../../ProfilePanels/constants";

// --- SELECTORS
export function* getShiftDetailsAsync(action) {
  try {
    const { shiftGroupId, isFetchPricing, isFromPanel } = action.payload;
    const fetchPricingParam = isFetchPricing ? `&isFetchPricing=1` : "";
    const response = yield call(
      fetch,
      window.RooConfig.API_URL +
        `api/hospital/shift/getShiftDetailsById?shiftGroupId=${shiftGroupId}${fetchPricingParam}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const responseData = yield response.json();
    const data = [...responseData.data.data];

    yield put({ type: loaderActions.UNSET_LOADER });

    if (responseData.status_code === 200) {
      if (isFromPanel) {
        yield put(setDefaultShiftDetails(data[0]));
      } else {
        yield put({ type: calendarActions.GET_SHIFT_DETAILS_SUCCESS, payload: data });
      }
    } else {
      yield put({
        type: calendarActions.GET_SHIFT_DETAILS_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting shift details" } });
  }
}

export function* confirmVetRequestAsync(action) {
  try {
    if (action.payload.requestedShiftStartTime) {
      action.payload.isDST = checkDST(action.payload.shiftDate);
    }
    const oConfirm = {
      ...action.payload,
      userId: localStorage.getItem("userId"),
    };
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/hospital/shift/confirmShift",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(oConfirm),
      }
    );

    const data = yield response.json();
    if (data.status_code === 200) {
      yield put({ type: calendarActions.INITIALIZE_POPUP_STATE });
      yield put({ type: loaderActions.UNSET_LOADER });
      yield put({
        type: calendarActions.CONFIRM_VET_SHIFT_SUCCESS,
        payload: { status: data.status_code, data: data.data },
      });
      if (data?.data?.data?.hasPaymentFailed) {
        yield put({
          type: hospitalActions.UPDATE_HAS_PAYMENT_FAILED_FLAG,
          payload: { hasPaymentFailed: 1 },
        });
      }
    } else {
      yield put({ type: loaderActions.UNSET_LOADER });
      yield put({
        type: calendarActions.CONFIRM_VET_SHIFT_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while confirming vet request" } });
    yield put({ type: loaderActions.UNSET_LOADER });
    yield put({
      type: calendarActions.CONFIRM_VET_SHIFT_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* confirmVetWithoutMessageAsync(action) {
  try {
    const { isFromPanel } = action.payload;
    delete action.payload.isFromPanel;

    if (action.payload.requestedShiftStartTime) {
      action.payload.isDST = checkDST(action.payload.shiftDate);
    }
    const oConfirm = {
      ...action.payload,
      userId: localStorage.getItem("userId"),
    };
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/hospital/shift/confirmShift",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(oConfirm),
      }
    );

    const data = yield response.json();
    if (data.status_code === 200) {
      yield put({ type: calendarActions.INITIALIZE_POPUP_STATE });
      yield put({ type: loaderActions.UNSET_LOADER });
      if (isFromPanel) {
        yield put({
          type: calendarActions.CONFIRM_VET_SHIFT_FROM_PANEL_SUCCESS,
          payload: data,
        });
      } else {
        yield put({
          type: calendarActions.CONFIRM_VET_SHIFT_NO_MODAL_SUCCESS,
          payload: { status: data.status_code, data: data.data },
        });
      }
      if (data?.data?.data?.hasPaymentFailed) {
        yield put({
          type: hospitalActions.UPDATE_HAS_PAYMENT_FAILED_FLAG,
          payload: { hasPaymentFailed: 1 },
        });
      }
    } else {
      yield put({ type: loaderActions.UNSET_LOADER });
      yield put({
        type: calendarActions.CONFIRM_VET_SHIFT_NO_MODAL_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while confirming vet request" } });
    yield put({ type: loaderActions.UNSET_LOADER });
    yield put({
      type: calendarActions.CONFIRM_VET_SHIFT_NO_MODAL_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* renewShiftAsync(action) {
  try {
    const apiRoute = window.RooConfig.API_URL + "api/hospital/shift/renewShift";

    const response = yield call(fetch, apiRoute, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();
    yield put({ type: loaderActions.UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: calendarActions.RENEW_SHIFT_SUCCESS,
        payload: action.payload,
      });
    } else {
      yield put({
        type: calendarActions.RENEW_SHIFT_FAIL,
        payload: data.status_code,
        message: data.message,
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while renewing shift" } });
    yield put({
      type: calendarActions.RENEW_SHIFT_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* renewMultipleShiftsAsync(action) {
  try {
    const apiRoute = window.RooConfig.API_URL + "api/hospital/shift/renewMultipleShifts";

    const oUpdate = {
      aShifts: action.payload,
      userId: localStorage.getItem("userId"),
      hospitalId: localStorage.getItem("hospitalId"),
      email: localStorage.getItem("email"),
    };
    const response = yield call(fetch, apiRoute, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(oUpdate),
    });

    const data = yield response.json();
    if (data.status_code === 200) {
      yield put({ type: loaderActions.UNSET_LOADER });
      yield put({
        type: calendarActions.RENEW_MULTIPLE_SHIFTS_SUCCESS,
        payload: { status: data.status_code },
      });
    } else {
      yield put({
        type: calendarActions.RENEW_MULTIPLE_SHIFTS_FAIL,
        payload: data.status_code,
        message: data.message,
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while renewing multiple shifts" } });
    yield put({
      type: calendarActions.RENEW_MULTIPLE_SHIFTS_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* deleteShiftAsync(action) {
  try {
    const { isFromPanel, shiftDetails } = action.payload;

    const apiRoute = window.RooConfig.API_URL + "api/hospital/shift/deleteShift";

    const response = yield call(fetch, apiRoute, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(shiftDetails),
    });

    const data = yield response.json();
    yield put({ type: loaderActions.UNSET_LOADER });
    if (data.status_code === 200) {
      if (!shiftDetails.isFromAdmin) {
        yield put({ type: hospitalActions.FETCH_SHIFT_COUNT_NOTIFICATION_REQUEST });
        yield put({ type: hospitalActions.NOTIFICATION_MODAL_OPEN });
      }
      if (isFromPanel) {
        yield put({
          type: calendarActions.DELETE_SHIFT_FROM_PANEL_SUCCESS,
        });
      } else {
        yield put({
          type: calendarActions.DELETE_SHIFT_SUCCESS,
          payload: { status: data.status_code },
        });
      }
    } else {
      yield put({
        type: calendarActions.DELETE_SHIFT_FAIL,
        payload: data.status_code,
        message: data.message,
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while deleting shift" } });
    yield put({
      type: calendarActions.DELETE_SHIFT_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// --- tech related

export function* getTechShiftDetailsAsync(action) {
  try {
    const response = yield call(
      fetch,
      window.RooConfig.API_URL +
        "api/hospital/tech_shift/getShiftDetailsById?shiftGroupId=" +
        action.payload,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const responseData = yield response.json();

    const data = [...responseData.data.data];

    yield put({ type: loaderActions.UNSET_LOADER });

    if (responseData.status_code === 200) {
      yield put({ type: calendarActions.GET_TECH_SHIFT_DETAILS_SUCCESS, payload: data });
    } else {
      yield put({
        type: calendarActions.GET_TECH_SHIFT_DETAILS_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting tech shift details" },
    });
  }
}

export function* confirmTechRequestAsync(action) {
  try {
    const oConfirm = {
      ...action.payload,
      userId: localStorage.getItem("userId"),
    };
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/hospital/tech_shift/confirmShift",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(oConfirm),
      }
    );

    const data = yield response.json();
    if (data.status_code === 200) {
      yield put({ type: calendarActions.INITIALIZE_POPUP_STATE });
      yield put({ type: loaderActions.UNSET_LOADER });
      yield put({
        type: calendarActions.CONFIRM_TECH_SHIFT_SUCCESS,
        payload: { status: data.status_code, data: data.data },
      });
      if (data?.data?.data?.hasPaymentFailed) {
        yield put({
          type: hospitalActions.UPDATE_HAS_PAYMENT_FAILED_FLAG,
          payload: { hasPaymentFailed: 1 },
        });
      }
    } else {
      yield put({ type: loaderActions.UNSET_LOADER });
      yield put({
        type: calendarActions.CONFIRM_TECH_SHIFT_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while confirming tech request" } });
    yield put({ type: loaderActions.UNSET_LOADER });
    yield put({
      type: calendarActions.CONFIRM_TECH_SHIFT_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* confirmTechWithoutMessageAsync(action) {
  const { isFromPanel } = action.payload;
  delete action.payload.isFromPanel;
  try {
    const oConfirm = {
      ...action.payload,
      userId: localStorage.getItem("userId"),
    };
    const response = yield call(
      fetch,
      window.RooConfig.API_URL + "api/hospital/tech_shift/confirmShift",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(oConfirm),
      }
    );

    const data = yield response.json();
    if (data.status_code === 200) {
      // yield put({ type: calendarActions.INITIALIZE_POPUP_STATE });
      yield put({ type: loaderActions.UNSET_LOADER });
      if (isFromPanel) {
        yield put({
          type: calendarActions.CONFIRM_TECH_SHIFT_FROM_PANEL_SUCCESS,
        });
      } else {
        yield put({
          type: calendarActions.CONFIRM_TECH_SHIFT_NO_MODAL_SUCCESS,
          payload: { status: data.status_code, data: data.data },
        });
      }
      if (data?.data?.data?.hasPaymentFailed) {
        yield put({
          type: hospitalActions.UPDATE_HAS_PAYMENT_FAILED_FLAG,
          payload: { hasPaymentFailed: 1 },
        });
      }
    } else {
      yield put({ type: loaderActions.UNSET_LOADER });
      if (isFromPanel) {
        yield put({
          type: SET_TOAST_DATA,
          payload: {
            variant: "error",
            message: "Error",
            description: data.message || errorMessage.EXCEPTION_MESSAGE,
          },
        });
        yield put({ type: CLOSE_PROFILE_PANEL });
      } else {
        yield put({
          type: calendarActions.CONFIRM_TECH_SHIFT_NO_MODAL_FAIL,
          payload: { status: data.status_code, message: data.message },
        });
      }
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while confirming tech request" } });
    if (isFromPanel) {
      yield put({
        type: SET_TOAST_DATA,
        payload: {
          variant: "error",
          message: "Roo hoo!",
          description: errorMessage.EXCEPTION_MESSAGE,
        },
      });
      yield put({ type: CLOSE_PROFILE_PANEL });
    } else {
      yield put({ type: loaderActions.UNSET_LOADER });
      yield put({
        type: calendarActions.CONFIRM_TECH_SHIFT_NO_MODAL_FAIL,
        payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
      });
    }
  }
}

export function* deleteTechShiftAsync(action) {
  try {
    const { isFromPanel, shiftDetails } = action.payload;
    const apiRoute = window.RooConfig.API_URL + "api/hospital/tech_shift/deleteShift";

    const response = yield call(fetch, apiRoute, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(shiftDetails),
    });

    const data = yield response.json();
    yield put({ type: loaderActions.UNSET_LOADER });

    if (data.status_code === 200) {
      if (!shiftDetails.isFromAdmin) {
        yield put({ type: hospitalActions.FETCH_TECH_SHIFT_COUNT_NOTIFICATION_REQUEST });
        yield put({ type: hospitalActions.NOTIFICATION_MODAL_OPEN });
      }
      if (isFromPanel) {
        yield put({
          type: calendarActions.DELETE_TECH_SHIFT_FROM_PANEL_SUCCESS,
        });
      } else {
        yield put({
          type: calendarActions.DELETE_TECH_SHIFT_SUCCESS,
          payload: { status: data.status_code },
        });
      }
    } else {
      yield put({
        type: calendarActions.DELETE_TECH_SHIFT_FAIL,
        payload: data.status_code,
        message: data.message,
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while deleting tech shift" } });
    yield put({ type: loaderActions.UNSET_LOADER });
    yield put({
      type: calendarActions.DELETE_TECH_SHIFT_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// --- end tech related

export function* getCalendarNotesAsync(action) {
  try {
    const apiRoute =
      window.RooConfig.API_URL +
      "api/calendarNotes?year=" +
      action.payload.year +
      "&month=" +
      action.payload.month +
      "&hospitalId=" +
      action.payload.hospitalId;
    const response = yield call(fetch, apiRoute, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = yield response.json();

    yield put({ type: loaderActions.UNSET_LOADER });

    if (data.status_code === 200) {
      yield put({
        type: calendarActions.GET_CALENDAR_NOTES_SUCCESS,
        payload: { status: data.status_code, data: data.data },
      });
    } else {
      yield put({
        type: calendarActions.GET_CALENDAR_NOTES_FAIL,
        payload: data.status_code,
        message: data.message,
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting calendar notes" } });
    yield put({
      type: calendarActions.GET_CALENDAR_NOTES_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* declineRequestAsync(action) {
  const { isFromPanel } = action.payload;
  delete action.payload.isFromPanel;

  try {
    const apiRoute = window.RooConfig.API_URL + "api/hospital/shift/decline/request";
    const response = yield call(fetch, apiRoute, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();

    yield put({ type: loaderActions.UNSET_LOADER });

    if (data.status_code === 200) {
      if (isFromPanel) {
        yield put({
          type: calendarActions.DECLINE_REQUEST_FROM_PANEL_SUCCESS,
          payload: { isCounterBid: !!action.payload.counteroffer },
        });
      } else {
        yield put({
          type: calendarActions.DECLINE_REQUEST_SUCCESS,
          payload: { status: data.status_code, shiftRequest: action.payload },
        });
      }
      action.payload.onSuccessRedirectCallback?.();
    } else {
      if (isFromPanel) {
        yield put({
          type: SET_TOAST_DATA,
          payload: {
            variant: "error",
            message: "Error",
            description: data.message || errorMessage.EXCEPTION_MESSAGE,
          },
        });
        yield put({ type: CLOSE_PROFILE_PANEL });
      } else
        yield put({
          type: calendarActions.DECLINE_REQUEST_FAIL,
          payload: data.status_code,
          message: data.message,
        });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while declining request" } });
    if (isFromPanel) {
      yield put({
        type: SET_TOAST_DATA,
        payload: {
          variant: "error",
          message: "Error",
          description: errorMessage.EXCEPTION_MESSAGE,
        },
      });
      yield put({ type: CLOSE_PROFILE_PANEL });
    } else
      yield put({
        type: calendarActions.DECLINE_REQUEST_FAIL,
        payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
      });
  }
}

export function* declineTechRequestAsync(action) {
  const { isFromPanel } = action.payload;
  delete action.payload.isFromPanel;

  try {
    const apiRoute = window.RooConfig.API_URL + "api/hospital/tech_shift/decline/request";
    const response = yield call(fetch, apiRoute, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();

    yield put({ type: loaderActions.UNSET_LOADER });

    if (data.status_code === 200) {
      if (isFromPanel) {
        yield put({
          type: calendarActions.DECLINE_TECH_REQUEST_FROM_PANEL_SUCCESS,
        });
      } else {
        yield put({
          type: calendarActions.DECLINE_TECH_REQUEST_SUCCESS,
          payload: { status: data.status_code, shiftRequest: action.payload },
        });
      }
    } else {
      if (isFromPanel) {
        yield put({
          type: SET_TOAST_DATA,
          payload: {
            variant: "error",
            message: "Error",
            description: data.message || errorMessage.EXCEPTION_MESSAGE,
          },
        });
        yield put({ type: CLOSE_PROFILE_PANEL });
      } else {
        yield put({
          type: calendarActions.DECLINE_TECH_REQUEST_FAIL,
          payload: data.status_code,
          message: data.message,
        });
      }
    }
  } catch (error) {
    yield put({ type: loaderActions.UNSET_LOADER });

    Sentry.captureException(error, { extra: { context: "Error while declining tech request" } });
    if (isFromPanel) {
      yield put({
        type: SET_TOAST_DATA,
        payload: {
          variant: "error",
          message: "Error",
          description: errorMessage.EXCEPTION_MESSAGE,
        },
      });
      yield put({ type: CLOSE_PROFILE_PANEL });
    } else {
      yield put({
        type: calendarActions.DECLINE_TECH_REQUEST_FAIL,
        payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
      });
    }
  }
}

export function* getHospitalLatestShiftDetailsAsync(action) {
  try {
    const { hospitalId, shiftDate } = action.payload;
    let queryParams = `hospitalId=${hospitalId}`;
    if (shiftDate) queryParams = queryParams + `&shiftDate=${shiftDate}`;

    const response = yield call(
      fetch,
      window.RooConfig.API_URL + `api/hospital/getHospitalLatestShiftDetails?${queryParams}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const responseData = yield response.json();
    yield put({ type: loaderActions.UNSET_LOADER });
    if (responseData.status_code === 200) {
      yield put({
        type: calendarActions.GET_HOSPITAL_LATEST_SHIFT_DETAILS_SUCCESS,
        payload: responseData?.data,
      });
    } else {
      yield put({
        type: calendarActions.GET_HOSPITAL_LATEST_SHIFT_DETAILS_FAIL,
        payload: responseData?.data,
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting hospital latest shift details" },
    });
    yield put({ type: loaderActions.UNSET_LOADER });
    yield put({
      type: calendarActions.GET_HOSPITAL_LATEST_SHIFT_DETAILS_FAIL,
      payload: { status: error.status_code, message: error.message, latestShiftDetails: null },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootCalendarSaga() {
  yield takeEvery(calendarActions.GET_SHIFT_DETAILS, getShiftDetailsAsync);
  yield takeEvery(calendarActions.CONFIRM_VET_SHIFT_REQUEST, confirmVetRequestAsync);
  yield takeEvery(calendarActions.DELETE_SHIFT, deleteShiftAsync);
  yield takeEvery(calendarActions.RENEW_SHIFT, renewShiftAsync);
  yield takeEvery(calendarActions.RENEW_MULTIPLE_SHIFTS, renewMultipleShiftsAsync);

  yield takeEvery(calendarActions.GET_TECH_SHIFT_DETAILS, getTechShiftDetailsAsync);
  yield takeEvery(calendarActions.CONFIRM_TECH_SHIFT_REQUEST, confirmTechRequestAsync);
  yield takeEvery(calendarActions.DELETE_TECH_SHIFT, deleteTechShiftAsync);

  yield takeEvery(calendarActions.GET_CALENDAR_NOTES, getCalendarNotesAsync);
  yield takeEvery(calendarActions.DECLINE_REQUEST, declineRequestAsync);
  yield takeEvery(calendarActions.DECLINE_TECH_REQUEST, declineTechRequestAsync);

  yield takeEvery(calendarActions.CONFIRM_VET_SHIFT_NO_MODAL, confirmVetWithoutMessageAsync);
  yield takeEvery(calendarActions.CONFIRM_TECH_SHIFT_NO_MODAL, confirmTechWithoutMessageAsync);
  yield takeEvery(
    calendarActions.GET_HOSPITAL_LATEST_SHIFT_DETAILS,
    getHospitalLatestShiftDetailsAsync
  );
}
