import * as Sentry from "@sentry/react";
import "isomorphic-fetch";
import { call, put, select, takeEvery } from "redux-saga/effects";
import "../../../helpers/InterceptFetch";

import { showPromoShiftSignUpToast } from "../../../Common/Toasts/action/ToastActions";
import { SHIFT_REQUEST } from "../../../constants/brazeEventNameConstants";
import * as ErrorMessage from "../../../constants/errorMessage";
import { UNSET_LOADER } from "../../../constants/loader";
import * as C from "../../../constants/techConstants";
import { logBrazeCustomEvent } from "../../../helpers/brazeUtility";

// --- SELECTORS
export const getNumberProbationShiftsLocal = (state) => state.techDashboard.nProbationShifts;

function* fetchHospitalViewDetailsAsync(action) {
  try {
    const response = yield call(
      fetch,
      window.RooConfig.API_URL +
        `api/hospital/getHopsitalProfileInfoById?userType=${action.payload.userTypeId}&hospitalId=${action.payload.hospitalId} `,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = yield response.json();
    if (data.status_code === 200) {
      yield put({ type: C.TECH_FETCH_HOSPITAL_VIEW_PROFILE_SUCCESS, payload: data.data });
      yield fetchHospitalAvailableShiftsAsync(action);
      yield put({ type: UNSET_LOADER });
    } else {
      yield put({ type: C.TECH_FETCH_HOSPITAL_VIEW_PROFILE_FAIL });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while fetching hospital profile: " },
    });
    yield put({
      type: C.TECH_FETCH_HOSPITAL_VIEW_PROFILE_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* fetchHospitalAvailableShiftsAsync(action) {
  try {
    const isExcludeBlockedShifts = action.payload.isExcludeBlockedShifts || false;
    const response = yield call(
      fetch,
      window.RooConfig.API_URL +
        `api/tech/shift/getShiftsByHospitalId?hospitalId=${action.payload.hospitalId}&techId=${
          action.payload.techId
        }&maxTier=${localStorage.getItem(
          "tier"
        )}&isExcludeBlockedShifts=${isExcludeBlockedShifts}&latitude=${
          action.payload.latitude
        }&longitude=${action.payload.longitude}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = yield response.json();
    if (data.status_code === 200) {
      yield put({
        type: C.FETCH_HOSPITAL_AVAILABLE_TECH_SHIFT_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({ type: C.FETCH_HOSPITAL_AVAILABLE_TECH_SHIFT_FAIL });
    }
    yield put({ type: UNSET_LOADER });
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while fetching hospital available shifts: " },
    });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: C.FETCH_HOSPITAL_AVAILABLE_TECH_SHIFT_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* requestHospitalShiftAsync(action) {
  try {
    const nProbationShifts = yield select(getNumberProbationShiftsLocal);
    const isProbationLimit = nProbationShifts >= 5;

    if (!isProbationLimit) {
      const response = yield call(fetch, window.RooConfig.API_URL + "api/tech/shift/requestShift", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload),
      });

      logBrazeCustomEvent(SHIFT_REQUEST, { shift_id: action.payload.shiftId });

      const data = yield response.json();
      if (data.status_code === 200) {
        const requestMessage = data.message;
        if (requestMessage == "User blocked") {
          localStorage.setItem("isActive", 4);
          window.location.reload();
        } else {
          if (data.data.added === 0) {
            const { hospitalName, shiftDate } = action.payload;
            yield put({
              type: C.TECH_REQUEST_TECH_SHIFT_NOT_AVAILABLE,
              payload: { hospitalName, shiftDate, message: data.data.message },
            });
          } else {
            yield fetchHospitalAvailableShiftsAsync({
              type: C.FETCH_HOSPITAL_AVAILABLE_TECH_SHIFT_REQUEST,
              payload: data.data.data,
            });
            if (data.data.data.promoId) yield put(showPromoShiftSignUpToast());
          }
        }
      } else {
        yield put({
          type: C.REQUEST_AVAILABLE_TECH_SHIFT_FAIL,
          payload: { status: data.status_code, erroMessage: data.message },
        });
      }
    } else {
      // probation limit from local check so show error without api call
      yield put({
        type: C.REQUEST_AVAILABLE_TECH_SHIFT_FAIL,
        payload: { errorMessage: ErrorMessage.TECH_PROBATION_LIMIT },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while requesting hospital shift: " },
    });
    yield put({
      type: C.REQUEST_AVAILABLE_TECH_SHIFT_FAIL,
      payload: { status: error.status_code, errorMessage: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* fetchTechProfileAsync(action) {
  try {
    const response = yield call(
      fetch,
      window.RooConfig.API_URL +
        `api/tech/getTechInfoById?userType=${action.payload.userTypeId}&techId=${action.payload.techId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({ type: C.FETCH_TECH_PROFILE_SUCCESS, payload: data.data });
    } else {
      yield put({ type: C.FETCH_TECH_PROFILE_FAIL });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while fetching tech profile: " } });
    yield put({
      type: C.FETCH_TECH_PROFILE_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootTechProfileSaga() {
  yield takeEvery(C.TECH_FETCH_HOSPITAL_VIEW_PROFILE_REQUEST, fetchHospitalViewDetailsAsync);
  yield takeEvery(C.FETCH_HOSPITAL_AVAILABLE_TECH_SHIFT_REQUEST, fetchHospitalAvailableShiftsAsync);
  yield takeEvery(C.REQUEST_AVAILABLE_TECH_SHIFT_REQUEST, requestHospitalShiftAsync);
  yield takeEvery(C.FETCH_TECH_PROFILE_REQUEST, fetchTechProfileAsync);
}
