import * as errorMessages from "../../../constants/errorMessage";
import * as ActionTypes from "../../../constants/hospitalUsers";

const initialState = {
  isHospitalUserAdded: null,
  isHospitalUserUpdated: null,
  isHospitalUserDeleted: null,
  hospitalUserDetails: null,
  errMsg: "",
};

// eslint-disable-next-line default-param-last
const ManageHospitalUserDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ADD_HOSPITAL_USER_REQUEST:
    case ActionTypes.ADD_HOSPITAL_GROUP_ADMIN:
      return {
        ...state,
        isHospitalUserAdded: null,
      };

    case ActionTypes.ADD_HOSPITAL_USER_SUCCESS:
    case ActionTypes.ADD_HOSPITAL_GROUP_ADMIN_SUCCESS:
      return {
        ...state,
        isHospitalUserAdded: !!action.payload.isUserExist ? false : true,
        errMsg: !!action.payload.isUserExist
          ? errorMessages[action.payload?.errMsg]
            ? errorMessages[action.payload?.errMsg]
            : errorMessages.HOSPITAL_USER_EXIST
          : null,
      };

    case ActionTypes.ADD_HOSPITAL_USER_FAIL:
    case ActionTypes.ADD_HOSPITAL_GROUP_ADMIN_FAIL:
      return {
        ...state,
        isHospitalUserAdded: false,
        errMsg: errorMessages.EXCEPTION_MESSAGE,
      };

    case ActionTypes.CHECK_IS_USER_CAN_BE_DELETED_SUCCESS:
    case ActionTypes.CHECK_IS_USER_CAN_BE_DELETED_FAIL:
      return {
        ...state,
        checkIfUserCanBeDeleted: action?.payload?.checkIfUserCanBeDeleted,
      };

    case ActionTypes.CHECK_IS_USER_CAN_BE_DELETED:
      return {
        ...state,
        checkIfUserCanBeDeleted: null,
      };

    case ActionTypes.DELETE_HOSPITAL_USER_SUCCESS:
      return {
        ...state,
        isHospitalUserDeleted: !!action.payload.isPrimaryContact ? false : true,
        errMsg: action.payload.isPrimaryContact
          ? errorMessages.CANNOT_DELETE_PRIMARY_CONTACT
          : null,
      };

    case ActionTypes.DELETE_HOSPITAL_USER_FAIL:
      return {
        ...state,
        isHospitalUserDeleted: false,
        errMsg: errorMessages.EXCEPTION_MESSAGE,
      };

    case ActionTypes.GET_HOSPITAL_USER_DETAILS_REQUEST:
      return {
        ...state,
        hospitalUserDetails: null,
      };

    case ActionTypes.GET_HOSPITAL_USER_DETAILS_SUCCESS:
      return {
        ...state,
        hospitalUserDetails: action.payload,
      };

    case ActionTypes.EDIT_HOSPITAL_USER_DETAILS_SUCCESS:
      return {
        ...state,
        isHospitalUserUpdated: true,
      };

    case ActionTypes.EDIT_HOSPITAL_USER_DETAILS_FAIL:
      return {
        ...state,
        isHospitalUserUpdated: false,
        errMsg: errorMessages.EXCEPTION_MESSAGE,
      };

    case ActionTypes.INITIALIZE_EDIT_HOSPITAL_USER_LIST:
      return {
        ...state,
        hospitalUserDetails: null,
        isHospitalUserAdded: null,
        isHospitalUserUpdated: null,
        isHospitalUserDeleted: null,
        isUserExist: false,
        errMsg: "",
        checkIfUserCanBeDeleted: null,
      };

    default:
      return {
        ...state,
      };
  }
};

// eslint-disable-next-line no-restricted-exports
export default ManageHospitalUserDetailsReducer;
