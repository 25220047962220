import { UsersList } from "../../../api/admin/users/vets";
import { ADMIN_PAYMENT_TO_VET_SUCCESS } from "../../../constants/confirmMessage";
import * as C from "../../../constants/contractorBonus";
import { ContractorBonusAction } from "../actions/ContractorBonusActions";

const initialState: {
  usersList: UsersList;
  isActionCompleted: boolean;
  isPaymentSuccess: boolean;
  actionMessage: string;
} = {
  usersList: [],
  isActionCompleted: false,
  isPaymentSuccess: false,
  actionMessage: "",
};

// eslint-disable-next-line default-param-last
const contractorBonusReducer = (state = initialState, action: ContractorBonusAction) => {
  switch (action.type) {
    case C.ADMIN_GET_USER_REQUEST:
      return {
        ...state,
        usersList: [],
        isActionCompleted: false,
        isPaymentSuccess: false,
        actionMessage: "",
      };
    case C.ADMIN_GET_USER_SUCCESS:
      return {
        ...state,
        usersList: action.payload,
      };
    case C.ADMIN_PAY_CONTRACTOR_BONUS_FAIL:
      return {
        ...state,
        isPaymentSuccess: false,
        isActionCompleted: true,
        actionMessage: action.payload.message,
      };
    case C.ADMIN_PAY_CONTRACTOR_BONUS_SUCCESS:
      return {
        ...state,
        isPaymentSuccess: true,
        isActionCompleted: true,
        actionMessage: ADMIN_PAYMENT_TO_VET_SUCCESS,
      };

    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default contractorBonusReducer;
