import * as C from "../../constants/registerTechConstants";

type TechData = {
  email: string;
  referralCodeId: string;
  userTypeId: number | null;
  fullName: string;
  lastName: string;
  address: string;
  aptUnitSuite: string;
  city: string;
  stateId: string;
  zipcode: number | null;
  mobilePhoneNumber: string;
  hearAboutRoo: string;
  heardFromWordOfMouth: string;
  techSchool: string;
  degreeCertificate: string;
  graduationYear: number;
  yearsOfPractice: string;
  isLicensed: boolean | null;
  isCertified: boolean | null;
  isRegistered: boolean | null;
  credentialStateIds: string[];
  specializationIds: string[];
  skillIds: string[];
  softwareIds: string[];
  practiceManagementSoftware: string;
  equipmentIds: string[];
  otherEquipment: string;
  appointmentFlow: number | null;
  freeDaysInMonthToWork: number | null;
  techAvailability: number | null;
  wantsFullTime: string;
  vetRelationship: boolean | null;
  techReferralCode: string;
  userName: string;
};

type RegistrationTechState = {
  techData: TechData;
  password: string;
  accountCreated: boolean;
  isBasicInfoFetched: boolean;
  isEducationExpFetched: boolean;
  isGetToKnowFetched: boolean;
  isPhotoUploadCompleted: boolean;
  isProfileCompleted: boolean;
  createAccountServer: any;
  states: string[];
  imageUploaded: boolean;
  techImage: any[];
  email: string | null;
  isBasicInfoCompleted: boolean;
  isEducationExpCompleted: boolean;
  isGetToKnowCompleted: boolean;
  isReducerEduSave: boolean;
  isReducerGetToKnowSave: boolean;
  techResume: any[];
};

const initialState: RegistrationTechState = {
  techData: {
    email: "",
    referralCodeId: "",
    userTypeId: null,
    fullName: "",
    lastName: "",
    address: "",
    aptUnitSuite: "",
    city: "",
    stateId: "",
    zipcode: null,
    mobilePhoneNumber: "",
    hearAboutRoo: "",
    heardFromWordOfMouth: "",
    techSchool: "",
    degreeCertificate: "",
    graduationYear: null,
    yearsOfPractice: "",
    isLicensed: null,
    isCertified: null,
    isRegistered: null,
    credentialStateIds: [],
    specializationIds: [],
    skillIds: [],
    softwareIds: [],
    practiceManagementSoftware: "",
    equipmentIds: [],
    otherEquipment: "",
    appointmentFlow: null,
    freeDaysInMonthToWork: null,
    techAvailability: null,
    wantsFullTime: "",
    vetRelationship: null,
    techReferralCode: "",
    userName: "",
  },
  password: "",
  accountCreated: false,
  isBasicInfoFetched: false,
  isEducationExpFetched: false,
  isGetToKnowFetched: false,
  isPhotoUploadCompleted: false,
  isProfileCompleted: false,
  createAccountServer: {
    statusCode: null,
    message: "",
  },
  states: [],
  imageUploaded: false,
  techImage: [],
  email: null,
  isBasicInfoCompleted: false,
  isEducationExpCompleted: false,
  isGetToKnowCompleted: false,
  isReducerEduSave: false,
  isReducerGetToKnowSave: false,
  techResume: [],
};

// eslint-disable-next-line default-param-last
const RegistrationTechReducer = (state: RegistrationTechState = initialState, action: any) => {
  switch (action.type) {
    case C.COMPLETE_TECH_PROFILE_SUCCESS:
      localStorage.setItem("isActive", "0");
      localStorage.setItem("isRegistrationComplete", "1");
      return {
        ...state,
        techData: {
          ...state.techData,
          techReferralCode: action.payload.referralCode,
        },
        isProfileCompleted: true,
      };

    case C.COMPLETE_TECH_PROFILE_FAIL:
      return {
        ...state,
        isProfileCompleted: false,
      };
    case C.FETCH_STATES_SUCCESS:
      return {
        ...state,
        states: [...action.payload.states],
      };

    case C.FETCH_TECH_HIREFLIX_LINK_SUCCESS:
      return {
        ...state,
        techData: {
          ...state.techData,
          hireflixLink: action.payload.hireflixLink,
        },
      };

    // --- RESUME
    case C.DELETE_TECH_RESUME_FAIL:
    case C.UPLOAD_TECH_RESUME_FAIL:
      return {
        ...state,
        techResume: [...state.techResume],
      };

    case C.UPLOAD_TECH_RESUME_SUCCESS:
    case C.DELETE_TECH_RESUME_SUCCESS:
      return {
        ...state,
        techResume: action.payload.techResume,
      };

    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default RegistrationTechReducer;
