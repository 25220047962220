import { useQuery } from "@tanstack/react-query";
import { useLocation, useParams } from "react-router-dom";

import { getConferenceEventBySlug } from "../../api/admin/conferenceEvent/getConferenceEvent";

export interface UseConferenceEvent {
  eventSlug: string;
  booth: boolean;
  utmSource?: string;
  utmCampaign?: string;
  utmMedium?: string;
  isLoading: boolean;
  isError: boolean;
  hospitalHubspotFormId: string | null;
}

export const useConferenceEvent = (): UseConferenceEvent => {
  const { eventSlug } = useParams<{ eventSlug: string }>();
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const { isLoading, isError, data } = useQuery({
    queryKey: [`getConferenceEventBySlug:${getConferenceEventBySlug}`],
    queryFn: () => getConferenceEventBySlug(eventSlug),
  });

  return {
    eventSlug,
    booth: params.get("booth") === "true",
    utmSource: params.get("utm_source") || undefined,
    utmCampaign: params.get("utm_campaign") || undefined,
    utmMedium: params.get("utm_medium") || undefined,
    isLoading,
    isError,
    hospitalHubspotFormId: data?.data?.event?.hospitalHubspotFormId || null,
  };
};
