import * as C from "../../../constants/adminPromos";

const initialState = {
  pastPromos: [],
  userList: [],
  promoDetails: {},
  errorMsg: "",
  isCreatePromo: false,
};

// eslint-disable-next-line default-param-last
const promosReducer = (state = initialState, action) => {
  switch (action.type) {
    case C.ADMIN_GET_USER_LIST_BY_PROMO_ID_SUCCESS:
      return {
        ...state,
        userList: action.payload,
      };
    case C.ADMIN_GET_USER_LIST_BY_PROMO_ID_FAIL:
      return {
        ...state,
        userList: [],
      };
    case C.ADMIN_GET_PROMO_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        promoDetails: action.payload,
        isPromoDetailsLoaded: true,
      };
    case C.ADMIN_GET_PROMO_DETAILS_BY_ID_FAIL:
      return {
        ...state,
        promoDetails: [],
        isPromoDetailsLoaded: false,
      };
    case C.ADMIN_CLEAR_PROMO_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        isPromoDetailsLoaded: false,
      };
    case C.ADMIN_GET_UPLOADED_USER_LIST_SUCCESS:
      return {
        ...state,
        uploadedUserList: action.payload,
      };
    case C.ADMIN_GET_UPLOADED_USER_LIST_FAIL:
      return {
        ...state,
        uploadedUserList: [],
      };
    case C.CLEAR_ERROR_MSG:
      return {
        ...state,
        errorMsg: "",
      };
    case C.MANUAL_PROMO_PAYOUT_SUCCESS:
      return {
        ...state,
        isPromoPayoutSuccess: true,
      };
    case C.MANUAL_PROMO_PAYOUT_FAIL:
      return {
        ...state,
        isPromoPayoutSuccess: false,
        errorMsg: action.payload.message,
      };
    case C.CLEAR_MANUAL_PAYOUT_SUCCESS:
      return {
        ...state,
        isPromoPayoutSuccess: false,
      };
    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default promosReducer;
